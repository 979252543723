import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal, openModal } from "../../store/actions/modalAction"
import { Box, CircularProgress, IconButton, Switch, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { Delete, Edit, ExpandCircleDownOutlined, Info, More, MoreHoriz } from "@mui/icons-material"


import getLoanBenefitApi, { deleteLoanBenefitField } from "../../apis/loanbenefit.api"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import MessageDilog from "../../components/MessageDilog"
import getComplainCategoryApi, { deleteComplainCategoryField } from "../../apis/complaincategory.api"

const DeleteButton = memo(({ params, setParams }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const onDelete = (e) => {
        e?.preventDefault()
        dispatch(closeModal())
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteComplainCategoryField({ id: params?._id }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))

    }

    const onClick = () => {
        dispatch(openModal(<MessageDilog title="Alert!" message="Are you sure to delete?" onSubmit={onDelete} />, "xs"))
    }

    if (loading)
        return <CircularProgress />
    return <IconButton onClick={onClick}>
        <Delete color="error" />
    </IconButton>
})
const ActionComponent = memo(({ params, setParams }) => {
    const dispatch = useDispatch()





    const onEdit = () => {
        dispatch(openModal(<ListController parent={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm", undefined, MODAL_KEYS.COMPLAIN_CATEGORY_LIST))
    }


    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      {!params.parent &&  <IconButton size="inherit" onClick={onEdit}>
            <Info color="info" fontSize="inherit" />
        </IconButton>}
        <DeleteButton params={params} setParams={setParams} />
    </Box>
})



const ListController = ({ parent }) => {
    const dispatch = useDispatch()


const modalKey = MODAL_KEYS.COMPLAIN_CATEGORY_LIST
    const title = parent ? "Complain Sub Category" : "Complain Category"
    const fetchApi = getComplainCategoryApi



    const columns = useMemo(() => [

        { id: 1, fieldName: 'title', label: 'Category', align: "left", sort: true },



        {
            id: 5,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent params={params} setParams={setParams} />,
        },

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        parent,
        searchable: ['title'],
        sort: 'createdAt',
        sortDirection: 1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController parent={parent} callBack={async () => { fetchList() }} />, "sm", undefined, MODAL_KEYS.COMPLAIN_CATEGORY_CREATE))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
            modalKey={modalKey}
                modal={parent && parent!=''}
                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController