import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";




export const getProjectsApi = async params => {
    const callResponse = await axios({
        url: endpoints.projectBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const getProjectCountsApi = async params => {
    const callResponse = await axios({
        url: endpoints.projectCounts,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateProjectInstallerApi = async data => {
    const callResponse = await axios({
        url: endpoints.projectUpdateInstaller,
        method: "PUT",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const getProjectsStockApi = async params => {
    const callResponse = await axios({
        url: endpoints.projectStock,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const getStockItemsApi = async params => {
    const callResponse = await axios({
        url: endpoints.projectStockItems,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};


export const getProjectsByIdApi = async params => {
    const { id } = params
    const callResponse = await axios({
        url: endpoints.projectById  + id,
        method: "get",
        headers: getHeaders(),
        // params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const getProjectsFilesByIdApi = async params => {
    const { id } = params
    const callResponse = await axios({
        url: endpoints.projectFiles + "/" + id,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const getStoredProjectsFilesByIdApi = async params => {
    
    const callResponse = await axios({
        url: endpoints.projectFiles,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const uploadStoredProjectsFilesByIdApi = async data => {
    
    const callResponse = await axios({
        url: endpoints.projectFiles,
        method: "post",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateProjectsFilesApi = async data => {
    
    const callResponse = await axios({
        url: endpoints.projectFiles,
        method: "put",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const deleteProjectsFilesApi = async data => {
    
    const callResponse = await axios({
        url: endpoints.projectFiles,
        method: "delete",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};


export const uploadJobToStcApi = async data => {
    
    const callResponse = await axios({
        url: endpoints.jobToStc,
        method: "post",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};


export const addProjectsApi = async data => {
    const callResponse = await axios({
        url: endpoints.projectBase,
        method: "POST",
        headers: getHeaders(),

        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const addProjectNotesApi = async data => {
    const callResponse = await axios({
        url: endpoints.projectNoteCreate,
        method: "POST",
        headers: getHeaders(),

        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const getProjectNotesApi = async params => {
    const callResponse = await axios({
        url: endpoints.projectNoteCreate,
        method: "GET",
        headers: getHeaders(),

        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};


export const getProjectLogsApi = async params => {
    const callResponse = await axios({
        url: endpoints.projectLogBase,
        method: "GET",
        headers: getHeaders(),

        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};


export const updateProjectsField = async data => {
    const callResponse = await axios({
        url: endpoints.projectBase,
        method: "PATCH",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateProjectsFlags = async data => {
    const callResponse = await axios({
        url: endpoints.projectFlag,
        method: "PATCH",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const selectProjectSystem = async data => {
    const callResponse = await axios({
        url: endpoints.projectSelectSystem,
        method: "PATCH",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const getProjectSystems = async params => {
    const callResponse = await axios({
        url: endpoints.projectSystems,
        method: "GET",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};



export const getPoDownloadsApi = async params => {
    const callResponse = await axios({
        url: endpoints.projectPOId+"/"+params?.id,
        method: "get",
        headers: getHeaders(),
        responseType: "blob",
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};



export const getProjectDocumentsApi = async params => {
    const callResponse = await axios({
        url: endpoints.projectDocumentBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const getProjectDocumentsTypeApi = async params => {
    const callResponse = await axios({
        url: endpoints.projectAvailableDocumentTypes,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const updateProjectDocumentsApi = async data => {
    const callResponse = await axios({
        url: endpoints.projectDocumentBase,
        method: "PUT",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const deleteProjectDocumentsApi = async data => {
    const callResponse = await axios({
        url: endpoints.projectDocumentBase,
        method: "PUT",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const createProjectDocumentsApi = async data => {
    const callResponse = await axios({
        url: endpoints.projectDocumentBase,
        method: "POST",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const downloadJobPackApi = async params => {
    const callResponse = await axios({
        url: endpoints.projectJobPackDownload,
        method: "GET",
        headers: getHeaders(),
        responseType: "blob",
        params,
    })
        .then(response => {
            
            return response.data
        })
        .catch(async err => {
           
            const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
            const responseData = isJsonBlob(err.response.data) ? await (err.response.data)?.text() : err.response.data || {};
            const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
    
            
           
            return responseJson
        });

    return callResponse;
};



































export default getProjectsApi