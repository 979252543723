import { memo, useEffect, useMemo, useState } from "react"
import EditAddressUI from "./EditAddressUI"
import useValidate from "../../store/hooks/useValidator"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal } from "../../store/actions/modalAction"
import {  LEAD_STATUS } from "../../utils/constants/lead.constant"
import { getAutoComplete, getLatAndLon } from "../../apis/googlemap.api"
import { updateLeadStatusApi } from "../../apis/lead.api"

const EditAddressController = ({ id, callback,addressInitial }) => {
    const title = "Add Location"
    const remarks = "latLonAdded"
    const initial = addressInitial
    const validate = useValidate()
    const dispatch = useDispatch()
    const autoCompleteApi = getAutoComplete
    const getLatLonApi = getLatAndLon
    const updateLatLon = updateLeadStatusApi;
    const [loading, setLoading] = useState(false)
    const [autoCompleteloading, setautoCompleteLoading] = useState(false)

    const [search, setSearch] = useState("")
    const [autoCompleteobj, setAutoCompleteObj] = useState("");
    const [placeId, setPlaceId] = useState("")
    const [lat, setLat] = useState("")
    const [lon, setLon] = useState("")
    const [address,setAddress] = useState("")
    const [assigned_to,setAssign_to ] = useState("")
    const [state, setstate] = useState("")
    const [postCode, setpostCode] = useState("")
    const status = LEAD_STATUS.UNHANDLED

    const autoComplete = (searchString) => {
        if (searchString.length > 4) {
            setautoCompleteLoading(true)
            dispatch(
                callApiAction(
                    async () => await autoCompleteApi({ searchString }),
                    async (response) => {
                        setAutoCompleteObj(response)
                        setautoCompleteLoading(false)
                    },
                    (err) => {
                        setautoCompleteLoading(false)
                    }
                )
            )
        }
    }
    const setLatLon = (placeId) => {
        dispatch(
            callApiAction(
                async () => await getLatLonApi({ placeId }),
                async (response) => {
                    setpostCode(response.postCode)
                    setstate(response.state)
                    setLat(response.lat)
                    setLon(response.lon)
                },
                (err) => {
                    setLoading(false)
                }
            )
        )
    }

    const onsubmit = async ()=>{
        setLoading(true)
        
        dispatch(
            callApiAction(
                async () => await updateLatLon({id,status,lat,lon,address,remarks,assigned_to,customer_state: state, customer_postcode: postCode}),
                async (response) => {
                    // await callBack(response)
                    setLoading(false)
                    dispatch(closeModal())
                    window.location.reload(true)
                },
                (err) => {
                    setLoading(false)
                     
                }
            )
        )
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            if (search.length > 4)
                autoComplete(search)
        }, 500)

        return () => clearTimeout(timer)
    }, [search])
    useEffect(() => {
        if (placeId)
            setLatLon(placeId)

    }, [placeId])
    return <EditAddressUI autoCompleteloading={autoCompleteloading} loading={loading} title={title} setSearch={setSearch} initial={initial} autoCompleteobj={autoCompleteobj} setPlaceId={setPlaceId} address={address} setAddress={setAddress} onsubmit={onsubmit} assigned_to={assigned_to} setAssign_to={setAssign_to}/>
}
export default memo(EditAddressController)