import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { getLeadApi, getLeadCallsApi } from "../../../apis/lead.api"
import { Link, useParams } from "react-router-dom"

import { findObjectKeyByValue, getDateFiltersTime, leadStatusColor } from "../../../utils/helpers/helper"
import { Avatar, Badge, Box, Button, ButtonBase, Chip, IconButton, Tooltip } from "@mui/material"
import { useMemo } from "react"
import { Delete, Done, Edit } from "@mui/icons-material"
import { LEAD_CALL_STATUS, LEAD_CALL_TYPE, LEAD_STATUS, REPEAT_LEAD_TYPE } from "../../../utils/constants/lead.constant"
import moment from "moment"
import { openModal } from "../../../store/actions/modalAction"
import LeadCallStatusUpdateController from "./LeadCallStatusUpdateController"
import LeadStatusComponent from "../LeadStatusComponent"
import CreateController from "../CreateController"
import DetailedViewController from "../DetailedViewController"
import { localStatecallModal } from "../../../utils/helpers/lead.helper"

const ActionComponent = memo(({ params, setParams, isCall }) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)


    const onCompleteClick = () => {
        dispatch(openModal(<LeadCallStatusUpdateController
            callDetails={params}
            lead_status={params.lead_status}
            id={params._id}
            callBack={(response, lead_status) => {
                
                setParams({ ...params, status: LEAD_CALL_STATUS.CALLED,lead_status,current_lead_status:lead_status })
            }} />, "md"))
    }
    const onCall = () => {
        if (params.status == LEAD_CALL_STATUS.PENDING) {
            localStatecallModal().set({
                lead_status: params.lead_status, id: params._id, disableDirectClose: true
            })
            dispatch(openModal(<LeadCallStatusUpdateController
                callDetails={params}
                disableDirectClose={true}
                lead_status={params.lead_status}
                id={params._id}
                callBack={(response, lead_status) => {
                    
                    setParams({ ...params, status: LEAD_CALL_STATUS.CALLED,lead_status,current_lead_status:lead_status })
                }} />, "md", true))
        }
    }

    if (isCall) {
        if (params.status == LEAD_CALL_STATUS.PENDING)
            return <ButtonBase sx={{ color: "info.main" }} onClick={onCall} href={"tel:" + params.customer_phone} target="_blank" >

                <Badge color="error" badgeContent={params.repeated_with == REPEAT_LEAD_TYPE.PHONE ? " " : 0} variant="dot">
                    {params.customer_phone}
                </Badge>
            </ButtonBase>
        else
            return <Link to={"tel:" + params.customer_phone} target="_blank"><Badge color="error" badgeContent={params.repeated_with == REPEAT_LEAD_TYPE.PHONE ? " " : 0} variant="dot">
                {params.customer_phone}
            </Badge></Link>
    }

    if (params.status == LEAD_CALL_STATUS.PENDING)
        return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
            <Tooltip title="Mark as done">
                <IconButton disabled={loading} size="inherit" onClick={onCompleteClick}>
                    <Done color="success" fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </Box>
    else return "NA"
})
const OpenLeadButton = memo(({params, setParams})=>{
    const dispatch = useDispatch()
    const onClick = ()=>{
        dispatch(openModal(<DetailedViewController id={params.lead_id} />,"md"))
    }
    return <Button size="small" color="info" sx={{p:0}}  onClick={onClick}>{params.customer_name}</Button>
})


const ListController = () => {
    const dispatch = useDispatch()



    const title = "Lead Calls"
    const fetchApi = getLeadCallsApi


    const columns = useMemo(() => [
        { id: 0, sort: true, minWidth: 150, fieldName: 'scheduled_date', label: 'Call At ', align: "left", renderValue: (params, setParams) => params.scheduled_date && params.scheduled_date != '' ? moment(params.scheduled_date).format("DD/MM/YYYY HH:mm") : "NA" },
        { id: 1, fieldName: 'customer_name', minWidth: 150, label: 'Name', align: "left" ,renderValue:(params,setParams)=><OpenLeadButton params={params} setParams={setParams} />},
        {
            id: 3, fieldName: 'phone', label: 'Phone no.', align: "left", renderValue: (params, setParams) => <ActionComponent params={params} isCall={true} setParams={setParams} />
        },
        { id: 4, fieldName: 'customer_address', minWidth: 300, label: 'Address', align: "left" },
        {
            id: 11, fieldName: 'intial_remarks', minWidth: 150, label: 'Notes for call', align: "left"
        },

        {
            id: 5, fieldName: 'assigned_to', minWidth: 150, label: 'Assigned To', align: "left", renderValue: (params) => params.assigned_to && params.assigned_to.length > 0 ?
                params.assigned_to[0].name
                : "NA",
        },
        {
            id: 7,
            fieldName: 'call_type',
            label: 'Call Type',
            minWidth: 100,
            sort: true,
            align: "left",
            renderValue: (params, setParams) => <Chip label={findObjectKeyByValue(params.call_type, LEAD_CALL_TYPE)} color={"info"} sx={{ lineHeight: "100% !important" }} size="small" />,
        },
        {
            id: 7,
            fieldName: 'status',
            label: 'Call Status',
            minWidth: 200,
            sort: true,
            align: "left",
            renderValue: (params, setParams) => <Chip label={findObjectKeyByValue(params.status, LEAD_CALL_STATUS)} color={params.status == LEAD_CALL_STATUS.PENDING ? "warning" : "success"} sx={{ lineHeight: "100% !important" }} size="small" />,
        },


        // {
        //     id: 9, fieldName: 'customer_response', minWidth: 150, label: 'Notes after call', align: "left"
        // },
        {
            id: 10, fieldName: 'initial_lead_status', minWidth: 150, label: 'Lead Status Before Call', align: "left",
            renderValue: (params, setParams) => <LeadStatusComponent params={{
                status: params.initial_lead_status
            }} onlyview />,
        },
        {
            id: 10, fieldName: 'current_lead_status', minWidth: 150, label: 'Current Lead Status', align: "left",
            renderValue: (params, setParams) => <LeadStatusComponent params={{
                status: params.current_lead_status
            }} onlyview />,
        },
        {
            id: 10, fieldName: 'lead_status', minWidth: 150, label: ' Lead Status After Call', align: "left",
            renderValue: (params, setParams) => <LeadStatusComponent params={{
                status: params.lead_status
            }} onlyview />,
        },

        {
            id: 8,
            fieldName: 'action',
            label: 'Actions',
            align: "left",
            renderValue: (params, setParams) => <ActionComponent params={params} setParams={setParams} />,
        },


    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['customer_name', 'customer_phone', 'customer_address'],
        source: '',
        status: LEAD_CALL_STATUS.PENDING,
        sort: 'createdAt',
        sortDirection: -1,
        tab: 0

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    useEffect(() => {
        if (localStatecallModal().get()) {
            const props = localStatecallModal().get()

            dispatch(openModal(<LeadCallStatusUpdateController {...props} />, "md", true))

        }
    }, [])


    useEffect(() => {
        fetchList()
    }, [filters])


    const onTabChange = (e, value) => {

        const allowedDurations = ['', 'today', 'past', 'future']
        setFilters({ ...filters, tab: value, ...getDateFiltersTime(allowedDurations[value]) })
    }
    const onCreateBtnClick = (e) => {
        dispatch(openModal(<CreateController callBack={async () => { fetchList() }} />, "sm"))
    }
    return (
        <>
            <ListUi
                title={title}

                onTabChange={onTabChange}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}
                onCreateBtnClick={onCreateBtnClick}

            />

        </>
    )
}
export default ListController