
import { Paper, Typography, Skeleton } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"



import { CalendarItem } from "./CalendarInnerBox"
import { getStatusOfProjectFlags, useProjectStatusColor } from "../../../utils/helpers/project.helper"
import { viewLimitedLengthName } from "../../../utils/helpers/helper"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import NoDataComponent from "../../../components/layouts/NoDataComponent"
import { openModal } from "../../../store/actions/modalAction"
import ProjectDetailsController from "../projectdetails/ProjectDetailsController"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import { DragPreviewImage, useDrag, useDragLayer, useDrop } from "react-dnd"
import { useRef } from "react"
import { getEmptyImage } from "react-dnd-html5-backend"
import ProjectUpdateFlagsController from "../flags/ProjectUpdateFlagsController"
import { PROJECT_FLAGS } from "../../../utils/constants/project.constant"
import moment from "moment"

const CustomDragLayer = () => {

    const projectStatusColor = useProjectStatusColor()
    const { item, isDragging, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        isDragging: monitor.isDragging(),
        currentOffset: monitor.getSourceClientOffset(),
    }));

    if (!isDragging || !currentOffset) {
        return null;
    }

    const { x, y } = currentOffset;
    return (
        <div style={{ pointerEvents: 'none', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 11111 }}>

            <Box sx={{ width: "200px" }}>
                <Box sx={{ transform: `translate(${x}px, ${y}px) rotate(-5deg)` }}>
                    <CalendarItem
                        color={projectStatusColor(item.status).bgColor}

                        completed={isDragging}
                    >
                        <Typography sx={{ pointerEvents: "none" }} variant="caption" lineHeight="120%" >{viewLimitedLengthName(item.name, 15)}</Typography>
                    </CalendarItem>
                </Box>
            </Box>
        </div>
    );
};
const Item = ({ status, _id, name ,onSchduleInstallation}) => {
    const ref = useRef(null);
    const dispatch = useDispatch()
    const projectStatusColor = useProjectStatusColor()

    const [, drop] = useDrop({
        accept: "installation_calendar",

    });
    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: 'installation_calendar',
            item: { _id, status, name },
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult();
                if (dropResult?.date)
                    dispatch(openModal(<ProjectUpdateFlagsController
                        callStart={() => { }}
                        callBack={() => {
                            onSchduleInstallation(_id)
                         }}
                        title={`Schedule Installtion For "${name}"`}
                        id={item?._id}
                        flag={PROJECT_FLAGS.INSTALLATION}
                        flag_value={getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).SCHEDULED}
                        defaultFilters={{
                            installtion_date: moment(dropResult?.date).toISOString()
                        }}
                    />,
                        'sm',
                        undefined,
                        MODAL_KEYS.UPDATE_PROJECT_FLAGS
                    ))
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [dispatch],
    )



    const onClick = (id) => {
        dispatch(openModal(<ProjectDetailsController project={id} />, 'xl', undefined, MODAL_KEYS.PROJECT))
    }
    drag(drop(ref));



    return <>
        <Box
            sx={{ opacity: isDragging ? 0.1 : 1 }}
            ref={ref} onClick={() => { onClick(_id) }} >
            <CalendarItem
                color={projectStatusColor(status).bgColor}
                completed={isDragging}
            >
                <Typography sx={{ pointerEvents: "none" }} variant="caption" lineHeight="120%" >{viewLimitedLengthName(name, 15)}</Typography>
            </CalendarItem>
        </Box>
        <Box ref={preview} />
    </>
}


const ReadyToInstallerProjectsUi = ({  list, loading ,onSchduleInstallation}) => {

    return (
        <>


            <Paper elevation={0} variant="outlined" sx={{ width: "100%", height: "100%", flexDirection: "column", display: "flex", overflow: "hidden" }} >
                <Box p={2} sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Typography>
                        Ready To Install Projects
                    </Typography>
                    <Box>
                        <Typography>

                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ width: "100%", padding: 2, height: "100%", overflow: "scroll" }} >
                    {
                        loading && [0, 1, 1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => <Box width="100%" key={index} mb={1}>
                            <Skeleton variant="rounded" height={"20px"} />
                        </Box>)
                    }
                    {
                        !loading && list.length > 0 && list.map((item) => <Item onSchduleInstallation={onSchduleInstallation} status={item?.status} name={item?.contacts?.[0]?.name} _id={item?._id} key={item?._id} />)
                    }
                    {
                        !loading && list.length == 0 && <CenteredBox>
                            <NoDataComponent message="Empty List" />
                        </CenteredBox>
                    }
                </Box>
            </Paper >

            <CustomDragLayer />
        </>
    )
}
export default ReadyToInstallerProjectsUi