import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { addProjectNotesApi, getProjectNotesApi } from "../../../apis/projects.api"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants/constants"
import { Box, Button, ButtonGroup, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography, useTheme } from "@mui/material"
import CustomInput from "../../../components/inputs/CustomInput"
import moment from "moment"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import NoDataComponent from "../../../components/layouts/NoDataComponent"
import SubmitButton from "../../../components/button/SubmitButton"
import { findObjectKeyByValue } from "../../../utils/helpers/helper"
import { NOTES_TYPE } from "../../../utils/constants/project.constant"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"

const ProjectsNotesController = ({ project_id, defaultNotes = [], setDefaultNotes = () => { } }) => {
    const theme = useTheme()
    const { user } = useSelector(state => state)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [notes, setNotes] = useState(defaultNotes)

    const [note, setNote] = useState('')
    const [type, setType] = useState(NOTES_TYPE.GENERAL)

    const [notFilter, setNoteFilter] = useState(undefined)


    const createNote = () => {
        if (!note || note == "") {
            dispatch(callSnackBar("Please write Something.", SNACK_BAR_VARIETNS.error))
            return
        }
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await addProjectNotesApi({ project_id, note, note_type: type }),
                async (response) => {
                    const noteVal = note
                    setNotes([...notes, { note: noteVal, type: type, date: moment().toISOString(), user_name: user.data.name, _id: Math.random() }])
                    setDefaultNotes([...notes, { note: noteVal, type: type, date: moment().toISOString(), user_name: user.data.name, _id: Math.random() }])
                    setNote('')

                    setLoading(false)

                },
                (err) => {
                    setLoading(false)
                    dispatch(callSnackBar("Notes Cant be created", SNACK_BAR_VARIETNS.error))
                }
            )
        )
    }

    const fetchNotesByProjectId = useCallback((id) => {

        setLoading(true)
        dispatch(callApiAction(
            async () => await getProjectNotesApi({ id }),
            (response) => {
                setNotes(response?.notes ?? [])
                setDefaultNotes(response?.notes ?? [])
                setLoading(false)

            },
            (err) => {
                setLoading(false)
            }
        ))

    }, [setDefaultNotes, setNotes, setLoading, dispatch])

    useEffect(() => {
        if (project_id && defaultNotes.length == 0)
            fetchNotesByProjectId(project_id)
    }, [])

    return <>
        <CustomDialog
            id={MODAL_KEYS.NOTES}
            title="Project Notes"
        >
            <Box sx={{ width: "100%" }} >
                <Grid container spacing={2} flexDirection="row-reverse" >

                    <Grid item xs={12} md={9} >
                        <Box variant="outlined" component={Paper} sx={{ width: "100%", minHeight: "100%" }} p={3} >

                            {notes && notes.length > 0 && <>
                                <ButtonGroup disableElevation fullWidth size="small" sx={{ mb: 3 }} mb={2}>
                                    <Button variant={notFilter == undefined ? "contained" : "outlined"} onClick={() => { setNoteFilter(undefined) }} >All</Button>
                                    {

                                        Object.values(NOTES_TYPE).map((val) => (<Button key={val} variant={notFilter == val ? "contained" : "outlined"} onClick={() => { setNoteFilter(val) }} >{findObjectKeyByValue(val, NOTES_TYPE)}</Button>))

                                    }
                                </ButtonGroup>
                                <Grid container sx={{ height: "100%" }} spacing={2}>
                                    {
                                        notes?.filter(item => notFilter == undefined ? true : (item.type == notFilter)).map?.((item) => {
                                            return <Grid item xs={6}>
                                                <Box key={item._id} sx={{ borderRadius: 1, height: "100%", border: "1px solid " + theme.palette.primary.main, width: "100%" }} >
                                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: 1, borderColor: "primary.main", background: theme.palette.secondary.main, }} px={2} >

                                                        <Typography variant="caption" textTransform="capitalize" >
                                                            {item?.user_name ?? "Unknown User"}
                                                        </Typography>
                                                        <Typography variant="caption" >
                                                            {moment(item.date).format("DD/MM/YYYY")}
                                                        </Typography>
                                                    </Box>

                                                    <Typography variant="body1" p={2} sx={
                                                        {
                                                            background: "white"
                                                        }
                                                    } >
                                                        {item.note}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        })
                                    }
                                    {
                                        notes?.filter(item => notFilter == undefined ? true : (item.type == notFilter)).length == 0 && <Grid item sx={{ height: "100%" }} xs={12} >
                                            <CenteredBox sx={{ height: "100%", width: "100%" }} >
                                                <NoDataComponent message="No Notes found" variant="body1" iconVariant="h4" />
                                            </CenteredBox>

                                        </Grid>
                                    }
                                </Grid>
                            </>}
                            {
                                (!notes || notes?.length == 0) && <CenteredBox sx={{ width: "100%", minHeight: "100%" }}>
                                    <NoDataComponent variant="h6" message="No Notes Availabled" ></NoDataComponent>
                                </CenteredBox>
                            }
                        </Box>
                    </Grid>


                    <Grid item xs={12} md={3}>
                        <Box component={Paper} sx={{ width: "100%",position:"sticky",top:"0px", background: "#f2f2f2" }} p={3}  >
                            <CustomInput
                                disabled={loading}
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                multiline
                                rows={3}
                                type="textarea"
                                label={"Write Note*"}

                            />
                            <FormControl sx={{ background: "white" }} fullWidth margin="dense">
                                <InputLabel id="demo-simple-select-label">Select Note Type</InputLabel>
                                <Select
                                    disableClearable

                                    margin="dense"
                                    label="Select Note Type"
                                    value={type}

                                    fullWidth
                                    onChange={(e) => { setType(e.target.value) }}

                                    renderInput={(params) => <CustomInput  {...params} placeholder="Select Type" label="Select Note Type" size="small" />}
                                >
                                    {
                                        Object.values(NOTES_TYPE).map((val) => (<MenuItem key={val} value={val} >{findObjectKeyByValue(val, NOTES_TYPE)}</MenuItem>))
                                    }

                                </Select>
                            </FormControl>
                            <SubmitButton title="Create Note" loading={loading} onClick={createNote} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </CustomDialog>
    </>
    // if (sent) {
    //     return <Chip color="success" size="small" label="Already Sent"></Chip>
    // }
    // return <SubmitButton title="Send STC To Bridge Select" loading={loading} onClick={onClick} />
}
export default ProjectsNotesController