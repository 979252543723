import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../../store/actions/commonAction"

import { useMemo } from "react"

import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"
import { getProjectOrdersApi } from "../../../../apis/order.api"
import ProjectViewButton from "../../buttons/ProjectViewButtons"
import { Download, Visibility } from "@mui/icons-material"
import FileDownloadComponent from "../../../../components/FileDownloadComponent"
import CustomButton from "../../../../components/button/CustomButton"
import endpoints from "../../../../apis/endpoints"
import { Button } from "@mui/material"
import { openModal } from "../../../../store/actions/modalAction"
import PoDownloadModal from "./PoDownloadModal"

const ActionComponent = ({ params, setParams }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal(<PoDownloadModal supplier={params?.supplier?.name} orderId={params?._id} />, 'sm', undefined, MODAL_KEYS.PO_DOWNLOAD))
    }
    return <>
        <Button startIcon={<Download />} size="small" disableElevation variant="contained" onClick={onClick} >
            Download Po
        </Button>
        {/* <FileDownloadComponent direct fileNameDefault={params?.supplier?.name+".pdf"} heades src={endpoints.projectPOId + params._id} text={
        <Button startIcon={<Download />} size="small" disableElevation variant="contained">
            Download Po
        </Button>
       } /> */}
    </>
}
const ListController = ({ project_id }) => {
    const dispatch = useDispatch()

    const modalKey = MODAL_KEYS.ORDERS

    const title = "Orders "
    const fetchApi = getProjectOrdersApi



    const columns = useMemo(() => [
        { id: 1, fieldName: 'supplier', label: 'Supplier', align: "left", renderValue: (params) => params?.supplier?.name ?? "" },
        { id: 2, fieldName: 'pannels', label: 'Pannels', align: "left", renderValue: (params) => params?.modules?.map?.((item) => `${item?.code}(${item?.quantity})`)?.join?.(', ') },
        { id: 3, fieldName: 'inverters', label: 'Inverters', align: "left", renderValue: (params) => params?.inverters?.map?.((item) => `${item?.code}(${item?.quantity})`)?.join?.(', ') },
        { id: 4, fieldName: 'battries', label: 'Batteries', align: "left", renderValue: (params) => params?.battries?.map?.((item) => `${item?.code}(${item?.quantity})`)?.join?.(', ') },
        { id: 5, fieldName: 'others', label: 'Other', align: "left", renderValue: (params) => params?.others?.map?.((item) => `${item?.code}(${item?.quantity})`)?.join?.(', ') },
        { id: 6, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <ActionComponent params={params} setParams={setParams} /> },
    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        id: project_id,
        pageSize: 10,
        search: '',
        searchable: ['name'],
        sort: 'createdAt',
        sortDirection: -1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList({
                    pageNo: 1,
                    pageSize: filters?.pageSize,
                    total: response?.length,
                    result: response ?? []
                })
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                title={title}
                modalKey={modalKey}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController