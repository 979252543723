import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { configureStore } from '@reduxjs/toolkit';
import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import theme from './assets/css/theme';

import rootReducer from './store/reducers';
import thunk from 'redux-thunk'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { SnackbarProvider } from 'notistack';

import { Chart, ArcElement, Legend, Tooltip } from 'chart.js'
import ChatModuleWrapper from './pages/chatapp/ChatModuleWrapper';
Chart.register(ArcElement, Legend, Tooltip);

const store = configureStore({ reducer: rootReducer, middleware: [thunk] })

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>

  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={4} autoHideDuration={2000}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ChatModuleWrapper>
            <App />
          </ChatModuleWrapper>
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
