import { memo, useCallback, useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import {  getLeadAnalytisByStatusAndSourceApi } from "../../../apis/lead.api"
import LeadAnalyticsByStatusAndSourceUI from "./LeadAnalyticsByStatusAndSourceUI"
import { getDateFiltersTime } from "../../../utils/helpers/helper"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
export const LeadAnalyticsByStatusAndSourceDialog = memo(({dateKey, modalKey, sourceParent, sourceParentName }) => {

    return <CustomDialog
        id={modalKey}
        title={sourceParentName ?? "NA"} >


        <LeadAnalyticsByStatusAndSourceController defaultDateKey={dateKey} key={modalKey} sourceParent={sourceParent} />
    </CustomDialog>
})
const LeadAnalyticsByStatusAndSourceController = ({ sourceParent,defaultDateKey }) => {
    const {dateRange} = useSelector(state=>state)
    
    const dispatch = useDispatch()    
    const fetchAPi = getLeadAnalytisByStatusAndSourceApi
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})

    const [filters, setFilters] = useState({
        sourceParent,     
        dateKey:defaultDateKey??'createdAt',   
        ...(dateRange.get('last_updated_at')?dateRange.get('last_updated_at'):{
            ...getDateFiltersTime('today'),
            _id:"today",
            label:"Today"
        })
    })
    
    const fetchDetails = useCallback(() => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchAPi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }, [filters])
    useEffect(() => { fetchDetails() }, [filters])

    return <LeadAnalyticsByStatusAndSourceUI list={list} loading={loading} filters={filters} setFilters={setFilters} />
}
export default LeadAnalyticsByStatusAndSourceController