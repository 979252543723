
import { Autocomplete, Button, ButtonGroup, MenuItem, Paper, Tab, Tabs, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"

import SubmitButton from "../../components/button/SubmitButton"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import { USER_ROLES } from "../../utils/constants/constants"
import DataTable from "../../components/tables/DataTable"

import { allowedLeadStatusDropDownList, fetchAllowedLeadSubTab } from "../../utils/helpers/lead.helper"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getUserApi } from "../../apis/user.api"
import getSourceApi from "../../apis/sources.api"
import AsyncSearchBar from "../../components/inputs/AsynSearchBar"
import getTeamApi from "../../apis/team.api"
import { LEAD_STATUS_LIST } from "../../utils/constants/lead.constant"
import { titleCase } from "../../utils/helpers/helper"
import { CustomTab, CustomTabs } from "../../components/layouts/common/Tabs"
import SourceFilterDropDown from "../../components/filterdropdowns/SourceFilterDropDown"
import SalesPersonFilterDropDown from "../../components/filterdropdowns/SalesPersonFilter"
import TeamFilterDropDown from "../../components/filterdropdowns/TeamFilterDropDown"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, exportLoading, setFilters, list, modal, loading, onCreateBtnClick, onExportBtnClick, columns }) => {

    const user = useSelector(state => state.user)
    const statuses = [{ label: 'All', _id: null, defaultStatus: null }, ...allowedLeadStatusDropDownList().map((item) => ({ label: titleCase(item.title), _id: item.value, default: item.defaultStatus }))]


    const allowedSubstatus = fetchAllowedLeadSubTab(filters.tab)
    return (
        <>

            <Box mb={3}>
                <Paper elevation={0} sx={{ width: "100%", padding: modal ? 0 : 4 }} >
                    {<Box mb={3} >
                        {!modal && <>
                            {<FilterTitleBox mb={2}>
                                <Typography variant="h5" mb={2} >{title}</Typography>

                                <Box mb={2} sx={{ display: "flex" }}>
                                    <SubmitButton variant="contained" onClick={(e) => { onCreateBtnClick(e, true) }} title={"Add Completed Lead"} />
                                    <Box mr={2} />
                                    <SubmitButton variant="contained" onClick={onCreateBtnClick} title={"Raise Lead"} />
                                </Box>
                                {/* <Box mb={2}>
                                
                            </Box> */}

                            </FilterTitleBox>}

                            <Box mt={0} mb={0} sx={{}}>
                                {user.data.role == USER_ROLES.admin && <CustomTabs

                                    TabScrollButtonProps
                                    textColor="default"

                                    variant="scrollable"
                                    scrollButtons="auto"
                                    value={filters.tab}
                                    onChange={(e, val) => { setFilters({ ...filters, tab: val, status: e.target.getAttribute('defaultstatus') }) }}>

                                    {
                                        statuses.map((item) => {
                                            return <CustomTab
                                                key={item?._id}
                                                active={item._id == filters.tab}
                                                defaultstatus={item.default}
                                                label={item.label} value={item._id}
                                            />
                                        })
                                    }

                                </CustomTabs>}
                            </Box>
                            <FilterTitleBox p={2} pb={0}>
                                <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }}  >


                                    <TimeRangeSelector placeHolder="Last Updated Date" onChange={(newRange) => { setFilters({ ...filters, lastUpdateStartDate: newRange.startDate, LastUpdateEndDate: newRange.endDate }) }} />

                                </PaddingBoxInDesktop>
                                <PaddingBoxInDesktop pl={3} mb={2} sx={{ display: "flex", flex: 1 }}  >


                                    <TimeRangeSelector placeHolder="Created Date" onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />

                                </PaddingBoxInDesktop>
                            </FilterTitleBox>


                            <FiltersBox p={2} pt={0} pb={0} >


                                {<PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }}>

                                <TeamFilterDropDown
                                      id={'teams-leads'}
                                    filters={filters}
                                    setFilters={setFilters}
                                />
                                   

                                </PaddingBoxInDesktop>}

                                { <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={3}>



                                    <SalesPersonFilterDropDown
                                        id={'sales-leads'}
                                        filters={filters}
                                        setFilters={setFilters}
                                        key={filters.team}
                                    />
                                </PaddingBoxInDesktop>}
                                {/* <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={3} > */}
                                {/* <Autocomplete
                             size="small"
                                    value={findObjectKeyByValue(filters.status, LEAD_STATUS)}
                                    disableClearable
                                    onChange={(e, newVal) => {
                                        setFilters({ ...filters, type: newVal._id })
                                    }}
                                    options={[ {label:"All",_id:null},...Object.keys(LEAD_STATUS).map((key) => ({ label: titleCase(key), _id: LEAD_STATUS[key] }))]}

                                    sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                                    renderInput={(params) => <StyledSearchBar placeholder="Select Status" {...params} size="small" />}
                                />
                            </PaddingBoxInDesktop> */}
                                <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={3} >


                                    <SourceFilterDropDown
                                            //   margin={0}
                                        id={'sources-leads'}
                                        filters={filters}
                                        setFilters={setFilters}
                                    />
                                    
                           
                                </PaddingBoxInDesktop>
                                <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={3} >
                                    <Autocomplete
                                        disableClearable
                                        id="combo-box-demo"
                                        onChange={(e, newVal) => {
                                            setFilters({ ...filters, status: newVal ? newVal._id : null })
                                        }}
                                        options={[{ label: 'All', _id: null }, ...LEAD_STATUS_LIST]}

                                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                                        renderInput={(params) => <StyledSearchBar placeholder="Select Status" {...params} size="small" />}
                                    />
                                </PaddingBoxInDesktop>


                            </FiltersBox>
                        </>}
                        <FiltersBox sx={{ display: "flex", width: "100%" }} mt={1} pl={2} >

                            <Box  >
                                {(user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.sub_admin || user.data.role == USER_ROLES.partner_admin) && <SubmitButton loading={exportLoading} variant="contained" onClick={onExportBtnClick} title={"Export CSV"} />}
                            </Box>
                            <PaddingBoxInDesktop sx={{ display: "flex", justifyContent: "flex-end", flex: 1 }}  >

                                {allowedSubstatus && <ButtonGroup size="small" variant="outlined" aria-label="Statuses">

                                    {
                                        allowedSubstatus.map((item) => {
                                            return <Button
                                                key={item.title}
                                                disableElevation
                                                variant={filters.status == item.value ? "contained" : "outlined"}
                                                onClick={(e) => {
                                                    setFilters({ ...filters, status: item.value })
                                                }} >{item.title}</Button>
                                        })
                                    }
                                </ButtonGroup>}
                            </PaddingBoxInDesktop>
                            <PaddingBoxInDesktop sx={{ display: "flex", flex: 1, maxWidth: "400px" }} pl={3} >
                                <AsyncSearchBar

                                    fullWidth
                                    title="Search Lead "
                                    size="small"
                                    placeholder={"Search Lead"}
                                    defaultValue={filters.search}
                                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                                />
                                {/* <StyledSearchBar fullWidth title="Search by Name | Email | Phone " size="small" placeholder={"Search Name | Email | Phone"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
                            </PaddingBoxInDesktop>
                        </FiltersBox>

                    </Box>}

                    <Box sx={{ minHeight: "300px" }} p={2} pt={0}>
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper >
            </Box >

        </>
    )
}
export default ListUi