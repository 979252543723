import { Autocomplete } from "@mui/material"
import { Box } from "@mui/system"
import CustomInput from "../../components/inputs/CustomInput"

import { AUS_STATES } from "../../utils/constants/constants"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import FileInput from "../../components/inputs/FileInput"


const InstallerFields = ({ loading, fields, setFields }) => {

    return <>

        <CustomInput
            disabled={loading}
            value={fields.accrediation_number}
            onChange={(e) => setFields({ ...fields, err: '', accrediation_number: e.target.value ,cec_id:e.target.value })}
            type="text"
            label={"Accrediation Number*"}

        />
        <CustomInput
            disabled={loading}
            value={fields.license_number}
            onChange={(e) => setFields({ ...fields, err: '', license_number: e.target.value })}
            type="text"
            label={"License No*"}

        />

        {/* <CustomInput
            disabled={TroubleshootOutlined}
            value={fields.cec_id}

            onChange={(e) => setFields({ ...fields, err: '', cec_id: e.target.value })}
            type="text"
            label={"Cec Id*(Add it from bridge Select)"}

        /> */}
        <DesktopDatePicker
            label="License Expiry"
            inputFormat="DD/MM/yyyy"
            renderInput={(props) => <CustomInput {...props} />}
            value={fields.license_expiry ? moment((fields.license_expiry)) : null}
            onChange={(e) => setFields({ ...fields, err: '', license_expiry: e.valueOf() })}
        />

        <Autocomplete
            value={fields.state}
            options={AUS_STATES.map((item) => ({ label: item, id: item }))}
            onChange={(e, newVal) => setFields({ ...fields, err: '', state: newVal.id })}
            disableClearable
            isOptionEqualToValue={(option, val) => {
                
                if (fields.state == option.id) {
                    return true
                }
                return false
            }}
            autoFocus={false}
            id="state"
            name="state"
            sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
            renderInput={(params) => <CustomInput placeholder="Select State*" {...params} label="Select State*" />}
        />


        <FileInput
            defaults={fields.aggrement_file ? [fields.aggrement_file] : []}
            title="Aggrement Proof*"
            subTitle="Only .pdf  less than 2mb are valid."
            accept=".pdf"
            onChange={(newUrl) => {
                setFields({ ...fields, aggrement_file: newUrl })
            }} />
        <Box mt={3} mb={3}></Box>
        <FileInput
            defaults={fields.license_file ? [fields.license_file] : []}
            title="License Proof*"
            subTitle="Only .pdf  less than 2mb are valid."
            accept=".pdf"
            onChange={(newUrl) => {
                setFields({ ...fields, license_file: newUrl })
            }} />
    </>
}
export default InstallerFields