

import { Chip, CircularProgress, Grid, Paper, Typography, styled, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import { memo, useCallback, useEffect, useState } from "react"
import { findObjectKeyByValue } from "../../../utils/helpers/helper"
import moment from "moment"
import { PROJECT_ACTION_TYPES, PROJECT_FLAGS, PROJECT_STATUS } from "../../../utils/constants/project.constant"
import { useDispatch } from "react-redux"
import { getProjectLogsApi } from "../../../apis/projects.api"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { callApiAction } from "../../../store/actions/commonAction"
import { getFlagsUnderProjectStatus, getStatusOfProjectFlags, projectStatusColor } from "../../../utils/helpers/project.helper"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"


const Container = styled(Box)(({ theme }) => ({



    border: "1px solid " + theme.palette.primary.main,
    alignItems: "center",
    borderTopLeftRadius: theme.shape.borderRadius,
    // background: theme.palette.secondary.main,

    borderRadius: theme.shape.borderRadius


}))

const useGetBgAndColor = (action) => {
    const theme = useTheme()
    let color = theme.palette.primary.main
    let bg = theme.palette.secondary.main

    if (action == PROJECT_ACTION_TYPES.FLAG_CHANGED) {
        color = theme.palette.primary.main
        bg = theme.palette.primary.light
    }
    if (action == PROJECT_ACTION_TYPES.STATUS_CHANGE) {
        color = theme.palette.primary.light
        bg = theme.palette.primary.main
    }
    return {
        color,
        bg
    }
}

const getValueString = (value, action) => {
    let valueStr = ''
    const values = value?.split(':')

    if (action == PROJECT_ACTION_TYPES.FLAG_CHANGED) {
        valueStr = findObjectKeyByValue(values?.[0], PROJECT_FLAGS) + " " + findObjectKeyByValue(values?.[1], getStatusOfProjectFlags(values?.[0]))
    }

    if (action == PROJECT_ACTION_TYPES.STATUS_CHANGE) {
        valueStr = findObjectKeyByValue(values?.[1], PROJECT_STATUS)
    }
    return valueStr
}

const ProjectLogComponent = memo(({ value, action, date, by, remarks }) => {

    const { bg, color } = useGetBgAndColor(action)
    return <Container mt={2} mb={2} p={2}>
        <Box display="flex" >
            <Box component={Paper} elevation={0} px={2} flex="none" sx={{ backgroundColor: bg, display: "flex" }}>
                <Typography sx={{ color }} fontWeight={600} fontFamily="Public Sans" variant="caption" >
                    {
                        findObjectKeyByValue(action, PROJECT_ACTION_TYPES) ?? "Action"
                    }
                    :&nbsp;
                    {
                        getValueString(value, action)
                    }
                </Typography>
            </Box>

        </Box>
        <Box>
            <Typography variant="caption" lineHeight="100%" align="left">Date: {moment(date).format("DD/MM/YYYY HH:mm")}</Typography>
        </Box>
        <Typography variant="caption" lineHeight="100%" >Remarks: {remarks ?? "NA"}</Typography>
        <Box />
        <Typography variant="caption" lineHeight="100%" >Update By: {by ? by.name : "System"}</Typography>
    </Container>
}
)

const ProjectLogsUi = ({ project_id, defaultLogs = [], setProjectLogs = () => { } }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [logs, setLogs] = useState(defaultLogs)

    const fetchProjectLogs = useCallback((id) => {

        setLoading(true)
        dispatch(callApiAction(
            async () => await getProjectLogsApi({ id }),
            (response) => {
                setLogs(response?.status_logs ?? [])
                setProjectLogs(response?.status_logs ?? [])
                setLoading(false)

            },
            (err) => {
                setLoading(false)
            }
        ))

    }, [])
    useEffect(() => {
        if (project_id && defaultLogs.length == 0)
            fetchProjectLogs(project_id)
    }, [])



    return <>
        <CustomDialog
            id={MODAL_KEYS.PROJECT_LOGS}
            title="Project Logs"
        >

            {loading ? <CenteredBox>
                <CircularProgress />
            </CenteredBox>
                :
                (logs && Array.isArray(logs) && logs.length > 0 ?
                    logs.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map((log, index) => (
                        <ProjectLogComponent
                            id={log?._id}
                            date={log?.date}
                            by={log?.changed_by}
                            value={log?.value}
                            action={log?.action}
                            remarks={log?.remarks}
                        />
                    ))

                    :
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography variant="h4" color="grey.main">No Logs</Typography>
                    </Box>)

            }
        </CustomDialog>

    </>



}
export default memo(ProjectLogsUi)