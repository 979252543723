import { Box, Button, ButtonBase, Chip, CircularProgress, Divider, IconButton, LinearProgress, ListItem, ListItemIcon, MenuItem, Paper, Tooltip, Typography, useTheme } from "@mui/material"


import moment from "moment"
import { AddCircle, Delete, Loop, MarkChatRead, MarkChatUnread } from "@mui/icons-material"
import { memo, useEffect, useRef, useState } from "react"
import NoDataComponent from "../../components/layouts/NoDataComponent"
import { useDispatch, useSelector } from "react-redux"
import { chatgroupsLastReadMessages, moduleAccessCondition } from "../../utils/helpers/helper"
import { USER_ROLES } from "../../utils/constants/constants"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { deleteMessageAction, loadMoreChats, markAsReadReadToNewMessage, markAsReadToAllChat, onMessageDeletedAction, openGroupChats } from "../../store/actions/chatAction"
import { CHAT_TYPES } from "../../utils/constants/chat.constant"
import FileInput from "../../components/inputs/FileInput"

import { ChatInput, ChatInputWrapper, MediaViewer } from "./ChatInput"
import ImageComponent from "../../components/ImageComponent"
import FileDownloadComponent from "../../components/FileDownloadComponent"
import { closeModal, openModal } from "../../store/actions/modalAction"
import MessageDilog from "../../components/MessageDilog"
import ContextMenu from "../../components/layouts/common/ContextMenu"
import ChatGroupDetailUI from "./ChatGroupDetails"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import MODULES from "../../utils/constants/module.constants"

const ChatGroupItem = memo(({ last_message, last_message_time, last_message_id = 'NA', name, id, index }) => {
    const dispatch = useDispatch()
    const [lastReadMessage, setLastReadMessage] = useState(chatgroupsLastReadMessages.get(id))

    const onClick = () => {
        setLastReadMessage({

            messagId: last_message_id ?? null,
            messageTime: last_message_time ?? moment().add(-1, 'second').valueOf()
        })
        chatgroupsLastReadMessages.set(id, last_message_id ?? null, last_message_time ?? moment().add(-1, 'second').valueOf())
        dispatch(openGroupChats(id, { name, _id: id }))
    }

    useEffect(() => {
        if (last_message_time != lastReadMessage?.messageTime) {
            setLastReadMessage(chatgroupsLastReadMessages.get(id))
        }
    }, [last_message_time, last_message_id, id])

    return <ButtonBase key={index} sx={{ transition: "all 0.3s linear" }} onClick={onClick} >

        <Box sx={(theme) => ({ width: "100%", border: "1px solid" + theme.palette.grey.main, background: theme.palette.light.main })} p={2}>
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="body2" fontWeight="bold">{name}</Typography>
                <Typography variant="body2">{moment(last_message_time).format('DD /MM /YYYY mm:ss')}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center" }}>
                <Tooltip title={last_message} sx={{ "*": { letterSpacing: "1px" } }}>
                    <Typography fontWeight={(!lastReadMessage?.messageTime || moment(lastReadMessage?.messageTime).valueOf() < moment(last_message_time).valueOf()) ? "800" : "400"} variant="body2" align="left" mt={1}>{last_message && last_message.length > 30 ? last_message.slice(0, 27) + '...' : last_message}</Typography>
                </Tooltip>

                <Typography variant="body2" align="left" mt={1}>
                    {(!lastReadMessage?.messageTime || moment(lastReadMessage?.messageTime).valueOf() < moment(last_message_time).valueOf()) ? <MarkChatUnread fontSize="inherit" color="error" /> : <MarkChatRead fontSize="inherit" color="success" />}
                </Typography>
            </Box>
        </Box>

    </ButtonBase>
})
const ChatGroupList = memo(({ loading, filters, setFilters }) => {
    const { chat } = useSelector(state => state)
    return <>
        {chat?.connected && <>


            {chat?.groups?.data && Array.isArray(chat?.groups?.data) && chat?.groups?.data?.length > 0 ? chat?.groups?.data?.filter((item) => {
                if (filters.search && filters.search != '') {
                    return item.name?.toLowerCase()?.search(filters.search) != -1
                }
                return true

            })?.sort((a, b) => moment(b.time).valueOf() - moment(a.time).valueOf())?.map((item, index) => <ChatGroupItem
                key={item._id}
                id={item._id}
                index={index}
                name={item.name}
                last_message={item.last_message}
                last_message_id={item.last_message_id}
                last_message_time={item.time}
            />) :

                <Box sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center" }}><Typography variant="h5">No Chat Group</Typography></Box>

            }
        </>}
        {
            !chat?.connected && <CenteredBox sx={{ flexDirection: "column" }}>


                <Typography variant="h5">Connecting...</Typography>
                <LinearProgress color="warning" sx={{ width: "50%" }} />
            </CenteredBox>
        }

    </>
})
const DeleteMessageButton = ({ messageId, groupId, handleClose }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const onDelete = () => {
        setLoading(true)
        dispatch(closeModal())

        dispatch(deleteMessageAction(groupId, messageId, () => { setLoading(false); dispatch(onMessageDeletedAction(groupId, messageId)) }, () => setLoading(false)))
    }
    const onClick = () => {
        handleClose?.()
        dispatch(openModal(<MessageDilog message="Deleted message will not be retrived." title="Alert" onSubmit={onDelete} />))
    }
    if (loading)
        return <CircularProgress />

    return <ListItem components={{ "Root": ButtonBase }} sx={{ padding: 1 }} onClick={onClick} >



        <Typography variant="subtitle2" ml={2} >Delete Message</Typography>
    </ListItem>
}
const ChatMessage = ({ groupId, messageId, message, date, isme, name, url, message_type }) => {
    const { user } = useSelector(state => state)
    return <>
        <ContextMenu renderMenuItems={({ handleClose }) => {
            return <>
                {moduleAccessCondition(user, [MODULES.DELETE_CHAT_MESSAGE], []) && <DeleteMessageButton handleClose={handleClose} groupId={groupId} messageId={messageId} />}
            </>
        }} >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: !isme ? "flex-start" : "flex-end" }} >
                <Box sx={(theme) => ({ maxWidth: "80%" })} p={1}>


                    <Typography variant="caption" textTransform="capitalize" sx={{ width: "100%", display: "flex", justifyContent: isme ? "flex-end" : "flex-start" }} align={!isme ? "right" : "left"}>
                        {name}, {moment(date).format("DD-MM-YYYY hh:mm A")}
                    </Typography>

                    <Box sx={(theme) => ({ maxWidth: "100%", wordWrap: "break-word", background: theme.palette.secondary.main, borderRadius: 1 })} p={1} px={2}>
                        {
                            (message_type == CHAT_TYPES.IMAGE && url != '') && <ImageComponent sx={{ height: "100%", width: "100%", maxWidth: "250px", objectFit: "contain" }} src={url} />
                        }
                        {
                            message_type == CHAT_TYPES.FILES && url != '' && <FileDownloadComponent sx={{ height: "100%", width: "100%", maxWidth: "250px", objectFit: "contain" }} src={url} />
                        }
                        <Typography variant="subtitle2" sx={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: message }}>

                        </Typography>

                    </Box>


                </Box>
            </Box>
        </ContextMenu>
    </>
}
const ChatList = memo(({ }) => {
    const dispatch = useDispatch()
    const { chat, user } = useSelector(state => state)
    const [loading, setLoading] = useState(false)
    const ChatBg = (process.env.PUBLIC_URL + '/' + process.env.REACT_APP_LOGO_SLUG)
    useEffect(() => {
        return () => {
            dispatch(markAsReadToAllChat(chat?.activeGroupId))
        }
    }, [chat?.activeGroupId])

    const onNextClick = () => {
        setLoading(true)
        dispatch(loadMoreChats(() => { setLoading(false) }, () => { setLoading(false) }))
    }
    return <>

        {
            chat.loading && <CenteredBox>
                <CircularProgress />
            </CenteredBox>
        }
        {!chat.loading && <>


            {
                !chat?.activeGroupId ? <CenteredBox sx={{ flexDirection: "column" }}>


                    <img src={ChatBg} style={{ width: "50%", objectFit: "contain", opacity: 0.5 }} />


                    <Typography variant="h4" color="primary" >Select Group To View Chat</Typography>
                </CenteredBox> : <>

                    {
                        chat?.chats?.[chat?.activeGroupId]?.moreAvailable && <Box sx={{}}>

                            <CenteredBox>
                                {
                                    loading ? <CircularProgress /> : <IconButton onClick={onNextClick} title="Load More">
                                        <Loop />
                                    </IconButton>
                                }

                            </CenteredBox>
                        </Box>
                    }

                    {
                        chat?.chats?.[chat?.activeGroupId]?.data?.map((item) => <ChatMessage messageId={item?._id} groupId={chat?.activeGroupId} key={item._id} url={item.url} message={item.message} name={item.name} message_type={item.message_type} date={item.time} isme={item.user_id == user.data._id} />)
                    }

                    {chat?.chats?.[chat?.activeGroupId]?.unreadMessages?.length !== 0 &&
                        <Box>
                            <Divider>
                                <Chip size="small" color="success" label="Unread Messages" />
                            </Divider>
                        </Box>
                    }
                    {
                        chat?.chats?.[chat?.activeGroupId]?.unreadMessages?.map((item) => <ChatMessage messageId={item?._id} groupId={chat?.activeGroupId} key={item._id} url={item.url} message={item.message} name={item.name} message_type={item.message_type} date={item.time} isme={item.user_id == user.data._id} />)
                    }
                    {
                        chat?.chats?.[chat?.activeGroupId]?.data?.length == 0 && chat?.chats?.[chat?.activeGroupId]?.unreadMessages?.length == 0 ? <CenteredBox>
                            <NoDataComponent message="No chats available" />
                        </CenteredBox> :
                            <Box sx={{ width: "100%", height: "100%", position: "absolute", top: "0px", left: "0px", zIndex: -1 }}>
                                <CenteredBox>
                                    <img src={ChatBg} style={{ height: "50%", width: "50%", objectFit: "contain", opacity: 0.2 }} />
                                </CenteredBox>
                            </Box>
                    }

                </>

            }
        </>}

    </>
})






const ChatMainUi = ({
    filters,
    setFilters,
    onCreateButtonClick
}) => {
    const { user, chat } = useSelector(state => state)
    const dispatch = useDispatch()
    const ref = useRef()
    const theme = useTheme()
    useEffect(() => {
        if (ref) {
            ref.current.scrollTop = ref.current.scrollHeight
        }
        dispatch(markAsReadReadToNewMessage())
    }, [chat?.activeGroupId, chat.loading])

    useEffect(() => {
        if (ref) {
            ref.current.scrollTop = ref.current.scrollHeight
        }
    }, [chat.scrollId])

    const onGroupClick = (id) => {
        dispatch(openModal(<ChatGroupDetailUI id={id} />, 'sm', undefined, MODAL_KEYS.CHAT_GROUP_DETAILS))
    }
    return <>
        <ChatInputWrapper>
            <Paper component={Box} sx={{ display: "flex", flex: 1, flexDirection: "column", width: "100%", overflow: "hidden", maxHeight: window.innerHeight - 100 }} mb={2} p={2}>
                <Box sx={{ display: "flex", flex: "none", justifyContent: "space-between" }} p={2}>
                    <Typography variant="h6">Chats</Typography>

                </Box>
                <Box sx={(theme) => ({ display: "flex", flex: 1, flexDirection: "column", width: "100%", overflow: "hidden", border: "1px solid " + theme.palette.grey.main })}>



                    <Box sx={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "stretch", overflow: "hidden" }}>


                        <>
                            <Box sx={(theme) => ({ display: "flex", flex: 2, overflowY: "auto", overflow: "hidden", flexDirection: "column", background: theme.palette.grey.main, borderRight: "1px solid " + theme.palette.grey.main })}>
                                <Box sx={(theme) => ({ background: theme.palette.primary.main })} p={2}>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <Typography textTransform="capitalize" color="text.invert">
                                            {user.data?.name}
                                        </Typography>
                                        {(moduleAccessCondition(user, [MODULES.CREATE_CHATS_GROUPS], [])) &&
                                            <Tooltip title="Create Group" >
                                                <IconButton sx={{ p: 0 }} onClick={onCreateButtonClick} >
                                                    <AddCircle fontSize="inherit" color="light" />
                                                </IconButton>
                                            </Tooltip>}

                                    </Box>

                                </Box>
                                <Box sx={{ display: "flex", flex: 1, flexDirection: "column", overflow: "auto" }}>
                                    <ChatGroupList filters={filters} setFilters={setFilters} />
                                </Box>

                            </Box>
                        </>
                        <Box sx={{ display: "flex", position: "relative", flex: 4, overflowY: "auto", overflow: "hidden", flexDirection: "column" }} >
                            <FileInput onlyImage custom>
                                {chat.activeGroupId && <Box component={ButtonBase} onClick={() => {
                                    if ((moduleAccessCondition(user, [MODULES.VIEW_CHATS_GROUP_DETAIL], [])))

                                        onGroupClick(chat.activeGroupId)


                                }} sx={{ background: theme.palette.primary.main, zIndex: 1, justifyContent: "flex-start" }} p={2} >
                                    <Typography textTransform="capitalize" color="text.invert">
                                        {
                                            chat.chats?.[chat.activeGroupId]?.groupDetails?.name ?? "Loading details..."
                                        }
                                    </Typography>

                                </Box>}
                                <Box sx={{ display: "flex", zIndex: 1, width: "100%", position: "relative", flex: 1, overflow: "hidden" }}>
                                    <Box ref={ref} p={3} sx={{ height: "100%", width: "100%", flexDirection: "column", overflowY: "scroll" }}>
                                        {<ChatList />}
                                    </Box>
                                    <MediaViewer />
                                </Box>

                                {

                                    <Box sx={{ zIndex: 1 }}>
                                        <ChatInput />
                                    </Box>
                                }
                            </FileInput>

                        </Box>

                    </Box>
                </Box>


            </Paper >
        </ChatInputWrapper>
    </>
}
export default ChatMainUi