
import { Button, ButtonGroup, MenuItem, Paper, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"



import SubmitButton from "../../../components/button/SubmitButton"
import { StyledSearchBar } from "../../../components/inputs/SearchBar"


import { USER_ROLES } from "../../../utils/constants/constants"
import DataTable from "../../../components/tables/DataTable"

import { allowedLeadStatusDropDownList, fetchAllowedLeadSubTab } from "../../../utils/helpers/lead.helper"
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop"
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import { getUserApi } from "../../../apis/user.api"
import getSourceApi from "../../../apis/sources.api"
import AsyncSearchBar from "../../../components/inputs/AsynSearchBar"
import getTeamApi from "../../../apis/team.api"
import { titleCase } from "../../../utils/helpers/helper"
import SourceFilterDropDown from "../../../components/filterdropdowns/SourceFilterDropDown"
import SalesPersonFilterDropDown from "../../../components/filterdropdowns/SalesPersonFilter"
import TeamFilterDropDown from "../../../components/filterdropdowns/TeamFilterDropDown"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, exportLoading, setFilters, list, roles, loading, onCreateBtnClick, onExportBtnClick, columns }) => {

    const user = useSelector(state => state.user)
    const statuses = [{ label: 'All', _id: null, defaultStatus: null }, ...allowedLeadStatusDropDownList().map((item) => ({ label: titleCase(item.title), _id: item.value, default: item.defaultStatus }))]


    const allowedSubstatus = fetchAllowedLeadSubTab(filters.tab)
    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={3} >
                        <FilterTitleBox >
                            <Typography variant="h5"  >{title}</Typography>



                        </FilterTitleBox>
                        <FilterTitleBox >
                            <Typography variant="caption"  >
                                This dashboard displays leads with statuses such as 'unhandled,' 'callback,' 'no answer,' and 'wrong number.' Additionally, it verifies that the corresponding log entries never indicate a completion or rejection status.
                            </Typography>



                        </FilterTitleBox>

                        <FiltersBox mt={2} p={2} pb={0} >
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }}  >


                                <TimeRangeSelector onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />

                            </PaddingBoxInDesktop>

                            {<PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={3}>

                                <TeamFilterDropDown
                                    id={'teams-leads'}
                                    filters={filters}
                                    setFilters={setFilters}
                                />



                            </PaddingBoxInDesktop>}

                            {<PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={3}>



                                <SalesPersonFilterDropDown
                                    id={'sales-leads'}
                                    filters={filters}
                                    setFilters={setFilters}
                                    key={filters.team}
                                />
                            </PaddingBoxInDesktop>}
                            <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={3} >

                                <SourceFilterDropDown
                                    id={'sales-leads'}
                                    filters={filters}
                                    setFilters={setFilters}
                                />
                            </PaddingBoxInDesktop>

                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={3} >
                                <AsyncSearchBar

                                    fullWidth
                                    title="Search Lead "
                                    size="small"
                                    placeholder={"Search Lead"}
                                    defaultValue={filters.search}
                                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                                />
                                {/* <StyledSearchBar fullWidth title="Search by Name | Email | Phone " size="small" placeholder={"Search Name | Email | Phone"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
                            </PaddingBoxInDesktop>
                            <FiltersBox sx={{ display: "flex", width: "100%", justifyContent: "space-between" }} mt={1} >

                                <Box  >
                                    {(user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.sub_admin || user.data.role == USER_ROLES.partner_admin) && <SubmitButton loading={exportLoading} variant="contained" onClick={onExportBtnClick} title={"Export CSV"} />}
                                </Box>
                                <PaddingBoxInDesktop sx={{ display: "flex", }}  >

                                    {allowedSubstatus && <ButtonGroup variant="outlined" aria-label="Statuses">

                                        {
                                            allowedSubstatus.map((item) => {
                                                return <Button
                                                    key={item.title}
                                                    disableElevation
                                                    variant={filters.status == item.value ? "contained" : "outlined"}
                                                    onClick={(e) => {
                                                        setFilters({ ...filters, status: item.value })
                                                    }} >{item.title}</Button>
                                            })
                                        }
                                    </ButtonGroup>}
                                </PaddingBoxInDesktop>
                            </FiltersBox>
                        </FiltersBox>


                    </Box>

                    <Box sx={{ minHeight: "300px" }} p={2} pt={0}>
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper >
            </Box >

        </>
    )
}
export default ListUi