import { memo,  useContext, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { Link } from "react-router-dom"
import {  openModal } from "../../store/actions/modalAction"
import { findObjectKeyByValue } from "../../utils/helpers/helper"
import { Box, Button,  IconButton } from "@mui/material"
import { useMemo } from "react"

import { Info } from "@mui/icons-material"

import CreateController from "../user/CreateController"
import moment from "moment"
import FileDownloadComponent from "../../components/FileDownloadComponent"
import endpoints from "../../apis/endpoints"
import { getInvoiceData } from "../../apis/invoice.api"
import { INVOICE_STATUS, INVOICE_TYPE } from "../../utils/constants/invoice.constant"
import ProjectDetailsController from "../projects/projectdetails/ProjectDetailsController"
import UpdateInvoiceButton from "./update/UpdateInvoiceButton"
import fileDownload from "js-file-download"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { BulkInvoiceContext, InvoiceSelectionCheckBox } from "./BulkInvoiceSelectionWrapper"
import InvoiceDetailsController from "./detailedview/InvoiceDetailedViewController"
import { isAllowedToEdit } from "../../utils/helpers/invoice.helper"
import ProjectInstallerReassignButton from "../projects/projectdetails/ProjectInstallerReassignButton"


const ProjectViewButton = memo(({ params, setParams }) => {

    const dispatch = useDispatch()
    
    const onProjectClick = () => {
        const projectId = typeof (params.project) == "object" ? params.project?._id : params.project
        dispatch(openModal(<ProjectDetailsController project={projectId} callBack={(response, updatedData) => {

        }} />, "lg", undefined, MODAL_KEYS.PROJECT))
    }



    return <Box >
        {params.project && <Button onClick={onProjectClick} size="small" color="primary" variant="outlined" >{params?.project_details?.open_solar_project_id}</Button>}

    </Box>
})


export const InvoiceViewButton = memo(({ params, setParams, withInvoiceNo }) => {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)


    const onClick = () => {

        dispatch(openModal(<InvoiceDetailsController id={params?._id} type={params?.type} invoiceNo={params?.invoice_no} callBack={(response, updatedData) => {

        }} />, "sm", undefined, MODAL_KEYS.INVOICE_DETAILS))
    }

    if (withInvoiceNo)
        return <Button onClick={onClick} size="small" >
            {params?.invoice_no}
        </Button>

    return <Box >
        {<IconButton onClick={onClick} size="small" color="primary" variant="outlined" >
            <Info color="primary" />

        </IconButton>}

    </Box>
})

const ListController = ({ title = "Invoices", isElectricianInvoice }) => {

    const bulkInvoiceContext = useContext(BulkInvoiceContext)
    const dispatch = useDispatch()



    // const title = "Invoice"
    const fetchApi = getInvoiceData
    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['invoice_no', 'customer_name'],
        status: INVOICE_STATUS.PENDING,
        parentStatus: 'pending',

        type: INVOICE_TYPE.CUSTOMER_INVOICE,
        installer_id: null,
        sort: 'due_date',
        sortDirection: -1,
        currentDate: moment().toISOString(),
        onlyAfterInstalltionComplition: null,
        beforeCESAFterInstllationComplete: null,
        onlyAfterCES: null

    })

    const columns = useMemo(() => {

        const tableCells = [

            {
                id: 0, sort: true, fieldName: 'due_date', label: 'Due Date.', align: "left", renderValue: (params, setParams) => {
                    return moment(params.due_date).format("DD/MM/YYYY")
                }
            },

            {
                id: 1, fieldName: 'invoice_no', label: 'Invoice no.', minWidth: "140px", align: "left", renderValue: (params, setParams) => {
                    if (params.invoice_no) {
                        return <>
                            <FileDownloadComponent text={params.invoice_no} direct={!(params.custom_invoice && params.custom_invoice != '')} heades src={params.custom_invoice && params.custom_invoice != '' ? params.custom_invoice : (endpoints.invoiceDownload + params._id)} />
                        </>
                    } else {
                        return "NA"
                    }
                }
            },
            {
                id: 7, fieldName: 'project', label: 'Project', align: "left", renderValue: (params) => {
                    return <>
                        <ProjectViewButton params={params} />
                    </>
                }
            }
        ]
        if (filters.type == INVOICE_TYPE.SOLAR_VICTORIA_INVOICE) {
            tableCells.push({ id: 2, fieldName: 'victoria_details.ref_no', label: 'INS No.', align: "left", minWidth: "150px", renderValue: (params) => params?.project_details?.victoria_details?.ref_no ?? "NA" })
        }

        if (![INVOICE_TYPE.ELECTRICIAN_INVOICE, INVOICE_TYPE.SUPPLIER_INVOICE].includes(filters.type)) {
            tableCells.push({ id: 2, fieldName: 'customer_name', label: 'Customer Name', align: "left", minWidth: "150px" })
            tableCells.push({
                id: 5, fieldName: 'customer_phone', label: 'Customer Phone', align: "left", renderValue: (params, setParams) => <Link to={"tel:" + params.customer_phone} target="_blank" >


                    {params.customer_phone}
                </Link>
            })
        } else if (filters.type == INVOICE_TYPE.SUPPLIER_INVOICE) {
            tableCells.push({
                id: 5, fieldName: 'supplier_id', label: 'Supplier', minWidth: "200px", align: "left", renderValue: (params, setParams) => params?.supplier_id?.name ?? "NA"

            })
        } else {
            tableCells.push({
                id: 5, fieldName: 'installer_id', label: 'Installer', align: "left", renderValue: (params, setParams) => <ProjectInstallerReassignButton withname={true} params={params} setParams={setParams} />

            })
        }
        tableCells.push({ id: 3, fieldName: 'amount', label: 'Total Amount', align: "left", renderValue: (params) => Number(params.amount).toFixed(2) })
        tableCells.push({ id: 4, fieldName: 'paid_amount', label: 'Paid Amount', align: "left", renderValue: (params) => Number(params.paid_amount ?? 0).toFixed(2) })
        tableCells.push({ id: 10, fieldName: 'paid_amount', label: 'Remained Amount', align: "left", renderValue: (params) => (Number(params.amount) - Number(params.paid_amount ?? 0)).toFixed(2) })
        tableCells.push({
            id: 5, fieldName: 'payments', label: 'Pending Verification', align: "left", renderValue: (params) => {
                return params?.payments?.filter?.((item) => !item.verified)?.reduce?.((curVal, item) => curVal + (Number(item?.amount)), 0) ?? 0
            }
        })



        // if (!dontDownload) {
        //     tableCells.push(
        //         {
        //             id: 19, fieldName: '_id', minWidth: "150px", label: 'Download Invoice', align: "left", renderValue: (params) => {
        //                 return <>

        //                     <FileDownloadComponent direct heades src={endpoints.invoiceDownload + params._id} />
        //                     <SendInvoiceToCustomerButton id={params._id} />
        //                 </>
        //             }
        //         }

        //     )
        // }
        // if (systemInvoice) {
        // tableCells.push(

        //     {
        //         id: 89, fieldName: '_id', minWidth: "200px", label: 'Action', align: "left", renderValue: (params, setParams) => {
        //             return <>

        //                 <PayInvoiceButton invoice={params} callBack={(e, response) => {
        //                     setParams({ ...params, payments: [...params.payments, { amount: response.paid_amount }] })
        //                 }} />
        //             </>
        //         }
        //     },
        // )
        tableCells.push({
            id: 89, fieldName: '_id', minWidth: "50px", label: 'Action', align: "left", renderValue: (params, setParams) => {
                return <Box display={"flex"}>
                    <InvoiceViewButton params={params} setParams={setParams} />
                    {isAllowedToEdit(params?.type) && <UpdateInvoiceButton fullEdit={filters.type == INVOICE_TYPE.SUPPLIER_INVOICE} params={params} setParams={setParams} />}
                </Box>
            }
        })

        if (bulkInvoiceContext.allow_selection) {

            tableCells.unshift(
                {
                    id: 99, fieldName: '_id', label: 'Select', align: "left", renderValue: (params, setParams) => {
                        return <InvoiceSelectionCheckBox {...params} />
                    }
                }
            )
        }

        return [...tableCells]
    }, [dispatch, filters.type, bulkInvoiceContext.allow_selection, bulkInvoiceContext.data, bulkInvoiceContext.dispatch]);








    // do not change 

    const [loading, setLoading] = useState(false)
    const [exportLoading, setexportLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = (isExport) => {
        if (!isExport) {
            setLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters }),
                (response) => {
                    setList(response)
                    setLoading(false)
                },
                (err) => {
                    setLoading(false)
                }
            ))
        } else {
            setexportLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters, csv: true }),
                (response) => {
                    fileDownload(response, findObjectKeyByValue(filters.type, INVOICE_TYPE) + "invoices.csv")
                    setexportLoading(false)
                },
                (err) => {
                     
                    setexportLoading(false)
                },
                true
            ))
        }
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController isInstaller callBack={async () => { fetchList() }} />, "sm"))
    }

    const onExportBtnClick = () => { fetchList(true) }

    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi

                title={title}
                onCreateBtnClick={onCreateBtnClick}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}
                exportLoading={exportLoading}
                onExportBtnClick={onExportBtnClick}


            />

        </>
    )
}
export default ListController