import { Engineering } from "@mui/icons-material"
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { USER_ROLES } from "../../../utils/constants/constants"
import { openModal } from "../../../store/actions/modalAction"
import { PROJECT_STATUS } from "../../../utils/constants/project.constant"
import UpdateProjectInstallerController from "../installer/UpdateProjectInstallerController"
import { moduleAccessCondition } from "../../../utils/helpers/helper"
import MODULES from "../../../utils/constants/module.constants"

const ProjectInstallerReassignButton = ({ params, setParams, withname }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()


    const onClick = () => {
        dispatch(openModal(<UpdateProjectInstallerController
            installer_id={(params.assigned_installer ?? params.installer_id)?._id}
            installer_name={(params.assigned_installer ?? params.installer_id)?.name}
            installtion_date={params.installtion_date}
            id={params._id} callBack={(fields) => {
                setParams({ ...params, installer_id: { name: fields.assigned_installer_name, _id: fields.assigned_installer } })
            }} />, "sm"))
    }
    if (
        
        ((params.installer_id?._id) || (params?.assigned_installer?._id))

    )
        return <Box sx={{ display: "flex" }} >
            {withname && <Typography>{(params.assigned_installer ?? params.installer_id)?.name ?? "NA"}</Typography>}
           {moduleAccessCondition(user, [MODULES.REASSIGN_INSTALLER])
        && <Tooltip title="Reassign installer" >

                <IconButton onClick={onClick} >
                    <Engineering color="error" />
                </IconButton>
            </Tooltip>}
        </Box>
    return <></>

}
export default ProjectInstallerReassignButton