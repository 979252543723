import UploadSheetUI from "../module/UploadSheetUI";
import React, { useState, useRef } from "react";

import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import { openModal } from "../../store/actions/modalAction";
import PreviewTableController from "./PreviewTableController";
import Papa from "papaparse";
import moment from "moment";

const UploadSheetController = (params) => {
  const dispatch = useDispatch();
  const type = String(params.params)

  const modalKey = "upload-sheet";
  const title = "Upload Sheet"
  const [loading, setLoading] = useState(false);
  // const [date, setDate] = useState(moment().add(-1, "month"));
  const [file, setFile] = useState(null);
  const [err, setErr] = useState("");
  const [hasErr, setHasErr] = useState(false);
  const [data, setData] = useState([]);

  const fields = [
    "name",
    "code",
    "walt",
    "types",
    "amount"
  ];

  const onFileSelect = (e) => {
    setLoading(true);
    
    const file = e.target.files[0];
    setFile(e.target.files[0]);

    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      
      const updatedData =  parsedData.map((item)=>{item.types = type; return item})
      
      const columns = Object.keys(updatedData[0]);
      
      let hasColumnError = false;

      for (let field of fields) {
        if (!columns.includes(field)) { 
          setErr(`Field with fieldname ${field} doesnot exist.`);
          setLoading(false);
          hasColumnError = true;
          break;
        }
      }

      if (parsedData && Array.isArray(parsedData)) {
        const tempData = parsedData.map((parsedrow) => {
          if (Object.keys(parsedrow).length == columns.length) {
            const rowData = {
              hasError: false,
              data: {},
            };
            const row = {};
            for (let cell in parsedrow) {
              
              if (
                (cell == "name" ) && (cell == "code") &&
                parsedrow[cell] == ""
              ) {
                rowData["hasError"] = true;
                setHasErr(true);
                row[cell] = {
                  value: parsedrow[cell],
                  err: "This cell should be non empty.",
                };
              } else if (
                cell != "name" &&  (cell != "code") &&
                (!cell || !parsedrow[cell] || isNaN(parsedrow[cell]))
              ) {
                row[cell] = {
                  value: 0,
                  err: "",
                };
              } else
                row[cell] = {
                  value: parsedrow[cell],
                  err: "",
                };
            }
            rowData["data"] = row;
            return rowData;
          }
        });
        setData(tempData);
      } else {
        setErr("cant read file.");
      }

      setLoading(false);
    };
    reader.readAsText(file);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (file != null ) {
      dispatch(
        openModal(
          
        
            <PreviewTableController
              hasError={hasErr}
              columns={fields}
              file={file}
              data={data}
            />,
            "sm",
            undefined,
          "preview-table"
          ),
        )
    } else {
      setErr("Please select file");
    }
  };

  return (
    <UploadSheetUI
    title={title}
      onSubmit={onSubmit}
      fields={fields}
      loading={loading}
      uploadSheet={onFileSelect}
      data={data}
      file={file}
      setFile={setFile}
      err={err}
      setErr={setErr}
      modalKey={modalKey}
    />
  );
};

export default UploadSheetController;
