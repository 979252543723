import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"

import { Box, Button, ButtonBase, Tooltip, Typography, styled, useTheme } from "@mui/material"
import { closeModal, openModal } from "../../../../store/actions/modalAction"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"
import ProjectUpdateFlagsController from "../../flags/ProjectUpdateFlagsController"
import { USER_ROLES } from "../../../../utils/constants/constants"
import { PROJECT_FLAGS, PROJECT_STATUS } from "../../../../utils/constants/project.constant"
import { INVOICE_STATUS, INVOICE_TYPE } from "../../../../utils/constants/invoice.constant"
import InvoiceCreateController from "../../invoice/CreateController"
import CustomButton, { CustomSubmitIconButton } from "../../../../components/button/CustomButton"
import { getStatusOfProjectFlags } from "../../../../utils/helpers/project.helper"
import CreateOrderButton from "../../order/create/CreateOrderButton"
import RecieveOrderButton from "../../order/recieve/RecieveOrderButton"
import CreateJobPackButton from "../../jobpack/CreateJobPackButton"
import SendToStcButton from "../SendToStcButton"
import CreateSystemInvoiceButton from "../../invoice/CreateSystemInvoiceButton"
import MessageDilog from "../../../../components/MessageDilog"
import { ArrowForward, ArrowRight, Beenhere, BookmarkAdd, BookmarkAdded, Cancel, Check, DoNotDisturb, LockClock, ThumbDown, ThumbsUpDown, Verified } from "@mui/icons-material"
import { moduleAccessCondition } from "../../../../utils/helpers/helper"
import MODULES from "../../../../utils/constants/module.constants"
import { CenteredBox } from "../../../../components/layouts/OneViewBox"

const FlagChangeButton = ({
    text,
    buttons = [{ flag: "", text: "", value: "", color: "" }],
    customComponent,
    projectDetails,
    callBack,
    allowed,
    completed,
    previousStageCompleted,
    hasPaymentAlert
}) => {
    const theme = useTheme()
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()


    const [loading, setLoading] = useState(false)



    const handleChange = (flag, flag_value, title) => {

        dispatch(openModal(
            <ProjectUpdateFlagsController
                title={title}
                flag={flag}
                flag_value={flag_value}
                id={projectDetails?._id}
                callStart={() => {
                    setLoading(true)
                }}
                callBack={() => {

                    setLoading(false)
                    callBack()
                }}


            />,
            "sm",
            undefined,
            MODAL_KEYS.UPDATE_PROJECT_FLAGS
        ))

    }
    const handleChangeWithCheckingInvoice = (flag, flag_value, title) => {
        let invoicePaid = false

        for (let invoice of (projectDetails?.invoices ?? [])) {
            if (invoice?.status == INVOICE_STATUS.PAID && invoice.type == INVOICE_TYPE.CUSTOMER_INVOICE) {
                invoicePaid = true
                break;
            }
        }
        if (!invoicePaid) {
            dispatch(openModal(
                <MessageDilog
                    title="Invoice Payment Alert!"
                    message="There is pending customer invoice ? do You still want to continue?"
                    onSubmit={() => {
                        dispatch(closeModal())
                        handleChange(flag, flag_value, title)
                    }}

                />,
                "sm",
                undefined
            ))
        }
        else {
            handleChange(flag, flag_value, title)
        }

    }
    const hasAllActionRights = moduleAccessCondition(user, [MODULES.ALL_ACTION])
    return <Box sx={{
        display: "flex",
        border: "1px solid " + theme.palette.primary.main,
        background: theme.palette.secondary.main,
        position: "relative",
        overflow: "hidden",
        borderRadius: 1,


    }} mb={1} >

        <Box sx={{ display: "flex", flex: 1 }} p={1} >
            <Typography variant="subtitle2">{text}</Typography>
        </Box>

        <Box sx={{ minHeight: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>

            {!allowed && <CenteredBox sx={{ width: "30px", background: theme.palette.grey.main }} >
                <Tooltip title="User Not allowed To Perfom Action" >
                    <Typography>
                        <DoNotDisturb color="error" />
                    </Typography>
                </Tooltip>
            </CenteredBox>}

            {completed && <CenteredBox sx={{ width: "30px", background: theme.palette.light.main }} >
                <Tooltip title="Action is Completed" >
                    <Typography>
                        <Verified sx={{ lineHeight: "100%" }} color="success" />
                    </Typography>
                </Tooltip>

            </CenteredBox>
            }
            {!previousStageCompleted && !completed && allowed && <CenteredBox sx={{ width: "30px", background: theme.palette.grey.main }} >
                <Tooltip title="Waiting For Previous Stage Complete" >
                    <Box><LockClock color="warning" /> </Box>
                </Tooltip>
            </CenteredBox>}

            {((previousStageCompleted && !completed && allowed) || hasAllActionRights) && Array.isArray(buttons) && buttons?.length > 0 && buttons.map((item) => <Tooltip key={item.value} title={(item.text ?? "Submit")}>


                <CustomSubmitIconButton disableElevation variant="contained" sx={{}} color={item.color ?? "primary"} size="small" onClick={() => {
                    if (hasPaymentAlert)
                        handleChangeWithCheckingInvoice(item.flag, item.value, ((item.text ?? "") + " " + text))
                    else
                        handleChange(item.flag, item.value, ((item.text ?? "") + " " + text))
                }}>
                    {item.component ?? <ArrowForward />}

                </CustomSubmitIconButton>
            </Tooltip>)}
            {
                ((previousStageCompleted && !completed && allowed) || hasAllActionRights) && customComponent
            }


        </Box>
    </Box>
}
const DepositRecieveButton = ({ project_id, callBack }) => {

    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal(<InvoiceCreateController status={INVOICE_STATUS.PAID} project={project_id} callBack={callBack} />, 'sm', undefined, MODAL_KEYS.CREATE_INVOICE))
    }
    return <CustomSubmitIconButton disableElevation variant="contained" sx={{}} color="primary" size="small" onClick={onClick} >
        <ArrowForward />
    </CustomSubmitIconButton>
}
const CreateElectricianInvoiceRecieveButton = ({ project_id, callBack }) => {

    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal(<InvoiceCreateController disableStatus={true} status={INVOICE_STATUS.PENDING} type={INVOICE_TYPE.ELECTRICIAN_INVOICE} project={project_id} callBack={callBack} />, 'sm', undefined, MODAL_KEYS.CREATE_INVOICE))
    }
    return <CustomSubmitIconButton disableElevation variant="contained" sx={{}} color="primary" size="small" onClick={onClick} >
        <ArrowForward />
    </CustomSubmitIconButton>
}


const UpdateFlagsButtons = ({ projectDetails, callBack = () => { } }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const role = user?.data?.role
    const { flags, status, ...remainedDetails } = projectDetails
    const [loading, setLoading] = useState(false)



    const handleChange = (flag, flag_value, title) => {

        dispatch(openModal(
            <ProjectUpdateFlagsController
                title={title}
                flag={flag}
                flag_value={flag_value}
                id={projectDetails?._id}
                callStart={() => {
                    setLoading(true)
                }}
                callBack={() => {

                    setLoading(false)
                    callBack()
                }}


            />,
            "sm",
            undefined,
            MODAL_KEYS.UPDATE_PROJECT_FLAGS
        ))

    }
    const handleChangeWithCheckingInvoice = (flag, flag_value, title) => {
        let invoicePaid = false

        for (let invoice of (projectDetails?.invoices ?? [])) {
            if (invoice?.status == INVOICE_STATUS.PAID && invoice.type == INVOICE_TYPE.CUSTOMER_INVOICE) {
                invoicePaid = true
                break;
            }
        }
        if (!invoicePaid) {
            dispatch(openModal(
                <MessageDilog
                    title="Invoice Payment Alert!"
                    message="There is pending customer invoice ? do You still want to continue?"
                    onSubmit={() => {
                        dispatch(closeModal())
                        handleChange(flag, flag_value, title)
                    }}

                />,
                "sm",
                undefined
            ))
        }
        else {
            handleChange(flag, flag_value, title)
        }

    }

    return <Box>

        {/* for Under Installation */}
        {
            status == PROJECT_STATUS.UNDER_PRE_APPROVAL &&


            <>
                {/* deposit */}
                <FlagChangeButton
                    text="Add Deposit"
                    allowed={moduleAccessCondition(user, [MODULES.DEPOSIT_RECIEVE_BUTTON])}
                    completed={flags?.[PROJECT_FLAGS.DEPOSIT] == getStatusOfProjectFlags(PROJECT_FLAGS.DEPOSIT).RECIEVED}
                    previousStageCompleted={true}
                    buttons={[]}
                    customComponent={<DepositRecieveButton project_id={projectDetails?._id} callBack={callBack} />}
                    projectDetails={projectDetails}
                    callBack={callBack}

                />
                {/* grid pre approval apply */}
                <FlagChangeButton
                    text="Apply Pre Approval"
                    allowed={moduleAccessCondition(user, [MODULES.DEPOSIT_RECIEVE_BUTTON])}
                    completed={flags?.[PROJECT_FLAGS.GRID_PRE_APPROVAL] != getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).PENDING}
                    previousStageCompleted={true}
                    buttons={[{
                        text: undefined,
                        color: undefined,
                        flag: PROJECT_FLAGS.GRID_PRE_APPROVAL,
                        value: getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPLIED
                    }]}
                    projectDetails={projectDetails}
                    callBack={callBack}

                />


                {/* grid pre approval approve / reject */}
                <FlagChangeButton
                    text="Pre Approval"
                    allowed={moduleAccessCondition(user, [MODULES.APPROVE_REJECT_PRE_APPROVAL])}
                    completed={[getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPROVED, getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).REJECTED].includes(flags?.[PROJECT_FLAGS.GRID_PRE_APPROVAL])}
                    previousStageCompleted={flags?.[PROJECT_FLAGS.GRID_PRE_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPLIED}
                    buttons={[
                        {
                            text: "Approve",
                            component: <Check />,
                            color: 'success',
                            flag: PROJECT_FLAGS.GRID_PRE_APPROVAL,
                            value: getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPROVED
                        },
                        {
                            text: "Reject",
                            component: <Cancel />,
                            color: 'error',
                            flag: PROJECT_FLAGS.GRID_PRE_APPROVAL,
                            value: getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).REJECTED
                        }

                    ]}
                    projectDetails={projectDetails}
                    callBack={callBack}

                />

                {/* SV pre approval apply / not applicable */}
                <FlagChangeButton
                    text="SV Application"
                    allowed={moduleAccessCondition(user, [MODULES.APPLY_SV])}
                    completed={flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL] != getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).PENDING}
                    previousStageCompleted={[getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPROVED, getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).REJECTED].includes(flags?.[PROJECT_FLAGS.GRID_PRE_APPROVAL])}
                    buttons={[
                        {
                            text: "Not Applicable",
                            component: <ThumbDown />,
                            color: 'error',
                            flag: PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL,
                            value: getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).NOT_APPLICABLE
                        },
                        {
                            text: "Apply",
                            // component: <Check />,
                            color: 'success',
                            flag: PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL,
                            value: getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPLIED
                        }

                    ]}
                    projectDetails={projectDetails}
                    callBack={callBack}

                />
                {/* SV pre approval approve /reject*/}
                <FlagChangeButton
                    text="SV Approval"
                    allowed={moduleAccessCondition(user, [MODULES.APPROVE_REJECT_SV])}
                    completed={flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPROVED || flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).REJECTED}
                    previousStageCompleted={[getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPLIED].includes(flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL])}
                    buttons={[
                        {
                            text: "Approve",
                            component: <Check />,
                            color: 'success',
                            flag: PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL,
                            value: getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPROVED
                        },
                        {
                            text: "Reject",
                            component: <Cancel />,
                            color: 'error',
                            flag: PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL,
                            value: getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).REJECTED
                        }

                    ]}
                    projectDetails={projectDetails}
                    callBack={callBack}

                />


            </>
        }
        {/* //--------------------------------- under Installation--------------------------------------------------- */}
        {
            status == PROJECT_STATUS.UNDER_INSTALLATION &&
            <>

                {/* {flags?.[PROJECT_FLAGS.INSTALLATION] == getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).SCHEDULED &&
                    <SendToStcButton val={projectDetails.stc_send} id={projectDetails._id} />
                } */}

                {/* Send To Stc */}
                <FlagChangeButton
                    text="Send To Stc"
                    allowed={moduleAccessCondition(user, [MODULES.SEND_TO_STC_BUTTON])}
                    completed={projectDetails.stc_send}
                    previousStageCompleted={flags?.[PROJECT_FLAGS.INSTALLATION] >= getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).SCHEDULED}
                    buttons={[]}
                    customComponent={<SendToStcButton val={projectDetails.stc_send} callBack={callBack} id={projectDetails._id} />}
                    projectDetails={projectDetails}
                    callBack={callBack}

                />

                {/* //Create Customer Invoice  */}
                <FlagChangeButton
                    text="Create Customer Invoice"
                    allowed={moduleAccessCondition(user, [MODULES.CREATE_CUSTOMER_INVOICE])}
                    completed={projectDetails?.system_invoice_created}
                    previousStageCompleted={flags?.[PROJECT_FLAGS.INSTALLATION] == getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).SCHEDULED}
                    buttons={[]}
                    customComponent={<CreateSystemInvoiceButton callBack={callBack} project_id={projectDetails?._id} system_invoice_created={projectDetails?.system_invoice_created} customer_name={projectDetails?.contacts?.[0]?.name} />}
                    projectDetails={projectDetails}
                    callBack={callBack}

                />
                {/* //Create Jobpack  */}
                <FlagChangeButton
                    text="Create Jobpack"
                    allowed={moduleAccessCondition(user, [MODULES.CREATE_JOB_PACK])}
                    completed={false}
                    previousStageCompleted={true}
                    buttons={[]}
                    customComponent={<CreateJobPackButton documents={projectDetails?.documents} name={projectDetails?.contacts?.[0]?.name} projectId={projectDetails?._id} />}
                    projectDetails={projectDetails}
                    callBack={callBack}

                />

                {/* <Box mb={1} />
                <CreateJobPackButton documents={projectDetails?.documents} name={projectDetails?.contacts?.[0]?.name} projectId={projectDetails?._id} />
                <Box mb={1} /> */}


                {/* create Order */}
                <FlagChangeButton
                    text="Create Orders"
                    allowed={moduleAccessCondition(user, [MODULES.PROJECT_ORDERS])}
                    completed={flags?.[PROJECT_FLAGS.PURCHASE_ORDER] >= getStatusOfProjectFlags(PROJECT_FLAGS.PURCHASE_ORDER).CREATED}
                    previousStageCompleted={true}
                    buttons={[]}
                    customComponent={<CreateOrderButton callBack={callBack} system={projectDetails?.selected_system} project_id={projectDetails?._id} />}
                    projectDetails={projectDetails}
                    callBack={callBack}

                />


                {/* Recieve Order */}


                <FlagChangeButton
                    text="Recieve Orders"
                    allowed={moduleAccessCondition(user, [MODULES.PROJECT_ORDERS])}
                    completed={flags?.[PROJECT_FLAGS.PURCHASE_ORDER] == getStatusOfProjectFlags(PROJECT_FLAGS.PURCHASE_ORDER).RECIEVED}
                    previousStageCompleted={flags?.[PROJECT_FLAGS.PURCHASE_ORDER] == getStatusOfProjectFlags(PROJECT_FLAGS.PURCHASE_ORDER).CREATED}
                    buttons={[]}
                    customComponent={<RecieveOrderButton callBack={callBack} suppliers={projectDetails?.assigned_suppliers} project_id={projectDetails?._id} />}
                    projectDetails={projectDetails}
                    callBack={callBack}

                />


                {/* Schedule Installation */}


                <FlagChangeButton
                    text="Schedule Installation"
                    allowed={moduleAccessCondition(user, [MODULES.SCHEDULE_INSTALLATION])}
                    completed={flags?.[PROJECT_FLAGS.INSTALLATION] >= getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).SCHEDULED}
                    previousStageCompleted={true}
                    buttons={[
                        {
                            text: undefined,
                            color: undefined,
                            flag: PROJECT_FLAGS.INSTALLATION,
                            value: getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).SCHEDULED
                        }
                    ]}

                    projectDetails={projectDetails}
                    callBack={callBack}

                />

                {/* STC Apply */}
                <FlagChangeButton
                    text="Apply STC"
                    allowed={moduleAccessCondition(user, [MODULES.APPLY_STC])}
                    previousStageCompleted={flags?.[PROJECT_FLAGS.INSTALLATION] == getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).SCHEDULED}
                    completed={flags?.[PROJECT_FLAGS.STC_CLAIMED] >= getStatusOfProjectFlags(PROJECT_FLAGS.STC_CLAIMED).APPLIED}
                    buttons={[
                        {
                            text: undefined,
                            color: undefined,
                            flag: PROJECT_FLAGS.STC_CLAIMED,
                            value: getStatusOfProjectFlags(PROJECT_FLAGS.STC_CLAIMED).APPLIED
                        }
                    ]}

                    projectDetails={projectDetails}
                    callBack={callBack}

                />

                {/* Qr Scan */}


                <FlagChangeButton
                    text={"SV Qr Scanned"}
                    allowed={
                        flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPROVED
                        &&
                        moduleAccessCondition(user, [MODULES.SV_QR_SCANNED])
                    }
                    previousStageCompleted={flags?.[PROJECT_FLAGS.INSTALLATION] == getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).SCHEDULED}
                    completed={flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_QR] == getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_QR).SCANNED}
                    buttons={[
                        {
                            text: undefined,
                            color: undefined,
                            flag: PROJECT_FLAGS.SOLAR_VICTORIA_QR,
                            value: getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_QR).SCANNED
                        }
                    ]}
                    projectDetails={projectDetails}
                    callBack={callBack}

                />

            </>

        }





        {/* //--------------------------------- under Post Installation--------------------------------------------------- */}
        {
            status == PROJECT_STATUS.UNDER_POST_INSTALLATION &&
            <>
                {/* Add CES */}
                <FlagChangeButton
                    text="CES Certi"
                    hasPaymentAlert
                    allowed={

                        moduleAccessCondition(user, [MODULES.ADD_CES])
                    }
                    previousStageCompleted={true}
                    completed={flags?.[PROJECT_FLAGS.CES_CERTI] == getStatusOfProjectFlags(PROJECT_FLAGS.CES_CERTI).RECIEVED}
                    buttons={[
                        {
                            text: "Add",
                            color: undefined,
                            flag: PROJECT_FLAGS.CES_CERTI,
                            value: getStatusOfProjectFlags(PROJECT_FLAGS.CES_CERTI).RECIEVED
                        }
                    ]}
                    projectDetails={projectDetails}
                    callBack={callBack}

                />



                <FlagChangeButton
                    text="Create Elec. Invoice"
                    hasPaymentAlert
                    allowed={

                        moduleAccessCondition(user, [MODULES.CREATE_ELECTRICIAN_INVOICE])
                    }
                    previousStageCompleted={true}
                    completed={(projectDetails?.invoices?.reduce?.((val, item) => {

                        return item.type == INVOICE_TYPE.ELECTRICIAN_INVOICE || val
                    }, false))}
                    buttons={[

                    ]}
                    projectDetails={projectDetails}
                    customComponent={<CreateElectricianInvoiceRecieveButton project_id={projectDetails?._id} callBack={callBack} />}
                    callBack={callBack}

                />

                {/* Claim STC */}


                <FlagChangeButton
                    text="Claim STC"
                    allowed={

                        moduleAccessCondition(user, [MODULES.CLAIM_STC])
                    }
                    previousStageCompleted={true}
                    completed={flags?.[PROJECT_FLAGS.STC_CLAIMED] >= getStatusOfProjectFlags(PROJECT_FLAGS.STC_CLAIMED).CLAIMED}
                    buttons={[
                        {
                            text: undefined,
                            color: undefined,
                            flag: PROJECT_FLAGS.STC_CLAIMED,
                            value: getStatusOfProjectFlags(PROJECT_FLAGS.STC_CLAIMED).CLAIMED
                        }
                    ]}
                    hasPaymentAlert
                    projectDetails={projectDetails}
                    callBack={callBack}

                />

                {/* Claim SV */}

                {/* {(
                     &&

                    ([USER_ROLES.admin, USER_ROLES.partner_admin].includes(role)
                        ||
                        ())
                )
                    && <CustomButton sx={{ mb: 1 }} size="small" onClick={() => { handleChangeWithCheckingInvoice(PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL).CLAIMED, "Claim SV") }} >
                        Claim SV
                    </CustomButton>
                } */}

                <FlagChangeButton
                    text="Claim SV"
                    allowed={
                        flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPROVED
                        &&
                        moduleAccessCondition(user, [MODULES.CLAIM_SV])
                    }
                    previousStageCompleted={true}
                    completed={flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL] != getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL).PENDING}
                    buttons={[
                        {
                            text: undefined,
                            color: undefined,
                            flag: PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL,
                            value: getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL).CLAIMED
                        }
                    ]}
                    hasPaymentAlert
                    projectDetails={projectDetails}
                    callBack={callBack}

                />
                {/* Apply Grid Post Approval */}
                {/* {(
                    // flags?.[PROJECT_FLAGS.GRID_PRE_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPROVED &&

                    ([USER_ROLES.admin, USER_ROLES.partner_admin].includes(role)
                        ||
                        (flags?.[PROJECT_FLAGS.GRID_POST_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.GRID_POST_APPROVAL).PENDING))
                )
                    && <CustomButton sx={{ mb: 1 }} size="small" onClick={() => { handleChangeWithCheckingInvoice(PROJECT_FLAGS.GRID_POST_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.GRID_POST_APPROVAL).APPLIED, " Apply  Post Approval") }} >
                        Apply  Post Approval
                    </CustomButton>
                } */}

                <FlagChangeButton
                    hasPaymentAlert
                    text="Apply  Post Approval"
                    allowed={

                        moduleAccessCondition(user, [MODULES.APPLY_POST_APPROVAL])
                    }
                    previousStageCompleted={true}
                    completed={flags?.[PROJECT_FLAGS.GRID_POST_APPROVAL] != getStatusOfProjectFlags(PROJECT_FLAGS.GRID_POST_APPROVAL).PENDING}
                    buttons={[
                        {
                            text: "Apply",
                            color: undefined,
                            flag: PROJECT_FLAGS.GRID_POST_APPROVAL,
                            value: getStatusOfProjectFlags(PROJECT_FLAGS.GRID_POST_APPROVAL).APPLIED
                        }
                    ]}
                    projectDetails={projectDetails}
                    callBack={callBack}

                />


                {/* Complete Grid Post Approval  */}

                <FlagChangeButton
                    hasPaymentAlert
                    text="Complete Post Approval"
                    allowed={

                        moduleAccessCondition(user, [MODULES.COMPLETE_POST_APPROVAL])
                    }
                    previousStageCompleted={true}
                    completed={flags?.[PROJECT_FLAGS.GRID_POST_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.GRID_POST_APPROVAL).COMPLETED}
                    buttons={[
                        {
                            text: "Complete",
                            color: undefined,
                            flag: PROJECT_FLAGS.GRID_POST_APPROVAL,
                            value: getStatusOfProjectFlags(PROJECT_FLAGS.GRID_POST_APPROVAL).COMPLETED
                        }
                    ]}
                    projectDetails={projectDetails}
                    callBack={callBack}

                />
            </>

        }

    </Box>
}
export default UpdateFlagsButtons