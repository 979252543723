
import { BatteryCharging20, Bento, CalendarViewMonth, Call, Cancel, CheckCircle, CompareOutlined, Email, Grid3x3Outlined, OpenInNew, People } from "@mui/icons-material"
import { Autocomplete, Button, ButtonBase, ButtonGroup, Chip, CircularProgress, Collapse, Divider, Grid, IconButton, Paper, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Tooltip, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo, useEffect, useState } from "react"

import { CenteredBox } from "../../../components/layouts/common/boxes"
import SelectedSystemButton from "./SelectSystemButton"
import { useDispatch } from "react-redux"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { getProjectSystems } from "../../../apis/projects.api"
import { callApiAction } from "../../../store/actions/commonAction"



const CustomBox = styled(Box)(({ theme }) => ({
    width: "100%",

    border: "1px solid " + theme.palette.primary.main,
    borderTop: "none"


}))
const CustomOuterHeaderBox = styled(Box)(({ theme, selected }) => ({
    width: "100%",

    background: selected ? theme.palette.success.main : theme.palette.primary.main,
    color:  theme.palette.text.invert,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius


}))

const CustomOuterBox = styled(Box)(({ theme }) => ({
    width: "100%",
    border: "1px solid " + theme.palette.light[200],


}))
const CustomHeaderBox = styled(Box)(({ theme }) => ({
    width: "100%",
    border: "1px solid " + theme.palette.primary.main,
    alignItems: "center",
    borderTopLeftRadius: theme.shape.borderRadius,
    background: theme.palette.secondary.main,
    display: "flex",
    borderTopRightRadius: theme.shape.borderRadius


}))


const CollapsableRow = ({ system, id, selected, selectedSystem,callBack }) => {

    const [open, setOpen] = useState(id==1)

    return <Box mb={2}>

        <CustomOuterHeaderBox selected={selected} p={3} onClick={() => setOpen(!open)} component={ButtonBase} sx={{ width: "100%" }}>
            
            {system.name}


        </CustomOuterHeaderBox>
        <Collapse in={open}>
            <CustomOuterBox p={3}>
                {!selectedSystem && <Box mb={4} >

                    <SelectedSystemButton id={system._id} selected={selectedSystem} callBack={callBack} />
                </Box>}
                <Box mb={3}>
                    <Grid container rowSpacing={3} spacing={3}>
                        <Grid item md={4} xs={12} >
                            <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}>Annual Output </Typography>
                            <Typography variant="body2" lineHeight="100%">  {system.annual_output_kwh?.toFixed?.(2)} KWH</Typography>
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Amount Without Tax</Typography>
                            <Typography variant="body2" lineHeight="100%">  {system.amount_excluding_tax?.toFixed?.(2)}</Typography>
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Amount With Tax</Typography>
                            <Typography variant="body2" lineHeight="100%">  {system.amount_including_tax?.toFixed?.(2)}</Typography>
                        </Grid>

                        <Grid item md={4} xs={12} >
                            <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> KW STC</Typography>
                            <Typography variant="body2" lineHeight="100%">  {system.kw_stc ? system.kw_stc : "NA"}</Typography>
                        </Grid>

                        <Grid item md={4} xs={12} >
                            <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Offset</Typography>
                            <Typography variant="body2" lineHeight="100%">  {system.offset ? system.offset : "NA"}%</Typography>
                        </Grid>


                    </Grid>

                </Box>
                <Box mt={3}>

                    <Typography variant="h6" lineHeight="100%">  Components</Typography>
                </Box>

                <CustomHeaderBox p={2} pl={3} mt={2}>
                    <Typography variant="h6" lineHeight="100%" color="orange" >  <CalendarViewMonth fontSize="inherit" /></Typography>
                    <Typography ml={2} variant="body1" lineHeight="100%">  Pannels</Typography>
                </CustomHeaderBox>

                <CustomBox p={3}>
                    {
                        system.modules && system.modules.length > 0 ? system.modules.map((module) => (<Box mt={2} key={module.code}>
                            <Grid container rowSpacing={3} spacing={3}>
                                <Grid item md={4} xs={12} >
                                    <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Name</Typography>
                                    <Typography variant="body2" lineHeight="100%">  {module.name}</Typography>
                                </Grid>
                                <Grid item md={4} xs={12} >
                                    <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Code</Typography>
                                    <Typography variant="body2" lineHeight="100%">  {module.code}</Typography>
                                </Grid>
                                <Grid item md={4} xs={12} >
                                    <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Quantity</Typography>
                                    <Typography variant="body2" lineHeight="100%">  {module.quantity}</Typography>
                                </Grid>
                            </Grid>

                        </Box>)


                        )
                            : <CenteredBox p={3}>No Pannels Found</CenteredBox>
                    }
                </CustomBox>


                <CustomHeaderBox p={2} pl={3} mt={3}>
                    <Typography variant="h6" lineHeight="100%" color="orange" >  <Bento fontSize="inherit" /></Typography>
                    <Typography ml={2} variant="body1" lineHeight="100%">  Inverters</Typography>
                </CustomHeaderBox>

                <CustomBox p={3}>
                    {
                        system.inverters && system.inverters.length > 0 ? system.inverters.map((inverter) => (<Box mt={2} key={inverter.code}>
                            <Grid container rowSpacing={3} spacing={3}>
                                <Grid item md={4} xs={12} >
                                    <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Name</Typography>
                                    <Typography variant="body2" lineHeight="100%">  {inverter.name}</Typography>
                                </Grid>
                                <Grid item md={4} xs={12} >
                                    <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Code</Typography>
                                    <Typography variant="body2" lineHeight="100%">  {inverter.code}</Typography>
                                </Grid>
                                <Grid item md={4} xs={12} >
                                    <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Quantity</Typography>
                                    <Typography variant="body2" lineHeight="100%">  {inverter.quantity}</Typography>
                                </Grid>
                            </Grid>

                        </Box>)


                        )
                            : <CenteredBox p={3}>No Inverter Found</CenteredBox>
                    }
                </CustomBox>



                <CustomHeaderBox p={2} pl={3} mt={3}>
                    <Typography variant="h6" lineHeight="100%" color="orange" >  <BatteryCharging20 fontSize="inherit" /></Typography>
                    <Typography ml={2} variant="body1" lineHeight="100%">  Batteries</Typography>
                </CustomHeaderBox>

                <CustomBox p={3}>
                    {
                        system.battries && system.battries.length > 0 ? system.battries.map((battries) => (<Box mt={2} key={battries.code}>
                            <Grid container rowSpacing={3} spacing={3}>
                                <Grid item md={4} xs={12} >
                                    <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Name</Typography>
                                    <Typography variant="body2" lineHeight="100%">  {battries.name}</Typography>
                                </Grid>
                                <Grid item md={4} xs={12} >
                                    <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Code</Typography>
                                    <Typography variant="body2" lineHeight="100%">  {battries.code}</Typography>
                                </Grid>
                                <Grid item md={4} xs={12} >
                                    <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Quantity</Typography>
                                    <Typography variant="body2" lineHeight="100%">  {battries.quantity}</Typography>
                                </Grid>
                            </Grid>

                        </Box>)


                        )
                            : <CenteredBox p={3}>No Battery Found</CenteredBox>
                    }
                </CustomBox>



                <CustomHeaderBox p={2} pl={3} mt={3}>
                    <Typography variant="h6" lineHeight="100%" color="orange" >  <CompareOutlined fontSize="inherit" /></Typography>
                    <Typography ml={2} variant="body1" lineHeight="100%">  Other Component</Typography>
                </CustomHeaderBox>

                <CustomBox p={3}>
                    {
                        system.others && system.others.length > 0 ? system.others.map((others) => (<Box mt={2} key={others.code}>
                            <Grid container rowSpacing={3} spacing={3}>
                                <Grid item md={4} xs={12} >
                                    <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Name</Typography>
                                    <Typography variant="body2" lineHeight="100%">  {others.name}</Typography>
                                </Grid>
                                <Grid item md={4} xs={12} >
                                    <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Code</Typography>
                                    <Typography variant="body2" lineHeight="100%">  {others.code}</Typography>
                                </Grid>
                                <Grid item md={4} xs={12} >
                                    <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Quantity</Typography>
                                    <Typography variant="body2" lineHeight="100%">  {others.quantity}</Typography>
                                </Grid>
                            </Grid>

                        </Box>)


                        )
                            : <CenteredBox p={3}>No Other Component Found</CenteredBox>
                    }
                </CustomBox>




            </CustomOuterBox>
        </Collapse>

    </Box>
}

const ProjectSystemDetailsUi = ({project_id, selected_system,defaultSystems, setDefaultSystems ,callBack}) => {
    const dispatch = useDispatch()
    const modalKey = MODAL_KEYS.SYSTEMS
    const [loading, setLoading] = useState(false)
    const [systems,setSystems] = useState(defaultSystems??[])
    const fetchSystemsProjectById = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getProjectSystems({ id: project_id }),
            (response) => {
                setSystems([...response])
                setDefaultSystems([...response])
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    useEffect(() => {

        if(defaultSystems && defaultSystems?.length==0)
            fetchSystemsProjectById()

    }, [])

    
        return <>
            <CustomDialog
                id={modalKey}
                title="Project Systems"
            >
                {
                    loading && <CenteredBox><CircularProgress /></CenteredBox>
                }

                {
                    !loading && <>
                    
                    
                    {systems && Array.isArray(systems) && systems.length > 0 ?
                        systems.map((system, index) => (
                            <CollapsableRow callBack={callBack} selectedSystem={selected_system?._id} selected={selected_system?._id == system._id} system={system} key={system._id} id={index + 1} />
                        ))

                        :
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant="h4" color="grey.main">No System Attached</Typography>
                        </Box>}
                    </>
                }

            </CustomDialog>


        </>


    return <></>
}
export default (ProjectSystemDetailsUi)