import { CircularProgress, MenuItem, Typography } from "@mui/material"
import { memo } from "react"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import CustomInput from "../../../components/inputs/CustomInput"




const LeadLastUpdateDateUI = ({ title, fields, setFields, loading, onSubmit }) => {
    
    return <>
        <CustomDialog

            loading={loading}
            err={fields.err}

            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"
            confirmText={`Update`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>

                    <DesktopDatePicker
                        margin="dense"
                        inputFormat="DD-MM-YYYY"
                        value={fields?.date ? moment(fields.date) : null}
                        label="Last Updated Date*"
                        renderInput={(props) => <CustomInput {...props} />}
                        onChange={(e) => setFields({ ...fields, date: e?.toISOString() })}
                    />

                </>}

        </CustomDialog>
    </>
}
export default memo(LeadLastUpdateDateUI)