import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { closeModal } from "../../../store/actions/modalAction"


import { getLeadByIdApi, updateLeadCallApi } from "../../../apis/lead.api"

import LeadCallStatusUpdateUi from "./LeadCallStatusUpdateUi"
import moment from "moment"

import { localStatecallModal } from "../../../utils/helpers/lead.helper"

const LeadCallStatusUpdateController = ({ callBack = () => { }, id,lead_id,lead_status, disableDirectClose, callDetails = {} }) => {
    const validate = useValidate()
    const dispatch = useDispatch()

    const title = "Complete Call"
    const updateStatusApi = updateLeadCallApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        lead_status: lead_status??( callDetails?.current_lead_status ),
        remarks: '',
        lead_id,
        scheduled_date: moment().add(1, "days")

    })

    const [leadDetails, setLeadDetail] = useState(null)
    const [leadLoading, setLeadLoading] = useState(false)



    const validationSchemaForStatusChange = useMemo(() => ([
        {
            required: true,
            value: fields.lead_status,
            field: 'Lead Status ',
        },
    ]), [fields])





    const statusChangeFun = async () => {
        const validationResponse = validate(validationSchemaForStatusChange)

        if (validationResponse === true) {

            const passData = { ...fields }
            passData['scheduled_date'] = passData['scheduled_date'].valueOf()

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateStatusApi(passData),
                    async (response) => {

                        await callBack(response,fields.lead_status)
                        setLoading(false)
                        localStatecallModal().remove()
                        dispatch(closeModal())
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    const fetchById = (id) => {
        setLeadLoading(true)
        dispatch(
            callApiAction(
                async () => await getLeadByIdApi({ id }),
                async (response) => {


                    setLeadDetail({ ...fields, ...response })

                    setLeadLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setLeadLoading(false)

                }
            )
        )
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        statusChangeFun()


    }


    useEffect(() => {        
        fetchById(lead_id??(callDetails?.lead_id))
    }, [])

    return <LeadCallStatusUpdateUi leadLoading={leadLoading} setLeadDetail={setLeadDetail} leadDetails={leadDetails}  title={title} disableDirectClose={disableDirectClose} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(LeadCallStatusUpdateController)