
import { Button, ButtonGroup, Divider, Paper, Tab, Tabs, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import DataTable from "../../../components/tables/DataTable"
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop"
import { USER_ROLES } from "../../../utils/constants/constants"
import AsynSearchBar from "../../../components/inputs/AsynSearchBar"
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector"
import SubmitButton from "../../../components/button/SubmitButton"
import { LEAD_CALL_STATUS } from "../../../utils/constants/lead.constant"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, onCreateBtnClick, filters, setFilters, list, onTabChange, loading, columns }) => {

    const user = useSelector(state => state.user)

    return (
        <>

            <Box mb={3}>

                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >


                    <Box mb={4} >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>

                            <Box mb={2} sx={{ display: "flex" }}>

                                <SubmitButton variant="contained" onClick={onCreateBtnClick} title={"Raise Lead"} />
                            </Box>

                        </FilterTitleBox>


                        <FiltersBox mt={3} >
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }}  >

                                {user.data.role == USER_ROLES.admin && <ButtonGroup variant="outlined" aria-label="Statuses">
                                    {<Button
                                        disableElevation
                                        variant={filters.status === "" ? "contained" : "outlined"}
                                        onClick={(e,) => {
                                            setFilters({ ...filters, status: '' })
                                        }} >All</Button>}
                                    <Button
                                        disableElevation
                                        variant={filters.status === LEAD_CALL_STATUS.PENDING ? "contained" : "outlined"}
                                        onClick={(e) => {
                                            setFilters({ ...filters, status: LEAD_CALL_STATUS.PENDING })
                                        }}>Pending</Button>
                                    <Button
                                        disableElevation
                                        variant={filters.status === LEAD_CALL_STATUS.CALLED ? "contained" : "outlined"}
                                        onClick={(e,) => {
                                            setFilters({ ...filters, status: LEAD_CALL_STATUS.CALLED })
                                        }}>Called</Button>
                                </ButtonGroup>}
                            </PaddingBoxInDesktop>
                            {filters.tab == 0 && <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }}  >


                                <TimeRangeSelector onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />

                            </PaddingBoxInDesktop>}
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={3} >

                                <AsynSearchBar
                                    fullWidth
                                    title="Search lead " size="small" placeholder={"Search Lead"}
                                    defaultValue={filters.search}
                                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                                />
                            </PaddingBoxInDesktop>
                        </FiltersBox>

                    </Box>

                    <Tabs scrollButtons={true} variant="scrollable" allowScrollButtonsMobile value={filters.tab} onChange={onTabChange} sx={{ width: "100%" }} component={Box} >
                        <Tab sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center" }} label="All" />
                        <Tab sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center" }} label="Today" />

                        <Tab sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center" }} label="Past" />

                        <Tab sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center" }} label="Future" />

                    </Tabs>
                    <Divider sx={{ mb: 3 }} />
                    <Box sx={{ minHeight: "300px" }}>
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi