import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";





export const getActivityApi = async params => {
    const callResponse = await axios({
        url: endpoints.activityBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};




export const getActivityByIdApi = async params => {
    const callResponse = await axios({
        url: endpoints.activityById,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};
export const createActivityApi = async (data) => {
    const callResponse = await axios({
        url: endpoints.activityBase,
        method: "POST",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const updateActivityApi = async (data) => {
    const callResponse = await axios({
        url: endpoints.activityBase,
        method: "PUT",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const deleteActivityApi = async (data) => {
    const callResponse = await axios({
        url: endpoints.activityBase,
        method: "DELETE",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const updateActivityPriorityApi = async (data) => {
    const callResponse = await axios({
        url: endpoints.activityPriority,
        method: "PATCH",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const updateActivityActiveStatusApi = async (data) => {
    const callResponse = await axios({
        url: endpoints.activityActiveStatus,
        method: "PATCH",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};