import { memo, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { closeModal } from "../../../store/actions/modalAction"


import { createLeadCallApi } from "../../../apis/lead.api"

import LeadCallCreateUI from "./LeadCallCreateUI"
import moment from "moment"
import { LEAD_CALL_TYPE, LEAD_STATUS } from "../../../utils/constants/lead.constant"

const LeadCallStatusUpdateController = ({ callBack = () => { }, lead_id, disableDirectClose, status }) => {
    const validate = useValidate()
    const dispatch = useDispatch()

    const title = "Create Lead Call"
    const updateStatusApi = createLeadCallApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        lead_id,
        lead_status: LEAD_STATUS.UNHANDLED,
        call_type: LEAD_CALL_TYPE.INQUIRY,
        remarks: '',
        scheduled_date: moment().add(1, "days")
    })




    const validationSchemaForStatusChange = useMemo(() => ([
        {
            required: true,
            value: fields.remarks,
            field: 'Remarks',
        },
    ]), [fields])





    const createFun = async () => {
        const validationResponse = validate(validationSchemaForStatusChange)

        if (validationResponse === true) {

            const passData = { ...fields }
            passData['scheduled_date'] = passData['scheduled_date'].valueOf()

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateStatusApi(passData),
                    async (response) => {

                        callBack({ status: fields.lead_status })

                        setLoading(false)
                        dispatch(closeModal())
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }




    const onSubmit = async (e) => {
        e.preventDefault()
        createFun()


    }



    return <LeadCallCreateUI title={title} disableDirectClose={disableDirectClose} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(LeadCallStatusUpdateController)