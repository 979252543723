import axios from "axios";
import { getFileHeaders, getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";

import { setup, setupCache } from "axios-cache-adapter";
import localforage from "localforage";
import localforageMemoryStorageDriver from "localforage-memoryStorageDriver";


const configureAxios = async () => {

    await localforage.defineDriver(localforageMemoryStorageDriver);

    const forageStore = localforage.createInstance({
        driver: [
            localforage.INDEXEDDB,
            localforage.LOCALSTORAGE,
            localforageMemoryStorageDriver._driver
        ],
        name: `${process.env.REACT_APP_COMPANY_SLAG}-cache `
    });

    return setup({

        // Options passed to `axios.create()` method
        headers: getHeaders(),

        // `axios-cache-adapter` options
        cache: {
            readHeaders: false,
            maxAge: 3 * 60 * 1000,
            exclude: {
                query: false
            },
            store: forageStore,
        }
    });
};



export const getFile = async (url) => {

    const api = await configureAxios();
    const callResponse = await api({
        url: endpoints.fileBase,
        method: "get",
        headers: getHeaders(),
        params: { fileUrl: url },
        responseType: "blob"
    })
        .then(response => response.data)
        .catch(err => { return { status: 0, response: err.response, code: err.response } });

    return callResponse;
};

export const getFileDirect = async (src, withHeaders) => {
    const obj = {
        url: src,
        method: "get",
        responseType: "blob"
    }
    if (withHeaders) {
        obj['headers'] = getHeaders()
    }
    const callResponse = await axios(obj)
        .then(response => response.data)
        .catch((err) => ({ status: 0, response: err.response, code: err.response }));

    return callResponse;
};

export const deleteFile = async (url) => {
    const callResponse = await axios({
        url: endpoints.fileBase,
        method: "delete",
        headers: getHeaders(),
        data: { fileUrl: url },
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const uploadFile = async (data, onUploadProgress) => {
    const callResponse = await axios({
        url: endpoints.fileFile,
        method: "POST",
        headers: getFileHeaders(),
        onUploadProgress,
        data
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const uploadImage = async (data, onUploadProgress) => {
    const callResponse = await axios({
        url: endpoints.fileImage,
        method: "POST",
        headers: getFileHeaders(),
        onUploadProgress,
        data
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

