const mode = "local";
let domain = process.env.REACT_APP_BASE_DOMAIN;
//
//// "proxy": "https://soalr-backend.vercel.app/",
// switch (mode) {
//   case "local":
//     domain = "http://localhost:8000/";
//     break;
//   case "ip":
//     domain = "https://soalr-backend-production-3838.up.railway.app/";
//     break;
//     case "development":
//     domain = "https://soalr-backend.vercel.app/";
//     break;
//     case "azsolar":
//     domain = "https://soalr-backend-production-4127.up.railway.app/";
//     break;
//   default:
//     domain = "/";
// }

const endpoints = {
  root: domain,
  fileImage: `${domain}api/file/image`,
  fileFile: `${domain}api/file/file`,
  fileBase: `${domain}api/file`,
  notificationFetch: `${domain}api/notification`,
  signIn: `${domain}api/auth/login`,
  resetToken: `${domain}api/auth/reset-token`,
  resetPassword: `${domain}api/auth/reset-password`,

  userAndSystemFetchByToken: `${domain}api/user/fetch-by-token`,
  userBase: `${domain}api/user/`,
  userDeleteUndo: `${domain}api/user/undo-delete`,
  userPassword: `${domain}api/user/reset-password`,
  installerUserBase: `${domain}api/user/installer/`,
  installerUserById: `${domain}api/user/installer/by-id`,
  customerBase: `${domain}api/customer/`,

  secretsBase: `${domain}api/secrets/`,
  secretsById: `${domain}api/secrets/by-id`,

  sourcesBase: `${domain}api/sources/`,
  sourcesById: `${domain}api/sources/by-id`,

  loanBenefitsBase: `${domain}api/loanbenefits/`,
  loanbenefitById: `${domain}api/loanbenefits/by-id`,


  sourceCapabilityBase: `${domain}api/source-capability/`,
  sourceCapabilityById: `${domain}api/source-capability/by-id`,



  salesPersonCapabilityBase: `${domain}api/sales-person-capability/`,
  salesPersonCapabilityById: `${domain}api/sales-person-capability/by-id`,

  fbIntegrationsBase: `${domain}api/fb-integration/`,
  fbIntegrationById: `${domain}api/fb-integration/by-id`,

  complainBase: `${domain}api/complain/`,
  complainNotes: `${domain}api/complain/note`,
  complainCounts: `${domain}api/complain/counts`,
  complainCategory: `${domain}api/complain/category`,
  complainById: `${domain}api/complain/by-id`,

  attendenceBase: `${domain}api/attendence/`,
  attendenceStatus: `${domain}api/attendence/status`,

  teamBase: `${domain}api/team/`,
  teamById: `${domain}api/team/by-id`,

  taskBase: `${domain}api/task/`,
  taskStatus: `${domain}api/task/status`,
  taskById: `${domain}api/task/by-id`,

  holiday: `${domain}api/holiday`,

  projectBase: `${domain}api/projects/`,
  projectCounts: `${domain}api/projects/counts`,
  projectFlag: `${domain}api/projects/flag`,
  projectUpdateInstaller: `${domain}api/projects/installer`,
  projectStock: `${domain}api/projects/stock`,
  projectStockItems: `${domain}api/projects/stock-items`,
  projectSelectSystem: `${domain}api/projects/select-system`,
  projectSystems: `${domain}api/projects/systems`,
  projectJobPackDownload: `${domain}api/projects/download-jobpack`,
  projectById: `${domain}api/projects/id/`,

  projectNoteCreate: `${domain}api/projects/notes/`,

  projectLogBase: `${domain}api/projects/logs/`,

  projectOrdersBase: `${domain}api/projects/order`,
  projectOrdersRecieve: `${domain}api/projects/order-recieve`,

  projectPOId: `${domain}api/projects/po`,
  projectFiles: `${domain}api/projects/files`,

  projectDocumentBase: `${domain}api/projects/documets`,
  projectAvailableDocumentTypes: `${domain}api/projects/available-documets-type`,

  jobToStc: `${domain}api/projects/send-job-stc`,

  invoiceBase: `${domain}api/invoice/`,
  invoiceRemarks: `${domain}api/invoice/remarks`,
  invoiceSummary: `${domain}api/invoice/summary`,
  invoiceOverAllSummary: `${domain}api/invoice/all-summary`,
  createInvoiceFromProject: `${domain}api/invoice/create-from-project`,
  bulkInvoice: `${domain}api/invoice/bulk`,
  invoiceDownload: `${domain}api/invoice/download/`,
  invoiceAnanalysis: `${domain}api/invoice/analysis/`,
  invoiceById: `${domain}api/invoice/id/`,
  pendingPayments: `${domain}api/invoice/pending-payments`,
  paypendingPayments: `${domain}api/invoice/close-payment`,
  verifypendingPayments: `${domain}api/invoice/verify-payment`,
  rejectPendingPayments: `${domain}api/invoice/reject-payment`,

  financeBase: `${domain}api/finance/`,
  financeCounts: `${domain}api/finance/counts`,
  financeById: `${domain}api/finance/id/`,

  expenseBase: `${domain}api/finance/expenses`,
  expenseSuggetionBase: `${domain}api/finance/expenses/suggestion`,
  preDefinedExpenseBase: `${domain}api/finance/predefined-expenses`,
  expenseFromPreDefinedExpenseBase: `${domain}api/finance/create-from-pre-exp`,
  expenseById: `${domain}api/finance/expenses/by-id`,

  activityBase: `${domain}api/activity`,
  activityById: `${domain}api/activity/by-id`,
  activityPriority: `${domain}api/activity/priority`,
  activityActiveStatus: `${domain}api/activity/active-status`,


  leadBase: `${domain}api/lead/`,
  leadUnhandled: `${domain}api/lead/unhandled`,
  leadCreateInOpenSolar: `${domain}api/lead/create-in-open`,
  leadAssignerCheck: `${domain}api/lead/check`,
  leadCallBase: `${domain}api/lead/calls`,
  leadReassign: `${domain}api/lead/reassign`,
  chatBaseUrl: `${domain}api/chat`,

  chatGroupsBaseUrl: `${domain}api/chat/groups`,
  chatGrouypsById: `${domain}api/chat/groups/id`,
  chatFetch: `${domain}api/chat/chats`,

  leadStatusUpdate: `${domain}api/lead/status`,
  leadById: `${domain}api/lead/by-id`,
  leadUpdateDate: `${domain}api/lead/date-update`,
  leadAnalysisByStatus: `${domain}api/lead/analysis-by-status`,
  leadAnalysisBySource: `${domain}api/lead/analysis-by-source`,
  leadAnalysisBySourceAndSalesPerson: `${domain}api/lead/analysis-by-source-sales-person`,
  leadAnalysisByStatusesAndSalesPerson: `${domain}api/lead/analysis-by-status-sales-person`,

  leadAnalysisByStatusesAndSource: `${domain}api/lead/analysis-by-status-source`,

  openSolarUserBase: `${domain}api/user/open-solar`,
  userById: `${domain}api/user/fetch-by-id`,

  getautoComplete: `${domain}api/location/autocomplete`,
  getlatandLon: `${domain}api/location/lat-lon`,

  messageBase: `${domain}api/message/`,
  messagesMobiles: `${domain}api/message/mobile`,
  messagesList: `${domain}api/message/sms`,
  messagesConversation: `${domain}api/message/conversation`,
  emailList: `${domain}api/email`,

  templateBase: `${domain}api/template/`,
  templateFetch: `${domain}api/template/fetch`,
  templateUpdate: `${domain}api/template/update`,
  templateFetchByid: `${domain}api/template/by-id`,

  versions: `${domain}api/version`,
  latestVersions: `${domain}api/version/latest`,

  termsAndConditionBase: `${domain}api/terms-condition/`,
  termsAndConditionById: `${domain}api/terms-condition/by-id`,

  moduleBase: `${domain}/api/modules`,
  moduleFetch: `${domain}api/modules/fetch`,
  moduleUpdate: `${domain}api/modules/update`,
  moduleFetchById: `${domain}api/modules/by-id`,
  moduleUpload: `${domain}api/modules/upload`,

  whiteListedBase: `${domain}api/white-listed/`,
  whiteListedById: `${domain}api/white-listed/by-id`,
};

export default endpoints;
