
import { FileOpen, PictureAsPdf } from "@mui/icons-material"
import { CircularProgress, IconButton, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { memo } from "react"

import FileDownloadComponent from "../../../components/FileDownloadComponent"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"


const Container = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    border: "1px solid " + theme.palette.primary.main,
    alignItems: "center",
    borderTopLeftRadius: theme.shape.borderRadius,
    background: theme.palette.secondary.main,
    display: "flex",
    borderRadius: theme.shape.borderRadius


}))

const CustomBox = styled(Box)(({ theme }) => ({
    width: "100%",
    minHeight: "200px",
    border: "1px solid " + theme.palette.primary.main,



}))
const CustomHeaderBox = styled(Box)(({ theme }) => ({
    width: "100%",
    background: theme.palette.primary.main,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius


}))

const ProjectFilesUi = ({ modalKey, files, loading }) => {


    return <>
        <CustomDialog
            id={modalKey}
            title="Open Solar Files"
        >
            {loading ? <CenteredBox><CircularProgress /></CenteredBox> : <Box  >


                {
                    files && Array.isArray(files) && files.length > 0 ?
                        files.map((file, index) => (
                            <Container key={file.title} mt={2} mb={2} p={2}>
                                <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
                                    <PictureAsPdf />  <Typography ml={2} variant="body1" lineHeight="120%" sx={{ wordBreak: "break-word" }}>  {file.title}</Typography>
                                </Box>
                                <Box>
                                    <FileDownloadComponent direct src={file.file} />
                                    <IconButton
                                        size="small"
                                        variant="contained"
                                        disableElevation
                                        
                                        
                                        onClick={() => { 
                                            window.open(file.file, "_blank", `resizable=yes, scrollbars=yes, titlebar=yes, width=${window.innerWidth/2}, height=${window.innerHeight}`) 
                                        }}
                                        // href={file.file}
                                        // target="_blank"
                                        
                                        ><FileOpen /></IconButton>
                                </Box>
                            </Container>
                        ))

                        :
                        <Box sx={{ display: "flex", alignItems: "center", height: "200px", justifyContent: "center" }}>
                            <Typography variant="h4" color="grey.main">No Files Attached</Typography>
                        </Box>
                }




            </Box >}
        </CustomDialog>

    </>



}
export default memo(ProjectFilesUi)