import { ButtonBase } from "@mui/material"
import ProjectDetailsController from "../projectdetails/ProjectDetailsController"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"

const ProjectViewButton = ({ project_id, callBack = () => { }, children,...propsToBePassed }) => {

    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal(<ProjectDetailsController project={project_id} {...propsToBePassed} />, 'xl', undefined, MODAL_KEYS.PROJECT))
    }


    return <ButtonBase onClick={onClick}>
        {children}
    </ButtonBase>
}

export default  ProjectViewButton