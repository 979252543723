import { useCallback, useEffect, useState } from "react"
import LeadAnalyticsByUI from "./LeadAnalyticsByStatusUi"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { getLeadAnalytisByStatusApi } from "../../../apis/lead.api"
import { getDateFiltersTime } from "../../../utils/helpers/helper"

const LeadAnalyticsByStatusController = () => {
    const { dateRange } = useSelector(state => state)
    const dispatch = useDispatch()
    const fetchAPi = getLeadAnalytisByStatusApi
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})

    const [filters, setFilters] = useState({
        assigned_to: null,
        source: null,
        ...(dateRange.get('last_updated_at') ? dateRange.get('last_updated_at') : {
            ...getDateFiltersTime('today'),
            _id: "today",
            label: "Today"
        })
    })
    const fetchDetails = useCallback(() => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchAPi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }, [filters])
    useEffect(() => { fetchDetails() }, [filters])
    return <LeadAnalyticsByUI list={list} loading={loading} filters={filters} setFilters={setFilters} />
}
export default LeadAnalyticsByStatusController