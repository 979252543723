const MODULES = {
  //dashboard
  DASHBOARD_MAIN: 1,
  INSTALLATION_CALENDAR: 1.1,
  STATUS_CROSS_SOURCE: 1.2,
  STATUS_CROSS_SALES_PERSONS: 1.3,
  SOURCE_CROSS_SALES_PERSONS: 1.4,

  //Chats
  CHATS_SECTION_MAIN: 2.1,
  CREATE_CHATS_GROUPS: 2.2,
  VIEW_CHATS_GROUP_DETAIL: 2.3,
  ADD_AND_REMOVE_CHATS_MEMBER: 2.4,
  DELETE_CHAT_MESSAGE: 2.5,

  //Task Management
  TASK_MANAGEMENT_MAIN: 3,

  //Users
  VIEW_USERS: 4.1,
  CREATE_USERS: 4.2,
  UPDATE_USERS: 4.3,
  DELETE_USERS: 4.4,
  CHANGE_PASSWORD: 4.5,
  UNDO_DELETED_USERS: 4.6,
  VIEW_INSTALLER: 4.7,
  UPDATE_MODULE_ACCESS: 4.8,

  //TEAMS
  VIEW_TEAMS: 5.1,
  CREATE_TEAMS: 5.2,
  UPDATE_TEAMS: 5.3,

  //CUSTOMER
  CUSTOMER_MODULE: 6.1,

  //STOCK
  STOCK_MODULE: 7.1,

  //SETTINGS

  VIEW_LEAD_SOURCE: 8.1,
  CREATE_LEAD_SOURCE: 8.2,
  UPDATE_LEAD_SOURCE: 8.3,

  //Holiday
  HOLIDAY_MODULE: 8.4,

  //Secrets
  SECRETS_MODULE: 8.5,

  //Loan benefits
  LOAN_BENEFITS_MODULES: 8.6,

  //Complain Categrories
  COMPLAIN_CATEGORIES_MODULE: 8.7,

  // Versions
  VERSION_MODULE: 8.8,

  // Template
  TEMPLATE_MODULE: 8.9,

  //Terms & Condition Module
  TERMS_AND_CONDITION_MODULE: 8.11,

  //ModuleS
  MODULESS: 8.12,

  //White Listed IP
  WHITE_LISTED_IP: 8.13,

  //Activity Module
  ACTIVITY_MODULE: 8.14,

  //Payment Verification
  PAYMENT_VERIFICATION_MODULE: 9,

  //Final Calculation Module
  FINANCE: 10,

  //Complains
  VIEW_COMPLAINS: 11.1,
  CREATE_COMPLAINS: 11.2,
  EDIT_COMPLAINS: 11.3,
  STATUS_MOVEMENT_COMPLAINS: 11.4,

  // Invoices
  SUMMARY_INVOICES: 12.1,
  EXPORT_CSV_INVOICE: 12.2,
  BULK_INVOICE: 12.3,
  CREATE_INVOICE: 12.4,
  PAY_INVOICE: 12.5,
  DELETE_INVOICE: 12.6,
  SHARE_INVOICE: 12.7,
  REFRESH_INVOICE: 12.8,
  EDIT_INVOICE: 12.9,
  DEPOSIT_INVOICE: 12.11,
  CUSTOMER_INVOICE: 12.12,
  EXTRAA_CHARGE_INVOICE: 12.13,
  GENERAL_INVOICE: 12.14,
  ELEC_INVOICE: 12.15,
  STC_INVOICE: 12.16,
  SV_INVOICE: 12.17,
  SUPPLIER_INVOICE: 12.18,
  ADF_INVOICE: 12.19,
  LOSS_INVOICE: 12.21,
  REFUND_INVOICE: 12.22,

  //Projects

  VIEW_PROJECTS: 13.1,
  CREATE_AND_REFRESH_PROJECTS: 13.2,
  
  REASSIGN_INSTALLER: 13.4,
  PROJECT_ORDERS: 13.5,
  PROJECT_LOGS: 13.6,
  EXPORT_CSV_PROJECTS: 13.34,
  VIEW_PROJECT_DOCUMENTS: 13.7,
  CREATE_PROJECT_DOCUMENTS: 13.8,
  UPLOAD_PROJECT_DOCUMENTS: 13.9,
  SELECT_SYSTEM: 13.11,
  STAGE_MOVEMENTS_PROJECTS: 13.12,
  ADMIN_APPROVALS_PROJECTS: 13.13,
  ALL_STAGE_MOVEMENTS_PROJECTS: 13.14,

  ALL_ACTION: 13.15,
  DEPOSIT_RECIEVE_BUTTON: 13.16,
  PRE_APPROVAL_APPLY: 13.17,
  APPROVE_REJECT_PRE_APPROVAL: 13.18,
  APPLY_SV: 13.19,
  APPROVE_REJECT_SV: 13.21,
  SEND_TO_STC_BUTTON: 13.22,
  CREATE_JOB_PACK: 13.23,
  SCHEDULE_INSTALLATION: 13.24,
  APPLY_STC: 13.25,
  SV_QR_SCANNED: 13.26,
  ADD_CES: 13.27,
  CREATE_CUSTOMER_INVOICE: 13.28,
  CREATE_ELECTRICIAN_INVOICE: 13.29,
  
  CLAIM_SV: 13.31,
  APPLY_POST_APPROVAL: 13.32,
  COMPLETE_POST_APPROVAL: 13.33,
  CLAIM_STC: 13.34,

  // //Leads
  // CENTERAL_SEARCH_LEADS: 14.1,
  // EXPORT_CSV_LEADS: 14.2,
  // VIEW_LEADS: 14.3,
  // EDIT_LEADS: 14.4,
  // EDIT_STATUS_LEADS: 14.5,
  // CREATE_LEADS: 14.6,
  // DELETE_LEADS: 14.7,
  // REASSIGN_SALEPERSON: 14.8,
  // LEAD_LOG_HISTORY: 14.9,
  // LEAD_CALL_HISTORY: 14.11,
  // VIEW_LEAD_CALLS: 14.12,

  //Filters
  SOURCE_FILTER: 15.1,
  SALES_PERSON_FILTER: 15.2,
  // INSTALLER_FILTER: 15.3,
  // SUPPLIER_FILTER: 15.4,
  TEAM_FILTER: 15.5,
  PROJECT_ACTION_FILTER: 15.6,

  //GENRAL FEATURES
  SEND_EMAIL: 16,
};
export default MODULES;
