import { Fragment, useCallback, useEffect, useState } from "react"

import { callApiAction } from "../../store/actions/commonAction"
import { useDispatch, useSelector } from "react-redux"


import { MODAL_KEYS } from "../../utils/constants/modal.constant"

import { getGroupsByIdApi } from "../../apis/chats.api"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { Avatar, Box, Button, ButtonBase, CircularProgress, Divider, Icon, IconButton, MenuItem, Typography } from "@mui/material"
import NoDataComponent from "../../components/layouts/NoDataComponent"
import moment from "moment"

import { findObjectKeyByValue, moduleAccessCondition } from "../../utils/helpers/helper"
import { USER_ROLES } from "../../utils/constants/constants"
import { Remove, RemoveCircle } from "@mui/icons-material"
import MessageDilog from "../../components/MessageDilog"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { addMemberToGroup, removeGroupMember } from "../../store/actions/chatAction"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getUserApi } from "../../apis/user.api"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import MODULES from "../../utils/constants/module.constants"


const AddGroupMemeberModal = ({ onSubmit }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({ memberId: null })
    return <CustomDialog

        onSubmit={(e) => {
            e?.preventDefault()
            onSubmit(state)
            dispatch(closeModal())
        }}

        title="Add Member" >

        <AsyncDropDown

            id={'chat-group'}
            lazyFun={async (params) => { return await getUserApi({ ...params, isDropDown: null }) }}

            OptionComponent={({ option, ...rest }) => {
                return <MenuItem {...rest}>{option.name}</MenuItem>
            }}
            onChange={async (changedVal) => { setState({ ...state, memberId: changedVal ? changedVal._id : null }) }}
            titleKey={'name'}
            valueKey={"_id"}
            InputComponent={(params) => <StyledSearchBar placeholder="Select Member" {...params} size="small" />}
        />
    </CustomDialog>
}
const AddGroupMemberButton = ({ groupId, callBack }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state)

    const onSubmit = (state) => {
        
        if (state.memberId && state?.memberId != '')
            dispatch(addMemberToGroup(groupId, state?.memberId, () => { callBack() }, () => { }))
    }
    const onClick = () => {
        dispatch(openModal(
            <AddGroupMemeberModal onSubmit={onSubmit} />,
            "xs"
            ,
            undefined

        ))
    }


    if (!moduleAccessCondition(user, [MODULES.ADD_AND_REMOVE_CHATS_MEMBER], []))
        return <></>
    return (
        <CenteredBox sx={{ width: "100%", justifyContent: "flex-end" }} >
            <Button onClick={onClick} variant="contained" size="small" disableElevation >Add Memeber</Button>
        </CenteredBox>
    );
}
const RemoveGroupMember = ({ memberName, memberId, groupId, onRemove = () => { } }) => {

    const { user } = useSelector(state => state)

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const onRremove = () => {
        setLoading(true)
        dispatch(closeModal())

        dispatch(removeGroupMember(groupId, memberId, () => { onRemove() }, () => { }))
    }
    const onClick = () => {

        dispatch(openModal(<MessageDilog message={"Are you sure to remove " + memberName + " ?"} title="Alert" onSubmit={onRremove} />,'sm'))
    }
    if (loading)
        return <CircularProgress />

    if (!moduleAccessCondition(user, [MODULES.ADD_AND_REMOVE_CHATS_MEMBER], []))
        return <></>

    return <IconButton onClick={onClick}>
        <RemoveCircle fontSize="inherit" color="error" />
    </IconButton>
}


const ChatMember = ({ name, id, role, groupId }) => {
    const { user } = useSelector(state => state)
    const [data, setData] = useState({
        name, id, role, groupId
    })
    if (!data)
        return <></>

    return <Box sx={{ width: "100%", p: 2, borderBottom: 1, borderColor: "lightgray" }}>

        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }} >
            <Avatar sx={{ height: "30px", width: "30px" }} >
                {data?.name?.slice(0, 1)}
            </Avatar>
            <Box sx={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "flex-start" }} ml={3} >
                <Typography textTransform="capitalize" lineHeight={"130%"} align="left">
                    {data?.name} {id == user?.data?._id && `(You)`}
                </Typography>
                <Typography lineHeight={"100%"} variant="caption" color="grey" align="left">
                    {findObjectKeyByValue(data?.role, USER_ROLES)}
                </Typography>
            </Box>
            <Box>
                <RemoveGroupMember memberName={data?.name} memberId={data.id} groupId={data.groupId} onRemove={() => { setData(null) }} />
            </Box>
        </Box>
    </Box>
}
const ChatGroupDetailUI = ({ id }) => {
    const dispatch = useDispatch()

    const modalKey = MODAL_KEYS.CHAT_GROUP_DETAILS
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)

    const fetchInvoiceById = useCallback(() => {

        setLoading(true)
        dispatch(callApiAction(
            async () => await getGroupsByIdApi({ id }),
            (response) => {

                setData(response)

                setLoading(false)

            },
            (err) => {
                setLoading(false)
            }
        ))

    }, [id])
    useEffect(() => {

        fetchInvoiceById()
    }, [])

    return <CustomDialog
        id={modalKey}
        loading={loading}
        title="Group Detail"
    >
        {loading ? <CenteredBox>
            <CircularProgress />
        </CenteredBox>
            :

            data?._id ?
                <Box>
                    <CenteredBox flexDirection="column" mb={2}>
                        <Avatar sx={{ height: "100px", width: "100px" }} >
                            {data?.name?.slice(0, 1)}
                        </Avatar>
                        <Typography variant="h6" lineHeight={"100%"} mt={2} color="primary">
                            {data?.name}
                        </Typography>
                        <Typography variant="caption" color="grey">
                            Created By {data?.created_by?.name} on {moment(data?.createdAt).format("DD/MM/YYYY")}
                        </Typography>
                    </CenteredBox>
                    <Divider  >
                        Members

                    </Divider>



                    <Box p={2}>
                        <AddGroupMemberButton groupId={data?._id} callBack={fetchInvoiceById} />

                        {/* <TitleBox mt={2} title="Members" > */}
                        {
                            data?.members?.map((item) => <ChatMember key={item?._id} name={item?.name} role={item?.role} groupId={data?._id} id={item?._id} />)
                        }
                        {/* </TitleBox> */}
                    </Box>
                </Box>

                : <NoDataComponent message="No Data Found" ></NoDataComponent>


        }
    </CustomDialog>

}
export default ChatGroupDetailUI
