import { Box, Button, ButtonBase, Grid, styled } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../../../store/actions/modalAction"
import ProjectsNotesController from "../ProjectNotesController"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"
import ProjectLogsUI from "../ProjectLogsUI"
import OpenSolarFiles from "../../documents/OpenSolarFiles"
import ComplainListDialog from "../../../complain/ComplainListDialog"
import ProjectSystemDetailsUi from "../ProjectSystemDetailsUi"
import OrderListController from "../../order/list/ListController"
import CustomButton from "../../../../components/button/CustomButton"
import { moduleAccessCondition } from "../../../../utils/helpers/helper"
import MODULES from "../../../../utils/constants/module.constants"
const CustomRoundedButtons = CustomButton
const ProjectViewMoreButtons = ({ projectDetails, setProjectDetails,callBack }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const onNotesClick = () => {
        dispatch(openModal(<ProjectsNotesController project_id={projectDetails?._id} defaultNotes={projectDetails?.notes ?? []} setDefaultNotes={(resp) => setProjectDetails({ ...projectDetails, notes: resp })} />, 'md', undefined, MODAL_KEYS.NOTES))
    }
    const onLogsButtonClick = () => {
        dispatch(openModal(<ProjectLogsUI project_id={projectDetails?._id} defaultLogs={projectDetails?.logs} setProjectLogs={(resp) => setProjectDetails({ ...projectDetails, logs: resp })} />, 'xs', undefined, MODAL_KEYS.PROJECT_LOGS))
    }
    const onOpenSolarFilesClick = () => {
        dispatch(openModal(<OpenSolarFiles projectId={projectDetails?._id} openSolarFiles={projectDetails?.open_files ?? []} setOpenSolarFiles={(resp) => setProjectDetails({ ...projectDetails, open_files: resp })} />, 'xs', undefined, MODAL_KEYS.OPEN_SOLAR_DOCS))
    }
    const onComplainButtonClick = () => {
        dispatch(openModal(<ComplainListDialog project_id={projectDetails?._id} />, 'md', undefined, MODAL_KEYS.COMPLAINS_LIST))
    }
    const onSystemsButtonClick = () => {
        dispatch(openModal(<ProjectSystemDetailsUi callBack={callBack} selected_system={projectDetails?.selected_system} project_id={projectDetails?._id} defaultSystems={projectDetails?.systems ?? []} setDefaultSystems={(resp) => setProjectDetails({ ...projectDetails, systems: resp })} />, 'md', undefined, MODAL_KEYS.SYSTEMS))
    }
    const onOrderButtonClick = () => {
        dispatch(openModal(<OrderListController project_id={projectDetails?._id} />, 'md', undefined, MODAL_KEYS.ORDERS))
    }
    return <Box sx={{ width: "100%" }} >

        <Grid container spacing={1} >
            <Grid item xs={6} >
                <CustomRoundedButtons onClick={onNotesClick}>
                    Notes
                </CustomRoundedButtons>
            </Grid>
            {moduleAccessCondition(user, [MODULES.VIEW_COMPLAINS], []) && <Grid item xs={6} >
                {<CustomRoundedButtons onClick={onComplainButtonClick} >
                    Complains
                </CustomRoundedButtons>}
            </Grid>}
            {moduleAccessCondition(user, [MODULES.PROJECT_ORDERS], []) &&  <Grid item xs={6} >
                <CustomRoundedButtons onClick={onOrderButtonClick} >
                    Orders
                </CustomRoundedButtons>
            </Grid>}

            <Grid item xs={6} >
                <CustomRoundedButtons onClick={onSystemsButtonClick} >
                    Systems
                </CustomRoundedButtons>
            </Grid>
            { <Grid item xs={6} >
                <CustomRoundedButtons onClick={onOpenSolarFilesClick} >
                    O.S. Files
                </CustomRoundedButtons>
            </Grid>}
            {moduleAccessCondition(user, [MODULES.PROJECT_LOGS], []) && <Grid item xs={6} >
                <CustomRoundedButtons onClick={onLogsButtonClick} >
                    Logs
                </CustomRoundedButtons>
            </Grid>}

        </Grid>
    </Box>
}
export default ProjectViewMoreButtons