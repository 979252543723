import { memo, useCallback, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import { closeModal, openModal } from "../../store/actions/modalAction";
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import CreateController from "./CreateController";
import {
  CheckCircle,
  Delete,
  Edit,
  Info,
  Paragliding,
} from "@mui/icons-material";

import getLoanBenefitApi, {
  deleteLoanBenefitField,
} from "../../apis/loanbenefit.api";
import { MODAL_KEYS } from "../../utils/constants/modal.constant";
import MessageDilog from "../../components/MessageDilog";
import getComplainApi, { updateComplainStatus } from "../../apis/complain.api";
import { COMPLAIN_STATUS } from "../../utils/constants/complain.constant";
import ProjectViewButton from "../projects/buttons/ProjectViewButtons";
import {
  findObjectKeyByValue,
  moduleAccessCondition,
} from "../../utils/helpers/helper";
import moment from "moment";
import MODULES from "../../utils/constants/module.constants";

import DetailedViewController from "./DetailedViewController";

const UpdateSttatusButton = memo(({ params, setParams }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const onSUbmit = (e) => {
    e?.preventDefault();
    dispatch(closeModal());
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await updateComplainStatus({
            id: params?._id,
            status: COMPLAIN_STATUS.COMPLETED,
          }),
        (response) => {
          setParams({ ...params, status: COMPLAIN_STATUS.COMPLETED });
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const onClick = () => {
    dispatch(
      openModal(
        <MessageDilog
          title="Alert!"
          message="Are you sure to mark it complete?"
          onSubmit={onSUbmit}
        />,
        "sm"
      )
    );
  };
  if (
    params.status == COMPLAIN_STATUS.COMPLETED ||
    !moduleAccessCondition(user, [MODULES.STATUS_MOVEMENT_COMPLAINS], [])
  )
    return <></>;
  if (loading) return <CircularProgress />;
  return (
    <IconButton onClick={onClick}>
      <CheckCircle color="success" />
    </IconButton>
  );
});
const ActionComponent = memo(({ params, setParams }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state);

  const onEdit = () => {
    dispatch(
      openModal(
        <CreateController
          project_id={params.project_id?._id}
          id={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "xl",
        undefined,
        MODAL_KEYS.COMPLAINS
      )
    );
  };

  const onDetail = () => {
    dispatch(
      openModal(
        <DetailedViewController id={params?._id} />,
        "md",
        undefined,
       
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
       <Tooltip title="View Statement" arrow>
        <IconButton size="inherit" onClick={onDetail}>
          <Info color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      {params.status != COMPLAIN_STATUS.COMPLETED &&
        moduleAccessCondition(user, [MODULES.EDIT_COMPLAINS], []) && (
          <IconButton size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
          </IconButton>
        )}
      <UpdateSttatusButton params={params} setParams={setParams} />
     
    </Box>
  );
});

const ListController = ({ project_id, modal }) => {
  const dispatch = useDispatch();

  const title = "Complains ";
  const fetchApi = getComplainApi;
  const deleteApi = null;

  const columns = useMemo(
    () => [
      {
        id: 6,
        fieldName: "complain_id",
        label: "Complain Id",
        align: "left",
        sort: true,
        minWidth: "150px",
      },
      {
        id: 0,
        fieldName: "createdAt",
        label: "Date",
        align: "left",
        sort: true,
        renderValue: (params) => moment(params.createdAt).format("DD/MM/YYYY"),
      },
      {
        id: 1,
        fieldName: "project",
        label: "Project",
        align: "left",
        sort: true,
        renderValue: (params) => (
          <ProjectViewButton project_id={params?.project_id?._id}>
            {params?.project_id?.open_solar_project_id}
          </ProjectViewButton>
        ),
      },
      {
        id: 4,
        fieldName: "category",
        label: "Category",
        align: "left",
        renderValue: (params) => params?.category?.title ?? "NA",
      },
      {
        id: 5,
        fieldName: "sub_category",
        label: "Sub Category",
        align: "left",
        renderValue: (params) => params?.sub_category?.title ?? "NA",
      },
      {
        id: 3,
        fieldName: "status",
        label: "Status",
        align: "left",
        sort: true,
        renderValue: (params) => (
          <Chip
            size="small"
            label={findObjectKeyByValue(params?.status, COMPLAIN_STATUS)}
            color={
              params.status == COMPLAIN_STATUS.COMPLETED ? "success" : "default"
            }
          />
        ),
      },
      // { id: 2, fieldName: 'remarks', label: 'Statement', align: "left" },

      {
        id: 5,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["name"],
    sort: "createdAt",
    project_id,
    category: null,
    subCategory: null,
    status: null,
    sortDirection: -1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <CreateController
          project_id={project_id}
          callBack={async () => {
            fetchList();
          }}
        />,
        "xl",
        undefined,
        MODAL_KEYS.COMPLAINS
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        modal={modal}
        title={title}
        onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;
