import { USER_ROLES } from "../utils/constants/constants";
import MODULES from "../utils/constants/module.constants";

const modulesConfig =[
  {
    title: "Dashboard",
    children: [
      {
        title: "Dashboard Main",
        recommended: "*",
        dependent_on: [],
        value: MODULES.DASHBOARD_MAIN,
      },
      {
        title: "Installation Calendar",
        recommended: "*",
        dependent_on: [MODULES.DASHBOARD_MAIN],
        value: MODULES.INSTALLATION_CALENDAR,
      },
      {
        title: "Source ⨯ Sales Person",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        dependent_on: [MODULES.DASHBOARD_MAIN],
        not_allowed: [USER_ROLES.sales],
        value: MODULES.SOURCE_CROSS_SALES_PERSONS,
      },
      {
        title: "Status ⨯ Sales Person",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        dependent_on: [MODULES.DASHBOARD_MAIN],
        not_allowed: [USER_ROLES.sales],
        value: MODULES.STATUS_CROSS_SALES_PERSONS,
      },
      {
        title: "Source ⨯ Status",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        dependent_on: [MODULES.DASHBOARD_MAIN],
        value: MODULES.STATUS_CROSS_SOURCE,
      },
    ],
  },

    {
        title: "Invoices",
        identifier: "INVOICE",
        children: [
            {
                title: "Invoice Summary",
                recommended: [USER_ROLES.admin],
                not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
                dependent_on: [],
                value: MODULES.SUMMARY_INVOICES
            },
            {
                title: "Bulk Invoices",
                recommended: [USER_ROLES.admin, USER_ROLES.partner_admin, USER_ROLES.sub_admin],
                not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
                dependent_on: [],
                value: MODULES.BULK_INVOICE
            },
            {
                title: "Export CSV",
                recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
                not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
                dependent_on: [],
                value: MODULES.EXPORT_CSV_INVOICE
            },
            {
                title: "Delete Invoice",
                recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
                not_allowed: [],
                dependent_on: [],
                value: MODULES.DELETE_INVOICE
            },
            {
                title: "Invoice Create",
                recommended: "*",
                not_allowed: [],
                dependent_on: [],
                value: MODULES.CREATE_INVOICE
            },

      {
        title: "Pay Invoice",
        recommended: "*",
        not_allowed: [],
        dependent_on: [],
        value: MODULES.PAY_INVOICE,
      },
      {
        title: "Share Invoice",
        recommended: "*",
        not_allowed: [],
        dependent_on: [],
        value: MODULES.SHARE_INVOICE,
      },
      {
        title: "Refresh Invoice",
        recommended: "*",
        not_allowed: [],
        dependent_on: [],
        value: MODULES.REFRESH_INVOICE,
      },
      {
        title: "Edit Invoice",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.sub_admin,
        ],
        not_allowed: [],
        dependent_on: [],
        value: MODULES.EDIT_INVOICE,
      },
      {
        title: "Invoice List",
        identifier: "LIST",
        children: [
          {
            title: "Deposit",
            recommended: "*",
            not_allowed: [],
            dependent_on: [],
            value: MODULES.DEPOSIT_INVOICE,
          },
          {
            title: "Customer",
            recommended: "*",
            not_allowed: [],
            dependent_on: [],
            value: MODULES.CUSTOMER_INVOICE,
          },
          {
            //this is also at post installation stage
            title: "Create Customer Invoice",
            recommended: "*",
            not_allowed: [],
            dependent_on: [MODULES.CUSTOMER_INVOICE],
            value: MODULES.CREATE_CUSTOMER_INVOICE,
          },

          {
            title: "Extraa Charge",
            recommended: "*",
            not_allowed: [],
            dependent_on: [],
            value: MODULES.EXTRAA_CHARGE_INVOICE,
          },
          {
            title: "General",
            recommended: "*",
            not_allowed: [],
            dependent_on: [],
            value: MODULES.GENERAL_INVOICE,
          },
          {
            title: "Create Electrician Invoice",
            recommended: [
              USER_ROLES.admin,
              USER_ROLES.sub_admin,
              USER_ROLES.partner_admin,
            ],
            not_allowed: [],
            dependent_on: [MODULES.ELEC_INVOICE],
            value: MODULES.CREATE_ELECTRICIAN_INVOICE,
          },
          {
            title: "Electrician",
            recommended: [
              USER_ROLES.admin,
              USER_ROLES.partner_admin,
              USER_ROLES.sub_admin,
            ],
            not_allowed: [],
            dependent_on: [],
            value: MODULES.ELEC_INVOICE,
          },
          {
            title: "STC",
            recommended: [
              USER_ROLES.admin,
              USER_ROLES.partner_admin,
              USER_ROLES.sub_admin,
            ],
            not_allowed: [],
            dependent_on: [],
            value: MODULES.STC_INVOICE,
          },
          {
            title: "SV",
            recommended: "*",
            not_allowed: [],
            dependent_on: [],
            value: MODULES.SV_INVOICE,
          },
          {
            title: "Supplier",
            recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
            not_allowed: [],
            dependent_on: [],
            value: MODULES.SUPPLIER_INVOICE,
          },
          {
            title: "ADF",
            recommended: "*",
            not_allowed: [],
            dependent_on: [],
            value: MODULES.ADF_INVOICE,
          },
          {
            title: "Loss",
            recommended: "*",
            not_allowed: [],
            dependent_on: [],
            value: MODULES.LOSS_INVOICE,
          },
          {
            title: "Refund",
            recommended: "*",
            not_allowed: [],
            dependent_on: [],
            value: MODULES.REFUND_INVOICE,
          },
        ],
      },
    ],
  },

  {
    title: "Projects",

    children: [
      {
        title: "Projects List",
        recommended: "*",
        not_allowed: [],
        dependent_on: [],
        value: MODULES.VIEW_PROJECTS,
      },
      {
        title: "Create|Refresh Project",
        recommended: "*",
        not_allowed: [],
        dependent_on: [],
        value: MODULES.CREATE_AND_REFRESH_PROJECTS,
      },
      {
        title: "Export CSV",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [],
        dependent_on: [],
        value: MODULES.EXPORT_CSV_PROJECTS,
      },
      {
        title: "Reassign Installer",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.sub_admin,
        ],
        not_allowed: [],
        dependent_on: [],
        value: MODULES.REASSIGN_INSTALLER,
      },

            {
                title: "Project Orders",
                recommended: [USER_ROLES.admin, USER_ROLES.partner_admin, USER_ROLES.sub_admin],
                not_allowed: [],
                dependent_on: [],
                value: MODULES.PROJECT_ORDERS
            },
            {
                title: "Project Logs",
                recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
                not_allowed: [],
                dependent_on: [],
                value: MODULES.PROJECT_LOGS
            },
            {
                title: "View Project Documents",
                recommended: "*",
                not_allowed: [],
                dependent_on: [],
                value: MODULES.VIEW_PROJECT_DOCUMENTS
            },
            {
                title: "Create Project Documents",
                recommended: "*",
                not_allowed: [],
                dependent_on: [],
                value: MODULES.CREATE_PROJECT_DOCUMENTS
            },
            {
                title: "Upload Project Documents",
                recommended: "*",
                not_allowed: [],
                dependent_on: [],
                value: MODULES.UPLOAD_PROJECT_DOCUMENTS
            },
            {
                title: "Manual Sold",
                recommended: [USER_ROLES.admin, USER_ROLES.partner_admin, USER_ROLES.sub_admin],
                not_allowed: [],
                dependent_on: [],
                value: MODULES.SELECT_SYSTEM
            },
            {
                title: "Stage Movements Module",
                children: [

                    {
                        title: "Stage Movements",
                        recommended: [USER_ROLES.partner_admin, USER_ROLES.sub_admin],
                        not_allowed: [],
                        dependent_on: [],
                        value: MODULES.STAGE_MOVEMENTS_PROJECTS
                    },
                    {
                        title: "Admin Approvals",
                        recommended: [USER_ROLES.partner_admin],
                        not_allowed: [],
                        dependent_on: [],
                        value: MODULES.ADMIN_APPROVALS_PROJECTS
                    },
                    {
                        title: "All Stage Movements",
                        recommended: [USER_ROLES.admin],
                        not_allowed: [],
                        dependent_on: [],
                        value: MODULES.ALL_STAGE_MOVEMENTS_PROJECTS
                    }
                ]
            },
            {
                title: "Action Buttons",
                value: MODULES.BACK_TO_PREVIOUS_STAGES,
                children: [
                    {
                        title: "All Action",
                        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
                        not_allowed: [],
                        dependent_on: [],
                        value: MODULES.ALL_ACTION
                    },
                    {
                        title: "Pre Approval Actions",

            children: [
              {
                title: "Deposit Recieve",
                recommended: "*",
                not_allowed: [],
                dependent_on: [],
                value: MODULES.DEPOSIT_RECIEVE_BUTTON,
              },
              {
                title: "Apply Pre Approval",
                recommended: "*",
                not_allowed: [],
                dependent_on: [],
                value: MODULES.PRE_APPROVAL_APPLY,
              },
              {
                title: "Approve/Reject Pre Approval",
                recommended: "*",
                not_allowed: [],
                dependent_on: [],
                value: MODULES.APPROVE_REJECT_PRE_APPROVAL,
              },
              {
                title: "Apply SV",
                recommended: "*",
                not_allowed: [],
                dependent_on: [],
                value: MODULES.APPLY_SV,
              },
              {
                title: "Approve/Reject SV",
                recommended: "*",
                not_allowed: [],
                dependent_on: [],
                value: MODULES.APPROVE_REJECT_SV,
              },
            ],
          },
          {
            title: "Installation Actions",

            children: [
              {
                title: "Send Job To Stc",
                recommended: "*",
                not_allowed: [],
                dependent_on: [],
                value: MODULES.SEND_TO_STC_BUTTON,
              },
              {
                title: "Create Customer Invoice",
                recommended: "*",
                not_allowed: [],
                dependent_on: [],
                value: MODULES.CREATE_CUSTOMER_INVOICE,
              },
              {
                title: "Create Job Pack",
                recommended: [
                  USER_ROLES.admin,
                  USER_ROLES.sub_admin,
                  USER_ROLES.partner_admin,
                ],
                not_allowed: [],
                dependent_on: [],
                value: MODULES.CREATE_JOB_PACK,
              },
              {
                title: "Schedule Installation",
                recommended: "*",
                not_allowed: [],
                dependent_on: [],
                value: MODULES.SCHEDULE_INSTALLATION,
              },
              {
                title: "Apply STC",
                recommended: [
                  USER_ROLES.admin,
                  USER_ROLES.sub_admin,
                  USER_ROLES.partner_admin,
                ],
                not_allowed: [],
                dependent_on: [],
                value: MODULES.APPLY_STC,
              },
              {
                title: "SV QR Scanned",
                recommended: [
                  USER_ROLES.admin,
                  USER_ROLES.sub_admin,
                  USER_ROLES.partner_admin,
                ],
                not_allowed: [],
                dependent_on: [],
                value: MODULES.SV_QR_SCANNED,
              },
            ],
          },
          {
            title: "Post Installation Actions",

            children: [
              {
                title: "Add CES",
                recommended: [
                  USER_ROLES.admin,
                  USER_ROLES.sub_admin,
                  USER_ROLES.partner_admin,
                ],
                not_allowed: [],
                dependent_on: [],
                value: MODULES.ADD_CES,
              },

              {
                title: "Create Electrician Invoice",
                recommended: [
                  USER_ROLES.admin,
                  USER_ROLES.sub_admin,
                  USER_ROLES.partner_admin,
                ],
                not_allowed: [],
                dependent_on: [],
                value: MODULES.CREATE_ELECTRICIAN_INVOICE,
              },
              {
                title: "Claim STC",
                recommended: [
                  USER_ROLES.admin,
                  USER_ROLES.sub_admin,
                  USER_ROLES.partner_admin,
                ],
                not_allowed: [],
                dependent_on: [],
                value: MODULES.CLAIM_STC,
              },
              {
                title: "Claim SV",
                recommended: [
                  USER_ROLES.admin,
                  USER_ROLES.sub_admin,
                  USER_ROLES.partner_admin,
                ],
                not_allowed: [],
                dependent_on: [],
                value: MODULES.CLAIM_SV,
              },
              {
                title: "Apply Post Approval",
                recommended: [
                  USER_ROLES.admin,
                  USER_ROLES.sub_admin,
                  USER_ROLES.partner_admin,
                ],
                not_allowed: [],
                dependent_on: [],
                value: MODULES.APPLY_POST_APPROVAL,
              },
              {
                title: "Complete Post Approval",
                recommended: [
                  USER_ROLES.admin,
                  USER_ROLES.sub_admin,
                  USER_ROLES.partner_admin,
                ],
                not_allowed: [],
                dependent_on: [],
                value: MODULES.COMPLETE_POST_APPROVAL,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Filters",

    children: [
      {
        title: "Sources",
        recommended: "*",
        not_allowed: [],
        dependent_on: [],
        value: MODULES.SOURCE_FILTER,
      },
      {
        title: "Sales Person",
        recommended: "*",
        not_allowed: [USER_ROLES.sales],
        dependent_on: [],
        value: MODULES.SALES_PERSON_FILTER,
      },
      // {
      //     title: "Installers",
      //     recommended: [USER_ROLES.admin, USER_ROLES.partner_admin, USER_ROLES.sub_admin],
      //     not_allowed: [],
      //     dependent_on: [],
      //     value: MODULES.INSTALLER_FILTER
      // },
      // {
      //     title: "Suppliers",
      //     recommended: [USER_ROLES.admin, USER_ROLES.partner_admin, USER_ROLES.sub_admin],
      //     not_allowed: [],
      //     dependent_on: [],
      //     value: MODULES.SUPPLIER_FILTER
      // },
      {
        title: "Teams",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.sub_admin,
        ],
        not_allowed: [USER_ROLES.tl, USER_ROLES.sales],
        dependent_on: [],
        value: MODULES.TEAM_FILTER,
      },
      {
        title: "Project Actions",
        recommended: "*",
        not_allowed: [],
        dependent_on: [],
        value: MODULES.PROJECT_ACTION_FILTER,
      },
    ],
  },

  {
    title: "Complains",

    children: [
      {
        title: "View",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.sub_admin,
        ],
        not_allowed: [],
        dependent_on: [],
        value: MODULES.VIEW_COMPLAINS,
      },
      {
        title: "Create",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.sub_admin,
        ],
        not_allowed: [],
        dependent_on: [MODULES.VIEW_TEAMS],
        value: MODULES.CREATE_COMPLAINS,
      },
      {
        title: "Update",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.sub_admin,
        ],
        not_allowed: [],
        dependent_on: [MODULES.VIEW_TEAMS],
        value: MODULES.EDIT_COMPLAINS,
      },
      {
        title: "Stage Movement",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.sub_admin,
        ],
        not_allowed: [],
        dependent_on: [MODULES.VIEW_TEAMS],
        value: MODULES.STATUS_MOVEMENT_COMPLAINS,
      },
    ],
  },
  {
    title: "Chats",
    children: [
      {
        title: "Chat Section",
        dependent_on: [],
        recommended: "*",
        value: MODULES.CHATS_SECTION_MAIN,
      },
      {
        title: "Chat Create Group",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        dependent_on: [MODULES.CHATS_SECTION_MAIN],
        value: MODULES.CREATE_CHATS_GROUPS,
      },
      {
        title: "View Chat Group Details",
        recommended: "*",
        dependent_on: [MODULES.CHATS_SECTION_MAIN],
        value: MODULES.VIEW_CHATS_GROUP_DETAIL,
      },
      {
        title: "Add & Remove Group Members",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        dependent_on: [MODULES.CHATS_SECTION_MAIN],
        value: MODULES.ADD_AND_REMOVE_CHATS_MEMBER,
      },
      {
        title: "Delete Messages",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        dependent_on: [MODULES.CHATS_SECTION_MAIN],
        value: MODULES.DELETE_CHAT_MESSAGE,
      },
    ],
  },
  {
    title: "Task Management",
    recommended: "*",
    not_allowed: [],
    dependent_on: [],
    value: MODULES.TASK_MANAGEMENT_MAIN,
  },

  {
    title: "Customer Module",
    not_allowed: [],
    dependent_on: [],
    recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
    value: MODULES.CUSTOMER_MODULE,
  },
  {
    title: "Stock Module",
    recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
    not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
    dependent_on: [],
    value: MODULES.STOCK_MODULE,
  },

  {
    title: "Payment Verification",
    recommended: [USER_ROLES.admin],
    not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
    dependent_on: [],
    value: MODULES.PAYMENT_VERIFICATION_MODULE,
  },

  {
    title: "Finance",
    recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
    not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
    dependent_on: [],
    value: MODULES.FINANCE,
  },
  {
    title: "Users",

    children: [
      {
        title: "View Users List",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.sales],
        dependent_on: [],
        value: MODULES.VIEW_USERS,
      },
      {
        title: "Create User",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.sales],
        dependent_on: [MODULES.VIEW_USERS],
        value: MODULES.CREATE_USERS,
      },
      {
        title: "Update User",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.sales],
        dependent_on: [MODULES.VIEW_USERS],
        value: MODULES.UPDATE_USERS,
      },
      {
        title: "Delete User",
        recommended: [USER_ROLES.admin],
        not_allowed: [USER_ROLES.sales],
        dependent_on: [MODULES.VIEW_USERS],
        value: MODULES.DELETE_USERS,
      },
      {
        title: "Change User Password",
        recommended: [USER_ROLES.admin],
        not_allowed: [USER_ROLES.sales],
        dependent_on: [MODULES.VIEW_USERS],
        value: MODULES.CHANGE_PASSWORD,
      },
      {
        title: "Change Module Access",
        recommended: [USER_ROLES.admin],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [MODULES.VIEW_USERS],
        value: MODULES.UPDATE_MODULE_ACCESS,
      },
      {
        title: "Undo Deleted Users",
        recommended: [USER_ROLES.admin],
        not_allowed: [USER_ROLES.sales],
        dependent_on: [MODULES.VIEW_USERS],
        value: MODULES.UNDO_DELETED_USERS,
      },
      {
        title: "View Installers",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.sub_admin,
        ],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [MODULES.VIEW_USERS],
        value: MODULES.VIEW_INSTALLER,
      },
    ],
  },
  {
    title: "Teams",

    children: [
      {
        title: "View Teams List",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [],
        value: MODULES.VIEW_TEAMS,
      },
      {
        title: "Create Teams",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [MODULES.VIEW_TEAMS],
        value: MODULES.CREATE_TEAMS,
      },
      {
        title: "Update Teams",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [MODULES.VIEW_TEAMS],
        value: MODULES.UPDATE_TEAMS,
      },
    ],
  },
  {
    title: "Settings",
    identifier: "SETTING",
    children: [
      {
        title: "Lead Sources",
        children: [
          {
            title: "View",
            recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
            not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
            dependent_on: [],
            value: MODULES.VIEW_LEAD_SOURCE,
          },
          {
            title: "Create",
            recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
            not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
            dependent_on: [MODULES.VIEW_LEAD_SOURCE],
            value: MODULES.CREATE_LEAD_SOURCE,
          },
          {
            title: "Update",
            recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
            not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
            dependent_on: [MODULES.VIEW_LEAD_SOURCE],
            value: MODULES.UPDATE_LEAD_SOURCE,
          },
        ],
      },
      {
        title: "Holidays",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [],
        value: MODULES.HOLIDAY_MODULE,
      },
      
      {
        title:"Versions",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [],
        value: MODULES.VERSION_MODULE,
      },
     
      
      {
        title: "Secrets Module",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [],
        value: MODULES.SECRETS_MODULE,
      },
     
      {
        title: "Loan Benefits Module",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [],
        value: MODULES.LOAN_BENEFITS_MODULES,
      },
      {
        title: "Activity Module",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [],
        value: MODULES.ACTIVITY_MODULE,
      },
      {
        title: "Complain Categories Module",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [],
        value: MODULES.COMPLAIN_CATEGORIES_MODULE,
      },
      {
        title: "Terms & Condition Module",
        recommended: [USER_ROLES.admin],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [],
        value: MODULES.TERMS_AND_CONDITION_MODULE,
      },
      {
        title: "Template Module",
        recommended: [USER_ROLES.admin],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [],
        value: MODULES.TEMPLATE_MODULE,
      },
      {
        title: "Modules",
        recommended: [USER_ROLES.admin],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [],
        value: MODULES.MODULESS,
      },
      {
        title: "White Listed Ip",
        recommended: [USER_ROLES.admin],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [],
        value: MODULES.WHITE_LISTED_IP,
      },
    ],
  },

  {
    title: "General Features",

    children: [
      {
        title: "Send Email",
        recommended: "*",
        not_allowed: [],
        dependent_on: [],
        value: MODULES.SEND_EMAIL,
      },
    ],
  },
];
export default modulesConfig;
