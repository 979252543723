import { memo, useEffect, useState } from "react"
import moment from "moment"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { addLeadInOpenSolarApi, deleteLeadApi, getLeadApi } from "../../apis/lead.api"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { Badge, Box, ButtonBase, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material"
import { useMemo } from "react"

import CreateController from "./CreateController"
import EmailCreateController from "./../email/CreateController"
import { Add, Delete, Edit, Info, Repeat, Visibility, Warning, WbTwilight } from "@mui/icons-material"
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import MessageDilog from "../../components/MessageDilog"
import { LEAD_REPEAT_PARAMETERS, LEAD_STATUS, REPEAT_LEAD_TYPE } from "../../utils/constants/lead.constant"
import DetailedViewController from "./DetailedViewController"
import LeadStatusComponent from "./LeadStatusComponent"
import EditAddressController from "./EditAddressController"
import fileDownload from "js-file-download"
import ProjectCreateButton from '../projects/CreateController'
import ProjectDetailsController from "../projects/projectdetails/ProjectDetailsController"
import LeadReAssignButton from "./reassign/LeadReAssignButton"
import { createAddress } from "../../utils/helpers/helper"
import { callSnackBar } from "../../store/actions/snackbarAction"
import LeadCallCreateButton from "./calls/LeadCallCreateButton"
import LeadCallStatusUpdateController from "./calls/LeadCallStatusUpdateController"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants/constants"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import LeadLastUpdateDateButton from "./last-update-date/LeadLastUpdateDateButton"

const LeadCallAction = memo(({ params, setParams, isCall }) => {
    const dispatch = useDispatch()





    const onCall = () => {

        // localStatecallModal().set({
        //     lead_status: params.status, id: params._id, disableDirectClose: true
        // })

        dispatch(openModal(<LeadCallStatusUpdateController
            lead_id={params._id}
            disableDirectClose={true}
            lead_status={params.status}

            callBack={(response, lead_status) => {

                setParams({ ...params, status: lead_status })
            }} />, "md", true))

    }

    return <ButtonBase sx={{ color: "info.main" }} onClick={onCall} href={"tel:" + params.customer_phone} target="_blank" >

        <Badge color="error" badgeContent={(params.lead_repeat_paramaters && params.lead_repeat_paramaters?.includes(LEAD_REPEAT_PARAMETERS.PHONE)) ? " " : 0} variant="dot">
            {params.customer_phone}
        </Badge>
    </ButtonBase>
})

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state)
    const [loading, setLoading] = useState(false)

    const onEdit = () => {
        dispatch(openModal(<CreateController id={params._id} callBack={(updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "md"))
    }

    const onViewMore = () => {
        dispatch(openModal(<DetailedViewController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "md", undefined, MODAL_KEYS.LEAD_DETAILS))
    }

    const deleteFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteApi({ id: params._id }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())


    }
    const onDelete = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to delete lead of "${params.customer_name}" ?`} />, "sm"))
    }


    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <Tooltip title="View More">
            <IconButton disabled={loading} size="inherit" onClick={onViewMore}>
                <Info color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>

        {params.status != LEAD_STATUS.SOLD && params.status != LEAD_STATUS.NOT_INTRESTED && <Tooltip title="Edit Info">
            <IconButton disabled={loading} size="inherit" onClick={onEdit}>
                <Edit color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>}

        <LeadLastUpdateDateButton id={params?._id} date={params.last_status_updated_at} callBack={(res) => setParams({ ...params, ...res })} />
        {
            <LeadReAssignButton fromTable id={params._id} callBack={(response) => {                
                setParams({ ...params, ...response })
            }} />
        }
        {params.open_solar_id && <Tooltip title="Open in Open solar">
            <IconButton variant="contained" disableElevation disabled={loading} size="inherit"
                onClick={() => window.open(`https://app.opensolar.com/#/projects/${params.open_solar_id}/info`, "_blank", `resizable=yes, scrollbars=yes, titlebar=yes, width=${window.innerWidth}, height=${window.innerHeight}`)}



            >
                <WbTwilight color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>}

        {loading && <CircularProgress color="primary" fontSize="inherit" />}

        {!loading && (user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.partner_admin || user.data.role == USER_ROLES.sub_admin) && (params.status == LEAD_STATUS.UNHANDLED || params.status == LEAD_STATUS.INCOMPLETE || user.data.role == USER_ROLES.admin) && <IconButton disabled={loading} size="inherit" onClick={onDelete}>
            <Delete color="error" fontSize="inherit" />
        </IconButton>}

        <LeadCallCreateButton lead_id={params._id} status={params.status} callBack={(response) => {
            setParams({ ...params, ...response })
        }} />


    </Box>
})

const EditComponent = memo(({ params, setParams }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const onEdit = () => {
        dispatch(openModal(<EditAddressController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} addressInitial={params.customer_address} />, "md"))
    }


    return <>
        {/* {params.status == LEAD_STATUS.INCOMPLETE &&
            <Box>
                <Tooltip title="Edit Address">
                    <IconButton color="error"
                        size="small"
                        variant="contained"
                        onClick={onEdit}
                        disableElevation>
                        <EditLocationAltIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        } */}
        {/* <Box></Box> */}
        <Badge
            color="error"
            badgeContent={

                (params.lead_repeat_paramaters && params.lead_repeat_paramaters?.includes(LEAD_REPEAT_PARAMETERS.ADDRESS)) ? " " : 0
            }
            variant="dot"
        >
            <Box display="inline" dangerouslySetInnerHTML={{ __html: createAddress(params) ?? "NA" }}></Box>
        </Badge>

    </>
})
export const OpenSolarButton = memo(({ setParams, params }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const onOpenSolarProjectCreates = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => addLeadInOpenSolarApi({ id: params._id }),
            (response) => {
                setParams({ ...params, open_solar_id: response?.open_solar_id })
                dispatch(callSnackBar("Project is created in open solar", SNACK_BAR_VARIETNS.suceess))
                setLoading(false)
            },
            (err) => {
                setLoading(false)
                dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
            }
        ))
    }
    if (!(params.customer_phone && params.customer_email && params.customer_address && params.customer_phone != '' && params.customer_email != '' && params.customer_address != ''))
        return <>
            <Tooltip title="Please add all details to create project in open-solar" >
                <Warning color="warning" />
            </Tooltip>
        </>
    if (loading) {
        return <CircularProgress />
    }
    if (params.open_solar_id && params.open_solar_id != '') {
        return <Tooltip title="Open in Open solar">
            <IconButton variant="contained" disableElevation disabled={loading} size="inherit" href={`https://app.opensolar.com/#/projects/${params.open_solar_id}/info`} target="_blank">
                <WbTwilight color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>
    }
    return <Tooltip title="create lead in open solar" >
        <IconButton onClick={onOpenSolarProjectCreates} >
            <Add />
        </IconButton>
    </Tooltip>
})
const ProjectButton = memo(({ params, setParams }) => {
    const dispatch = useDispatch()


    const onViewMore = () => {
        dispatch(openModal(<ProjectDetailsController project={params.project_id} callBack={() => {

        }} />, "lg", undefined, MODAL_KEYS.PROJECT))
    }

    if (!params.open_solar_id) {
        return <>
            <OpenSolarButton params={params} setParams={setParams} />
        </>
    }
    return <>
        {params.project_id ? <IconButton onClick={onViewMore}>
            <Visibility />
        </IconButton> : <ProjectCreateButton id={params.open_solar_id} create callBack={(updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />}
    </>
})
export const LeadListModal = ({ filters = {} }) => {

    return <CustomDialog
        id={MODAL_KEYS.LEAD}
        title="Leads"
    >
        <ListController modal defaultFilters={filters} />
    </CustomDialog>
}
const ListController = ({ defaultFilters = {}, modal }) => {
    const dispatch = useDispatch()


    const onViewMore = (params, setParams) => {
        dispatch(openModal(<DetailedViewController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "md", undefined, MODAL_KEYS.LEAD_DETAILS))
    }
    const title = "Lead Management"
    const fetchApi = getLeadApi
    const deleteApi = deleteLeadApi

    const columns = useMemo(() => [
        { id: 0, minWidth: 100, fieldName: 'createdAt', label: 'Created At', minWidth: "110px", align: "left", sort: true, renderValue: (params, setParams) => params.createdAt && params.createdAt != '' ? moment(params.createdAt).format("DD/MM/YYYY") : "NA" },
        { id: -1, minWidth: 100, fieldName: 'last_status_updated_at', label: 'Last Updated At', minWidth: "110px", align: "left", sort: true, renderValue: (params, setParams) => params.last_status_updated_at && params.last_status_updated_at != '' ? moment(params.last_status_updated_at).format("DD/MM/YYYY") : "NA" },
        { id: 1, minWidth: 150, fieldName: 'name', label: 'Name', align: "left", renderValue: (params, setParams) => <ButtonBase sx={{ textAlign: "left", alignItems: "flex-start" }} onClick={() => onViewMore(params, setParams)}>{params.customer_name + " " + (params.customer_family_name ?? "")}</ButtonBase > },
        {
            id: 2, fieldName: 'email', label: 'Email', align: "left",
            minWidth: "250px",
            renderValue: (params, setParams) => <ButtonBase onClick={() => { dispatch(openModal(<EmailCreateController to={params.customer_email} />, "lg", undefined, MODAL_KEYS.EMAIL)) }} >
                <Badge color="error" badgeContent={(params.lead_repeat_paramaters && params.lead_repeat_paramaters?.includes(LEAD_REPEAT_PARAMETERS.EMAIL)) ? " " : 0} variant="dot">
                    {params.customer_email}
                </Badge>
            </ButtonBase>
        },
        {
            id: 3, fieldName: 'phone', minWidth: "100px", label: 'Phone no.', align: "left", renderValue: (params, setParams) => <LeadCallAction params={params} setParams={setParams} />
        },
        {
            id: 4, fieldName: 'customer_address', minWidth: 400, label: 'Address', align: "left",
            renderValue: (params, setParams) => <>

                <EditComponent params={params} setParams={setParams} />
            </>
        },
        {
            id: 5, fieldName: 'assigned_to', minWidth: 150, label: 'Assigned To', align: "left", renderValue: (params) => params.assigned_to && params.assigned_to.length > 0 ?
                <Box >

                    <Typography textTransform="capitalize" variant="td">
                        {params.assigned_to[0].name}
                    </Typography>

                </Box> : "NA",
        },
        {
            id: 6,
            fieldName: 'source',
            label: 'Source',
            minWidth: 50,
            align: "left",
            sort: true,
            renderValue: (params) => params.source ? params.source.name : "NA",
        },
        {
            id: 7,
            fieldName: 'status',
            label: 'Status',
            minWidth: 200,
            sort: true,
            align: "left",
            renderValue: (params, setParams) => <LeadStatusComponent onlyview params={params} setParams={setParams} />,
        },
        {
            id: 8,
            fieldName: 'action',
            label: 'Project ',
            align: "left",
            renderValue: (params, setParams) => <ProjectButton params={params} setParams={setParams} />,
        },
        {
            id: 9,
            minWidth: "250px",
            fieldName: 'action',
            label: 'Actions',
            align: "left",
            renderValue: (params, setParams) => <ActionComponent deleteApi={deleteApi} params={params} setParams={setParams} />,
        },


    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['customer_name', 'customer_family_name', 'customer_email', 'customer_phone', 'customer_address'],
        source: '',
        tab: null,
        status: null,
        assigned_to: null,
        startDate: null, endDate: null,
        sort: 'updatedAt',
        rangeSelector: "",
        sortDirection: -1,
        ...defaultFilters

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [exportLoading, setexportLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = (isExport) => {
        if (!isExport) {
            setLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters }),
                (response) => {
                    setList(response)
                    setLoading(false)
                },
                (err) => {
                    setLoading(false)
                }
            ))
        } else {
            setexportLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters, csv: true }),
                (response) => {
                    fileDownload(response, "lead.csv")
                    setexportLoading(false)
                },
                (err) => {
                     
                    setexportLoading(false)
                },
                true
            ))
        }
    }

    const onCreateBtnClick = (e, alreadyInOpenSolar) => {
        dispatch(openModal(<CreateController alreadyInOpenSolar={alreadyInOpenSolar} callBack={async () => { fetchList() }} />, "sm"))
    }


    const onExportBtnClick = () => { fetchList(true) }

    useEffect(() => {
        fetchList()
    }, [filters])


    // useEffect(() => {
    //     if (localStatecallModal().get()) {
    //         const props = localStatecallModal().get()

    //         dispatch(openModal(<LeadCallStatusUpdateController {...props} />, "md", true))

    //     }
    // }, [])


    return (
        <>
            <ListUi
                modal={modal}
                title={title}
                onCreateBtnClick={onCreateBtnClick}
                onExportBtnClick={onExportBtnClick}
                exportLoading={exportLoading}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController