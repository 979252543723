import { Autocomplete, ButtonBase, CircularProgress, Collapse, IconButton, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'



import CustomInput from "../../components/inputs/CustomInput"

import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import FileInput from "../../components/inputs/FileInput"

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Delete, Description, PictureAsPdf, UploadFile } from "@mui/icons-material"
import { MuiChipsInput } from "mui-chips-input"
import { validateEmail } from "../../utils/helpers/helper"
const FileViewrBox = styled(Box)(({ theme }) => ({
    width: "100%",
    border: "1px solid " + theme.palette.primary.main,
    position: "relative",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    cusrsor: "pointer",
    alignItems: "center",

    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1),

    marginBottom: theme.spacing(1),
    "*": {
        zIndex: 1,
        wordBreak: "break-word",
    },
    "::after": {
        zIndex: 0,
        content: "' '",
        position: "absolute",
        top: "0px",

        left: "0px",
        height: "100%",
        width: "100%",
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.primary.main,
        opacity: "0.2",
    }
}))
const FileContainer = styled(ButtonBase)(({ theme }) => ({
    // width: "100%",
    border: "2px dashed " + theme.palette.primary.main,
    position: "relative",
    // minHeight: "50px",
    display: "flex",
    justifyContent: "flex-start",
    cusrsor: "pointer",

    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    paading: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "input": {
        cursor: "pointer",
        opacity: "0",
        zIndex: 11,
        position: "absolute",
        top: "0px",
        left: "0px",
        height: "100%",
        width: "100%",
    },
    "::after": {
        content: "' '",
        position: "absolute",
        top: "0px",
        left: "0px",
        height: "100%",
        width: "100%",
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.primary.main,
        opacity: "0.2",
    }
}))

const CCANDBCCBOX = ({ fields, setFields, loading }) => {
    const [show, setShow] = useState(false)
    const toogleShow = () => {
        if (show) {
            setFields({ ...fields, err: '', cc: [], bcc: [] })
            setShow(false)
        } else {
            setShow(true)
        }
    }
    return <>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }} >
            <Typography color='info.main' component={ButtonBase} onClick={toogleShow} >{show?"Hide":"Show"} CC && BCC</Typography>
        </Box>
        {<Collapse in={show} >
            <MuiChipsInput disabled={loading} fullWidth margin="dense" value={fields.cc ?? []} type="email"
                label={"CC*"} onChange={(newData) => {
                    if (newData && newData.length > 0 && newData.reduce((val, item) => validateEmail(item) ? val : false, true) == true) {
                        setFields({ ...fields, err: '', cc: newData })
                    } else {
                        setFields({ ...fields, err: '', cc: [] })
                    }


                }} />
            <MuiChipsInput disabled={loading} fullWidth margin="dense" label={"BCC*"} value={fields.bcc ?? []} onChange={(newData) => {
                if (newData && newData.length > 0 && newData.reduce((val, item) => validateEmail(item) ? val : false, true) == true) {
                    setFields({ ...fields, err: '', bcc: newData })
                } else {
                    setFields({ ...fields, err: '', bcc: [] })
                }


            }} />
        </Collapse>
        }    </>
}
const CreateUi = ({ title, fields, setFields, loading, onSubmit, setfiles, files, templates,modalKey }) => {
    const { user } = useSelector(state => state)
    const [editor, setEditor] = useState(null)
    //     useEffect(() => {
    //         if (editor) {
    // editor.setData()
    //         }
    //     }, [fields.template])
    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${title}`}
            closeText="Close"
            confirmText={`Send`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    <CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.to}
                        onChange={(e) => setFields({ ...fields, err: '', to: e.target.value })}
                        type="email"
                        label={"To*"}

                    />
                    <CCANDBCCBOX fields={fields} loading={loading} setFields={setFields} />
                    <CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.subject}
                        onChange={(e) => setFields({ ...fields, err: '', subject: e.target.value })}
                        type="text"
                        label={"subject*"}

                    />

                    <Autocomplete

                        disableClearable
                        onChange={(e, val) => {
                            setFields({ ...fields, template: val.value, html: templates[val.value]?.template?.() })
                        }}
                        options={templates}

                        getOptionLabel={(option) => option.label}
                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        renderInput={(params) => <CustomInput placeholder="Select Template" label="Select Template" {...params} />}
                    />
                    <Box mt={2} key={fields.template}>
                        <CKEditor

                            config={{
                                placeholder: "Enter Email Body...",
                                style: { height: "500px", minHeight: "500px" },
                                toolbar: { items: ['p', 'heading', 'italic', 'bold', 'blockquote', 'link', 'table', 'undo', 'redo', 'numberedList', 'bulletedList'] }

                            }}
                            editor={ClassicEditor}
                            data={fields.html}
                            onReady={editor => {

                                setEditor(editor)
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setFields({ ...fields, html: data })
                            }}
                            onBlur={(event, editor) => {

                            }}
                            onFocus={(event, editor) => {


                            }}

                        />


                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>


                        <FileContainer mt={2} mb={2}>

                            <input type="file" name="file" multiple accept=".pdf,.png,.jpg,.jpeg" onChange={(e) => {

                                if (e.target.files?.length > 0) {
                                    setfiles([...files, ...e.target.files])
                                }
                            }}></input>

                            {/* {
                                loading && <CenteredBox p={3}>

                                    <LinearProgress sx={{ zIndex: 1111, width: "100%" }} variant="determinate" value={percentage} />
                                </CenteredBox>
                            } */}
                            {!loading && <><Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                                <Typography variant="h4" align="center" lineHeight="100%"><UploadFile fontSize="inherit" lineHeight="100%"></UploadFile></Typography>
                            </Box>
                                {/* <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", flex: 1 }} ml={3}>
                                    <Typography align="left" variant="h6" fontWeight="bold">{"Attch Files"}</Typography>
                                    <Typography align="left" variant="body2">{"only image files & pdf are allowed"}</Typography>
                                </Box> */}
                            </>}


                        </FileContainer>
                        {
                            Array.from(files).map((file, index) => <FileViewrBox p={2} key={file.name}>
                                <Description />&nbsp;&nbsp;
                                <Box display="flex" flex={1}>
                                    <Typography>
                                        {file.name}
                                    </Typography>
                                </Box>

                                <IconButton size="small"

                                    onClick={() => {
                                        const filesArr = [...files]
                                        filesArr?.splice(index, 1)
                                        setfiles(filesArr)
                                    }}
                                >
                                    <Delete color="error"></Delete>
                                </IconButton>
                            </FileViewrBox>)
                        }
                    </Box>
                </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)