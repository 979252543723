import { Autocomplete, Avatar, Badge, Box, Button, CircularProgress, IconButton, MenuItem, Tooltip, Typography } from "@mui/material"
import CustomInput from "../../components/inputs/CustomInput"
import { findNameByRole, findObjectKeyByValue, titleCase } from "../../utils/helpers/helper"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { getOpenSolarUserApi, getUserApi } from "../../apis/user.api"
import GoogleAddress from "../../components/inputs/GoogleAddress"

const EditAddressUI = ({ loading, title, autoCompleteloading, setSearch, initial, autoCompleteobj, setPlaceId, address, setAddress, onsubmit, assigned_to, setAssign_to }) => {
    
    return (
        <>
            <CustomDialog
                loading={loading}
                // onSubmit={onSubmit}
                title={`${title}`}
                closeText="Close"
            // confirmText={`${isUpdate ? "Update" : "Raise"}`}s
            >
                {loading ? <CenteredBox><CircularProgress /></CenteredBox> : <>
                    <Box>
                        {/* <Autocomplete
                        loading={autoCompleteloading}
                            disableClearable
                            defaultValue ={initial}
                            value={address}
                            onChange={(e, newVal) => {
                                setPlaceId(newVal._id)                                
                                setAddress(newVal.label)

                            }}
                            
                            
                            options={Array.isArray(autoCompleteobj) ? (autoCompleteobj).map((key) => ({ label: titleCase(key.description), _id: key.place_id })) : []}
                            label={"Customer Address.*"}
                            renderInput={(params) => <CustomInput {...params} placeholder="Customer Address.*" label="Customer Address.*"
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                                sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                            />}
                        /> */}

                        <GoogleAddress
                            onChange={() => {

                            }}
                            defaultAddress={initial}
                        >

                        </GoogleAddress>
                        <AsyncDropDown
                            id={'assigned-to-address'}
                            value={assigned_to}
                            lazyFun={getUserApi}
                            label="Assigned To"
                            OptionComponent={({ option, ...rest }) => {
                                return <MenuItem {...rest}>{option.name}</MenuItem>
                            }}
                            disablePortal={true}
                            onChange={async (changedVal) => { setAssign_to(changedVal._id) }}
                            titleKey={'name'}
                            valueKey={"_id"}
                        />
                    </Box>
                    <Box mt={"4px"}>
                        <Button variant="contained" color="primary" sx={{ float: "right" }} mt={"4px"} onClick={onsubmit}>
                            change
                        </Button>
                    </Box>
                </>}
            </CustomDialog>
        </>
    )
}

export default EditAddressUI