import {
  CircularProgress,
  Box,
  Autocomplete,
  Button,
  Popover,
  Typography,
} from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import { insertNewString, titleCase } from "../../utils/helpers/helper";
import CustomInput from "../../components/inputs/CustomInput";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { findObjectKeyByValue } from "../../utils/helpers/helper";
import { TEMPLATE_CATEGORY } from "../../utils/constants/constants";
import EVENT from "../../utils/constants/event.constant";
import eventConfig from "../../config/event.config";
import { Add } from "@mui/icons-material";

const CreateUi = ({
  title,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  const editorRef = useRef(null);
  const insertTextAtCaret = (text) => {
    const editor = editorRef.current.editor;

    editor.model.change((writer) => {
      const selection = editor.model.document.selection;
      const range = selection.getFirstRange();

      if (range) {
        writer.insertText("{{" + text + "}}", range.end);
      }
    });

    editor.editing.view.focus();
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleVariableClick = (variable) => {
    insertTextAtCaret(variable);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <CustomDialog
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Email"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            {
              <>
                <CustomInput
                  autoFocus={true}
                  disabled={loading}
                  value={fields.title}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      title: e.target.value,
                    })
                  }
                  type="text"
                  label={"Title*"}
                />

                <Autocomplete
                  value={findObjectKeyByValue(fields.event, EVENT)}
                  onChange={(e, newVal) => {
                    setFields({
                      ...fields,
                      event: newVal ? newVal._id : null,
                    });
                  }}
                  options={[
                    ...Object.keys(EVENT).map((key) => ({
                      label: titleCase(key),
                      _id: EVENT[key],
                    })),
                  ]}
                  sx={{
                    width: "100%",
                    display: "flex",
                    "*": { display: "flex", justifyContent: "center" },
                  }}
                  renderInput={(params) => (
                    <CustomInput
                      placeholder="Select Event*"
                      {...params}
                      label="Select Event*"
                    />
                  )}
                />

                {/* {fields.event && (
                  <>
                  
                    <Autocomplete
                      value={fields.allowed_variables}
                      onChange={(e, newVal) => {
                        if (newVal) {
                          // setFields({
                          //   ...fields,
                          //   template:insertNewString(fields.template,`{{${newVal}}}`)
                          // });
                          insertTextAtCaret(`{{${newVal}}}`);
                        }
                      }}
                      options={eventConfig[fields?.event - 1].allowed_variables}
                      sx={{
                        width: "100%",
                        display: "flex",
                        "*": { display: "flex", justifyContent: "center" },
                      }}
                      renderInput={(params) => (
                        <CustomInput
                          placeholder="Select Variables*"
                          {...params}
                          label="Select Variables*"
                        />
                      )}
                    />
                  </>
                )} */}
                {/* fields.event */}
                {true && (
                  <>
                    {" "}
                    <Button
                      onClick={handleClick}
                      aria-describedby={id}
                      sx={{
                        display: "flex",
                        height: "100%",
                        zIndex: 0,
                        textTransform: "capitalize",
                        boxShadow: "none",
                        border: "1px solid #393939",
                        width: "200px",
                        height: "56px",
                        ":hover": {
                          boxShadow: "none",
                        },
                      }}
                    >
                      <Add color="primary" /> &nbsp;Add Variables
                    </Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      sx={{ width: "100%" }}
                    >
                      {loading && state.length === 0 ? (
                        <Box
                          style={{
                            width: "380px",
                            height: "180px",
                            maxWidth: "100%",
                            backgroundColor: "background.paper",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Box sx={{ padding: "16px", width: "200px" }}>
                          {fields.event &&
                            eventConfig[fields.event - 1].allowed_variables.map(
                              (variable, index) => (
                                <Typography
                                  key={index}
                                  variant="body1"
                                  onClick={() => handleVariableClick(variable)}
                                  style={{
                                    cursor: "pointer",
                                    marginBottom: "8px",
                                  }}
                                >
                                  {variable}
                                </Typography>
                              )
                            )}
                        </Box>
                      )}
                    </Popover>
                  </>
                )}

                <Box mt={2}>
                  <CKEditor
                    config={{
                      placeholder: "Enter Template...",
                      style: { height: "500px", minHeight: "500px" },
                      toolbar: {
                        items: [
                          "p",
                          "heading",
                          "italic",
                          "bold",
                          "blockquote",
                          "link",
                          "table",
                          "undo",
                          "redo",
                          "numberedList",
                          "bulletedList",
                        ],
                      },
                    }}
                    editor={ClassicEditor}
                    data={fields.template}
                    onReady={(editor) => {
                      editorRef.current = { editor };
                      // setEditor(editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFields({ ...fields, template: data });
                    }}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {}}
                  />
                </Box>
              </>
            }
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUi);
