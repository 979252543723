
import { Call, Cancel, CheckCircle, Email, FmdGood, Map, OpenInNew, People, Phone, Pin } from "@mui/icons-material"
import { Autocomplete, Button, ButtonBase, ButtonGroup, Chip, CircularProgress, Divider, Grid, IconButton, Paper, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Tooltip, Typography, styled, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import { createAddress, findObjectKeyByValue, projectStatusColor } from "../../../utils/helpers/helper"
import { CenteredBox } from "../../../components/layouts/OneViewBox"


const CustomBox = styled(Box)(({ theme }) => ({
    width: "100%", height: "100%",
    background: theme.palette.secondary.main,
    display: "flex",
    overflow: "hidden",
    border: "1px solid  " + theme.palette.primary.main,


}))
const SiteBox = styled(Box)(({ theme,size }) => ({
    width: "100%",
    height: "100%",
    flexDirection:size!="small"? "column":'row-reverse',
    justifyContent:size!="small"? "center":"space-evenly",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.secondary.main,
    display: "flex",
    overflow: "hidden",
    position: "relative",
    color: theme.palette.primary.main,
    border: "1px solid  " + theme.palette.primary.main,

    paddingLeft: 1,

    "::after": {
        content: "' '",
        position: "absolute",
        top: "0px",
        left: "0px",
        height: "100%",
        width: '10px',
        zIndex: 1,
        background: theme.palette.primary.main,
        // background: theme.palette.primary.light,
    }

}))
const CustomHeaderBox = styled(Box)(({ theme }) => ({
    width: "100%",
    background: theme.palette.primary.main,
    color: theme.palette.primary.light,

}))

const ProjectContactDetailsUi = ({ data }) => {
    const contact = data?.contacts?.[0] ?? {}
    return <CustomBox component={Paper} flexDirection="column" elevation={0} p={2}>


        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography variant="body2" ><People  ></People> </Typography>
            <Typography ml={2} variant="body2">{contact.name}</Typography>
        </Box>



        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography variant="body2"><Email /> </Typography>
            <Typography ml={2} variant="body2">{contact.email}</Typography>
        </Box>





        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography variant="body2"><Phone ></Phone> </Typography>
            <Typography ml={2} variant="body2">{contact.phone}</Typography>
        </Box>




        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography variant="body2"><FmdGood /></Typography>
            <Typography ml={2} variant="body2">{createAddress(data, true)}</Typography>
        </Box>


    </CustomBox>
}
export default ProjectContactDetailsUi


const ModuleComponent = ({ code, quantity }) => {
    const theme = useTheme()
    return <Box sx={{ display: "flex", borderRadius: 1, overflow: "hidden", alignItems: "stretch", minHeight: "100%" }}>
        <Box sx={{ display: "flex", background: theme.palette.primary.light }} px={2} >
            <Typography variant="caption" >
                {code}
            </Typography>
        </Box>
        <Box sx={{ width: "30px", background: theme.palette.primary.main, minHeight: "100%", flex: "none" }}>
            <CenteredBox>
                <Typography color="primary.light" variant="body2" >
                    {quantity}
                </Typography>
            </CenteredBox>
        </Box>

    </Box>
}
export const ProjectSelectedSystemDetailsUi = ({ data }) => {
    const selectedSystem = data?.selected_system
    // if(!selectedSystem){}
    return <CustomBox sx={{ background: "unset" }} component={Paper} flexDirection="column" elevation={0}>

        {!selectedSystem ? <CenteredBox><Typography variant="h6" >
            No System Selected
        </Typography></CenteredBox> : <>

            <CustomHeaderBox  ><Typography align="center" variant="h6" >
                {selectedSystem?.kw_stc} KW
            </Typography></CustomHeaderBox>


            <Box p={2}>
                <Grid container spacing={2} >
                    <Grid item xs={6} >
                        <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                            <Typography variant="body2" fontWeight={600}>Pannels: </Typography>
                            <Box>
                                {selectedSystem?.modules?.length > 0 ? selectedSystem?.modules?.map((item) => <ModuleComponent code={item.code} quantity={item.quantity} key={item?.code} />) : "NA"}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >
                        <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                            <Typography variant="body2" fontWeight={600}>Inverters: </Typography>
                            <Box>
                                {selectedSystem?.inverters?.length > 0 ? selectedSystem?.inverters?.map((item) => <ModuleComponent code={item.code} quantity={item.quantity} key={item?.code} />) : "NA"}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >
                        <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                            <Typography variant="body2" fontWeight={600}>Batteries: </Typography>
                            <Box>
                                {selectedSystem?.battries?.length > 0 ? selectedSystem?.battries?.map((item) => <ModuleComponent code={item.code} quantity={item.quantity} key={item?.code} />) : "NA"}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >
                        <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                            <Typography variant="body2" fontWeight={600}>Others: </Typography>
                            <Box>
                                {selectedSystem?.others?.length > 0 ? selectedSystem?.others?.map((item) => <ModuleComponent code={item.code} quantity={item.quantity} key={item?.code} />) : "NA"}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
               

            </Box>

        </>}
    </CustomBox>
}

const SiteComponent = ({ title, value ,size}) => {
    return <SiteBox size={size}>
        <Typography variant={size=="small"?"caption":"body1"} >{value??"NA"}</Typography>
        <Typography variant="caption" fontWeight={600} color="dark.main" >{title}</Typography>
    </SiteBox>
}
export const ProjectSiteDetailsUi = ({ data }) => {
    const selectedSystem = data?.selected_system
    // if(!selectedSystem){}
    return <>
    
    <Grid container spacing={2} >
        <Grid item xs={2.4} >

            <SiteComponent title="Roof Type" value={data?.roof_type} />
        </Grid>
        <Grid item xs={2.4} >
            <SiteComponent title="Phase" value={data?.phase} />
        </Grid>
        
        <Grid item xs={2.4} >

            <SiteComponent title="Storey" value={data?.no_of_storeys} />
        </Grid>
        <Grid item xs={2.4} >
            <SiteComponent title="Output (KWH)" value={(selectedSystem?.annual_output_kwh?.toFixed?.(2) ?? "0")} />
        </Grid>
        <Grid item xs={2.4} >
            <SiteComponent title="Price" value={(selectedSystem?.amount_including_tax?.toFixed?.(2) ?? "0")} />
        </Grid>
        <Grid item xs={6} >
            <SiteComponent  size="small" title="NMI No:" value={(data?.nmi_no??"NA")} />
        </Grid>
        <Grid item xs={6} >
            <SiteComponent size="small" title="Meter No:" value={(data?.meter_identifier??"NA")} />
        </Grid>
    </Grid>
    </>
}
