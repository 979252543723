import { Menu, Message } from "@mui/icons-material";
import {  Badge, Box,  IconButton,  Paper,  Tooltip } from "@mui/material"
import { useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import Profile from "./Profile";
import NotificationModel from "../../../pages/notification/NotificationModel";
import LeadFinder from "../../../pages/dashboard/lead-finder/ListController"
import { memo } from "react";
import CheckInCheckoutButton from "../../../pages/attendences/CheckInCheckoutButton";
import { moduleAccessCondition } from "../../../utils/helpers/helper";
import { USER_ROLES } from "../../../utils/constants/constants";
import { MailButton } from "../../../pages/projects/projectdetails/buttons/ProjectIconButtons";
import MODULES from "../../../utils/constants/module.constants";

const headerStyle = (theme) => ({
    width: "100%",
    background: "white", position: "sticky", top: "0px", display: "flex", alignItems: "center",
    pt: 3, pb: 3, pr: 3,
    borderBottom: "1px solid " + theme.palette.grey.main,
    zIndex: 111,
    borderRadius: "0px"
})

const Header = ({ open, setOpen }) => {
    const {user,chat} = useSelector(state => state)
    return (
        <Paper elevation={0} sx={headerStyle}>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>


                <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>

                    <IconButton onClick={() => { setOpen(!open) }}>
                        <Menu />
                    </IconButton>

                    <Box sx={{ width: "", display: "flex", flex: 1, maxWidth: "400px" }} >
                        <LeadFinder />
                    </Box>
                </Box>
                {moduleAccessCondition(user,[MODULES.SEND_EMAIL],[]) && <MailButton color="grey.main" />}
                <Box sx={{ml:1}}>
                    {moduleAccessCondition(user,[MODULES.CHATS_SECTION_MAIN],[]) &&
                    <Tooltip title="Chat Messages" >
                       
                            <IconButton LinkComponent={Link} to="/chats" >
                            <Badge color="error" badgeContent={chat?.hasNewMessage?" ":0} variant="dot" >
                                <Message />
                                </Badge>
                            </IconButton>
                        
                    </Tooltip>}
                </Box>
                <NotificationModel />
                <Profile />
            </Box>
        </Paper>
    )
}
export default memo(Header)