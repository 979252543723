import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { memo } from "react"
import { useSelector } from 'react-redux'



import CustomInput from "../../../components/inputs/CustomInput"
import { LEAD_CALL_TYPE, LEAD_STATUS } from "../../../utils/constants/lead.constant"
import {  fetchAllowedStatusForLead } from "../../../utils/helpers/lead.helper"


import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { MobileDateTimePicker } from "@mui/x-date-pickers"

import { StyledSearchBar } from "../../../components/inputs/SearchBar"
import { findObjectKeyByValue } from "../../../utils/helpers/helper"





const LeadCallCreateUI = ({ title, leadLoading, leadDetails, disableDirectClose, isUpdate, fields, setFields, loading, onSubmit }) => {

    const { user } = useSelector(state => state)

    const allowedUpdates = fetchAllowedStatusForLead(null)

    return <>

        <CustomDialog

            loading={loading || leadLoading}
            err={fields.err}
            disableDirectClose={disableDirectClose}
            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"
            confirmText={`Create`}
        >


            {loading || leadLoading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
<FormControl fullWidth margin="dense">
  <InputLabel id="demo-simple-select-label">Select Lead status</InputLabel>
                    <Select
                        disableClearable

                        margin="dense"
                        label="Select Lead status"
                        value={fields.lead_status}
                        
                        fullWidth
                        onChange={(e) => { setFields({ ...fields, lead_status: e.target.value }) }}

                        renderInput={(params) => <CustomInput  {...params} placeholder="Select Status" label="Select Call Type" size="small" />}
                    >
                        {
                            allowedUpdates.map((statusVal) => (<MenuItem value={statusVal} >{findObjectKeyByValue(statusVal, LEAD_STATUS)}</MenuItem>))
                        }

                    </Select>
                    </FormControl>

                    
                    <FormControl fullWidth margin="dense">
  <InputLabel id="demo-simple-select-label">Select Call Type</InputLabel>
                    <Select
                        disableClearable

                        margin="dense"
                        placeholder="Select Call Type"
                        label="Select Call Type"
                        value={fields.call_type}

                        fullWidth
                        onChange={(e) => { setFields({ ...fields, call_type: e.target.value }) }}

                        renderInput={(params) => <StyledSearchBar {...params} label="Select Call Type" placeholder="Select Call Type" size="small" />}
                    >
                        {
                            Object.values(LEAD_CALL_TYPE).map((statusVal) => (<MenuItem value={statusVal} >{findObjectKeyByValue(statusVal, LEAD_CALL_TYPE)}</MenuItem>))
                        }

                    </Select>
                    </FormControl>
                    <Box mt={3}></Box>
                    {<MobileDateTimePicker

                        renderInput={(props) => <CustomInput {...props} />}
                        label="Schedule  Call  Time"
                        value={fields.scheduled_date}
                        onChange={(changedVal) => { setFields({ ...fields, scheduled_date: changedVal }) }}
                    />}
                    {<CustomInput
                        disabled={loading}
                        value={fields.remarks}
                        onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                        multiline
                        rows={2}
                        type="textarea"
                        label={"Remarks For Call Creation*"}

                    />}

                </>}

        </CustomDialog>
    </>
}
export default memo(LeadCallCreateUI)