
import { Checkbox, CircularProgress, IconButton, Paper, Tooltip, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"

import DataTable from "../../components/tables/DataTable"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"

import { Add, AddCircle, Remove } from "@mui/icons-material"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import NoDataComponent from "../../components/layouts/NoDataComponent"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import { CenteredBox } from "../../components/layouts/OneViewBox"

const PredefinedListItem = ({ _id,title, amount, selected,index, onChange, onRemove }) => {

    return <Box sx={{ display: "flex" }} alignItems="center" >
        <Box sx={{minWidth:"50px"}}>
            <Checkbox checked={selected} onChange={(e) => onChange({
                _id,
                amount,
                title,
                selected: e.target.checked
            })} />
        </Box>
        <Box sx={{ display: "flex", flex: 1 }} >
            {title}
        </Box>
        <Box>
            <StyledSearchBar size="small" value={amount} onChange={(e) => onChange({
                _id,
                amount: e.target.value,
                title,
                selected
            })} />
        </Box>
        <Box>
            <IconButton size="small" onClick={onRemove} ><Remove /></IconButton>
        </Box>
    </Box>
}


const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))


const ListUi = ({ title, modal,onRemove, modalKey, onSubmit, onChangeFun, list, onCreateBtnClick, loading }) => {



    return (
        <>
            <CustomDialog
            onSubmit={onSubmit}
            confirmText="Add"
                loading={loading}
                id={modalKey}
                title={title}
            >
                {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                    <Box>
                      
                            <Box  >
                               
                                <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: 'flex-end' }} pl={3} >

                                    <Box>
                                        {
                                            <Tooltip title="Add Predefined Expense" >
                                                <IconButton onClick={onCreateBtnClick} title="Add" >
                                                    <AddCircle />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </Box>
                                </PaddingBoxInDesktop>
                            </Box>

                            <Box sx={{ minHeight: "300px" }} p={2}>
                                {
                                    list?.length == 0 && <NoDataComponent message="No predefined expenses" ></NoDataComponent>
                                }
                                {
                                    list?.length > 0 && list?.map((item,index) => {
                                      return  <PredefinedListItem
                                      index={index+1}
                                      _id={item?._id}
                                            key={item?._id}
                                            amount={item?.amount}
                                            title={item?.title}
                                            selected={item?.selected}
                                            onChange={(res) => {
                                                onChangeFun(res, index)
                                            }}
                                            onRemove={
                                               ()=>{
                                                onRemove(item?._id)
                                               }
                                            }
                                        />
                                    })
                                }
                                {/* <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} /> */}
                            </Box>
                        
                    </Box >
                }
            </CustomDialog>
        </>
    )
}
export default ListUi