import { Autocomplete, Button, ButtonGroup, Paper,  Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import {  useSelector } from "react-redux"
import SubmitButton from "../../components/button/SubmitButton"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import { USER_ROLES } from "../../utils/constants/constants"
import DataTable from "../../components/tables/DataTable"
import { moduleAccessCondition, titleCase } from "../../utils/helpers/helper"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import AsyncSearchBar from "../../components/inputs/AsynSearchBar"
import MODULES from "../../utils/constants/module.constants"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, roles, loading, onCreateBtnClick, columns }) => {

    const user = useSelector(state => state.user)

    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>

                            <Box mb={2}>
                              {moduleAccessCondition(user, [MODULES.CREATE_USERS], []) &&  <SubmitButton variant="contained" onClick={onCreateBtnClick} title={"Add Member"} />}
                            </Box>

                        </FilterTitleBox>


                        <FiltersBox mt={3} >
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} >
                                <Autocomplete
                                    disableClearable
                                    id="combo-box-demo"
                                    onChange={(e, newVal) => {
                                        setFilters({ ...filters, role: newVal ? newVal._id : null })
                                    }}
                                    options={[{ label: 'All', _id: null }, ...Object.keys(USER_ROLES).filter((item) => (user.data.role != USER_ROLES.admin && (USER_ROLES[item] != USER_ROLES.admin && USER_ROLES[item] != USER_ROLES.partner_admin)) || user.data.role == USER_ROLES.admin).map((key) => ({ label: titleCase(key), _id: USER_ROLES[key] }))]}
                                    sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                                    renderInput={(params) => <StyledSearchBar placeholder="Select Role" {...params} size="small" />}
                                />
                            </PaddingBoxInDesktop>
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={3} >


                                <AsyncSearchBar

                                    fullWidth
                                    title="Search Name | Email" size="small" placeholder={"Search Name | Email"}
                                    defaultValue={filters.search}
                                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                                />
                                {/* <StyledSearchBar fullWidth title="Search Name | Email" size="small" placeholder={"Search Name | Email"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
                            </PaddingBoxInDesktop>

                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }} pl={3} >


                                <ButtonGroup disableElevation variant="outlined" >

                                    <Button onClick={() => setFilters({ ...filters, deleted: null })} variant={filters.deleted == null ? "contained" : "outlined"} >Active User</Button>
                                    <Button onClick={() => setFilters({ ...filters, deleted: true })} variant={filters.deleted == true ? "contained" : "outlined"}>Deleted User</Button>
                                </ButtonGroup>
                            </PaddingBoxInDesktop>

                        </FiltersBox>

                    </Box>

                    <Box sx={{ minHeight: "300px" }}>
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi