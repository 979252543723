import { Checkbox, CircularProgress, MenuItem, Typography } from "@mui/material"
import { memo } from "react"
import { useSelector } from 'react-redux'
import CustomInput from "../../../components/inputs/CustomInput"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"

import FileInput from "../../../components/inputs/FileInput"
import { PROJECT_FLAGS } from "../../../utils/constants/project.constant"
import { getStatusOfProjectFlags } from "../../../utils/helpers/project.helper"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import getLoanBenefitApi from "../../../apis/loanbenefit.api"
import { getInstallerApi } from "../../../apis/user.api"



const ProjectUpdateFlagUi = ({ title, fields, setFields, loading, onSubmit, modalKey }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}

            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"
            confirmText={`Submit`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>

                    {/* grid apre application apply */}
                    {
                        fields.flag == PROJECT_FLAGS.GRID_PRE_APPROVAL && fields.flag_value == getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPLIED &&
                        <>
                            <CustomInput
                                disabled={loading}
                                value={fields.nmi_no}
                                onChange={(e) => setFields({ ...fields, err: '', nmi_no: e.target.value })}
                                autoFocus={true}
                                type="text"
                                label={"NMI No.*"}

                            />
                            <CustomInput
                                disabled={loading}
                                value={fields.meter_no}
                                onChange={(e) => setFields({ ...fields, err: '', meter_no: e.target.value })}

                                type="text"
                                label={"Meter No.*"}

                            />
                            <CustomInput
                                disabled={loading}
                                value={fields.pre_approval_applied_ref_no}
                                onChange={(e) => setFields({ ...fields, err: '', pre_approval_applied_ref_no: e.target.value })}

                                type="text"
                                label={"Grid Pre Application Ref No.*"}

                            />
                            <DesktopDatePicker
                                margin="dense"
                                inputFormat="DD-MM-YYYY"
                                value={fields?.pre_approval_applied_date ? moment(fields.pre_approval_applied_date) : null}
                                label="Grid Pre Application Date*"
                                renderInput={(props) => <CustomInput {...props} />}
                                onChange={(e) => setFields({ ...fields, pre_approval_applied_date: e?.toISOString() })}
                            />

                        </>


                    }


                    {/* grid apre application approve */}

                    {
                        fields.flag == PROJECT_FLAGS.GRID_PRE_APPROVAL && fields.flag_value == getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPROVED &&
                        <>
                            <CustomInput
                                disabled={loading}
                                value={fields.pre_approval_ref_no}
                                onChange={(e) => setFields({ ...fields, err: '', pre_approval_ref_no: e.target.value })}
                                autoFocus={true}
                                type="text"
                                label={"Grid Pre Approval Ref No.*"}

                            />
                            <CustomInput
                                disabled={loading}
                                value={fields.approved_export_limit}
                                onChange={(e) => setFields({ ...fields, err: '', approved_export_limit: e.target.value })}
                                
                                type="text"
                                label={"Grid Approved Export Limit.*"}

                            />
                            <DesktopDatePicker
                                margin="dense"
                                inputFormat="DD-MM-YYYY"
                                value={fields?.pre_approval_date ? moment(fields.pre_approval_date) : null}
                                label="Grid Pre Approval Date*"
                                renderInput={(props) => <CustomInput {...props} />}
                                onChange={(e) => setFields({ ...fields, pre_approval_date: e?.toISOString() })}
                            />
                            <FileInput
                                defaults={fields.approval_letter ? [fields.approval_letter] : []}
                                title="Approval Letter*"
                                subTitle="Only .pdf  less than 2mb are valid."
                                accept=".pdf"
                                onChange={(newUrl) => {
                                    setFields({ ...fields, approval_letter: newUrl })
                                }} />
                        </>


                    }



                    {/* grid solar victoria apply */}
                    {
                        fields.flag == PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL && fields.flag_value == getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPLIED &&
                        <>
                            <CustomInput
                                disabled={loading}
                                value={fields.solar_victoria_ref_no}
                                onChange={(e) => setFields({ ...fields, err: '', solar_victoria_ref_no: e.target.value })}
                                autoFocus={true}
                                type="text"
                                label={"INS No.*"}

                            />
                            <DesktopDatePicker
                                margin="dense"
                                inputFormat="DD-MM-YYYY"
                                value={fields?.solar_victoria_applied_date ? moment(fields.solar_victoria_applied_date) : null}
                                label="SV Application Date*"
                                renderInput={(props) => <CustomInput {...props} />}
                                onChange={(e) => setFields({ ...fields, solar_victoria_applied_date: e?.toISOString() })}
                            />
                            <AsyncDropDown
                                id={'loanbenefits-updateflags'}
                                lazyFun={async (params) => { return await getLoanBenefitApi({ ...params }) }}
                                multiple
                                OptionComponent={({ option, ...rest }) => {
                                    return <MenuItem {...rest}>{option.name}</MenuItem>
                                }}
                                onChange={async (changedVal) => { setFields({ ...fields, loan_benefits: changedVal ?? [] }) }}
                                titleKey={'name'}
                                valueKey={"value"}
                                InputComponent={(params) => <CustomInput placeholder="Select Benefits" {...params} size="small" />}
                            />
                        </>


                    }

                    {/* grid solar victoria approve */}
                    {
                        fields.flag == PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL && fields.flag_value == getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPROVED &&

                        <DesktopDatePicker
                            autoFocus={true}
                            margin="dense"
                            inputFormat="DD-MM-YYYY"
                            value={fields?.solar_victoria_approval_date ? moment(fields.solar_victoria_approval_date) : null}
                            label="SV Approval Date*"
                            renderInput={(props) => <CustomInput {...props} />}
                            onChange={(e) => setFields({ ...fields, solar_victoria_approval_date: e?.toISOString() })}
                        />
                    }

                    {/* Schedule Installation */}
                    {
                        fields.flag == PROJECT_FLAGS.INSTALLATION && fields.flag_value == getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).SCHEDULED &&
                        <>

                            <DesktopDatePicker
                                autoFocus={true}
                                margin="dense"
                                inputFormat="DD-MM-YYYY"
                                value={fields?.installtion_date ? moment(fields.installtion_date) : null}
                                label="Installation Date*"
                                renderInput={(props) => <CustomInput {...props} />}
                                onChange={(e) => setFields({ ...fields, installtion_date: e?.toISOString() })}
                            />
                            <AsyncDropDown
                                id={'installer-updateflags'}
                                lazyFun={async (params) => { return await getInstallerApi({ ...params, onlyValid: true }) }}

                                OptionComponent={({ option, ...rest }) => {
                                    return <MenuItem {...rest}>{option.name}</MenuItem>
                                }}
                                onChange={async (changedVal) => { setFields({ ...fields, assigned_installer: changedVal ? changedVal?._id : null }) }}
                                titleKey={'name'}
                                valueKey={"_id"}
                                InputComponent={(params) => <CustomInput placeholder="Installer*" {...params} size="small" />}
                            />
                        </>
                    }

                    {/* STC Applied */}
                    {
                        fields.flag == PROJECT_FLAGS.STC_CLAIMED && fields.flag_value == getStatusOfProjectFlags(PROJECT_FLAGS.STC_CLAIMED).APPLIED &&
                        <CustomInput
                            disabled={loading}
                            value={fields.no_of_stc}
                            onChange={(e) => setFields({ ...fields, err: '', no_of_stc: e.target.value })}
                            autoFocus={true}
                            type="number"
                            label={"No. of STC*"}

                        />

                    }


                    {/* Ces Recieved */}

                    {
                        fields.flag == PROJECT_FLAGS.CES_CERTI && fields.flag_value == getStatusOfProjectFlags(PROJECT_FLAGS.CES_CERTI).RECIEVED &&
                        <>
                            <FileInput
                                defaults={fields.ces ? [fields.ces] : []}
                                title="CES Certificate*"
                                subTitle="Only .pdf  less than 2mb are valid."
                                accept=".pdf"
                                onChange={(newUrl) => {
                                    setFields({ ...fields, ces: newUrl })
                                }} />

                            {/* 
                            <Typography mt={3} mb={2} fontWeight={600}>Electrician Invoice Details:</Typography>
                            <CustomInput
                                disabled={loading}
                                value={fields.electrician_invoice_no}
                                onChange={(e) => setFields({ ...fields, err: '', electrician_invoice_no: e.target.value })}
                                autoFocus={true}
                                type="text"
                                label={"Invoice No*"}

                            />
                            <FileInput
                                defaults={fields.custom_invoice ? [fields.custom_invoice] : []}
                                title="Invoice Slip*"
                                subTitle="Only .pdf  less than 2mb are valid."
                                accept=".pdf"
                                onChange={(newUrl) => {
                                    setFields({ ...fields, custom_invoice: newUrl })
                                }} />
                            <CustomInput
                                disabled={loading}
                                value={fields.electrician_invoice_amount}
                                onChange={(e) => setFields({ ...fields, err: '', electrician_invoice_amount: e.target.value })}
                                autoFocus={true}
                                type="number"
                                label={"Amount*"}

                            />
                            <DesktopDatePicker
                                margin="dense"
                                inputFormat="DD-MM-YYYY"
                                value={fields?.electrician_invoice_due_date ? moment(fields.electrician_invoice_due_date) : null}
                                label="Due Date / Payment Date*"
                                renderInput={(props) => <CustomInput {...props} />}
                                onChange={(e) => setFields({ ...fields, electrician_invoice_due_date: e?.toISOString() })}
                            /> */}
                        </>


                    }



                    {/* STC Claim */}
                    {
                        fields.flag == PROJECT_FLAGS.STC_CLAIMED && fields.flag_value == getStatusOfProjectFlags(PROJECT_FLAGS.STC_CLAIMED).CLAIMED &&
                        <>
                            <CustomInput
                                disabled={loading}
                                value={fields.stc_claimed_ref_no}
                                onChange={(e) => setFields({ ...fields, err: '', stc_claimed_ref_no: e.target.value })}
                                autoFocus={true}
                                type="text"
                                label={"STC Ref No.*"}

                            />
                            <DesktopDatePicker
                                margin="dense"
                                inputFormat="DD-MM-YYYY"
                                value={fields?.stc_claimed_date ? moment(fields.stc_claimed_date) : null}
                                label="STC Claimed Date*"
                                renderInput={(props) => <CustomInput {...props} />}
                                onChange={(e) => setFields({ ...fields, stc_claimed_date: e?.toISOString() })}
                            />
                        </>


                    }


                    {/* Solar Victoria Claimed */}
                    {
                        fields.flag == PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL && fields.flag_value == getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL).CLAIMED &&
                        <>
                            <CustomInput
                                disabled={loading}
                                value={fields.sv_claimed_ref_no}
                                onChange={(e) => setFields({ ...fields, err: '', sv_claimed_ref_no: e.target.value })}
                                autoFocus={true}
                                type="text"
                                label={"SV Ref No.*"}

                            />
                            <DesktopDatePicker
                                margin="dense"
                                inputFormat="DD-MM-YYYY"
                                value={fields?.sv_claimed_date ? moment(fields.sv_claimed_date) : null}
                                label="SV Claimed Date*"
                                renderInput={(props) => <CustomInput {...props} />}
                                onChange={(e) => setFields({ ...fields, sv_claimed_date: e?.toISOString() })}
                            />
                        </>


                    }

                    {/* Grid Post Approval Claimed */}
                    {
                        fields.flag == PROJECT_FLAGS.GRID_POST_APPROVAL && fields.flag_value == getStatusOfProjectFlags(PROJECT_FLAGS.GRID_POST_APPROVAL).APPLIED &&
                        <>
                            <CustomInput
                                disabled={loading}
                                value={fields.cr_no}
                                onChange={(e) => setFields({ ...fields, err: '', cr_no: e.target.value })}
                                autoFocus={true}
                                type="text"
                                label={"CR No.*"}

                            />
                            <DesktopDatePicker
                                margin="dense"
                                inputFormat="DD-MM-YYYY"
                                value={fields?.grid_post_application_date ? moment(fields.grid_post_application_date) : null}
                                label="Post Approval Application Date*"
                                renderInput={(props) => <CustomInput {...props} />}
                                onChange={(e) => setFields({ ...fields, grid_post_application_date: e?.toISOString() })}
                            />
                        </>


                    }


                    {/* Grid Post Approval Complition Date */}
                    {
                        fields.flag == PROJECT_FLAGS.GRID_POST_APPROVAL && fields.flag_value == getStatusOfProjectFlags(PROJECT_FLAGS.GRID_POST_APPROVAL).COMPLETED &&
                        <>

                            <DesktopDatePicker
                                autoFocus={true}
                                margin="dense"
                                inputFormat="DD-MM-YYYY"
                                value={fields?.grid_post_complition_date ? moment(fields.grid_post_complition_date) : null}
                                label="Post Approval Complition Date*"
                                renderInput={(props) => <CustomInput {...props} />}
                                onChange={(e) => setFields({ ...fields, grid_post_complition_date: e?.toISOString() })}
                            />
                        </>


                    }
                    {<CustomInput
                        disabled={loading}
                        value={fields.remarks}
                        onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                        multiline
                        rows={3}
                        type="textarea"
                        label={"Remarks"}

                    />}



                </>}

        </CustomDialog>
    </>
}
export default memo(ProjectUpdateFlagUi)