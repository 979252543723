import { Box, CircularProgress } from "@mui/material";

import { Fragment, memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomInput from "../../components/inputs/CustomInput";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const CreateUi = ({
  title,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  const [editor, setEditor] = useState(null);
  return (
    <>
      <CustomDialog
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            {
              <CustomInput
                autoFocus={true}
                disabled={loading}
                value={fields.title}
                onChange={(e) =>
                  setFields({ ...fields, err: "", title: e.target.value })
                }
                type="text"
                label={"Title*"}
              />
            }
            {
              <Box mt={2} mb={2}>
                <CKEditor
                  config={{
                    placeholder: "T&C Description...",
                    style: { height: "500px", minHeight: "500px" },
                    toolbar: {
                      items: [
                        "p",
                        "heading",
                        "italic",
                        "bold",
                        "blockquote",
                        "link",
                        "table",
                        "undo",
                        "redo",
                        "numberedList",
                        "bulletedList",
                      ],
                    },
                  }}
                  editor={ClassicEditor}
                  data={fields?.description}
                  onReady={(editor) => {
                    setEditor(editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFields({ ...fields, description: data });
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </Box>
            }
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUi);
