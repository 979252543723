import { useState } from "react"
import SubmitButton from "../../../components/button/SubmitButton"
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants/constants"

import { Close, PlaylistAdd } from "@mui/icons-material"

import CustomInput from "../../../components/inputs/CustomInput"
import { addRemarksToPaymentsApi } from "../../../apis/invoice.api"

const AddRemarksToInvoiceButton = ({ id, callBack = () => { } }) => {

    const { user } = useSelector(state => state)
    const [mode, setMode] = useState(0)

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        id,
        remarks: ''
    })
    const dispatch = useDispatch()

    const onSubmit = (e) => {
        e?.preventDefault?.()
        if (!fields.remarks || fields.remarks.trim() == '') {
            dispatch(callSnackBar("Remarks is Required", SNACK_BAR_VARIETNS.error))
            return
        }
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await addRemarksToPaymentsApi({ ...fields }),
                async (response) => {


                    setLoading(false)
                    dispatch(callSnackBar("Remarks added successfully", SNACK_BAR_VARIETNS.suceess))
                    callBack(fields.remarks)
                    handleClose()
                },
                (err) => {
                    setLoading(false)
                    dispatch(callSnackBar(err ?? "Remarks cant added successfully", SNACK_BAR_VARIETNS.error))
                }
            )
        )
    }


    const onOpen = () => { setMode(1) }

    const handleClose = () => { setMode(0) }


    return <>
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            open={mode == 1}

            onClose={handleClose}>
            <DialogTitle variant="h6">
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                    <Box component={'div'}>
                        Add Remarks

                    </Box>
                    <IconButton onClick={handleClose} size="small">
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent >
                <CustomInput
                    multiline
                    rows={3}
                    label="Remarks*"
                    value={fields.remarks}
                    onChange={(e) => setFields({ ...fields, remarks: e.target.value })}

                />
            </DialogContent>
            {<DialogActions   >
                <SubmitButton variant='outlined' onClick={handleClose} title={'close'} />
                <SubmitButton onClick={onSubmit} loading={loading} disabled={loading} type="button" title={'Add'} />
            </DialogActions>}
        </Dialog>
        <Tooltip title="Add/Update remarks" >
            <IconButton onClick={onOpen} >
                <PlaylistAdd color="info" />
            </IconButton>
        </Tooltip>

    </>

}
export default AddRemarksToInvoiceButton