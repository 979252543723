import { useCallback, useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { getLeadAnalytisBySourceApi, getLeadAnalytisByStatusApi } from "../../../apis/lead.api"
import LeadAnalyticsBySourceUI from "./LeadAnalyticsBySourceUi"
import { getDateFiltersTime } from "../../../utils/helpers/helper"

const LeadAnalyticsBySourceController = () => {
    const {dateRange} = useSelector(state=>state)
    const dispatch =useDispatch()
    const fetchAPi = getLeadAnalytisBySourceApi
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})
    
    const [filters,setFilters] = useState({
        
        ...(dateRange.get('created_at')?dateRange.get('created_at'):{
            ...getDateFiltersTime('this_month'),
            _id:"this_month",
            label:"This Month"
        })
    })
    const fetchDetails = useCallback(() => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchAPi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }, [filters])
    useEffect(() => { fetchDetails() }, [filters])
    return <LeadAnalyticsBySourceUI list={list} loading={loading} filters={filters} setFilters={setFilters} />
}
export default LeadAnalyticsBySourceController