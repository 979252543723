import { memo, useCallback, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import { closeModal, openModal } from "../../store/actions/modalAction";
import {
  Box,
  CircularProgress,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import CreateController from "./CreateController";
import { Delete, Edit, Info } from "@mui/icons-material";

import getSourceApi, { updateSourceField } from "../../apis/sources.api";
import getTeamApi from "../../apis/team.api";
import { moduleAccessCondition } from "../../utils/helpers/helper";
import MODULES from "../../utils/constants/module.constants";
import getTermsAndConditionApi from "../../apis/termsandcondition.api";
import DetailsUI from "./DetailsUI";

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state);

  const onEdit = () => {
    dispatch(
      openModal(
        <CreateController
          id={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm"
      )
    );
  };

  const onDetail = () => {
    dispatch(
      openModal(
        <DetailsUI params={params} />,
        "sm",
        undefined,
        "description"
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      {/* {moduleAccessCondition(user, [MODULES.UPDATE_TEAMS], []) && ( */}
      <IconButton size="inherit" onClick={onEdit}>
        <Edit color="info" fontSize="inherit" />
      </IconButton>
      {/* )} */}
      <Tooltip title="View Description" arrow>
        <IconButton size="inherit" onClick={onDetail}>
          <Info color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Box>
  );
});

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Terms and Condition ";
  const fetchApi = getTermsAndConditionApi;
  const deleteApi = null;

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "title",
        label: "Title",
        align: "left",
        sort: true,
      },

      {
        id: 5,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["title"],
    sort: "title",
    sortDirection: 1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <CreateController
          callBack={async () => {
            fetchList();
          }}
        />,
        "sm"
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;
