import { MenuItem } from "@mui/material"

import AsyncDropDown from "../inputs/AsyncDropDown"
import { StyledSearchBar } from "../inputs/SearchBar"
import { useSelector } from "react-redux"
import { moduleAccessCondition } from "../../utils/helpers/helper"
import MODULES from "../../utils/constants/module.constants"
import { getUserApi } from "../../apis/user.api"
import { USER_ROLES } from "../../utils/constants/constants"

const SalesPersonFilterDropDown = ({ filters, setFilters,filtersKey="assigned_to", ...props }) => {

    const { user } = useSelector(state => state)

    if (!moduleAccessCondition(user, [MODULES.SALES_PERSON_FILTER]))
        return <></>
    return <AsyncDropDown

        margin={0}

        InputComponent={(props) => <StyledSearchBar placeholder="Select Sales Person"  {...props} margin="none" size="small" />}
        lazyFun={async (params) => { return await getUserApi({ ...params, role: USER_ROLES.sales, team: filters.team }) }}

        OptionComponent={({ option, ...rest }) => {
            return <MenuItem {...rest}>{option.name}</MenuItem>
        }}
        onChange={async (changedVal) => {

            setFilters({ ...filters, [filtersKey]: changedVal ? changedVal._id : null })
        }}
        titleKey={'name'}
        valueKey={"_id"}
        {...props}
    />

}

export default SalesPersonFilterDropDown