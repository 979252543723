import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Badge,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  ButtonBase,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import { toTitleCase } from "../../utils/helpers/helper";
import SubmitButton from "../../components/button/SubmitButton";
import { UploadSheetApi } from "../../apis/module.api";

import { closeModal } from "../../store/actions/modalAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants";
import CustomDialog from "../../components/layouts/common/CustomDialog";

const PreviewTableController = ({ hasError, columns, data, file }) => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    // formData.append("date", date);
    dispatch(
      callApiAction(
        async () => await UploadSheetApi(formData),
        (response) => {
          
          dispatch(closeModal("preview-table"));
          dispatch(closeModal("upload-sheet"))
          dispatch(
            callSnackBar(
              "Sheet uploaded successfully",
              SNACK_BAR_VARIETNS.suceess
            )
          );
          setLoading(false);
        },
        (err) => {
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
          setLoading(false);
        }
      )
    );
  };

  return (
    <>
     <CustomDialog
        id={"preview-table"}
        loading={loading}
        dialogProps={{ sx: { height: "200px", overflow: "hidden" } }}
        onSubmit={onSubmit}
        title={`Preview Table`}
        closeText="Close"
        confirmText="Upload"
        >
      <TableContainer
        component={Box}
        mb={3}
        // p={3}
        sx={{
          boxShadow:
            "0px 2px 9px rgba(50, 71, 92, 0.06), 0px 4px 9px 1px rgba(50, 71, 92, 0.04), 0px 2px 6px 4px rgba(50, 71, 92, 0.02)",
          borderRadius: "4px",
          border: "1px solid #BFBFBF",
        }}
      >
        <Table aria-label="collapsible table" sx={{}}>
          <TableHead sx={{ background: "#F2F2F2" }}>
            <TableRow>
              {columns.map((name) => (
                <TableCell key={name}>{toTitleCase(name)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              data &&
              data.map((item, index) =>
                item ? (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      background: item.hasError ? "pink" : "inherit",
                    }}
                  >
                    {columns.map((name) => (
                      
                      <TableCell
                        sx={{
                          background:
                            item.data[name] && item.data[name].err != ""
                              ? "red"
                              : "unset",
                        }}
                        key={name}
                      >
                        {item.data[name] && item.data[name].value}
                      </TableCell>
                    ))}
                  </TableRow>
                ) : (
                  <></>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <Box sx={{ position: "sticky", bottom: "0px", zIndex: 11 }}>
        <SubmitButton
          loading={loading}
          disabled={hasError}
          onClick={onSubmit}
          title={"Upload"}
        />
      </Box> */}
      </CustomDialog>
    </>
  );
};

export default PreviewTableController;
