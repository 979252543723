export const LEAD_STATUS = {
    UPDATED: -2,
    // INITIAL: -1,
    UNHANDLED: -1,//this is named as INitial in backend
    NO_ANSWER: 1,
    CALL_BACK: 2,
    NOT_INTRESTED: 3,
    INTEREST_CALL_BACK: 4,
    QUATATION_SENT: 5,
    QUATATION_REJECTED: 8,
    SOLD: 6,
    INCOMPLETE: 7,
    FOLLOW_UP: 9,
    WRONG_NUMBER: 10,
    PROJECT_REJECTED: 11
}

export const LEAD_STATUS_LIST = [
    {
        label: "Unhandled",
        _id: LEAD_STATUS.UNHANDLED
    },
    {
        label: "Incomplete",
        _id: LEAD_STATUS.INCOMPLETE
    },
    {
        label: "Call Back",
        _id: LEAD_STATUS.CALL_BACK
    },
    {
        label: "No Answer",
        _id: LEAD_STATUS.NO_ANSWER
    },
    {
        label: "Interest Call Back",
        _id: LEAD_STATUS.INTEREST_CALL_BACK
    },
    {
        label: "Quatation Sent",
        _id: LEAD_STATUS.QUATATION_SENT
    },
    {
        label: "Quatation Followup",
        _id: LEAD_STATUS.FOLLOW_UP
    },
    {
        label: "Sold",
        _id: LEAD_STATUS.SOLD
    },
    {
        label: "Wrong No.",
        _id: LEAD_STATUS.WRONG_NUMBER
    },
    {
        label: "Quatation Rejected",
        _id: LEAD_STATUS.QUATATION_REJECTED
    },
    {
        label: "Not Intereseted",
        _id: LEAD_STATUS.NOT_INTRESTED
    }
]
export const LEAD_ANALYSIS_STATUS = {

    PENDING: 1,
    IN_PROCESS: 2,
    INTERESTED: 3,
    SOLD: 4,
    NOT_INTERESTED: 5,



}
export const LEAD_CALL_STATUS = {

    PENDING: 0,
    CALLED: 1,




}
export const LEAD_CALL_TYPE = {

    INQUIRY: 0,
    INTERESTED: 1,
    QUATATION_FOLLOW_UP: 2,
    AUTO_GENERATED: 3


}




export const REPEAT_LEAD_TYPE = {

    PHONE: 0,
    EMAIL: 1



}
export const LEAD_REPEAT_PARAMETERS = {
    PHONE: "0",
    ADDRESS: "1",
    EMAIL: "2"
}
export const LEAD_LOG_TYPE = {
    LEAD_CREATED: 1,
    LEAD_UPDATED: 2,
    LEAD_TRANSFERRED: 3,
    STATUS_CHANGED: 4,
    LEAD_CREATED_IN_OPEN_SOLAR: 5,
    LEAD_UPDATED_IN_OPEN_SOLAR: 6,
    PROJECT_CREATED_FROM_LEAD: 7


}