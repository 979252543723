import { memo,  useEffect, useState } from "react"
import { useDispatch } from 'react-redux'
import {  openModal } from "../../store/actions/modalAction"
import { fetchAllowedStatusForLead,   leadStatusColor } from "../../utils/helpers/lead.helper"
import {  Chip, CircularProgress,  MenuItem, Select} from "@mui/material"
import { LEAD_STATUS } from "../../utils/constants/lead.constant"

import { StyledSearchBar } from "../../components/inputs/SearchBar"
import LeadStatusUpdateController from "./status/LeadStatusUpdateController"
import { findObjectKeyByValue } from "../../utils/helpers/helper"


const LeadStatusComponent = memo(({ params, setParams, onlyview }) => {
    const dispatch = useDispatch()

    const [status, setStatus] = useState(params.status)

    const [loading, setLoading] = useState(false)

    const allowedUpdates = fetchAllowedStatusForLead(parseInt(status))

    const handleChange = (e) => {

        dispatch(openModal(<LeadStatusUpdateController


            status={e.target.value}
            id={params._id}
            callBack={() => {
                setStatus(e.target.value)
            }} />, "sm"))

    }

    useEffect(() => {
        if (params.status != status) {
            setStatus(params.status)
        }
    }, [params.status])

    if (!status)
        return <>NA</>
    if (loading)
        return <CircularProgress fontSize="inherit" />
    if (allowedUpdates && !onlyview)
        return <Select
            disableClearable
            // onChange={(e, newVal) => {
            //     setFilters({ ...filters, ...getDateFiltersTime(newVal ? newVal._id : null) })
            // }}
            value={status}
            size="small"
            fullWidth
            onChange={handleChange}

            renderInput={(params) => <StyledSearchBar placeholder="Select Status" {...params} size="small" />}
        >
            {
                allowedUpdates.map((statusVal) => (<MenuItem value={statusVal} >{findObjectKeyByValue(statusVal, LEAD_STATUS)}</MenuItem>))
            }

        </Select>
    else {

        return <Chip size="small" key={status} color={leadStatusColor(status)} label={findObjectKeyByValue(status, LEAD_STATUS)} />
    }
})
export default LeadStatusComponent