import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Paper, Typography } from "@mui/material"
import { getDateFiltersTime } from "../../../utils/helpers/helper"
import { DATE_TIME_FILTERS } from "../../../utils/constants/constants"
import { StyledSearchBar } from "../../inputs/SearchBar"
import { useState } from "react"
import moment from "moment"
import CustomInput from "../../inputs/CustomInput"
import { DatePicker } from "@mui/x-date-pickers"
import SubmitButton from "../../button/SubmitButton"
import { useDispatch } from "react-redux"
import { dateRangeSelectionAction } from "../../../store/actions/dateRangeSelectionAction"

const TimeRangeSelector = ({ dateType='date',value, onChange ,defaultVal,placeHolder="Select Time Duration"}) => {

const dispatch = useDispatch()
    const [customDate, setCustomDate] = useState({ startDate: moment(), endDate: moment().add(1, "days") })
    const handleClose = () => {
        setDateModal(false)
    }
    const [dateModal, setDateModal] = useState(false)
    const onChangeDate = (e, newVal) => {
        if (newVal._id == 'custom') {
            setDateModal(true)
        } else {
            const startAndEndDates = getDateFiltersTime(newVal ? newVal._id : null)
            dispatch(dateRangeSelectionAction(dateType,startAndEndDates.startDate,startAndEndDates.endDate,newVal?.label,newVal?._id))
            onChange(startAndEndDates)

        }

    }
    return <>
        <Dialog
            open={dateModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle>
                <Typography variant="h6">Select  Range</Typography>
            </DialogTitle>
            <DialogContent>

                <DatePicker inputFormat="DD/MM/YYYY" label="From*"

                    value={customDate.startDate}
                    renderInput={(props) => <CustomInput {...props} />}
                    onChange={async (changedVal) => {
                        const newVal =  changedVal
                        setCustomDate({ ...customDate, startDate: changedVal.startOf('date'), endDate: moment(newVal).add(1,"month") }) 
                        
                        
                        }}
                ></DatePicker>

                <DatePicker inputFormat="DD/MM/YYYY" label="To*"
                    minDate={customDate.startDate}
                    value={customDate.endDate}
                    renderInput={(props) => <CustomInput {...props} />}
                    onChange={async (changedVal) => { setCustomDate({ ...customDate, endDate: changedVal.endOf('date') }) }}
                ></DatePicker>

                
            </DialogContent>

            <DialogActions>

            <SubmitButton title="Submit" onClick={() => {
                    
                
                    onChange({startDate:customDate.startDate.valueOf(),endDate:customDate.endDate.valueOf()})
                    dispatch(dateRangeSelectionAction(dateType,customDate.startDate.valueOf(),customDate.endDate.valueOf(),"Custom",'custom'))
                    handleClose()
                }}></SubmitButton>
            </DialogActions>

        </Dialog>
        <Autocomplete
            disableClearable
            defaultValue={defaultVal??null}
            onChange={onChangeDate}
            options={[{ label: 'All', _id: null }, ...Object.keys(DATE_TIME_FILTERS).map((key) => ({ label: DATE_TIME_FILTERS[key], _id: key })), { label: 'Custom', _id: 'custom' }]}

            sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
            renderInput={(params) => <StyledSearchBar placeholder={placeHolder} {...params} size="small" />}
        />
    </>
}
export default TimeRangeSelector