import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";

export const getTermsAndConditionApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.termsAndConditionBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getTermsAndConditionByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.termsAndConditionById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addTermsAndConditionApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.termsAndConditionBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateTermsAndConditionField = async (data) => {
  const callResponse = await axios({
    url: endpoints.termsAndConditionBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export default getTermsAndConditionApi;
