import {
  AccountBalance,
  AccountBalanceWallet,
  Analytics,
  Announcement,
  AutoMode,
  Business,
  Category,
  Chat,
  ChatBubble,
  CheckCircle,
  CloudSync,
  CreditScore,
  CurrencyExchange,
  Dashboard,
  Diversity1,
  ElectricMeter,
  Email,
  Engineering,
  Error,
  Feedback,
  Group,
  Groups2,
  Groups2Outlined,
  IntegrationInstructions, Inventory,
  Key,
  Leaderboard,
  LibraryBooks,
  ListAlt,
  Loyalty,
  Message,
  Money,
  MoneyOff,
  NextWeek,
  Payment,
  PaymentOutlined,
  Pending,
  PendingActions,
  People,
  PlaylistAddCheck,
  QuestionAnswer,
  Report,
  RuleFolderOutlined,
  Sell,
  Settings,
  Source,
  SupportAgentOutlined,
  Workspaces,
  Description,
  ProductionQuantityLimits,
  AllInclusive,
  Paid,
  Timer,
  WorkHistoryOutlined,
  LocalActivity,
  ManageAccounts,
} from "@mui/icons-material";

import { Navigate } from "react-router-dom";
import AppContainer from "./components/layouts/common/AppContainer";

import SignInController from "./pages/signin/SignInController";
import UserListController from "./pages/user/ListController";
import PendingPaymentListController from "./pages/pendingpayments/ListController";
import PendingVerificationListController from "./pages/pendingverification/ListController";
import TermsListController from "./pages/terms-and-condition/ListController";
import ExpenseListController from "./pages/expenses/ListController";
import InvoiceListController from "./pages/invoice/ListController";
import UnhandledLeadListController from "./pages/lead/unhandled/ListController";
import LeadListController from "./pages/lead/ListController";
import CustomerListController from "./pages/customer/ListController";
import StockListController from "./pages/projects/stock/ListController";
import InstallerListController from "./pages/installer/ListController";
import TeamListController from "./pages/team/ListController";
import ProjectListController from "./pages/projects/ListController";

import SourcesListController from "./pages/sources/ListController"
import LoanBenefitsListController from "./pages/loanbenefits/ListController"
import FBIntegrationListController from "./pages/facebookintegration/ListController"
import SourceCapabilityListController from "./pages/sourceCapability/ListController"
import SalesPersonCapabilityListController from "./pages/salesPersonCapability/ListController"

import LeadCallListController from "./pages/lead/calls/ListController"

import { USER_ROLES } from "./utils/constants/constants";
import PagenotFound, {
  PageNotAllowed,
} from "./components/layouts/PagenotFound";
import ListController from "./pages/secrets/ListController";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import DashboardController from "./pages/dashboard/DashboardController";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import ProjectDetailsController from "./pages/projects/projectdetails/ProjectDetailsController";
import HolidayController from "./pages/Holiday/HolidayController";
import BulkInvoiceListController from "./pages/invoice/BulkInvoiceListController";

import InvoiceOverAllSummary from "./pages/invoice/InvoiceOverAllSummary";
import ChatMainController from "./pages/chatapp/ChatMainController";
import ComplainListController from "./pages/complain/ListController";
import ComplainCategoryListController from "./pages/complain-category/ListController";
import BulkInvoiceSelectionWrapper from "./pages/invoice/BulkInvoiceSelectionWrapper";
import { moduleAccessCondition } from "./utils/helpers/helper";
import TaskCalendarController from "./pages/task/TaskCalendarController";
import MODULES from "./utils/constants/module.constants";
import modulesConfig from "./config/modules.config";
import VersionsController from "./pages/versions/VersionController";
import TemplateListController from "./pages/template/ListController";
import ModuleListController from "./pages/module/ListController";
import WhiteListedController from "./pages/whiteListedIp/ListController"
import ActivityListedController from "./pages/activtiy/ListController"

const defaultRedirect = (user) => {
  if (!user.isLoggedIn) {
    return "/sign-in";
  }
  if ((user.isLoggedIn && user?.data?.modules?.includes(MODULES.DASHBOARD_MAIN))) {
    return '/dashboard'
  }

  if ((user.isLoggedIn && user?.data?.modules?.includes(MODULES.VIEW_PROJECTS))) {
    return '/projects'
  }
  if ((user.isLoggedIn && user?.data?.modules?.includes(MODULES.VIEW_COMPLAINS))) {
    return '/complains'
  }
  return "/not-allowed";
};

const defineRoutes = (user) => {
  const redirectDefault = defaultRedirect(user);

  return [
    {
      path: "sign-in",
      element: !user.isLoggedIn ? (
        <SignInController />
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
      hideInPannel: true,
    },

    {
      path: "",

      element: user.isLoggedIn ? (
        <Navigate replace to={redirectDefault} />
      ) : (
        <Navigate replace to="/sign-in" />
      ),

      hideInPannel: true,
    },
    {
      path: "dashboard",
      icon: <Dashboard />,
      title: "Dashboard",
      hideInPannel: !moduleAccessCondition(user, [MODULES.DASHBOARD_MAIN], []),

      element: moduleAccessCondition(user, [MODULES.DASHBOARD_MAIN], []) ? (
        <AppContainer>
          <DashboardController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "dashboard/:tab",
      icon: <Dashboard />,
      title: "Dashboard",
      hideInPannel: true,

      element: moduleAccessCondition(user, [MODULES.DASHBOARD_MAIN], []) ? (
        <AppContainer>
          <DashboardController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },

    {
      path: "task",
      title: "Tasks Management",
      icon: <PlaylistAddCheck />,
      hideInPannel: !moduleAccessCondition(
        user,
        [MODULES.TASK_MANAGEMENT_MAIN],
        []
      ),
      element: moduleAccessCondition(
        user,
        [MODULES.TASK_MANAGEMENT_MAIN],
        []
      ) ? (
        <AppContainer>
          <TaskCalendarController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
      // <LeadAnalyticsBySourceAndSalesPersonController />
    },
    // {
    //     path: "emails",
    //     title: "Emails",
    //     icon: <Analytics />,
    //     hideInPannel: !moduleAccessCondition(user, [], [USER_ROLES.admin, USER_ROLES.partner_admin]),
    //     element: moduleAccessCondition(user, [], [USER_ROLES.admin, USER_ROLES.partner_admin]) ? <EmailController /> : <Navigate replace to={redirectDefault} />

    // },
    {
      path: "chats",
      icon: <QuestionAnswer />,
      title: "Messages",
      hideInPannel: true,
      element: moduleAccessCondition(user, [MODULES.CHATS_SECTION_MAIN], []) ? (
        <AppContainer overflow="hidden">
          <ChatMainController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },

    {
      path: "lead",
      icon: <ArtTrackIcon />,
      title: "Leads",
      hideInPannel: !moduleAccessCondition(
        user,
        [],
        [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.sub_admin,
          USER_ROLES.tl,
          USER_ROLES.sales,
        ]
      ),

      element: moduleAccessCondition(
        user,
        [],
        [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.sub_admin,
          USER_ROLES.tl,
          USER_ROLES.sales,
        ]
      ) ? (
        <AppContainer />
      ) : (
        <Navigate replace to={redirectDefault} />
      ),

      children: [
        {
          path: "",
          title: "Lead Management",
          icon: Leaderboard,
          element: <LeadListController />,
        },

        {
          path: "calls",
          title: "Lead Calls",
          icon: PermPhoneMsgIcon,
          element: <LeadCallListController />,
        },
        {
          path: "unhandled-lead",
          title: "Unhandled Lead",
          icon: Error,

          hideInPannel: !moduleAccessCondition(
            user,
            [],
            [USER_ROLES.admin, USER_ROLES.partner_admin]
          ),
          element: moduleAccessCondition(
            user,
            [],
            [USER_ROLES.admin, USER_ROLES.partner_admin]
          ) ? (
            <UnhandledLeadListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        // {
        //   path: "sales-person-capability",
        //   title: "Sales Person Capability",
        //   icon: ManageAccounts,
        //   hideInPannel: !moduleAccessCondition(user, [MODULES.VIEW_LEAD_SOURCE], []),
        //   element: moduleAccessCondition(user, [MODULES.VIEW_LEAD_SOURCE], []) ? <SalesPersonCapabilityListController /> : <Navigate replace to={redirectDefault} />,
        // },
        // {
        //   path: "source-capability",
        //   title: "Source Capability",
        //   icon: Settings,
        //   hideInPannel: !moduleAccessCondition(user, [MODULES.VIEW_LEAD_SOURCE], []),
        //   element: moduleAccessCondition(user, [MODULES.VIEW_LEAD_SOURCE], []) ? <SourceCapabilityListController /> : <Navigate replace to={redirectDefault} />,
        // },
      ],
    },

    {
      path: "projects",
      icon: <SolarPowerIcon />,
      title: "Projects",
      hideInPannel: !moduleAccessCondition(user, [MODULES.VIEW_PROJECTS], []),
      element: moduleAccessCondition(user, [MODULES.VIEW_PROJECTS], []) ? (
        <AppContainer>
          <ProjectListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "project-detail/:id",
      icon: <SolarPowerIcon />,
      title: "Project Detail",
      hideInPannel: true,
      element: moduleAccessCondition(user, [MODULES.VIEW_PROJECTS], []) ? (
        <AppContainer>
          <ProjectDetailsController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    //         modulesConfig.filter((item => item.identifier === "INVOICE"))?.children?.filter(item => item?.identifier == "LIST")?.map((item) => item?.value)?.flat(2)
    // modulesConfig.filter((item => item.identifier === "INVOICE"))?.children?.filter(item => item?.identifier == "LIST")?.map((item) => item?.value)?.flat(2)
    {
      path: "invoices",
      icon: <Payment />,
      title: "Invoices",
      element: moduleAccessCondition(
        user,
        [
          MODULES.SUMMARY_INVOICES,
          MODULES.BULK_INVOICE,
          ...modulesConfig
            .find((item) => item?.identifier == "INVOICE")
            .children?.find((item) => item.identifier == "LIST")
            ?.children?.filter(
              (item) =>
                ![
                  MODULES.CREATE_INVOICE,
                  MODULES.CREATE_ELECTRICIAN_INVOICE,
                ].includes(item?.value)
            )
            ?.map((item) => item.value),
        ],
        []
      ) ? (
        <AppContainer />
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      hideInPannel: !moduleAccessCondition(
        user,
        [
          MODULES.SUMMARY_INVOICES,
          MODULES.BULK_INVOICE,
          ...modulesConfig
            .find((item) => item?.identifier == "INVOICE")
            .children?.find((item) => item.identifier == "LIST")
            ?.children?.filter(
              (item) =>
                ![
                  MODULES.CREATE_INVOICE,
                  MODULES.CREATE_ELECTRICIAN_INVOICE,
                ].includes(item?.value)
            )
            ?.map((item) => item.value),
        ],
        []
      ),
      // !(user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.partner_admin || user.data.role == USER_ROLES.sub_admin),
      children: [
        {
          path: "over-all",
          title: "Invoice Overall Summary",
          icon: AccountBalanceWallet,
          hideInPannel: !moduleAccessCondition(
            user,
            [MODULES.SUMMARY_INVOICES],
            []
          ),
          element: moduleAccessCondition(
            user,
            [MODULES.SUMMARY_INVOICES],
            []
          ) ? (
            <InvoiceOverAllSummary />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "bulk",
          title: "Bulk Invoices",
          icon: Workspaces,
          hideInPannel: !moduleAccessCondition(
            user,
            [MODULES.BULK_INVOICE],
            []
          ),
          element: moduleAccessCondition(user, [MODULES.BULK_INVOICE], []) ? (
            <BulkInvoiceSelectionWrapper>
              <BulkInvoiceListController />
            </BulkInvoiceSelectionWrapper>
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
          // hideInPannel: !(user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.sub_admin || user.data.role == USER_ROLES.partner_admin),
          // element: !(user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.sub_admin || user.data.role == USER_ROLES.partner_admin) ? <></> : <BulkInvoiceSelectionWrapper><BulkInvoiceListController /></BulkInvoiceSelectionWrapper>,
        },
        {
          path: "invoices",
          title: "Invoices",
          icon: PaymentOutlined,

          hideInPannel: !moduleAccessCondition(
            user,
            modulesConfig
              .find((item) => item?.identifier == "INVOICE")
              .children?.find((item) => item.identifier == "LIST")
              ?.children?.filter(
                (item) =>
                  ![
                    MODULES.CREATE_INVOICE,
                    MODULES.CREATE_ELECTRICIAN_INVOICE,
                  ].includes(item?.value)
              )
              ?.map((item) => item.value),
            []
          ),
          element: moduleAccessCondition(
            user,
            modulesConfig
              .find((item) => item?.identifier == "INVOICE")
              .children?.find((item) => item.identifier == "LIST")
              ?.children?.filter(
                (item) =>
                  ![
                    MODULES.CREATE_INVOICE,
                    MODULES.CREATE_ELECTRICIAN_INVOICE,
                  ].includes(item?.value)
              )
              ?.map((item) => item.value),
            []
          ) ? (
            <BulkInvoiceSelectionWrapper>
              <InvoiceListController />
            </BulkInvoiceSelectionWrapper>
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
      ],
    },
    {
      path: "payment",
      icon: <Paid />,
      title: "Payments",
      element: moduleAccessCondition(
        user,
        [MODULES.PAYMENT_VERIFICATION_MODULE],
        []
      ) ? <AppContainer /> : <Navigate replace to={redirectDefault} />,
      hideInPannel: !moduleAccessCondition(
        user,
        [MODULES.PAYMENT_VERIFICATION_MODULE],
        []
      ),
      children: [
        {
          path: "payment-verification",
          title: "Payment Verfifcation",
          icon: CreditScore,
          hideInPannel: !moduleAccessCondition(
            user,
            [MODULES.PAYMENT_VERIFICATION_MODULE],
            []
          ),
          element: moduleAccessCondition(
            user,
            [MODULES.PAYMENT_VERIFICATION_MODULE],
            []
          ) ? (

            <PendingVerificationListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "pending-payments",
          title: "Pending Payments",
          icon: WorkHistoryOutlined,
          hideInPannel: !moduleAccessCondition(
            user,
            [MODULES.PAYMENT_VERIFICATION_MODULE],
            []
          ),
          element: moduleAccessCondition(
            user,
            [MODULES.PAYMENT_VERIFICATION_MODULE],
            []
          ) ? (

            <PendingPaymentListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        }
      ]
    },
    {
      path: "complains",
      icon: <Report />,
      title: "Complains",
      hideInPannel: !moduleAccessCondition(user, [MODULES.VIEW_COMPLAINS], []),
      element: moduleAccessCondition(user, [MODULES.VIEW_COMPLAINS], []) ? (
        <AppContainer>
          <ComplainListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
  

    {
      path: "expenses",
      title: "Expenses",
      icon: <Money />,
      hideInPannel: !moduleAccessCondition(user, [MODULES.FINANCE], []),
      element: moduleAccessCondition(user, [MODULES.FINANCE], []) ? (
        <AppContainer>
          <ExpenseListController />{" "}
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },

    {
      path: "users",
      icon: <Groups2Outlined />,
      title: "Users",
      hideInPannel: !moduleAccessCondition(
        user,
        [MODULES.VIEW_USERS, MODULES.VIEW_INSTALLER],
        []
      ),
      element: moduleAccessCondition(
        user,
        [MODULES.VIEW_USERS, MODULES.VIEW_INSTALLER],
        []
      ) ? (
        <AppContainer />
      ) : (
        <Navigate replace to={redirectDefault} />
      ),

      children: [
        {
          path: "all-users",
          title: "All Users",
          icon: People,
          hideInPannel: !moduleAccessCondition(user, [MODULES.VIEW_USERS], []),
          element: moduleAccessCondition(user, [MODULES.VIEW_USERS], []) ? (
            <UserListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
          // element: <UserListController />,
        },

        {
          path: "installer",
          title: "Installers",
          icon: Engineering,
          // element: <InstallerListController />,
          hideInPannel: !moduleAccessCondition(
            user,
            [MODULES.VIEW_INSTALLER],
            []
          ),
          element: moduleAccessCondition(user, [MODULES.VIEW_INSTALLER], []) ? (
            <InstallerListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "team",
          title: "Teams",
          icon: Diversity1,
          // element: <TeamListController />,
          hideInPannel: !moduleAccessCondition(user, [MODULES.VIEW_TEAMS], []),
          element: moduleAccessCondition(user, [MODULES.VIEW_TEAMS], []) ? (
            <TeamListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
      ],
    },
    {
      path: "customers",
      title: "Customers",
      icon: <SupportAgentOutlined />,
      hideInPannel: !moduleAccessCondition(user, [MODULES.CUSTOMER_MODULE], []),
      element: moduleAccessCondition(user, [MODULES.CUSTOMER_MODULE], []) ? (
        <AppContainer>
          <CustomerListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "stock",
      title: "Stock",
      icon: <Inventory />,
      // element: <AppContainer><StockListController /></AppContainer>,
      hideInPannel: !moduleAccessCondition(user, [MODULES.STOCK_MODULE], []),
      element: moduleAccessCondition(user, [MODULES.STOCK_MODULE], []) ? (
        <AppContainer>
          <StockListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "setting",
      icon: <Settings />,
      title: "Settings",
      hideInPannel: !moduleAccessCondition(
        user,
        modulesConfig
          .find((item) => item.identifier == "SETTING")
          .children?.map((item) => item.value),
        []
      ),
      element: moduleAccessCondition(
        user,
        modulesConfig
          .find((item) => item.identifier == "SETTING")
          .children?.map((item) => item.value),
        []
      ) ? (
        <AppContainer />
      ) : (
        <Navigate replace to={redirectDefault} />
      ),

      children: [
        {
          path: "categories",
          title: "Complain Categories",
          icon: Category,
          hideInPannel: !moduleAccessCondition(
            user,
            [MODULES.COMPLAIN_CATEGORIES_MODULE],
            []
          ),
          element: moduleAccessCondition(
            user,
            [MODULES.COMPLAIN_CATEGORIES_MODULE],
            []
          ) ? (
            <ComplainCategoryListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },

        {
          path: "holiday",
          title: "Holidays",
          icon: RuleFolderOutlined,

          hideInPannel: !moduleAccessCondition(
            user,
            [MODULES.HOLIDAY_MODULE],
            []
          ),
          element: moduleAccessCondition(user, [MODULES.HOLIDAY_MODULE], []) ? (
            <HolidayController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
          // element: <HolidayController />,
        },

        {
          path: "secrets",
          title: "Secrets Management",
          icon: Key,
          hideInPannel: !moduleAccessCondition(
            user,
            [MODULES.SECRETS_MODULE],
            []
          ),
          element: moduleAccessCondition(user, [MODULES.SECRETS_MODULE], []) ? (
            <ListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "loan-benfits",
          title: "Loan Benefits",
          icon: Loyalty,
          hideInPannel: !moduleAccessCondition(
            user,
            [MODULES.LOAN_BENEFITS_MODULES],
            []
          ),
          element: moduleAccessCondition(
            user,
            [MODULES.LOAN_BENEFITS_MODULES],
            []
          ) ? (
            <LoanBenefitsListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "fb-integration",
          title: "Fb Integration",
          icon: IntegrationInstructions,
          hideInPannel: !moduleAccessCondition(user, [MODULES.VIEW_LEAD_SOURCE], []),
          element: moduleAccessCondition(user, [MODULES.VIEW_LEAD_SOURCE], []) ? <FBIntegrationListController /> : <Navigate replace to={redirectDefault} />,
        },
        
        {
          path: "source",
          title: "Lead Sources",
          icon: NextWeek,
          hideInPannel: !moduleAccessCondition(
            user,
            [MODULES.VIEW_LEAD_SOURCE],
            []
          ),
          element: moduleAccessCondition(
            user,
            [MODULES.VIEW_LEAD_SOURCE],
            []
          ) ? (
            <SourcesListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "template",
          title: "Template",
          icon: Description,
          hideInPannel: !moduleAccessCondition(
            user,
            [MODULES.TEMPLATE_MODULE],
            []
          ),
          element: moduleAccessCondition(
            user,
            [MODULES.TEMPLATE_MODULE],
            []
          ) ? (
            <TemplateListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "version",
          title: "Versions",
          icon: CloudSync,
          hideInPannel: !moduleAccessCondition(
            user,
            [MODULES.VERSION_MODULE],
            []
          ),
          element: moduleAccessCondition(user, [MODULES.VERSION_MODULE], []) ? (
            <VersionsController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "modules",
          title: "Modules",
          icon: ProductionQuantityLimits,
          hideInPannel: !moduleAccessCondition(user, [MODULES.MODULESS], []),
          element: moduleAccessCondition(user, [MODULES.MODULESS], []) ? (
            <ModuleListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "activities",
          title: "Activities",
          icon: LocalActivity,
          hideInPannel: !moduleAccessCondition(user, [MODULES.ACTIVITY_MODULE], []),
          element: moduleAccessCondition(user, [MODULES.ACTIVITY_MODULE], []) ? (
            <ActivityListedController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        
        {
          path: "white-listed-ip",
          title: "White Listed IP",
          icon: AllInclusive,
          hideInPannel: !moduleAccessCondition(user, [MODULES.WHITE_LISTED_IP], []),
          element: moduleAccessCondition(user, [MODULES.WHITE_LISTED_IP], []) ? (
            <WhiteListedController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "terms-and-condition",
          title: "Terms and Condition",
          icon: LibraryBooks,
          hideInPannel: !moduleAccessCondition(
            user,
            [MODULES.TERMS_AND_CONDITION_MODULE],
            []
          ),
          element: moduleAccessCondition(
            user,
            [MODULES.TERMS_AND_CONDITION_MODULE],
            []
          ) ? (
            <TermsListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
      ],
    },
    {
      path: "not-allowed",

      element: <PageNotAllowed />,

      hideInPannel: true,
    },
    {
      path: "*",
      element: !user.isLoggedIn ? (
        <Navigate replace to={redirectDefault} />
      ) : (
        <PagenotFound />
      ),
      hideInPannel: true,
    },
  ];
};
export default defineRoutes;

/* 

================================================================
Demo Object
================================================================
        {
            path: "sign-in",
            element: !user.isLoggedIn ? <SignInController /> : <Navigate replace to="/leaves" />,
            hideInPannel:true, //for showing it onSide pannel or not
            title: "Leave Management", // it will use as name in side navbar
            component:<></>


             children: [
                {
                    path: "",
                    title: "My Leaves",// it will use as name in side navbar
                    element: <MyLeaveController />,
                    hideInPannel:true,
                }
            ],
        },




================================================================
================================================================


*/
