import { combineReducers } from "@reduxjs/toolkit"
import modalReducer from "./modalReducer";
import userReducer from "./userReducer";
import notificationReducer from "./notificationReducer";
import snackBarReducer from "./snackbarReducer";
import chatReducer from "./chatReducer";
import dateRangeSelectionReducer from "./dateRangeSelectionReducer";
import fetchVersionReducer from "./fetchVersionReducer";

const rootReducer = combineReducers({
    user: userReducer,
    modal: modalReducer,    
    notifications: notificationReducer,
    chat:chatReducer,
    snackBar: snackBarReducer,
    dateRange:dateRangeSelectionReducer,
    version:fetchVersionReducer,
})
export default rootReducer;