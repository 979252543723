import { Box, IconButton, Tooltip } from "@mui/material"
import CreateController from "../../CreateController"
import { Call, Email, FmdGood, WbTwilight } from "@mui/icons-material"
import ProjectInstallerReassignButton from "../ProjectInstallerReassignButton"
import EmailCreateController from "../../../email/CreateController"
import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../../../store/actions/modalAction"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"
import { moduleAccessCondition } from "../../../../utils/helpers/helper"
import MODULES from "../../../../utils/constants/module.constants"

const AddressButton = ({ lat, lon }) => {
    if (isNaN(lat) || isNaN(lon))
        return <></>
    return <IconButton href={`https://www.google.com/maps?q=${lat},${lon}`} target="_blank" >

        <FmdGood color="primary" />

    </IconButton>
}
export const MailButton = ({ email, color = "primary" }) => {
    const dispatch = useDispatch()
    return <Tooltip title="Send Email">
        <IconButton onClick={() => { dispatch(openModal(<EmailCreateController to={email} />, "lg", undefined, MODAL_KEYS.EMAIL)) }} >

            <Email color={color} />

        </IconButton>
    </Tooltip>
}
export const CallButton = ({ phone }) => {

    return <Tooltip title="Dial Call">

        <IconButton target="_blank" href={"tel:" + phone} >

            <Call color="primary" />

        </IconButton>
    </Tooltip>
}
export const OpenInOpenSolarButton = ({ open_solar_project_id }) => {

    return <Tooltip title="Open in Open solar">
        <IconButton variant="contained" disableElevation size="inherit"
            onClick={() => { window.open(`https://app.opensolar.com/#/projects/${open_solar_project_id}/info`, "_blank", `resizable=yes, scrollbars=yes, titlebar=yes, width=${window.innerWidth}, height=${window.innerHeight}`) }}
        >
            <WbTwilight color="info" fontSize="inherit" />
        </IconButton>
    </Tooltip>
}
const ProjectIconButtons = ({ params, setParams }) => {
    const { user } = useSelector(state => state)

    return <Box sx={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }} >

        <MailButton email={params.contacts[0].email} />
        <CallButton phone={params.contacts[0].phone} />
        <AddressButton lat={params.lat} lon={params?.lon} />
        {moduleAccessCondition(user, [MODULES.CREATE_AND_REFRESH_PROJECTS]) && <CreateController id={params.open_solar_project_id} callBack={() => { }} />}


        <OpenInOpenSolarButton open_solar_project_id={params?.open_solar_project_id} />

        <ProjectInstallerReassignButton params={params} setParams={setParams} />
    </Box >
}
export default ProjectIconButtons