import { useDispatch, useSelector } from "react-redux"
import { USER_ROLES } from "../../../utils/constants/constants"
import { IconButton, Tooltip } from "@mui/material"
import { EditCalendar, ManageAccounts } from "@mui/icons-material"
import LeadReassignController from "./LeadLastUpdateDateController"
import { openModal } from "../../../store/actions/modalAction"


const LeadLastUpdateDateButton = ({ id, date, callBack=()=>{} }) => {

    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    

    const onOpenModalClick = () => {
        dispatch(openModal(<LeadReassignController date={date} id={id} callBack={callBack} />, "xs"))
    }

    
        if (user.data.role == USER_ROLES.admin)
            return <Tooltip title="Update Last Updated Date" >
                <IconButton onClick={onOpenModalClick} >
                <EditCalendar color="warning" />
            </IconButton>
            </Tooltip>
        return <></>
    
  

}
export default LeadLastUpdateDateButton