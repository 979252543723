import { memo,  useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { closeModal } from "../../../store/actions/modalAction"
import {  LEAD_STATUS } from "../../../utils/constants/lead.constant"
import {  updateLeadStatusApi } from "../../../apis/lead.api"
import LeadBasicStatusUpdateUi from "./LeadBasicStatusUpdateUi"
import LeadCallBackStatusUpdateUi from "./LeadCallBackStatusUpdateUi"
import moment from "moment"

const LeadStatusUpdateController = ({ callBack, id, status }) => {
    const validate = useValidate()
    const dispatch = useDispatch()

    const title = "Update Lead  Status"
    const updateStatusApi = updateLeadStatusApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        status,
        remarks: '',
        assigned_to: null,
        scheduled_date: moment().add(1, "days")

    })



    const validationSchemaForStatusChange = useMemo(() => ([

    ]), [fields])





    const statusChangeFun = async () => {
        const validationResponse = validate(validationSchemaForStatusChange)

        if (validationResponse === true) {

            const passData = { ...fields }
            passData['scheduled_date'] = passData['scheduled_date'].valueOf()

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateStatusApi(passData),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal())
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }




    const onSubmit = async (e) => {
        e.preventDefault()
        statusChangeFun()


    }



    if (status == LEAD_STATUS.CALL_BACK || status == LEAD_STATUS.NO_ANSWER||status == LEAD_STATUS.FOLLOW_UP|| status == LEAD_STATUS.INTEREST_CALL_BACK|| status == LEAD_STATUS.QUATATION_SENT)
        return <LeadCallBackStatusUpdateUi title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
    return <LeadBasicStatusUpdateUi title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(LeadStatusUpdateController)