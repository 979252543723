import { Box, Grid, Paper, Tab, Tabs } from "@mui/material"
import LeadAnalyticsByStatusController from "./lead-analytics/LeadAnalyticsByStatusController"
import LeadAnalyticsBySourceController from "./lead-analytics/LeadAnalyticsBySourceController"

import { useState } from "react"
import { useSelector } from "react-redux"

import LeadAnalyticsByStatusAndSalesPersonController from "./lead-analytics/LeadAnalyticsByStatusAndSalesPersonController"
import { moduleAccessCondition } from "../../utils/helpers/helper"
import { USER_ROLES } from "../../utils/constants/constants"
import LeadAnalyticsBySourceAndSalesPersonController from "./lead-analytics/LeadAnalyticsBySourceAndSalesPersonController"
import InstallationCalendar from "../projects/installationcalendar/InstallationCalendar"
import LeadAnalyticsBySourceAndSalesPersonUI from "./lead-analytics/LeadAnalyticsBySourceAndSalesPersonUI"
import LeadAnalyticsByStatusAndSourceUI from "./lead-analytics/LeadAnalyticsByStatusAndSourceUI"
import LeadAnalyticsByStatusAndSourceController from "./lead-analytics/LeadAnalyticsByStatusAndSourceController"
import MODULES from "../../utils/constants/module.constants"
import FinanceCountsController from "../finance/FinanceCountsController"
import { useNavigate, useParams } from "react-router-dom"
// import ListController from "./lead-finder/ListController"

const MainDashboard = () => {
    return <>

        <Grid container spacing={2}  >

            <Grid item xs={12} md={4}>
                <LeadAnalyticsByStatusController />
            </Grid>
            <Grid item xs={12} md={8} >
                <LeadAnalyticsBySourceController />
            </Grid>
        </Grid>


        <Box mt={3} sx={{ display: "flex" }}>

        </Box>
    </>
}
const DashboardUi = () => {
    const { user } = useSelector(state => state)
    const params = useParams()
    const navigate = useNavigate()
    const [tab, setTab] = useState(params.tab??'main')
    return <>

        <Box mb={3} >
            {<Paper sx={{ mb: 3 }}>
                <Tabs variant="scrollable" allowScrollButtonsMobile scrollButtons sx={{ width: "100%" }} value={tab} onChange={(e, newVal) =>{
                     setTab(newVal)
                     navigate("/dashboard/"+newVal)
                }}>
                    {moduleAccessCondition(user, [MODULES.DASHBOARD_MAIN], []) && <Tab label="main" value={'main'} />}
                    {moduleAccessCondition(user, [MODULES.FINANCE], []) && <Tab label="Finance" value={'finance'} />}
                    {moduleAccessCondition(user, [MODULES.INSTALLATION_CALENDAR], []) && <Tab label="Installation Calendar" value={'intsallation-calendar'} />}
                    {moduleAccessCondition(user, [MODULES.SOURCE_CROSS_SALES_PERSONS], []) && <Tab label="Source ⨯ Sales Person" value={'sources-cross-sp'} />}
                    {moduleAccessCondition(user, [MODULES.STATUS_CROSS_SALES_PERSONS], []) && <Tab label="Status ⨯ Sales Person" value={'status-cross-sp'} />}
                    {moduleAccessCondition(user, [MODULES.STATUS_CROSS_SOURCE], []) && <Tab label="Source ⨯ Status" value={'sources-cross-status'} />}
                   
                </Tabs>
            </Paper>}
            {(tab == 'main' ) && <MainDashboard />}
            
            {tab == 'intsallation-calendar' && <InstallationCalendar />}
            {tab == 'finance' && <FinanceCountsController />}
            {tab == 'sources-cross-sp' && <LeadAnalyticsBySourceAndSalesPersonController />}
            {tab == 'status-cross-sp' && <LeadAnalyticsByStatusAndSalesPersonController />}
            {tab == 'sources-cross-status' && <LeadAnalyticsByStatusAndSourceController />}
            

        </Box>
    </>
}
export default DashboardUi