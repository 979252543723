import { Button, IconButton, Tooltip } from "@mui/material"
import { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import UpdateInvoiceAmountController from "./UpdateInvoiceAmount.controller"
import { INVOICE_STATUS, INVOICE_TYPE } from "../../../utils/constants/invoice.constant"
import { USER_ROLES } from "../../../utils/constants/constants"
import { Edit } from "@mui/icons-material"
import { moduleAccessCondition } from "../../../utils/helpers/helper"
import MODULES from "../../../utils/constants/module.constants"

const UpdateInvoiceButton = ({ params, setParams, fullEdit }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()

    const onClick = () => {
        dispatch(openModal(<UpdateInvoiceAmountController fullEdit={fullEdit} id={params._id} amount={params.amount} callBack={(response) => {
            setParams({ ...params, amount: response.amount, invoice_no: response.invoice_no ?? params.invoice_no })
        }} />, "sm"))
    }
    if (params.status == INVOICE_STATUS.PAID)
        return <></>

    

    if (!moduleAccessCondition(user, [MODULES.EDIT_INVOICE]))
        return <></>

    return <Tooltip title="Edit Amount" >
        <IconButton size="small" onClick={onClick}>
            <Edit color="primary" />
        </IconButton>
    </Tooltip>
}
export default memo(UpdateInvoiceButton)