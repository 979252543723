import { useCallback, useEffect, useState } from "react"
import ProjectDetailsUi, { ProjectDetailsDialog } from "./ProjectDetailsUi"
import { callApiAction } from "../../../store/actions/commonAction"
import { useDispatch } from "react-redux"
import { getProjectsByIdApi } from "../../../apis/projects.api"

const ProjectDetailsController = ({ project,viewOnly,noDialog,title,children }) => {
    const dispatch = useDispatch()
    
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)

    const fetchProjectById = useCallback((id) => {

        setLoading(true)
        dispatch(callApiAction(
            async () => await getProjectsByIdApi({ id }),
            (response) => {
                
                setData(response)
                setLoading(false)
                
            },
            (err) => {
                setLoading(false)
            }
        ))

    }, [])
    useEffect(() => {
        if (project)
            fetchProjectById(project)
    }, [project])
    const callBack = ()=>{
        fetchProjectById(project)
    }
    if(noDialog)
    return <ProjectDetailsUi
    children={children}
    title={title}
    viewOnly={viewOnly}
    data={data}
    loading={loading}
    setData={setData}
    />
    return <ProjectDetailsDialog
    children={children}
    title={title}
    viewOnly={viewOnly}
    data={data}
    loading={loading}
    setData={setData}
    callBack={callBack} />
}
export default ProjectDetailsController
// import { useCallback, useEffect } from "react"
// import { useState } from "react"
// import { useParams } from "react-router-dom"
// import { getProjectsByIdApi, getProjectsFilesByIdApi, getStoredProjectsFilesByIdApi, uploadStoredProjectsFilesByIdApi } from "../../../apis/projects.api"
// import { useDispatch } from "react-redux"
// import { callApiAction } from "../../../store/actions/commonAction"
// import ProjectDetailsMainUi from "./ProjectDetailsMainUi"
// import { callSnackBar } from "../../../store/actions/snackbarAction"
// import { SNACK_BAR_VARIETNS } from "../../../utils/constants/constants"

// const ProjectDetailsController = ({ project }) => {
//     const { projectId } = useParams()
//     const dispatch = useDispatch()
//     const [params, setParams] = useState({
//         tab: 0

//     })

//     const [details, setDetails] = useState({})
//     const [files, setFiles] = useState([])

//     const [storedFiles, setStoredFiles] = useState([])
//     const [storedFilesLoading, setStoredFilesLoading] = useState([])

//     const [invoices, setInvoices] = useState([])


//     const [loading, setLoading] = useState(false)
//     const [filesLoading, setFilesLoading] = useState(false)
//     const [files_v2, set_files_v2] = useState([])
//     const fetchProjectById = useCallback((id) => {

//         setLoading(true)
//         dispatch(callApiAction(
//             async () => await getProjectsByIdApi({ id }),
//             (response) => {
//                 setDetails(response)
//                 setLoading(false)
//                 setFiles([])
//             },
//             (err) => {
//                 setLoading(false)
//             }
//         ))

//     }, [])


//     const fetchFilesProjectById = (id) => {

//         setFilesLoading(true)
//         dispatch(callApiAction(
//             async () => await getProjectsFilesByIdApi({ ...params, id }),
//             (response) => {
//                 setFiles(response)

//                 setFilesLoading(false)
//             },
//             (err) => {
//                 setFilesLoading(false)
//             }
//         ))


//         dispatch(callApiAction(
//             async () => await getStoredProjectsFilesByIdApi({ ...params, project_id: project || projectId }),
//             (response) => {
//                 if (response && Array.isArray(response))
//                     setStoredFiles(response.map(item => item.path))

//                 setStoredFilesLoading(false)
//             },
//             (err) => {
//                 setStoredFilesLoading(false)
//             }
//         ))
//     }

//     const uploadFilesProject = (pathUrl) => {

//         dispatch(callApiAction(
//             async () => await uploadStoredProjectsFilesByIdApi({ path: pathUrl, project_id: project || projectId }),
//             (response) => {
//                 dispatch(callSnackBar("File Uploaded Successfully", SNACK_BAR_VARIETNS.suceess))
//                 setStoredFiles([...storedFiles, pathUrl])
//             },
//             (err) => {
//                 dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
//             }
//         ))
//     }

//     // useEffect(() => {
//     //     if (params.tab == 2) {
//     //         if (files.length == 0)
//     //             fetchFilesProjectById(projectId)
//     //     }
//     // }, [params.tab])

//     useEffect(() => {
//         if (project) {
//             fetchProjectById(project)
//         } else {
//             fetchProjectById(projectId)
//         }

//     }, [projectId, project])

//     return <ProjectDetailsMainUi
//         setStoredFiles={setStoredFiles}
//         params={params}
//         setParams={setParams}
//         callBack={fetchProjectById}
//         fromModal={project}
//         project={projectId ? projectId : project}
//         invoices={invoices}
//         details={details}
//         files={files}
//         uploadFilesProject={uploadFilesProject}
//         storedFiles={storedFiles}
//         storedFilesLoading={storedFilesLoading}
//         setDetails={setDetails}
//         loading={loading}
//         filesLoading={filesLoading}
//         fetchFilesProjectById={fetchFilesProjectById}
//         files_v2={files_v2}
//         set_files_v2={set_files_v2}
//     />

// }
// export default ProjectDetailsController