import { IconButton, Tooltip } from "@mui/material"
import { LEAD_STATUS } from "../../../utils/constants/lead.constant"
import { AddIcCall } from "@mui/icons-material"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import LeadCallCreateController from "./LeadCallCreateController"

const LeadCallCreateButton = ({ status, lead_id, callBack = () => { } }) => {

    const dispatch = useDispatch()

    const onClick = () => {
        dispatch(openModal(<LeadCallCreateController status={status} lead_id={lead_id} callBack={callBack}  />))
    }

    if ([LEAD_STATUS.NOT_INTRESTED, LEAD_STATUS.QUATATION_REJECTED, LEAD_STATUS.WRONG_NUMBER, LEAD_STATUS.SOLD].includes(status)) {
        return <Tooltip title="Create Lead Call" ><IconButton onClick={onClick} ><AddIcCall color="warning" /></IconButton></Tooltip>
    }

    return <></>

}
export default LeadCallCreateButton