import {  useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { useMemo } from "react"
import { getProjectsStockApi } from "../../../apis/projects.api"
import { STOCK_TYPE } from "../../../utils/constants/project.constant"

const ListController = ({  }) => {
    const dispatch = useDispatch()

    const { user } = useSelector(state => state)

    const title = "Stock "
    const fetchApi = getProjectsStockApi

    const columns = useMemo(() => [
        {
            id: 0, fieldName: 'stockName',sort:true, label: 'Name', align: "left",
            renderValue: (params, setParams) => params.stockName + " ("+params.stockCode+")"
        },
        {
            id: 1, fieldName: 'pendningStockCount',sort:true, label: 'Pending Installation', align: "left"
        },
        {
            id: 2, fieldName: 'installedStockCount',sort:true, label: 'Installed', align: "left"
        },
        {
            id: 3, fieldName: 'pendningStockProject', sort:true,label: 'Project Pending', align: "left"
        },
        {
            id: 4, fieldName: 'installedStockProject', sort:true,label: 'Project Installed', align: "left"
        },

        {
            id: 5, fieldName: 'project', sort:true,label: 'Project Includes', align: "left"
        }
    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        searchModule:STOCK_TYPE.PANNELS,
        search: '',
        sort:'pendningStockCount',
        sortDirection:-1,
        tab: 0

    })




    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})    



    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    

    useEffect(() => {
        fetchList()
    }, [filters])


    
    
    return (
        <>
            <ListUi
                title={title}
                

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}

                
            />

        </>
    )
}
export default ListController