import { CircularProgress, Grid, MenuItem, Typography } from "@mui/material"

import { memo } from "react"
import { useSelector } from 'react-redux'
import CustomInput from "../../components/inputs/CustomInput"


import { USER_ROLES } from "../../utils/constants/constants"

import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getUserApi } from "../../apis/user.api"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"

import getSourceApi from "../../apis/sources.api"
import GoogleAddress from "../../components/inputs/GoogleAddress"
import AlreadExistLeadView from "./AlreadExistLeadView"




const CreateUi = ({ title, alredyExistData, createFunction, isUpdate, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog

            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Raise"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Raise"}`}
        >
            <AlreadExistLeadView isUpdate={isUpdate} createFunction={createFunction} submitLoading={loading} currentData={fields} alreadyExistData={alredyExistData} />

            {loading && <CenteredBox><CircularProgress /></CenteredBox>}
            {<>
                <Grid container spacing={1} mt={1} >
                    <Grid item xs={6} >
                        <CustomInput
                            autoFocus={true}
                            disabled={loading}
                            value={fields.customer_name}
                            onChange={(e) => setFields({ ...fields, err: '', customer_name: e.target.value })}
                            type="text"
                            label={"Customer Name*"}
                            margin="none"

                        />
                    </Grid>
                    <Grid item xs={6} >
                        <CustomInput
                            disabled={loading}
                            value={fields.customer_family_name}
                            onChange={(e) => setFields({ ...fields, err: '', customer_family_name: e.target.value })}
                            type="text"
                            label={"Customer Surname"}
                            margin="none"
                        />
                    </Grid>
                    <Grid item xs={12}  >
                        <Typography variant="caption" align="center"  >Enter atleast one field from email and phone  </Typography>
                    </Grid>
                    <Grid item xs={6} >

                        <CustomInput
                            disabled={loading}
                            value={fields.customer_email}
                            onChange={(e) => setFields({ ...fields, err: '', customer_email: e.target.value })}

                            type="email"
                            label={"Customer Email"}
                            margin="none"

                        />
                    </Grid>
                    <Grid item xs={6} >
                        <CustomInput
                            disabled={loading}
                            value={fields.customer_phone}
                            onChange={(e) => setFields({ ...fields, err: '', customer_phone: e.target.value })}
                            type="text"
                            label={"Customer Phone No.*"}
                            margin="none"

                        />
                    </Grid>

                    {!isUpdate && fields.alreadyInOpenSolar && <Grid item xs={12} > <CustomInput
                        disabled={loading}
                        value={fields.open_solar_id}
                        onChange={(e) => setFields({ ...fields, err: '', open_solar_id: e.target.value })}
                        type="text"
                        label={"Open Solar Id*"}

                    /></Grid>}
                    <Grid item xs={12} >
                        <GoogleAddress
                            key={loading}
                            onChange={(val) => {
                                setFields({
                                    ...fields, err: '',                                        // ...val
                                    customer_postcode: val?.postCode,
                                    customer_country: val?.country,
                                    customer_state: val?.state,
                                    customer_locality: val?.locality,
                                    customer_address: val?.address,
                                    lat: val?.lat,
                                    lon: val?.lon,

                                })
                            }}
                            defaultAddress={
                                {
                                    postCode: fields.customer_postcode,
                                    country: fields.customer_country,
                                    state: fields.customer_state,
                                    locality: fields.customer_locality,
                                    address: fields.customer_address,
                                    lat: fields.lat,
                                    lon: fields.lon,
                                }
                            }
                        />
                    </Grid>
                    {(!isUpdate || (isUpdate && (user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.sub_admin || user.data.role == USER_ROLES.partner_admin))) && <Grid item xs={user.data.role != USER_ROLES.sales ? 6 : 12} >

                        <AsyncDropDown
                            id={'sources-leads'}
                            key={loading}
                            defaultVal={fields.source}
                            // value={fields.source}
                            lazyFun={async (params) => { return await getSourceApi({ ...params }) }}
                            label="Sources*"
                            OptionComponent={({ option, ...rest }) => {
                                return <MenuItem {...rest}>{option.name}</MenuItem>
                            }}
                            onChange={async (changedVal) => { setFields({ ...fields, source: changedVal ? changedVal._id : null }) }}
                            titleKey={'name'}
                            valueKey={"_id"}
                            margin="none"
                        />
                    </Grid>}
                    {!isUpdate && <>

                        {user.data.role != USER_ROLES.sales && <Grid item xs={6} >
                            <AsyncDropDown
                                id={'sales-leads'}
                                // value={fields.assigned_to}
                                lazyFun={async (params) => { return await getUserApi({ ...params, role: USER_ROLES.sales }) }}
                                label="Assigned To*"
                                OptionComponent={({ option, ...rest }) => {
                                    return <MenuItem {...rest}>{option.name}</MenuItem>
                                }}
                                onChange={async (changedVal) => { setFields({ ...fields, assigned_to: changedVal ? changedVal._id : null }) }}
                                titleKey={'name'}
                                valueKey={"_id"}
                                margin="none"
                            />
                        </Grid>}
                        <Grid item xs={12} >
                            <CustomInput
                                disabled={loading}
                                value={fields.remarks}
                                onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                                multiline
                                rows={2}
                                type="textarea"
                                label={"Remarks"}
                                margin="none"

                            />
                        </Grid>
                    </>}
                </Grid>
            </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)