import { memo, useCallback, useEffect, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { closeModal, openModal } from "../../../store/actions/modalAction"

import ProjectUpdateFlagUi from "./ProjectUpdateFlagUi"
import { updateProjectsFlags } from "../../../apis/projects.api"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import { PROJECT_FLAGS } from "../../../utils/constants/project.constant"
import { getStatusOfProjectFlags } from "../../../utils/helpers/project.helper"


const ProjectUpdateFlagsController = ({ callStart, callBack, id, flag, flag_value, title,defaultFilters={} }) => {
    const validate = useValidate()
    const dispatch = useDispatch()

    const modalKey = MODAL_KEYS.UPDATE_PROJECT_FLAGS

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        flag,
        flag_value,
        remarks: '',

        pre_approval_applied_ref_no: null,
        pre_approval_applied_date: null,
        meter_no:null,
        nmi_no:null,


        pre_approval_ref_no: null,
        approval_letter: null,
        pre_approval_date: null,
        approved_export_limit:null,

        solar_victoria_applied_date: null,
        solar_victoria_ref_no: null,
        loan_benefits: [],
        solar_victoria_approval_date: null,


        assigned_installer: null,
        installtion_date: null,

        no_of_stc: null,


        ces: null,
        electrician_invoice_no: null,
        custom_invoice: null,
        electrician_invoice_amount: null,
        electrician_invoice_due_date: null,

        stc_claimed_date: null,
        stc_claimed_ref_no: null,

        sv_claimed_date: null,
        sv_claimed_ref_no: null,


        grid_post_application_date: null,
        cr_no: null,
        grid_post_complition_date: null,
        ...defaultFilters

    })

    const validatorCreator = useCallback((flag, flagValue, value, title) => {
        return  {
            custom: () => {
                if (fields.flag == flag && fields.flag_value == flagValue && (!value || String(value)?.trim() == '')) {
                    return title + " is required"
                }
                return true
            }
        }
    }, [])

    const validationSchemaForStatusChange = useMemo(() => ([
        validatorCreator(PROJECT_FLAGS.GRID_PRE_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPLIED, fields.nmi_no, 'NMI No.'),
        validatorCreator(PROJECT_FLAGS.GRID_PRE_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPLIED, fields.meter_no, 'Meter No.'),
        validatorCreator(PROJECT_FLAGS.GRID_PRE_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPLIED, fields.pre_approval_applied_ref_no, 'Grid pre application ref no.'),
        validatorCreator(PROJECT_FLAGS.GRID_PRE_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPLIED, fields.pre_approval_applied_date, 'Grid pre application date'),
        
       
        validatorCreator(PROJECT_FLAGS.GRID_PRE_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPROVED, fields.pre_approval_ref_no, 'Grid pre approval ref no.'),
        validatorCreator(PROJECT_FLAGS.GRID_PRE_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPROVED, fields.approved_export_limit, 'Grid Approved Export Limit is required'),
        validatorCreator(PROJECT_FLAGS.GRID_PRE_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPROVED, fields.pre_approval_date, 'Grid pre approval date is required'),
        validatorCreator(PROJECT_FLAGS.GRID_PRE_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPROVED, fields.approval_letter, 'Pre approval letter is required'),

        validatorCreator(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPLIED, fields.solar_victoria_applied_date, 'Solar victoria application date'),
        validatorCreator(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPLIED, fields.solar_victoria_ref_no, 'INS no. '),
        validatorCreator(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPROVED, fields.solar_victoria_approval_date, 'Solar victoria approval date'),

        validatorCreator(PROJECT_FLAGS.INSTALLATION, getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).SCHEDULED, fields.installtion_date, 'Installation date'),
        validatorCreator(PROJECT_FLAGS.INSTALLATION, getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).SCHEDULED, fields.assigned_installer, 'Installer'),


        validatorCreator(PROJECT_FLAGS.STC_CLAIMED, getStatusOfProjectFlags(PROJECT_FLAGS.STC_CLAIMED).APPLIED, fields.no_of_stc, 'No of Stc'),


        validatorCreator(PROJECT_FLAGS.CES_CERTI, getStatusOfProjectFlags(PROJECT_FLAGS.CES_CERTI).RECIEVED, fields.ces, 'CES Certificate'),
        // validatorCreator(PROJECT_FLAGS.CES_CERTI, getStatusOfProjectFlags(PROJECT_FLAGS.CES_CERTI).RECIEVED, fields.electrician_invoice_no, 'Invoice No'),
        // validatorCreator(PROJECT_FLAGS.CES_CERTI, getStatusOfProjectFlags(PROJECT_FLAGS.CES_CERTI).RECIEVED, fields.custom_invoice, 'Invoice Slip'),
        // validatorCreator(PROJECT_FLAGS.CES_CERTI, getStatusOfProjectFlags(PROJECT_FLAGS.CES_CERTI).RECIEVED, fields.electrician_invoice_amount, 'Amount'),
        // validatorCreator(PROJECT_FLAGS.CES_CERTI, getStatusOfProjectFlags(PROJECT_FLAGS.CES_CERTI).RECIEVED, fields.electrician_invoice_due_date, 'Due Date/Payment Date'),


        validatorCreator(PROJECT_FLAGS.STC_CLAIMED, getStatusOfProjectFlags(PROJECT_FLAGS.STC_CLAIMED).CLAIMED, fields.stc_claimed_date, 'STC claimed Date'),
        validatorCreator(PROJECT_FLAGS.STC_CLAIMED, getStatusOfProjectFlags(PROJECT_FLAGS.STC_CLAIMED).CLAIMED, fields.stc_claimed_ref_no, 'STC Ref no. '),


        validatorCreator(PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL).CLAIMED, fields.sv_claimed_date, 'SV claimed Date'),
        validatorCreator(PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL).CLAIMED, fields.sv_claimed_ref_no, 'SV Ref no. '),


        validatorCreator(PROJECT_FLAGS.GRID_POST_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.GRID_POST_APPROVAL).APPLIED, fields.grid_post_application_date, 'Post approval application date'),
        validatorCreator(PROJECT_FLAGS.GRID_POST_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.GRID_POST_APPROVAL).APPLIED, fields.cr_no, 'Post approval Ref no. '),

        validatorCreator(PROJECT_FLAGS.GRID_POST_APPROVAL, getStatusOfProjectFlags(PROJECT_FLAGS.GRID_POST_APPROVAL).COMPLETED, fields.grid_post_complition_date, 'Post approval complition date'),


    ]), [fields])





    const statusChangeFun = async () => {
        const validationResponse = validate(validationSchemaForStatusChange)

        if (validationResponse === true) {

            const passData = { ...fields }


            callStart()

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateProjectsFlags(passData),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }




    const onSubmit = async (e) => {
        e.preventDefault()
        statusChangeFun()


    }


    return <ProjectUpdateFlagUi modalKey={modalKey} title={title} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />

}
export default memo(ProjectUpdateFlagsController)