import { memo,  useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"
import UpdateProjectInstallerUi from "./UpdateProjectInstallerUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"

import { closeModal } from "../../../store/actions/modalAction"
import { useParams } from "react-router-dom"


import moment from "moment"
import { updateProjectInstallerApi } from "../../../apis/projects.api"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants/constants"


const UpdateProjectInstallerController = ({ installer_id, installer_name,installtion_date, project, callBack = () => { }, id }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const title = "Project Installer "
    const createApi = updateProjectInstallerApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        installtion_date: moment(installtion_date).toISOString(),
        assigned_installer: installer_id,
        assigned_installer_name: installer_name,

    })



    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.assigned_installer,
            field: 'Assigned Installer '
        },
        {
            required: true,
            value: fields.installtion_date,
            field: 'Type '
        }
    ]), [fields])




    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(fields),
                    async (response) => {

                        await callBack(fields)
                        setLoading(false)
                        dispatch(callSnackBar("Installer assigned successfully",SNACK_BAR_VARIETNS.suceess))
                        dispatch(closeModal())
                    },
                    (err) => {
                        dispatch(callSnackBar(err,SNACK_BAR_VARIETNS.error))
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


 


    const onSubmit = async (e) => {
        e.preventDefault()
        createFunction()


    }

 


    return <UpdateProjectInstallerUi  title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(UpdateProjectInstallerController)