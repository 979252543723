import { Box, ButtonBase, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material"

import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop"
import { Chart, ArcElement } from 'chart.js'
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector"
import { useDispatch } from "react-redux"
import { memo } from "react"
import { openModal } from "../../../store/actions/modalAction"
import { LeadAnalyticsByStatusAndSourceDialog } from "./LeadAnalyticsByStatusAndSourceController"
import { LeadListModal } from "../../lead/ListController"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"

const LeadAnalyticCell = memo(({ isLast, id, value, system_size, source, analytics_status, dateKey,startDate, endDate }) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const onClick = () => {
        if (id) {
            dispatch(openModal(<LeadAnalyticsByStatusAndSourceDialog dateKey={dateKey} modalKey={id} sourceParent={id} sourceParentName={value} />, 'lg', undefined, id))
        }
        if (source) {
            const filtersTobePassed = {
                source, analyticalStatus: analytics_status,
            }
            if(dateKey=='createdAt'){
                filtersTobePassed['startDate'] = startDate
                filtersTobePassed['endDate'] = endDate
            }else{
                filtersTobePassed['lastUpdateStartDate'] = startDate
                filtersTobePassed['LastUpdateEndDate'] = endDate
            }
            dispatch(openModal(<LeadListModal filters={{
                ...filtersTobePassed
                // lastUpdateStartDate: startDate, LastUpdateEndDate: endDate
            }} />,
                'lg', undefined, MODAL_KEYS.LEAD))
        }
    }

    return <TableCell sx={{ background: isLast ? theme.palette.primary.light : "unset" }} >
        <Box
            onClick={onClick} component={id || source ? ButtonBase : undefined} sx={{ display: 'flex' }} >

            <Typography sx={{ textDecoration: id ? "underline" : "unset" }} color={id ? "info.main" : undefined} fontWeight="bold" textTransform="capitalize" variant="subtitle2" >

                {value}

            </Typography>
            <Typography textTransform="capitalize" variant="subtitle2" >

                &nbsp;{`${(system_size ? `(${system_size} kw)` : "")}`}

            </Typography>
        </Box>
    </TableCell>
})
const LeadAnalyticsByStatusAndSourceUI = ({ loading, list, filters, setFilters }) => {

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"))
    return (
        <>
            <Paper elevation={0} sx={{ mb: 2 }} >
                <Box p={3}>

                    <Box p={2}  component={Paper} variant="outlined" sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} >


                        <Box sx={{ display: "flex", flex: 1, minWidth: "300px" }}>
                            <FormControl>
                                <FormLabel id="date-type-radio">Date Type</FormLabel>
                                <RadioGroup
                                    aria-labelledby="date-type-radio"
                                    value={filters.dateKey}
                                    row
                                    name="radio-buttons-group"
                                    onChange={(e) => setFilters({ ...filters, dateKey: e.target.value })}
                                >
                                    <FormControlLabel value="createdAt" control={<Radio />} label="Creation Date" />


                                    <FormControlLabel value="last_status_updated_at" control={<Radio />} label="Last Update Date" />

                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: "flex", flex: 1, maxWidth: "400px" }}>

                            <TimeRangeSelector dateType="last_updated_at" defaultVal={{ label: filters.label, _id: filters._id }} margin="none" size="small" onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />
                            {/* <TimeRangeSelector defaultVal={{ label: "This Month" }} onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} /> */}
                        </Box>



                    </Box>
                </Box>
            </Paper>
            <Paper elevation={0} sx={{ width: isSmallScreen ? "100%" : "unset" }} >
                <Box p={3}>
                    <Box sx={{ display: "flex", alignItems: 'center', flexWrap: "wrap" }} mb={2}>
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <Typography variant="h6" fontWeight={"bold"} ></Typography>

                        </Box>
                        <PaddingBoxInDesktop sx={{ display: "flex", flex: 1, maxWidth: "300px" }}  >



                        </PaddingBoxInDesktop>
                    </Box>
                    <Box sx={{ display: "flex" }} >




                    </Box>

                    <Box sx={{ width: "100%", maxWidth: "100%" }}>
                        {
                            loading && <Skeleton width={"100%"} variant="rectangular" height={isSmallScreen ? "250px" : "400px"} />
                        }
                        <TableContainer sx={{ width: "100%" }}>
                            <Table size="small" sx={{ width: "100%", borderRadius: "5px", overflow: "hidden" }}>
                                <TableHead sx={(theme) => ({ background: theme.palette.primary.main, "*": { color: theme.palette.text.invert } })}>
                                    {
                                        !loading && list && Array.isArray(list) && list?.slice(0, 1).map((item, index) => {

                                            return <TableRow key={index} >
                                                {
                                                    item?.map((subItem, subIndex) => <TableCell key={subIndex}>
                                                        <Typography textTransform="capitalize" variant="subtitle2" >
                                                            {subItem?.value}


                                                        </Typography>
                                                    </TableCell>)
                                                }
                                            </TableRow>
                                        }

                                        )
                                    }
                                </TableHead>
                                <TableBody>
                                    {
                                        !loading && list && Array.isArray(list) && list?.slice(1).map((item, index) => {

                                            return <TableRow key={index} sx={(theme) => ({ background: index == list.length - 2 ? theme.palette.primary.light : index % 2 == 0 ? "#f2f2f2" : '' })} >

                                                {
                                                    item?.map((subItem, subIndex) => <LeadAnalyticCell startDate={filters.startDate} dateKey={filters.dateKey} endDate={filters.endDate} analytics_status={subItem?.analytics_status} source={subItem?.source} id={subItem?._id} value={subItem?.value} system_size={subItem?.system_size} isLast={subIndex == item.length - 1} key={subIndex} />)
                                                }

                                            </TableRow>
                                        }

                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Box>
                </Box>

            </Paper>

        </>
    )
}
export default LeadAnalyticsByStatusAndSourceUI