import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"
import {  SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants/constants"
import { addLeadApi, getLeadByIdApi, updateLeadData, updateLeadField } from "../../apis/lead.api"
import { getAutoComplete, getLatAndLon } from "../../apis/googlemap.api"
import { validateEmail, validatePhoneNumber } from "../../utils/helpers/helper"
import { callSnackBar } from "../../store/actions/snackbarAction"

const CreateController = ({ callBack, id, alreadyInOpenSolar = false }) => {
    const { user } = useSelector(state => state)
    const validate = useValidate()
    const dispatch = useDispatch()


    const title = "Lead "
    const createApi = addLeadApi
    const updateApi = updateLeadData
    const getByIdApi = getLeadByIdApi


    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        customer_name: '',
        customer_family_name: '',
        customer_email: '',
        customer_phone: '',

        customer_address: undefined,
        customer_locality: undefined,
        customer_country: undefined,
        customer_state: undefined,
        customer_postcode: undefined,
        lat: undefined,
        lon: undefined,

        source: null,
        remarks: '',

        assigned_to: user.data.role != USER_ROLES.sales ? null : user.data._id,
        alreadyInOpenSolar,
        open_solar_id: null

    })
    const [alredyExistData, setAlreadyExistData] = useState([])

    const [originalDocument, setOriginalDocument] = useState({


    })


    const validationSchemaForCreate = useMemo(() => ([
        {
            required: true,
            value: fields.customer_name,
            field: 'Customer Name',
        },

        {

            value: fields.customer_email,
            field: 'Customer Email ',

            custom: () => {
                if ((!fields.customer_email || fields.customer_email?.trim() == '') && (!fields.customer_phone || fields.customer_phone?.trim() == '')) {
                    return "Enter atleast one field from email and phone"
                }
                if (fields.customer_email != '' && !validateEmail(fields.customer_email)) {
                    return "Email is invalid."
                }
                return true
            }
        },
        {

            value: fields.customer_phone,
            field: 'Customer Phone ',
            custom: () => {
                if ((!fields.customer_email || fields.customer_email?.trim() == '') && (!fields.customer_phone || fields.customer_phone?.trim() == '')) {
                    return "Enter atleast one field from email and phone"
                }
                if (fields.customer_phone && fields.customer_phone.trim() != '') {
                    if (!validatePhoneNumber(fields.customer_phone)) {
                        return "Phone no should be 10 letters & starts with 0"
                    }
                }

                return true
            }
        },
        // {
        //     required: true,
        //     value: fields.customer_address,
        //     field: 'Customer Address ',
        // },
        // {
        //     required: true,
        //     value: fields.customer_locality,
        //     field: 'Customer Locality ',
        // },
        // {
        //     required: true,
        //     value: fields.customer_postcode,
        //     field: 'Customer  Postcode',
        // },
        // {
        //     required: true,
        //     value: fields.customer_state,
        //     field: 'Customer State ',
        // },
        // {
        //     required: true,
        //     value: fields.customer_country,
        //     field: 'Customer State ',
        // },



        {
            required: true,
            value: fields.source,
            field: 'Source',

        },
        {
            field: "Open Solar Id",
            custom: () => {

                if (alreadyInOpenSolar && (!fields.open_solar_id || fields.open_solar_id == '')) {
                    return "Open Solar Id is required."
                }
                return true
            }
        },
        {
            required: true,
            value: fields.assigned_to,
            field: 'Assigned To',

        },
    ]), [fields, alreadyInOpenSolar])

    const validationSchemaForUpdate = useMemo(() => ([


        {
            required: true,
            value: fields.customer_name,
            field: 'Customer Name',
        },

        {

            value: fields.customer_email,
            field: 'Customer Email ',

            custom: () => {
                if ((!fields.customer_email || fields.customer_email?.trim() == '') && (!fields.customer_phone || fields.customer_phone?.trim() == '')) {
                    return "Enter atleast one field from email and phone"
                }
                if (fields.customer_email != '' && !validateEmail(fields.customer_email)) {
                    return "Email is invalid."
                }
                return true
            }
        },
        {

            value: fields.customer_phone,
            field: 'Customer Phone ',
            custom: () => {
                if ((!fields.customer_email || fields.customer_email?.trim() == '') && (!fields.customer_phone || fields.customer_phone?.trim() == '')) {
                    return "Enter atleast one field from email and phone"
                }
                if (fields.customer_phone && fields.customer_phone.trim() != '') {
                    if (!validatePhoneNumber(fields.customer_phone)) {
                        return "Phone no should be 10 letters & starts with 0"
                    }
                }

                return true
            }
        },

        {
            required: true,
            value: fields.source,
            field: 'Source',

        },

    ]), [fields])



    const createFunction = async (forcefully) => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {
            
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi({ ...fields, forcefully }),
                    async (response) => {
                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal())
                    },
                    (err, code, responseData) => {

                        setLoading(false)
                        setFields({ ...fields, err })

                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))

                        if (code === 406) {
                            setAlreadyExistData(responseData)
                        } else {
                            setAlreadyExistData([])
                        }
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    const updateFunction = async (forcefully) => {
        const validationResponse = validate(validationSchemaForUpdate)
        let updatedData = { id }

        for (let field in fields) {
          
            if (originalDocument[field]  && (fields[field] != originalDocument[field])) {
                
                updatedData[field] = fields[field]
            }


            if (!originalDocument[field] && fields[field]) {
                updatedData[field] = fields[field]
            }
        }

        
        
        if (validationResponse === true) {
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi({ ...updatedData, forcefully }),
                    async (response) => {
                        await callBack({ ...fields })
                        setLoading(false)
                        dispatch(callSnackBar("Lead Updated Successfully", SNACK_BAR_VARIETNS.suceess))
                        dispatch(closeModal())

                    },
                    (err, code, responseData) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                        if (code === 406) {
                            setAlreadyExistData(responseData)
                        } else {
                            setAlreadyExistData([])
                        }
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }


    }


    const onSubmit = async (e) => {
        e.preventDefault()
        if (id)
            updateFunction()
        else
            createFunction()

    }

    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {


                    setFields({ ...fields, ...response })
                    setOriginalDocument(response)
                    setLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    return <CreateUi createFunction={id ? updateFunction : createFunction} alredyExistData={alredyExistData} title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)