import { memo, useEffect, useMemo, useReducer, useState } from "react"
import UpdateModuleAccessUi from "./UpdateModuleAccessUi"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import {  IconButton, Tooltip } from "@mui/material"
import { ManageHistory } from "@mui/icons-material"
import { useDispatch } from "react-redux"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { ModuleAccessContext, moduleAccessAction, moduleContextReducer } from "./moduleStore"
import { getUserByIdApi, updateUserModuleApi } from "../../apis/user.api"
import { callApiAction } from "../../store/actions/commonAction"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants"

export const UpdateModuleAccessButton = ({ id, role }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal(<UpdateModuleAccessController id={id} role={role} />, "lg", undefined, MODAL_KEYS.MODULE_ACCESS))
    }
    return <Tooltip title="update Modules Access" >
        <IconButton onClick={onClick}>
            <ManageHistory color="error" />
        </IconButton>
    </Tooltip>
}

const UpdateModuleAccessController = ({ id, role }) => {
const dispatch = useDispatch()

    const title = "Module Access"
    const modalKey = MODAL_KEYS.MODULE_ACCESS
    const [loading, setLoading] = useState(false)
    const [modules, dispatchModules] = useReducer(moduleContextReducer, [])

    const getByIdApi = getUserByIdApi
    const submitApi = updateUserModuleApi
    const updateFun = async () => {
      

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await submitApi({modules,id}),
                    async (response) => {

                        
                        setLoading(false)
                        dispatch(closeModal(MODAL_KEYS.MODULE_ACCESS))
                        dispatch(callSnackBar("Modules Updated",SNACK_BAR_VARIETNS.suceess))

                    },
                    (err) => {
                        setLoading(false)
                        dispatch(callSnackBar(err,SNACK_BAR_VARIETNS.error))
                    }
                )
            )
        
    }
    const onSubmit = (e) => {
        e?.preventDefault?.()
        updateFun()

    }

    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {


                    dispatchModules(moduleAccessAction.setModules(response?.modules ?? []))
                    setLoading(false)

                },
                (err) => {
                    
                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])
    
    return <ModuleAccessContext.Provider value={modules} >
        <UpdateModuleAccessUi modalKey={modalKey} role={role} title={title} onSubmit={onSubmit} loading={loading} dispatchModules={dispatchModules} />
    </ModuleAccessContext.Provider>
}
export default memo(UpdateModuleAccessController)