
import { CircularProgress, Grid, InputAdornment, Paper, Popover, Popper, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"

import AsynSearchBar from "../../../components/inputs/AsynSearchBar"
import { useEffect, useRef } from "react"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import NoDataComponent from "../../../components/layouts/NoDataComponent"
import { Comment } from "@mui/icons-material"
import { createAddress } from "../../../utils/helpers/helper"
import LeadStatusComponent from "../../lead/LeadStatusComponent"




const OuterBox = styled(Box)(({ theme }) => ({
    width: "100%",
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
    border: "1px solid " + theme.palette.primary.main

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, exportLoading, setFilters, list, roles, loading, onCreateBtnClick, onExportBtnClick, columns }) => {

    const ref = useRef()

    useEffect(() => {
      
        window.addEventListener("keydown", (event) => {
            if (event.ctrlKey && event.key === 's') {
                event.preventDefault();
                if(ref){
                    
                    ref.current?.querySelector('input')?.focus?.()                    
                }
                
            }
            
            if ( event.key === 'Escape') {
                event.preventDefault();
                if(ref){
                    setFilters({...filters,search:""})
                    
                }
                
            }
        })
        return () => window.removeEventListener("keydown", () => { })
    }, [ref])
    return (
        <>


            <Box sx={{ width: "100%" }} >

                <AsynSearchBar
                    aria-describedby={'lead-dropdown'}
                    ref={ref}
                    fullWidth
                    title="Search Lead "
                    size="small"
                    InputProps={{endAdornment:<InputAdornment sx={{}} position="end">
                        <Typography variant="caption" fontWeight={600} >
                        [CTRL+S]
                        </Typography>
                    </InputAdornment>}}
                    placeholder={"Search Lead "}
                    value={filters.search}
                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                />

                <Popover
                    onClose={() => setFilters({ ...filters, search: "" })}
                    key={filters.search?.length}
                    id={'lead-dropdown'}
                    open={filters.search?.length > 0}
                    sx={{ zIndex: 111111 }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    anchorEl={ref.current}
                    disableAutoFocus={true}
                    disableEnforceFocus={true}
                >
                    <Box component={Paper} sx={{ width: "400px", maxWidth: "100%" }} p={3} >
                        {
                            loading && <CenteredBox><CircularProgress size={20} /></CenteredBox>
                        }
                        {
                            list.result && list.result.length > 0 ? list.result.map((item) => {
                                return <OuterBox key={item._id}>
                                    <Box>
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={4} ><Typography variant="caption" lineHeight="150%" >Name:</Typography></Grid>
                                            <Grid item xs={8} ><Typography variant="caption" lineHeight="150%" >{item.customer_name + " " + item.customer_family_name}</Typography></Grid>

                                            <Grid item xs={4} ><Typography variant="caption" lineHeight="150%" >Email:</Typography></Grid>
                                            <Grid item xs={8} ><Typography variant="caption" lineHeight="150%" >{item.customer_email}</Typography></Grid>
                                            <Grid item xs={4} ><Typography variant="caption" lineHeight="150%" >Phone:</Typography></Grid>
                                            <Grid item xs={8} ><Typography variant="caption" lineHeight="150%" >{item.customer_phone}</Typography></Grid>
                                            <Grid item xs={4} ><Typography variant="caption" lineHeight="150%" >Address:</Typography></Grid>
                                            <Grid item xs={8} ><Typography variant="caption" lineHeight="150%" >{createAddress(item)}</Typography></Grid>
                                            <Grid item xs={4} ><Typography variant="caption" lineHeight="150%" >Sales Person:</Typography></Grid>
                                            <Grid item xs={8} ><Typography variant="caption" lineHeight="150%" >{item.assigned_to?.[0]?.name}</Typography></Grid>
                                            <Grid item xs={4} ><Typography variant="caption" lineHeight="150%" >Status:</Typography></Grid>
                                            <Grid item xs={8} ><Typography variant="caption" lineHeight="150%" ><LeadStatusComponent params={item} onlyview /></Typography></Grid>
                                        </Grid>
                                    </Box>
                                </OuterBox>
                            }) : <CenteredBox>
                                <NoDataComponent iconVariant="subtitle1" variant="subtitle2" Icon={() => <Comment />} message="No Lead" />
                            </CenteredBox>
                        }
                    </Box>

                </Popover>
            </Box>


        </>
    )
}
export default ListUi