import * as React from 'react';

import { styled } from '@mui/material/styles';
import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

const SubmitButtonStyled = styled(Button)(({ theme }) => ({

    width: "100%",        
    minWidth:"100px",  
      
}));

const SubmitButton = ({ title,loading, ...props }) => {
    return <SubmitButtonStyled variant='contained' {...props}>
        {props.icon}
        {!loading && title}

        {loading && <CircularProgress size={25} color="light" ml={4} />}

    </SubmitButtonStyled>
}

export default SubmitButton

