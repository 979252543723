import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";

export const getUserAndSystemDataApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.userAndSystemFetchByToken,
    method: "get",
    headers: getHeaders(),
    params: data
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getUserApi = async params => {
  const callResponse = await axios({
    url: endpoints.userBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const updateUserModuleApi = async data => {
  const callResponse = await axios({
    url: endpoints.userBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getInstallerApi = async params => {
  const callResponse = await axios({
    url: endpoints.installerUserBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getInstalleByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.installerUserById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getCustomerApi = async params => {
  const callResponse = await axios({
    url: endpoints.customerBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getOpenSolarUserApi = async params => {
  const callResponse = await axios({
    url: endpoints.openSolarUserBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getUserByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.userById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addUserApi = async data => {
  const callResponse = await axios({
    url: endpoints.userBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateUserField = async data => {
  const callResponse = await axios({
    url: endpoints.userBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const updatPasswordApi = async data => {
  const callResponse = await axios({
    url: endpoints.userPassword,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const undoUserDeleteApi = async data => {
  const callResponse = await axios({
    url: endpoints.userDeleteUndo,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateInstallerUserField = async data => {
  const callResponse = await axios({
    url: endpoints.installerUserBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};



export const deleteUserApi = async data => {
  const callResponse = await axios({
    url: endpoints.userBase,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};




export default getUserAndSystemDataApi