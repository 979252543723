import { Autocomplete, Box, CircularProgress, Grid, LinearProgress, MenuItem, Paper, Skeleton, Typography } from "@mui/material"
import { findNameByRole, findObjectKeyByValue, getDateFiltersTime, titleCase } from "../../../utils/helpers/helper"
import { DATE_TIME_FILTERS, LEAD_ANALYSIS_STATUS, LEAD_STATUS, USER_ROLES } from "../../../utils/constants/constants"
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop"
import { StyledSearchBar } from "../../../components/inputs/SearchBar"
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector"
import { useSelector } from "react-redux"
import SourceFilterDropDown from "../../../components/filterdropdowns/SourceFilterDropDown"
import SalesPersonFilterDropDown from "../../../components/filterdropdowns/SalesPersonFilter"
import TeamFilterDropDown from "../../../components/filterdropdowns/TeamFilterDropDown"

const LeadAnalyticsByUI = ({ loading, list, filters, setFilters }) => {

    const { user } = useSelector(state => state)
    const percentage = !loading && list.sold ? ((list.sold?.count / (list.total!=0?list.total:1)) * 100).toFixed(0) : 0

    return (
        <>
            <Paper>
                <Box p={3}>
                    <Box sx={{ display: "flex", alignItems: 'center', flexWrap: "wrap", flexDirection: "column", width: "100%" }} mb={4}>
                        <Box sx={{ display: "flex", flex: 1, width: "100%" }}>
                            <Typography variant="h6" fontWeight={"bold"} >Lead Report</Typography>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={6} >

                                <SourceFilterDropDown
                                    id={'source-lead-analytics-status'}
                                    filters={filters}
                                    setFilters={setFilters}
                                    margin={0}
                                />
                            </Grid>
                            {<Grid item xs={6} >



                                <TeamFilterDropDown
                                    id={'teams-lead-analytics-status'}
                                    filters={filters}
                                    setFilters={setFilters}
                                />


                            </Grid>}
                            <Grid item xs={6} >

                                <SalesPersonFilterDropDown
                                    id={'sales-lead-analytics-status'}
                                    filters={filters}
                                    setFilters={setFilters}
                                    key={filters.team}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <TimeRangeSelector dateType="last_updated_at" defaultVal={{ label: filters.label, _id: filters._id }} onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />
                            </Grid>
                        </Grid>

                    </Box>
                    <Grid container spacing={2}>

                        {
                            loading && [0, 1].map((item) => {
                                return <Grid key={item} item xs={6} md={6}>
                                    <Skeleton width={"100%"} height="150px" />
                                </Grid>
                            })
                        }
                        {
                            !loading && list && <>

                                  <Grid item xs={4} >
                                        <Paper sx={{height:"100%"}} variant="outlined">
                                            <Box p={2} >
                                                <Typography mb={2} textTransform={"capitalize"} align="center" variant="h5" fontWeight={"bold"}>{list.total}</Typography>
                                                <Typography textTransform={"capitalize"} align="center" variant="body2">&nbsp;</Typography>
                                                <Typography mb={2} textTransform={"capitalize"} align="center" variant="subtitle2" fontWeight={"bold"}>Allocated Lead</Typography>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                <Grid item xs={4} >
                                    <Paper sx={{height:"100%",borderColor:"success.main"}} variant="outlined">
                                        <Box p={2} >
                                            <Typography mb={2} textTransform={"capitalize"} align="center" variant="h5" fontWeight={"bold"}>{list.sold?.count??0}</Typography>
                                            <Typography textTransform={"capitalize"} align="center" variant="body2">({list.sold?.system_size?.toFixed?.(2)??0}kw)</Typography>
                                            <Typography mb={2} textTransform={"capitalize"} align="center" variant="subtitle2" fontWeight={"bold"}>Sold</Typography>

                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} >
                                    <Paper  sx={{borderColor:"error.main"}} variant="outlined">
                                        <Box p={2} >
                                            
                                            
                                            <Typography mb={2} textTransform={"capitalize"} align="center" variant="h5" fontWeight={"bold"}>{list.rejected?.count??0}</Typography>
                                            <Typography textTransform={"capitalize"} align="center" variant="body2">({list.rejected?.system_size?.toFixed?.(2)??0}kw)</Typography>
                                            <Typography mb={2} textTransform={"capitalize"} align="center" variant="subtitle2" fontWeight={"bold"}>Rejected</Typography>

                                        </Box>
                                    </Paper>
                                </Grid>
                                {
                                  
                                }
                            </>
                        }
                    </Grid>
                </Box>

            </Paper>

            {(user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.sub_admin || user.data.role == USER_ROLES.partner_admin || user.data.role == USER_ROLES.sales || user.data.role == USER_ROLES.tl) && <Paper component={Box} mt={2}>
                <Box p={3}>
                    <Box sx={{ display: "flex", alignItems: 'center', flexDirection: "column", flexWrap: "wrap" }} mb={4}>
                        <Box sx={{ display: "flex", flex: 1 }}>
                            <Typography variant="h6" fontWeight={"bold"} >Conversion Ratio</Typography>
                        </Box>


                        {
                            loading && !percentage && <CircularProgress sx={{ height: "150px", width: "150px" }} />
                        }
                        {
                            !loading && percentage >= 0 &&
                            <Box className={`${percentage > 70 ? 'green' : 'red'}`}>
                                <Box className={`progress ${percentage > 70 ? 'green' : 'red'}`} >
                                    <Box className="inner">
                                        <Box className="percent"><span>{percentage}</span>%</Box>
                                        <Box className="water" key={percentage} sx={{ top: (100 - percentage) + "% !important" }}></Box>
                                        <Box className="glare"></Box>
                                    </Box>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Paper>
            }

        </>
    )
}
export default LeadAnalyticsByUI