import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { deleteUserApi, getInstallerApi, getUserApi, updateUserField } from "../../apis/user.api"
import { useParams } from "react-router-dom"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { findNameByRole } from "../../utils/helpers/helper"
import { Box, CircularProgress, IconButton, Typography } from "@mui/material"
import { useMemo } from "react"

import { Delete, Download, Edit } from "@mui/icons-material"
import MessageDilog from "../../components/MessageDilog"
import CreateController from "../user/CreateController"
import moment from "moment"
import FileDownloadComponent from "../../components/FileDownloadComponent"
import endpoints from "../../apis/endpoints"
import InstallerUpdateController from "./InstallerUpdateController"



const ListController = () => {
    const dispatch = useDispatch()



    const title = "Installers"
    const fetchApi = getInstallerApi


    const columns = useMemo(() => [

        { id: 1, fieldName: 'name', label: 'Name', align: "left", sort: true },
        { id: 2, fieldName: 'email', label: 'Email', align: "left" },

        { id: 8, fieldName: 'cec_id', label: 'Cec Id(Add this from bridge select)', align: "left", minWidth: 300 },
        { id: 3, fieldName: 'accrediation_number', label: 'Name', align: "left" },
        { id: 4, fieldName: 'license_number', label: 'License Number', align: "left" },
        {
            id: 5, fieldName: 'license_expiry', label: 'Licencse Expiry', align: "left",  renderValue: (params) => {
                return moment(params.license_expiry).format("DD MMM YYYY")
            }
        },
        {
            id: 6, fieldName: 'license_file', label: 'License File', align: "left", renderValue: (params) => {
                return <FileDownloadComponent src={params.license_file} />
            }
        },
        {
            id: 7, fieldName: 'aggrement_file', label: 'Aggrement File', align: "left", renderValue: (params) => {
                return <FileDownloadComponent src={params.aggrement_file} />
            }
        },

        {
            id: 8, fieldName: 'action', label: 'Action', align: "left", renderValue: (params) => {

                return <IconButton color="info" onClick={() => dispatch(openModal(<InstallerUpdateController id={params.installer_id}  callBack={fetchList}/>))}>
                    <Edit  color="info" fontSize="inherit" />
                </IconButton>
            }
        }

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        role: '',
        sort: '',
        sortDirection: 1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController isInstaller callBack={async () => { fetchList() }} />, "sm"))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController