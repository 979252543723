import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { getCustomerApi } from "../../apis/user.api"
import { Link } from "react-router-dom"
import { openModal } from "../../store/actions/modalAction"
import { findObjectKeyByValue } from "../../utils/helpers/helper"
import { Box, Button, ButtonBase, Chip } from "@mui/material"
import { useMemo } from "react"
import ProjectDetailsController from "../projects/projectdetails/ProjectDetailsController"
import DetailedViewController from "../lead/DetailedViewController"
import { CUSTOMER_STATUS } from "../../utils/constants/constants"
import CreateController from "../email/CreateController"
import fileDownload from "js-file-download"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"

const ActionComponent = memo(({ params }) => {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)


    const onProjectClick = () => {
        dispatch(openModal(<ProjectDetailsController project={params.project_id} callBack={(response, updatedData) => {

        }} />, "lg",undefined,MODAL_KEYS.PROJECT))
    }

    const onLeadClick = () => {
        dispatch(openModal(<DetailedViewController id={params._id} callBack={(response, updatedData) => {

        }} />, "lg"))
    }


    return <Box >
        {params.project_id &&<Button sx={{width:"100%"}} variant="contained" disabled={loading} size="small" onClick={onProjectClick}>
            Open&nbsp;Project
        </Button>}
        <Box mt={1} />
        {params._id &&<Button sx={{width:"100%"}} variant="contained" disabled={loading} size="small" onClick={onLeadClick}>
            Open&nbsp;Lead
        </Button>}
    </Box>
})

const ListController = () => {
    const dispatch = useDispatch()



    const title = "Customers"
    
    const fetchApi = getCustomerApi


    const columns = useMemo(() => [

        { id: 1, fieldName: 'customer_name', label: 'Name', align: "left", sort: true,renderValue:(params)=> params.customer_name +" "+ params.customer_family_name },
        {
            id: 2, fieldName: 'customer_email', label: 'Email', align: "left",
            minWidth: "250px",
            renderValue: (params, setParams) => <ButtonBase onClick={()=>{dispatch(openModal(<CreateController to={params.email} />,"lg",undefined,MODAL_KEYS.EMAIL))}} >

                {params.customer_email}

            </ButtonBase>
        },
        {
            id: 3, fieldName: 'customer_phone', minWidth: "200px", label: 'Phone no.', align: "left", renderValue: (params, setParams) => <Link to={"tel:" + params.phone} target="_blank" >


                {params.customer_phone}
            </Link>
        },
        {
            id: 4,
            fieldName: 'status',
            label: 'Status',
            // sort: true,
            align: "left",
            renderValue: (params) => <Chip size="small" label={findObjectKeyByValue(params.status, CUSTOMER_STATUS) || ''} color={

                params.status == CUSTOMER_STATUS.COMPLETED ? "success" : params.status == CUSTOMER_STATUS.REJECTED ? "error" : "default"


            } />,
        },
        {
            id: 5,
            fieldName: 'action',
            label: 'Action',
            
            align: "left",
            renderValue: (params) => <ActionComponent params={params}  />
        }

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        status:null,
        role: '',
        sort: '',
        sortDirection: 1,
        searchable:['customer_name','customer_email','customer_phone']

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [exportLoading, setexportLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = (isExport) => {
        // setLoading(true)
        // dispatch(callApiAction(
        //     async () => await fetchApi({ ...filters }),
        //     (response) => {
        //         setList(response)
        //         setLoading(false)
        //     },
        //     (err) => {
        //         setLoading(false)
        //     }
        // ))

        if (!isExport) {
            setLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters }),
                (response) => {
                    setList(response)
                    setLoading(false)
                },
                (err) => {
                    setLoading(false)
                }
            ))
        } else {
            setexportLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters, csv: true }),
                (response) => {
                    fileDownload(response, "customers.csv")
                    setexportLoading(false)
                },
                (err) => {
                    
                    setexportLoading(false)
                },
                true
            ))
        }
    }




    useEffect(() => {
        fetchList()
    }, [filters])

    const onExportBtnClick = () => { fetchList(true) }


    return (
        <>
            <ListUi
                title={title}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}

                onExportBtnClick={onExportBtnClick}
                exportLoading={exportLoading}
            />

        </>
    )
}
export default ListController