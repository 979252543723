import { CircularProgress, MenuItem, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { memo } from "react"
import { useSelector } from 'react-redux'

import { USER_ROLES } from "../../../utils/constants/constants"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"

import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"

import { getUserApi } from "../../../apis/user.api"
import SubmitButton from "../../../components/button/SubmitButton"




const LeadReassignUi = ({ title, fields, setFields, loading, onSubmit, noModal }) => {
    const { user } = useSelector(state => state)




    if (noModal) {
        return <Box sx={{ width: "100%" }}>
            {
                fields.assigned_to === user.data._id ? <Typography align="center" variant="caption"  >
                    Are you sure to reassign lead to you?
                </Typography> : <AsyncDropDown
                    id={'reassign-leads-create'}
                    value={fields.assigned_to}
                    lazyFun={async (params) => { return await getUserApi({ ...params, role: USER_ROLES.sales }) }}
                    label="Assigned To*"
                    OptionComponent={({ option, ...rest }) => {
                        return <MenuItem {...rest}>{option.name}</MenuItem>
                    }}
                    onChange={async (changedVal) => {

                        setFields({ ...fields, assigned_to: changedVal ? changedVal._id : null, assigned_to_name: changedVal ? changedVal.name : null })
                    }
                    }
                    titleKey={'name'}
                    valueKey={"_id"}
                />
            }
            <SubmitButton loading={loading} title="Reassign" onClick={onSubmit} />

        </Box>
    }
    return <>

        <CustomDialog

            loading={loading}
            err={fields.err}

            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"
            confirmText={`Update`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    <AsyncDropDown
                        id={'reassign-sales-leads-create'}
                        defaultVal={fields.assigned_to}
                        lazyFun={async (params) => { return await getUserApi({ ...params, role: USER_ROLES.sales }) }}
                        label="Assigned To*"
                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest}>{option.name}</MenuItem>
                        }}
                        onChange={async (changedVal) => { setFields({ ...fields, assigned_to: changedVal ? changedVal._id : null, assigned_to_name: changedVal ? changedVal.name : null }) }}
                        titleKey={'name'}
                        valueKey={"_id"}
                    />

                </>}

        </CustomDialog>
    </>
}
export default memo(LeadReassignUi)