import { useEffect, useMemo, useState,memo } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getUserByIdApi, updateUserField } from "../../apis/user.api";
import { callApiAction } from "../../store/actions/commonAction";
import {
  findObjectKeyByValue,
  loggedInUser,
  unEscapeStr,
} from "../../utils/helpers/helper";
import VersionsUi from "./VersionUI";
import moment from "moment";
// import DeleteHolidayButton from "./DeleteHolidayButton";
import { APP_TYPES } from "../../utils/constants/constants";
import { Box, IconButton, Tooltip } from "@mui/material";
import { deleteVersion, fetchVersiones } from "../../apis/version.api";
import { fetchVersionDataAction, setVersionDataOnDeleteAction } from "../../store/actions/fetchVersionAction";
import DeleteVersionButton from "./DeleteVersionButton";
import DetailsUI from "./DetailsUI";
import { openModal,closeModal } from "../../store/actions/modalAction";
import { Delete, Info } from "@mui/icons-material";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants";
import MessageDilog from "../../components/MessageDilog";


const ActionComponent = memo(({ params, setParams }) => {
  const versions = useSelector((state) => state.version); 
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  // const onEdit = () => {
  //   dispatch(
  //     openModal(
  //       <CreateController
  //         id={params._id}
  //         callBack={(response, updatedData) => {
  //           setParams({ ...params, ...updatedData });
  //         }}
  //       />,
  //       "sm"
  //     )
  //   );
  // };
  const onDelete = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteVersion({ id: params._id }),
        (response) => {
          setLoading(false);
          setParams({});
          const updatedData =  versions?.version_data?.filter(
            (item) => item._id != params._id
          );
          
          dispatch(
            setVersionDataOnDeleteAction(updatedData, versions?.version_filters)
          );
          dispatch(callSnackBar("Version Deleted", SNACK_BAR_VARIETNS.suceess));
        },
        (err) => {
          setLoading(false);
          dispatch(
            callSnackBar("Can not delete Version", SNACK_BAR_VARIETNS.error)
          );
        }
      )
    );
    dispatch(closeModal());
  };

  const onClick = () => {
    dispatch(
      openModal(
      <MessageDilog onSubmit={onDelete} title="Alert!" message={`Are you sure to delete "${params.name}" ?`} />, "sm")
    );
  };
  const onDetail = () => {
    dispatch(
      openModal(<DetailsUI params={params} />, "sm", undefined, "version-description")
    );
  };

<DeleteVersionButton params={params} setParams={setParams} />
  return (
    <>
      <Box sx={{ width: "100%", display: "flex" }}>
        
        <Box>
          <Tooltip title=" view description" arrow>
            <IconButton size="inherit" onClick={onDetail}>
              <Info color="info" fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <IconButton size="inherit" onClick={onClick}>
            <Delete color="error" fontSize="inherit" />
          </IconButton>
        </Box>
      </Box>
    </>
  );
});


const VersionController = ({ userId }) => {
  const versions = useSelector((state) => state.version);
  const dispatch = useDispatch();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  // const { settings } = useSelector((state) => state);
  
  
  const fetchVersionsApi = fetchVersiones;

  const columns = useMemo(
    () => [
      {
        id: 0,
        fieldName: "launch_date",
        label: "Launch Date",
        align: "left",
        sort: true,
        minWidth: "150px",
        renderValue: (params) =>
          moment(params.launch_date).format("DD/MM/YYYY"),
      },
      {
        id: 1,
        fieldName: "name",
        label: "Name",
        align: "left",
        sort: true,
        minWidth: "150px",
      },

      {
        id: 4,
        fieldName: "main_id",
        label: "Version Code",
        align: "left",
        sort: true,
        minWidth: "150px",
        renderValue: (params) => params.main_version + "." + params.sub_version,
      },
      {
        id: 1,
        fieldName: "app_type",
        label: "Type",
        align: "left",
        sort: true,
        renderValue: (params) =>
          findObjectKeyByValue(params.app_type, APP_TYPES),
      },
      // {
      //   id: 2,
      //   fieldName: "description",
      //   label: "description",
      //   align: "left",
      //   renderValue: (params) => (
      //     <Box
      //       dangerouslySetInnerHTML={{
      //         __html: unEscapeStr(params.description),
      //       }}
      //     />
      //   ),
      // },
      {
        id: 3,
        fieldName: "action",
        label: "Action",
        align: "left",
        renderValue: (params,setParams) => (
        
          // <Box sx={{ display: "flex" }}>
          //   <DeleteVersionButton params={params} setParams={setParams} />
          // </Box>
          <>
          <ActionComponent params={params} setParams={setParams} />
        </>
        ),
      },
    ],
    [dispatch]
  );
  const [filters, setFilters] = useState({
    pageSize: 10,
    pageNo: 1,
    sort: "launch_date",
    sortDirection: -1,
  });

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchVersionsApi(filters),
        (response) => {
          setList(response.result);

          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const getVersionList = () => {
    if (
      !versions?.version_data ||
      versions?.version_data?.length === 0 ||
      JSON.stringify(filters) != JSON.stringify(versions?.version_filters)
    ) {
      dispatch(fetchVersionDataAction(filters));
    }
  };
  useEffect(() => {
    getVersionList();
    // fetchList()
  }, [filters]);

  return (
    <VersionsUi
      columns={columns}
      filters={filters}
      setFilters={setFilters}
      setList={setList}
      callBack={getVersionList}
      loading={versions?.version_loading}
      list={versions?.version_data}
    />
  );
};
export default VersionController;
