import { Button, Card, Chip, CircularProgress, Divider, Grid, Tab, Tabs, Typography, ButtonBase } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'


import { LEAD_CALL_STATUS, LEAD_LOG_TYPE, LEAD_REPEAT_PARAMETERS, LEAD_STATUS } from "../../utils/constants/lead.constant"
import { createAddress, findObjectKeyByValue, titleCase } from "../../utils/helpers/helper"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { Link } from "react-router-dom"
import moment from "moment"
import NoDataComponent from "../../components/layouts/NoDataComponent"
import { Call } from "@mui/icons-material"
import { openModal } from "../../store/actions/modalAction"
import CreateController from "../email/CreateController"
import LeadStatusComponent from "./LeadStatusComponent"
import { leadStatusColor } from "../../utils/helpers/lead.helper"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { USER_ROLES } from "../../utils/constants/constants"

const KeyValueShowComponent = ({ keyName, value }) => {
    return <>
        <Grid item xs={6}>
            <Typography variant="body1" > {keyName}</Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="body1" color="grey" > {value}</Typography>
        </Grid>
    </>
}

const DetailedViewUi = ({ title, modalKey, isUpdate, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)
    const [value, setValue] = useState(0)
    const dispatch = useDispatch()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            title={`Lead Details`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>

                    <Tabs allowScrollButtonsMobile value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab sx={{ flex: 1, display: "flex" }} label="Genral" />
                        <Tab sx={{ flex: 1, display: "flex" }} label="Log History" />
                        <Tab sx={{ flex: 1, display: "flex" }} label="Call History" />
                    </Tabs>
                    <Divider></Divider>

                    <Box pl={2} pr={2} >
                        {value == 0 && <>


                            <Box mt={4} mb={4}>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <Grid container spacing={2}>
                                            <KeyValueShowComponent keyName={" Name"} value={fields.customer_name + " " + fields.customer_family_name} />
                                            <KeyValueShowComponent keyName={" Email"} value={<ButtonBase onClick={() => { dispatch(openModal(<CreateController to={fields.customer_email} />, "lg", undefined, MODAL_KEYS.EMAIL)) }} >

                                                {fields.customer_email}

                                            </ButtonBase>} />
                                            <KeyValueShowComponent keyName={" Phone"} value={<Link target={`_blank`} to={'tel:' + fields.customer_phone} >{fields.customer_phone}</Link>} />
                                        </Grid>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Grid container spacing={2}>
                                            <KeyValueShowComponent keyName={"Is Repeated"} value={

                                                (fields.lead_repeat_paramaters?.length > 0 ? "Yes" : "No") +
                                                (fields.lead_repeat_paramaters?.length > 0 ?
                                                    "(" + fields.lead_repeat_paramaters.map((item) => findObjectKeyByValue(item, LEAD_REPEAT_PARAMETERS)).join(',') + ")"
                                                    : "")}


                                            />
                                            <KeyValueShowComponent keyName={" Address"} value={createAddress(fields)} />

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Divider >
                                General Details
                            </Divider>
                            <Box mt={4} mb={4}>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <Grid container spacing={2}>
                                            <KeyValueShowComponent keyName={"Status"} value={<Chip label={findObjectKeyByValue(fields.status, LEAD_STATUS)} sx={{ lineHeight: "100%" }} size="small" color="info" />} />
                                            <KeyValueShowComponent keyName={"Source"} value={<Chip label={fields.source ? fields.source.name : "NA"} sx={{ lineHeight: "100% !important" }} size="small" color="info" />} />
                                            {
                                                [USER_ROLES.admin, USER_ROLES.partner_admin].includes(user.data.role)
                                                && fields?.sub_sources
                                                && fields?.sub_sources?.length > 0
                                                && <KeyValueShowComponent keyName={"Sub Sources"} value={fields?.sub_sources?.map((item) => (item?.name))?.join(" > ")} />
                                            }
                                           {fields.open_solar_id && <KeyValueShowComponent keyName={"Open In Open Solar"} value={<Button size="small" variant="contained" target={`_blank`} href={`https://app.opensolar.com/#/projects/${fields.open_solar_id}/info`} >Open</Button>} />}
                                        </Grid>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Grid container spacing={2}>
                                            <KeyValueShowComponent keyName={"Assigned User Name"} value={fields.assigned_to && fields.assigned_to[0] && fields.assigned_to[0].name} />

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>

                        </>}

                        {/* <Divider > 
                        Logs
                    </Divider> */}
                        {value == 1 && <Box mt={4} mb={4}>
                            {
                                fields.logs && Array.isArray(fields.logs) && fields.logs.sort((a, b) => {
                                    return moment(b.date).valueOf() - moment(a.date).valueOf()
                                }).map((log) => {
                                    return (
                                        <Card variant="outlined" key={log._id} p={2}>
                                            <Box p={3}>
                                                <Grid container spacing={2}>
                                                    <KeyValueShowComponent keyName={"Log Type"} value={<Chip label={findObjectKeyByValue(log.type, LEAD_LOG_TYPE)} color={leadStatusColor(log.status)} sx={{ lineHeight: "100% !important" }} size="small" />} />
                                                    <KeyValueShowComponent keyName={"Lead Status"} value={<Chip label={findObjectKeyByValue(log.status, LEAD_STATUS)} color={leadStatusColor(log.status)} sx={{ lineHeight: "100% !important" }} size="small" />} />
                                                    <KeyValueShowComponent keyName={"Did By"} value={log.by && log.by.name} />
                                                    <KeyValueShowComponent keyName={"Remarks"} value={log.remarks ?? "NA"} />
                                                    <KeyValueShowComponent keyName={"Date"} value={log.date && moment(log.date).format("DD/MM/YYYY HH:mm")} />
                                                </Grid>
                                            </Box>
                                        </Card>
                                    )
                                })
                            }
                        </Box>}


                        {value == 2 && <Box mt={4} mb={4}>

                            {
                                fields.call_history && Array.isArray(fields.call_history) && fields.call_history.length > 0 ? fields.call_history.sort((a, b) => moment(b.scheduled_date).valueOf() - moment(a.scheduled_date).valueOf()).map((log) => {
                                    return (
                                        <Card variant="outlined" key={log._id} component={Box} p={2} mt={2}>
                                            <Box p={3}>
                                                <Grid container spacing={2}>
                                                    <KeyValueShowComponent keyName={"Call Status"} value={<Chip label={findObjectKeyByValue(log.status, LEAD_CALL_STATUS)} color={log.status == LEAD_CALL_STATUS.PENDING ? "warning" : "success"} sx={{ lineHeight: "100% !important" }} size="small" />} />

                                                    <KeyValueShowComponent keyName={"Assigned To"} value={log.assigned_to && log.assigned_to[0] && log.assigned_to[0].name} />

                                                    <KeyValueShowComponent keyName={"Customer Response"} value={log.customer_response ?? "NA"} />
                                                    <KeyValueShowComponent keyName={"Call Assigned Time"} value={log.scheduled_date && moment(log.scheduled_date).format("DD/MM/YYYY HH:mm")} />

                                                    <KeyValueShowComponent keyName={"Lead Status before call"} value={log.initial_lead_status ? <LeadStatusComponent onlyview params={{ status: log.initial_lead_status }} /> : "NA"} />
                                                    <KeyValueShowComponent keyName={"Lead current status"} value={log.current_lead_status ? <LeadStatusComponent onlyview params={{ status: log.current_lead_status }} /> : "NA"} />
                                                    <KeyValueShowComponent keyName={"Lead Status after call"} value={log.lead_status ? <LeadStatusComponent onlyview params={{ status: log.lead_status }} /> : "NA"} />

                                                </Grid>
                                            </Box>
                                        </Card>
                                    )
                                }) : <NoDataComponent message="No call history avilable" Icon={Call} />
                            }
                        </Box>}
                    </Box>
                </>}

        </CustomDialog>
    </>
}
export default memo(DetailedViewUi)