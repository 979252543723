import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { PROJECT_STATUS } from "../../../utils/constants/project.constant"

import { createInvoiceFromProjectApi, getPendingPaymentsApi, verifyPendingPaymentsApi } from "../../../apis/invoice.api"
import SubmitButton from "../../../components/button/SubmitButton"
import { ArrowForward, CheckCircle, Refresh } from "@mui/icons-material"
import { useState } from 'react'
import fileDownload from 'js-file-download'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import { CustomSubmitIconButton } from '../../../components/button/CustomButton'

const CreateSystemInvoiceButton = ({ callBack = () => { },

    recreate,
    customer_name,
    system_invoice_created,
    project_id
}) => {
    const dispatch = useDispatch()
    const createApi = createInvoiceFromProjectApi


    const [loading, setLoading] = useState(false)



    const createInvoice = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await createApi({ id: project_id }),
            (response) => {
                fileDownload(response, customer_name + "-Invoice.pdf")
                setLoading(false)
                callBack()
            },
            (err) => {
                setLoading(false)
            },
            true
        ))
    }
    if (loading)
        return <CircularProgress size={20} />
    if (recreate) {

        return <Tooltip title="Recreate Same Invoice" >
            <IconButton onClick={createInvoice} >
                <Refresh color='error' />
            </IconButton>
        </Tooltip>
    }
    if (system_invoice_created) {
        return <></>
    }





    return (
        <>
            <CustomSubmitIconButton disableElevation variant="contained" sx={{}} color="primary" size="small" loading={loading} onClick={createInvoice} >
                <ArrowForward />

            </CustomSubmitIconButton>

        </>
    )


}
export default CreateSystemInvoiceButton