import {  CircularProgress } from "@mui/material"
import {  memo } from "react"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"

import InstallerFields from "./InstallerFields"



const InstallerUpdateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit }) => {
    


    return <>

        <CustomDialog

            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>

                    <InstallerFields loading={loading} fields={fields} setFields={setFields} />

                </>}

        </CustomDialog>
     
    </>
}
export default memo(InstallerUpdateUi)