import { CircularProgress, Grid,  MenuItem, Select, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { memo } from "react"
import { useSelector } from 'react-redux'
import CustomInput from "../../../components/inputs/CustomInput"
import { LEAD_STATUS } from "../../../utils/constants/lead.constant"

import {  fetchAllowedStatusForLead } from "../../../utils/helpers/lead.helper"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { MobileDateTimePicker } from "@mui/x-date-pickers"
import { StyledSearchBar } from "../../../components/inputs/SearchBar"
import LeadStatusComponent from "../LeadStatusComponent"
import { useTheme } from "@emotion/react"
import { OpenSolarButton } from "../ListController"
import { createAddress ,findObjectKeyByValue} from "../../../utils/helpers/helper"


const LeadViewBox = memo(({  customer_name, customer_email, customer_phone, status,setLeadDetail,createdAt, ...rest }) => {


    const theme = useTheme()
    return <Box mb={2} sx={{ background: theme.palette.secondary.main, border: "1px solid " + theme.palette.primary.main }} p={2} >
        <Grid container spacing={0} >
            <Grid item xs={4} ><Typography variant="caption" fontWeight="bold" >Name:</Typography></Grid>
            <Grid item xs={8} ><Typography variant="caption" >{customer_name}</Typography></Grid>

            <Grid item xs={4} ><Typography variant="caption" fontWeight="bold" >Email:</Typography></Grid>
            <Grid item xs={8} ><Typography variant="caption" >{customer_email}</Typography></Grid>


            <Grid item xs={4} ><Typography variant="caption" fontWeight="bold" >Phone:</Typography></Grid>
            <Grid item xs={8} ><Typography variant="caption" >{customer_phone}</Typography></Grid>


            <Grid item xs={4} ><Typography variant="caption" fontWeight="bold" >Address:</Typography></Grid>
            <Grid item xs={8} ><Typography variant="caption" >{createAddress(rest)}</Typography></Grid>





            <Grid item xs={4} ><Typography variant="caption" fontWeight="bold" >Lead Status:</Typography></Grid>
            <Grid item xs={8} ><Typography variant="caption" textTransform="capitalize" >


                <LeadStatusComponent onlyview params={{ status }} />


            </Typography></Grid>

            {/*  */}


            <Grid item xs={4} ><Typography variant="caption" fontWeight="bold" >Open Solar Status:</Typography></Grid>
            <Grid item xs={8} ><Typography variant="caption" textTransform="capitalize" >


                <OpenSolarButton params={{...rest,status}} setParams={setLeadDetail} />


            </Typography></Grid>


        </Grid>

    </Box>
})




const LeadCallStatusUpdateUi = ({ title, leadLoading, leadDetails, disableDirectClose, isUpdate, fields, setFields, loading, onSubmit }) => {
    
    const { user } = useSelector(state => state)

    const allowedUpdates = fetchAllowedStatusForLead(parseInt(leadDetails?.status))

    return <>

        <CustomDialog

            loading={loading || leadLoading}
            err={fields.err}
            disableDirectClose={disableDirectClose}
            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"
            confirmText={`Update`}
        >
            {
                !loading && leadDetails && <LeadViewBox {...leadDetails} />
            }

            {loading || leadLoading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    <Select
                        disableClearable

                        margin="dense"
                        placeholder="Select Updatable status"
                        value={fields.lead_status}

                        fullWidth
                        onChange={(e) => { setFields({ ...fields, lead_status: e.target.value }) }}

                        renderInput={(params) => <StyledSearchBar placeholder="Select Status" {...params} size="small" />}
                    >
                        {
                            allowedUpdates.map((statusVal) => (<MenuItem value={statusVal} >{findObjectKeyByValue(statusVal, LEAD_STATUS)}</MenuItem>))
                        }

                    </Select>
                    <Box mt={3}></Box>
                    {(fields.lead_status == LEAD_STATUS.CALL_BACK || fields.lead_status == LEAD_STATUS.FOLLOW_UP || fields.lead_status == LEAD_STATUS.QUATATION_SENT || fields.lead_status == LEAD_STATUS.NO_ANSWER || fields.lead_status == LEAD_STATUS.INTEREST_CALL_BACK|| fields.lead_status == LEAD_STATUS.INTEREST_CALL_BACK) && <MobileDateTimePicker

                        renderInput={(props) => <CustomInput {...props} />}
                        label="Schedule Next Call  Time"
                        inputFormat="DD/MM/YYYY HH:mm"
                        value={fields.scheduled_date}
                        onChange={(changedVal) => { setFields({ ...fields, scheduled_date: changedVal }) }}
                    />}
                    {<CustomInput
                        disabled={loading}
                        value={fields.remarks}
                        onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                        multiline
                        rows={3}
                        type="textarea"
                        label={"Customer Response"}

                    />}

                </>}

        </CustomDialog>
    </>
}
export default memo(LeadCallStatusUpdateUi)