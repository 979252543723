import axios from "axios";
import { getFileHeaders, getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";




export const getModuleApi = async params => {
  const callResponse = await axios({
    url: endpoints.moduleFetch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getModuleByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.moduleFetchById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addModuleApi = async data => {
  const callResponse = await axios({
    url: endpoints.moduleBase,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateModuleApi = async data => {
  const callResponse = await axios({
    url: endpoints.moduleUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const UploadSheetApi = async (data) =>
{
    const callResponse = await axios({
        url: endpoints.moduleUpload,
        method: "post",
        headers:getFileHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({status:0,response:err.response,code:err.response.status}));

    return callResponse;
};

export default getModuleApi