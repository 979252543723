// const { USER_ROLES } = require("../utils/constants");
const EVENT = require("../utils/constants/event.constant");

const eventConfig = [
  {
    title: "Task Management",
    value: EVENT.TASK_MANAGEMENT_MAIN,
    description: "Description",
    allowed_variables: ["name", "task_id", "assigned_to"],
  },
  {
    title: "Create User",
    description: "Description",
    allowed_variables: ["name", "open_solar_user_id", "email", "phone"],
    value: EVENT.CREATE_USERS,
  },
  {
    title: "General Event",
    description: "Description",
    allowed_variables: ["name", "email", "phone"],
    value: EVENT.GENRAL_EVENT,
  },
];
module.exports = eventConfig;
