import { LEAD_STATUS } from "../constants/lead.constant";

export const fetchAllowedStatusForLead = (currentStatus) => {
    switch (currentStatus) {
        case null: return Object.values(LEAD_STATUS);
        case LEAD_STATUS.UNHANDLED: return [LEAD_STATUS.UNHANDLED, LEAD_STATUS.WRONG_NUMBER, LEAD_STATUS.CALL_BACK, LEAD_STATUS.QUATATION_SENT, LEAD_STATUS.INTEREST_CALL_BACK, LEAD_STATUS.NOT_INTRESTED, LEAD_STATUS.SOLD];
        case LEAD_STATUS.WRONG_NUMBER: return [LEAD_STATUS.WRONG_NUMBER, LEAD_STATUS.CALL_BACK, LEAD_STATUS.INTEREST_CALL_BACK];
        case LEAD_STATUS.CALL_BACK: return [LEAD_STATUS.CALL_BACK, LEAD_STATUS.NO_ANSWER, LEAD_STATUS.QUATATION_SENT, LEAD_STATUS.INTEREST_CALL_BACK, LEAD_STATUS.NOT_INTRESTED, LEAD_STATUS.SOLD];
        case LEAD_STATUS.NO_ANSWER: return [LEAD_STATUS.CALL_BACK, , LEAD_STATUS.WRONG_NUMBER, LEAD_STATUS.NO_ANSWER, LEAD_STATUS.QUATATION_SENT, LEAD_STATUS.INTEREST_CALL_BACK, LEAD_STATUS.NOT_INTRESTED, LEAD_STATUS.SOLD];
        case LEAD_STATUS.INTEREST_CALL_BACK: return [LEAD_STATUS.INTEREST_CALL_BACK, LEAD_STATUS.QUATATION_SENT, LEAD_STATUS.NOT_INTRESTED, LEAD_STATUS.SOLD];
        case LEAD_STATUS.QUATATION_SENT: return [LEAD_STATUS.QUATATION_SENT, LEAD_STATUS.FOLLOW_UP, LEAD_STATUS.QUATATION_REJECTED, LEAD_STATUS.SOLD];
        case LEAD_STATUS.FOLLOW_UP: return [LEAD_STATUS.QUATATION_SENT, LEAD_STATUS.FOLLOW_UP, LEAD_STATUS.QUATATION_REJECTED, LEAD_STATUS.SOLD];


        default: return []
    }
}
export const allowedLeadStatusDropDownList = () => {


    return [

        {
            title: "Pending",
            defaultStatus: LEAD_STATUS.UNHANDLED,
            value: 1
        },
        {
            title: "In Process",
            defaultStatus: LEAD_STATUS.CALL_BACK,
            value: 2
        },
        {
            title: "Intrested",
            defaultStatus: LEAD_STATUS.INTEREST_CALL_BACK,
            value: 3
        },
        {
            title: "Sold",
            defaultStatus: LEAD_STATUS.SOLD,
            value: 4
        },
        {
            title: "Rejected",
            defaultStatus: LEAD_STATUS.NOT_INTRESTED,
            value: 5
        },


    ]
}
export const localStatecallModal = () => {
    return {
      set: (val) => localStorage.setItem('call_modal_state', JSON.stringify(val)),
      get: () => localStorage.getItem('call_modal_state') ? JSON.parse(localStorage.getItem('call_modal_state')) : null,
      remove: () => localStorage.removeItem('call_modal_state')
    }
  }
export const fetchAllowedLeadSubTab = (tab) => {

    switch (tab) {
        case 1: return [
            {
                title: "Unhandled",
                value: LEAD_STATUS.UNHANDLED
            },
            {
                title: "Incomplete",
                value: LEAD_STATUS.INCOMPLETE
            }


        ];

        case 2: return [
            {
                title: "Call Back",
                value: LEAD_STATUS.CALL_BACK
            },
            {
                title: "No Answers",
                value: LEAD_STATUS.NO_ANSWER
            }
        ];
        case 3: return [
            {
                title: "Intrested Call Back",
                value: LEAD_STATUS.INTEREST_CALL_BACK
            },
            {
                title: "Quatation Send",
                value: LEAD_STATUS.QUATATION_SENT
            },
            {
                title: "Quatation Follow Up",
                value: LEAD_STATUS.FOLLOW_UP
            }
        ];
        case 5: return [
            {
                title: "Wrong Number",
                value: LEAD_STATUS.WRONG_NUMBER
            },
            {
                title: "Quatation Rejected",
                value: LEAD_STATUS.QUATATION_REJECTED
            },

            {
                title: "Not Intrested",
                value: LEAD_STATUS.NOT_INTRESTED
            },
            {
                title: "Reject After Sold",
                value: LEAD_STATUS.PROJECT_REJECTED
            },

        ];

        default: return null
    }

}

export const leadStatusColor = (currentStatus) => {

    switch (parseInt(currentStatus)) {

        case LEAD_STATUS.QUATATION_SENT: return "warning";
        case LEAD_STATUS.INTEREST_CALL_BACK: return "warning";
        case LEAD_STATUS.SOLD: return "success";
        case LEAD_STATUS.NOT_INTRESTED: return "error";
        case LEAD_STATUS.QUATATION_REJECTED: return "error";
        case LEAD_STATUS.FOLLOW_UP: return "warning";
        default: return 'default'
    }
}
