import { useDispatch, useSelector } from "react-redux"
import { USER_ROLES } from "../../../utils/constants/constants"
import { IconButton, Tooltip } from "@mui/material"
import { ManageAccounts } from "@mui/icons-material"
import SubmitButton from "../../../components/button/SubmitButton"
import { useState } from "react"
import LeadReassignController from "./LeadReassignController"
import { openModal } from "../../../store/actions/modalAction"
import DetailedViewController from "../DetailedViewController"

const LeadReAssignButton = ({ id, fromTable, callBack=()=>{} }) => {

    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const [alloweReAssign, setAllowReAssign] = useState(false)

    const onOpenModalClick = () => {
        dispatch(openModal(<LeadReassignController id={id} callBack={callBack} />, "xs"))
    }

    if (fromTable) {
        if (user.data.role != USER_ROLES.sales)
            return <Tooltip title="Reassign Lead" >
                <IconButton onClick={onOpenModalClick} >
                <ManageAccounts color="error" />
            </IconButton>
            </Tooltip>
        return <></>
    }
    return <>
        {!alloweReAssign && <SubmitButton  disableElevation onClick={() => {
            setAllowReAssign(true)
        }} title={user.data.role == USER_ROLES.sales ? "Reassign to me" : "Reassign"} />}

        {alloweReAssign && <LeadReassignController



            assigned_to={user.data.role == USER_ROLES.sales ? user.data._id : null}
            noModal
            callBack={() => {
                if(user.data.role == USER_ROLES.sales)
                dispatch(openModal(<DetailedViewController id={id} />,"md"))
            }}
            id={id} />}
    </>

}
export default LeadReAssignButton