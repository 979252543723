export const MODAL_KEYS = {
    PROJECT: "PROJECT",
    MODULE_ACCESS: "MODULE_ACCESS",
    EMAIL:"EMAIL",
    SOURCES_CREATE:"SOURCES_CREATE",
    SOURCES_LIST:"SOURCES_LIST",
    PROJECT_FLAG_FILTERS:"PROJECT_FLAG_FILTERS",
    PROJECT_STOCK_FILTERS:"PROJECT_STOCK_FILTERS",
    CHAT_GROUP_DETAILS: "CHAT_GROUP_DETAILS",
    OPEN_SOLAR_DOCS: "OPEN_SOLAR_DOCS",
    SYSTEMS: "SYSTEMS",
    PROJECT_LOGS: "PROJECT_LOGS",
    LEAD: "LEAD",
    LEAD_DETAILS: "LEAD_DETAILS",
    CREATE_ORDER: "CREATE_ORDER",
    RECIEVE_ORDER: "RECIEVE_ORDER",
    LOAN_AND_BENEFITS: "LOAN_AND_BENEFITS",
    SALES_PERSON_CAPABILITY: "SALES_PERSON_CAPABILITY",
    SOURCE_CAPABILITY: "SOURCE_CAPABILITY",

    COMPLAIN_CATEGORY_CREATE: "COMPLAIN_CATEGORY_CREATE",
    COMPLAIN_CATEGORY_LIST: "COMPLAIN_CATEGORY_LIST",
    COMPLAINS: "COMPLAINS",
    COMPLAINS_NOTES_ADD: "COMPLAINS_NOTES_ADD",
    COMPLAINS_LIST: "COMPLAINS_LIST",
    CREATE_INVOICE: "CREATE_INVOICE",
    PAY_PENDING_INVOICE: "PAY_PENDING_INVOICE",
    INVOICE_DETAILS: "INVOICE_DETAILS",
    NOTES: "NOTES",
    ORDERS:"ORDERS",
    UPDATE_PROJECT_FLAGS:"UPDATE_PROJECT_FLAGS",
    UPDATE_PROJECT_STATUS:"UPDATE_PROJECT_STATUS",
    
    EXPENSE_CREATE:"EXPENSE_CREATE",
    EXPENSES_LIST:"EXPENSE_LIST",

    ACTIVITY_CREATE:"ACTIVITY_CREATE",
    ACTIVITY_LIST:"ACTIVITY_LIST",

    PRE_DEFINED_EXPENSE_CREATE:"PRE_DEFINED_EXPENSE_CREATE",
    PRE_DEFINED_EXPENSES_LIST:"PRE_DEFINED_EXPENSES_LIST",

    FINANCE_LIST:"FINANCE_LIST",
    PO_DOWNLOAD:"PO_DOWNLOAD",
    FB_INTEGRATION:"FB_INTEGRATION",
    INVOICE_DOWNLOAD:"INVOICE_DOWNLOAD"


}