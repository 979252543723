import { Box, CircularProgress, Grid, MenuItem, Paper, Typography, useTheme } from "@mui/material"
import { getAutoComplete, getLatAndLon } from "../../apis/googlemap.api"
import AsyncDropDown from "./AsyncDropDown"
import CustomInput from "./CustomInput"
import { memo, useCallback, useEffect, useState } from "react"
import { CenteredBox } from "../layouts/OneViewBox"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"

const AddressView = memo(({ title, value }) => {
    const theme = useTheme()
    return <CenteredBox elevation={0} component={Paper} sx={{ width: "100%", height: "100%", flexDirection: "column", border: "1px solid " + theme.palette.primary.main }} >
        <Typography variant="body1" align="center" fontWeight={600}  >{title}</Typography>
        <Typography textOverflow="ellipsis" sx={{width:"100%",wordBreak:"break-word"}} variant="body2" align="center" >{value ?? "NA"}</Typography>
    </CenteredBox>
})

const GoogleAddress = ({ onChange = () => { }, defaultAddress }) => {
    
    const dispatch = useDispatch()
    const theme = useTheme()
    const [predictedAddress, setPredictedAddress] = useState(null)
    const [address, setAddress] = useState(defaultAddress ?? null)
    const [loading, setLoading] = useState(false)

    const fetchLatAndLong = useCallback((placeId) => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getLatAndLon({ placeId }),
            (response) => {

                setAddress(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }, [])

    useEffect(() => {
        if (address) {
            onChange(address)
        } else {
            onChange({
                postCode: '',
                country: "",
                state: "",
                locality: "",
                address: "",
                lat:null,
                long:null
            })
        }
    }, [address])
    useEffect(() => {
        if (predictedAddress) {
            fetchLatAndLong(predictedAddress?.place_id)
        }
    }, [predictedAddress])

    return <Box elevation={0} component={Paper} sx={{ background: theme.palette.secondary.main, border: "1px solid " + theme.palette.primary.main }} p={2}>
        <AsyncDropDown
id={'google-address'}
            lazyFun={async (params) => {
                if (params.search.length > 3) {
                    return await getAutoComplete({ ...params })
                } else {

                    return {
                        status: 1,
                        data: {
                            result: []
                        }
                    }
                }

            }}

            OptionComponent={({ option, ...rest }) => {
                return <MenuItem {...rest}>{option.description}</MenuItem>
            }}
            onChange={async (changedVal) => {
                setPredictedAddress(changedVal)
            }}
            titleKey={'description'}
            valueKey={"place_id"}
            InputComponent={(params) => <CustomInput placeholder="Select Address" {...params} />}
        />
        <Box>
            {
                loading && <CenteredBox>
                    <CircularProgress size={20} />
                </CenteredBox>
            }
            {

                <Grid container spacing={2} >
                    <Grid item xs={6} >
                        <AddressView title="Street" value={address?.address} />
                    </Grid>
                    <Grid item xs={6} >
                        <AddressView title="Locality" value={address?.locality} />
                    </Grid>
                    
                    <Grid item xs={2.5} >
                        <AddressView title="Zip Code" value={address?.postCode} />
                    </Grid>
                    <Grid item xs={2.5} >
                        <AddressView title="State" value={address?.state} />
                    </Grid>
                    <Grid item xs={2} >
                        <AddressView title="Country" value={address?.country} />
                    </Grid>
                    <Grid item xs={2.5} >
                        <AddressView title="Lat" value={address?.lat} />
                    </Grid>
                    <Grid item xs={2.5} >
                    <AddressView title="Long" value={address?.lat} />
                    </Grid>
                </Grid>
            }
        </Box>


    </Box>
}
export default memo(GoogleAddress)