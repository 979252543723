const EVENT = {
  //Task Management
  TASK_MANAGEMENT_MAIN: 1,

  //Users
  CREATE_USERS: 2,

  //GENRAL_EVENT
  GENRAL_EVENT: 3,

  //GENRAL FEATURES
  // SEND_EMAIL: 16,
};
module.exports = EVENT;
