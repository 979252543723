import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants/constants"

import { verifyPendingPaymentsApi } from "../../apis/invoice.api"
import SubmitButton from "../../components/button/SubmitButton"
import { CheckCircle } from "@mui/icons-material"
import { useState } from 'react'
import { callSnackBar } from '../../store/actions/snackbarAction'
import { moduleAccessCondition } from '../../utils/helpers/helper'
import MODULES from '../../utils/constants/module.constants'

const VerifyPaymentButton = ({ callBack = () => { }, verified, invoice_id, payment_id }) => {
    const dispatch = useDispatch()

    const { user } = useSelector(state => state)
    const verifyApi = verifyPendingPaymentsApi


    const [loading, setLoading] = useState(false)



    const verifyPayment = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await verifyApi({ invoice_id, payment_id }),
            (response) => {

                setLoading(false)
                callBack()
                dispatch(callSnackBar('payment verified successfully', SNACK_BAR_VARIETNS.suceess))
            },
            (err) => {
                dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                setLoading(false)
            }
        ))
    }



    if (moduleAccessCondition(user, [MODULES.PAYMENT_VERIFICATION_MODULE])) {

        if (verified) {
            return <CheckCircle color="success" />
        }
        return (
            <>
                <SubmitButton disableElevation size="small" title="Verify" loading={loading} onClick={verifyPayment} />

            </>
        )
    }

    return <></>
}
export default VerifyPaymentButton