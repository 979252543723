import { Autocomplete, CircularProgress, MenuItem } from "@mui/material";

import { Fragment, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomInput from "../../components/inputs/CustomInput";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { MODULES_TYPES } from "../../utils/constants/constants";
import { StyledSearchBar } from "../../components/inputs/SearchBar";
import { findObjectKeyByValue, titleCase } from "../../utils/helpers/helper";

const CreateUi = ({
  title,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  return (
    <>
      <CustomDialog
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            <CustomInput
              autoFocus={true}
              disabled={loading}
              value={fields.name}
              onChange={(e) =>
                setFields({ ...fields, err: "", name: e.target.value })
              }
              type="text"
              label={"Name*"}
            />
            <CustomInput
              // autoFocus={true}
              disabled={loading}
              value={fields.code}
              onChange={(e) =>
                setFields({ ...fields, err: "", code: e.target.value })
              }
              type="text"
              label={"Code*"}
            />
            <CustomInput
              // autoFocus={true}
              disabled={loading}
              value={fields.watt}
              onChange={(e) =>
                setFields({ ...fields, err: "", watt: e.target.value })
              }
              type="text"
              label={"Watt*"}
            />

            <Autocomplete
              disableClearable
              value={findObjectKeyByValue(fields.type, MODULES_TYPES)}
              onChange={(e, newVal) => {
                setFields({ ...fields, type: newVal ? newVal._id : null });
              }}
              options={[
                ...Object.keys(MODULES_TYPES).map((key) => ({
                  label: titleCase(key),
                  _id: MODULES_TYPES[key],
                })),
              ]}
              sx={{
                width: "100%",
                display: "flex",
                "*": { display: "flex", justifyContent: "center" },
              }}
              renderInput={(params) => (
                <CustomInput
                  placeholder="Select Module Type*"
                  {...params}
                  label="Select Module Type*"
                />
              )}
            />

            <CustomInput
              // autoFocus={true}
              disabled={loading}
              value={fields.amount}
              onChange={(e) =>
                setFields({ ...fields, err: "", amount: e.target.value })
              }
              type="text"
              label={"Amount*"}
            />
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUi);
