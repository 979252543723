import { memo, useCallback, useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { Box, ButtonBase, CircularProgress, Divider, Grid, IconButton, Paper, Typography, styled } from "@mui/material"
import { CenteredBox } from "../../../components/layouts/common/boxes"
import FileInput, { FileContext } from "../../../components/inputs/FileInput"

import ImageComponent from "../../../components/ImageComponent"
import { AddPhotoAlternate, BrowserUpdated, Delete, Download, RemoveCircle, UploadFile } from "@mui/icons-material"
import { updateProjectDocumentsApi } from "../../../apis/projects.api"
import FileDownloadComponent from "../../../components/FileDownloadComponent"
import { FILE_TYPES, PROJECT_DOCUMENT_ACTION } from "../../../utils/constants/project.constant"
import { moduleAccessCondition } from "../../../utils/helpers/helper"
import MODULES from "../../../utils/constants/module.constants"



const ProjectDocumentRowContainer = styled(Box)(({ theme, hasFile }) => ({
    display: "flex",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid " + (hasFile ? theme.palette.success.main : theme.palette.primary.main),
    // 
    position: "relative",
    "::after": {

        content: "' '",
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: "0px",
        right: "0px",
        backgroundColor: (hasFile ? theme.palette.success.main : theme.palette.primary.main),
        opacity: .1,
        zIndex: 0
    },
    ".document-title": {

    }
}));
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const FileView = memo(({ id, dispatch, name, accept, type }) => {
    const apiDispatch = useDispatch()
    const { user } = useSelector(state => state)
    const [firstRendered, setFirstRendered] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const { files, loading, percentage, onChangeFile, onDeleteFile } = useContext(FileContext)

    const updateDetails = useCallback((id, files) => {

        apiDispatch(callApiAction(
            async () => await updateProjectDocumentsApi({ id, files }),
            (response) => {
                dispatch({ type: PROJECT_DOCUMENT_ACTION.SET_ITEM, id, files })
            },
            (err) => {

            }
        ))
    }, [])
    const deleteFile = useCallback(() => {
        setDeleteLoading(true)
        onDeleteFile(files?.[0], () => {

            setDeleteLoading(false)

        })
    }, [files, onDeleteFile, id])
    useEffect(() => {
        if (firstRendered) {
            updateDetails(id, files)
        } else {
            setFirstRendered(true)
        }
    }, [files?.[0]])

    if (files?.[0]) {
        return <Box display="flex" justifyContent="center" alignItems="center">


            {type == FILE_TYPES.IMAGES ?
                <ImageComponent viewOnClickOnly src={files[0]} sx={{ height: "25px", width: "25px", objectFit: "cover", borderRadius: "100%" }} ></ImageComponent> :
                <Box display="flex" >

                    <FileDownloadComponent color="primary" src={files[0]} fileNameDefault={name + "." + (files[0]?.split('.')[files[0]?.split('.')?.length - 1] ?? "pdf")} sx={{ padding: 0 }} />
                    <Box ml={1} />

                    <FileDownloadComponent view color="primary" src={files[0]} fileNameDefault={name + "." + (files[0]?.split('.')[files[0]?.split('.')?.length - 1] ?? "pdf")} sx={{ padding: 0 }} />
                </Box>
            }
            {moduleAccessCondition(user, [MODULES.UPLOAD_PROJECT_DOCUMENTS]) && <>
                {deleteLoading ? <CircularProgress size={25} /> : <IconButton sx={{ padding: 0, ml: 2 }} onClick={deleteFile} >
                    <RemoveCircle color="error" size={"small"} />

                </IconButton>}

            </>}

        </Box>
    }
    if (moduleAccessCondition(user, [MODULES.UPLOAD_PROJECT_DOCUMENTS]))
        return <>


            {
                loading ? <CircularProgress size={25} variant="determinate" value={percentage} />
                    : <Box>
                        <IconButton
                            component="label"
                            role={undefined}
                            tabIndex={-1} sx={{ padding: 1 }}>
                            <VisuallyHiddenInput onChange={onChangeFile} type="file" accept={accept} />



                            {type == FILE_TYPES.IMAGES ? <AddPhotoAlternate color="primary" fontSize="inherit" /> : <UploadFile color="primary" fontSize="inherit" />}

                        </IconButton>

                    </Box>
            }

        </>

    return <></>
})

const ProjectDocumentRow = ({ files, id, type, name, updatedAt, dispatch }) => {





    return <>

        <ProjectDocumentRowContainer hasFile={files?.length > 0} p={2} mb={1} >
            <Box display="flex" flex={1} zIndex={1} >
                <Typography className="document-title" variant="subtitle2" align="center" >
                    {name}
                </Typography>
            </Box>
            <Box zIndex={1}>
                <FileInput defaults={files} custom onlyImage={type == FILE_TYPES.IMAGES} accept={FILE_TYPES.PDFS ? ".pdf" : undefined} >

                    {type == FILE_TYPES.IMAGES ? <FileView
                        id={id}
                        dispatch={dispatch}
                        type={FILE_TYPES.IMAGES}
                        name={name}
                        accept={"image/*"}
                    /> :
                        <FileView
                            id={id}
                            dispatch={dispatch}
                            type={FILE_TYPES.PDFS}
                            accept={".pdf"}
                            name={name}
                        />
                    }
                </FileInput>

            </Box>


        </ProjectDocumentRowContainer>

    </>

}
export default memo(ProjectDocumentRow)