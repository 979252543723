import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { memo } from "react";

const DetailComponent = ({ value }) => {
  return (
    <Paper variant="outlined" component={Box} p={2}>
      <Box sx={{ width: "100%" }} my={2}>
        <Grid container spacing={1}>
          <Grid item xs={7}>
            <Typography variant="body1" fontWeight={400} ml={2}>
              {value ?? "NA"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const DetailsUI = memo(({ params }) => {
  return (
    <CustomDialog id="template-list" title="Template View">
      <Box sx={{ width: "100%" }}>
        <DetailComponent
          title="Template"
          value={
            <Box
              dangerouslySetInnerHTML={{
                __html: params?.template,
              }}
            ></Box>
          }
        />
      </Box>
    </CustomDialog>
  );
});

export default DetailsUI;
