import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { useMemo } from "react"

import moment from "moment"
import { getPendingPaymentsApi } from "../../apis/invoice.api"
import VerifyPaymentButton from "./VerifyPaymentButton"
import { Box, Button, Chip, IconButton } from "@mui/material"
import { findObjectKeyByValue } from "../../utils/helpers/helper"
import { INVOICES_NAME, INVOICE_TYPE, PAYMENT_OPTION } from "../../utils/constants/invoice.constant"
import RejectPaymentButton from "./RejectPaymentButton"
import { openModal } from "../../store/actions/modalAction"
import ProjectDetailsController from "../projects/projectdetails/ProjectDetailsController"
import { Visibility } from "@mui/icons-material"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { STOCK_TYPE } from "../../utils/constants/project.constant"
import { InvoiceViewButton } from "../invoice/ListController"

const ProjectViewButton = ({ params }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal(<ProjectDetailsController project={params.project?._id} />, 'xl', undefined, MODAL_KEYS.PROJECT))
    }
    if (params.project)
        return <Button size="small" variant="outlined" onClick={onClick} >
            {params?.project?.open_solar_project_id}
        </Button>
    return <></>
}
const ListController = ({ }) => {
    const dispatch = useDispatch()

    const { user } = useSelector(state => state)

    const title = "Pending Payment Verifications "
    const fetchApi = getPendingPaymentsApi

    const columns = useMemo(() => [
        {
            id: 5, fieldName: 'payments.payment_date', sort: true, label: 'Payment Date', align: "left", renderValue: (params) => moment(params?.payments?.payment_date).format("DD/MM/YYYY")
        },
        {
            id: 0, fieldName: 'customer_name', sort: true, label: 'Customer', align: "left"
        },
        {
            id: 98, fieldName: 'project', sort: true, label: 'Project', align: "left", renderValue: (params) => <ProjectViewButton params={params} />
        },
        {
            id: 89, fieldName: 'invoce_no', sort: true, minWidth: "100px", label: 'Invoice No', align: "left", renderValue: (params, setParamas) => <InvoiceViewButton
                params={params}
                withInvoiceNo
                setParamas={setParamas}
            >

            </InvoiceViewButton>
        },
        {
            id: 1, fieldName: 'payments.payment_by', sort: true, label: 'Payment Through', align: "left", renderValue: (params) => findObjectKeyByValue(params.payments?.payment_by, PAYMENT_OPTION)
        },
        {
            id: 7, fieldName: 'payments.transaction_id', sort: true, label: 'Transaction Id', align: "left", renderValue: (params) => params.payments?.transaction_id
        },
        {
            id: 2, fieldName: 'amount', sort: true, label: 'Invoice Amount', align: "left"
        },
        {
            id: 3, fieldName: 'paid_amount', sort: true, label: 'Already Paid Amount', align: "left"
        },
        {
            id: 4, fieldName: 'payments.amount', sort: true, label: 'To be Verified Amount', align: "left", renderValue: (params) => params?.payments?.amount ?? "NA"
        },
        {
            id: 10, fieldName: 'type', sort: true, label: 'Invoice  Type', align: "left", renderValue: (params) => <Chip size="small" label={ INVOICES_NAME[params.type]??findObjectKeyByValue(params.type, INVOICE_TYPE)} />
        },

        {
            id: 6, fieldName: 'action', minWidth: "300px", label: 'Verification Action', align: "left", renderValue: (params, setParamas) => <Box sx={{ display: "flex" }} >
                <VerifyPaymentButton
                    payment_id={params?.payments?._id}
                    invoice_id={params._id}
                    verified={params?.payments?.verified}
                    callBack={() => {
                        const payments = { ...params?.payments }
                        payments['verified'] = true
                        setParamas({ ...params, payments, paid_amount: params.paid_amount + params?.payments?.amount })
                    }}
                />
                <RejectPaymentButton
                    verified={params?.payments?.verified}
                    payment_id={params?.payments?._id}
                    invoice_id={params._id}

                    callBack={() => {


                        setParamas(null)
                    }}
                />
            </Box>
        }
    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        
        search: '',
        searchable: ['invoice_no', 'customer_name'],
        sort: 'payments.payment_date',
        sortDirection: -1,
        tab: 0

    })




    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})



    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }



    useEffect(() => {
        fetchList()
    }, [filters])




    return (
        <>
            <ListUi
                title={title}


                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController