import { Box, CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material"

import { Fragment, memo } from "react"
import { useDispatch, useSelector } from 'react-redux'



import CustomInput from "../../components/inputs/CustomInput"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { TASK_PRIORITY } from "../../utils/constants/task.constants"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getUserApi } from "../../apis/user.api"



const CreateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit }) => {

const {user} = useSelector(state=>state)

    return <>

        <CustomDialog
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    {<CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.name}
                        onChange={(e) => {
                            if (e.target.value && e.target.value.length <= 25)
                                setFields({ ...fields, err: '', name: e.target.value })
                            if (!e.target.value)
                                setFields({ ...fields, err: '', name: null })
                        }}
                        type="text"

                        helperText="name should be maximum 25 chahrachters long"
                        label={"Task Name*"}

                    />}

                    {<CustomInput
                        multiline
                        rows={4}
                        disabled={loading}
                        value={fields.description}
                        onChange={(e) => {

                            setFields({ ...fields, err: '', description: e.target.value })

                        }}
                        type="text"
                        label={"Task Description*"}

                    />}
                    <Box sx={{ display: "flex" }} >
                        <Box sx={{ display: "flex", flex: 1 }} >
                            <DesktopDatePicker
                                label="Start Date*"
                                inputFormat="DD/MM/YYYY"
                                value={moment(fields.start_date)}
                                renderInput={(props) => <CustomInput {...props} />}
                                onChange={async (changedVal) => { setFields({ ...fields, start_date: changedVal.toISOString() }) }}
                            />

                        </Box>
                        <Box sx={{ display: "flex", flex: 1 }} ml={3} >
                            <DesktopDatePicker
                                minDate={moment(fields.start_date)}
                                label="Due Date*"
                                inputFormat="DD/MM/YYYY"
                                value={moment(fields.due_date)}
                                renderInput={(props) => <CustomInput {...props} />}
                                onChange={async (changedVal) => { setFields({ ...fields, due_date: changedVal.toISOString() }) }}
                            />

                        </Box>


                    </Box>


                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Priority</FormLabel>
                        <RadioGroup
                            value={fields.priority}
                            onChange={(e) => {
                                setFields({ ...fields, priority: e.target.value })
                            }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel disabled={loading} value={TASK_PRIORITY.HIGH} control={<Radio />} label="High" />
                            <FormControlLabel disabled={loading} value={TASK_PRIORITY.MEDIUM} control={<Radio />} label="Medium" />
                            <FormControlLabel disabled={loading} value={TASK_PRIORITY.LOW} control={<Radio />} label="Low" />

                        </RadioGroup>
                    </FormControl>

                    <AsyncDropDown
                    id={'task-assignedto'}
                        InputComponent={(props) => <CustomInput placeholder="Assign User"  {...props} />}
                        lazyFun={getUserApi}
                        defaultOptions={[{name:"Assign to Self",_id:user.data._id}]}
                        OptionComponent={({ option, ...rest }) => {
                            return <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} {...rest}>
                                <Typography ml={3} variant="body1">{option.name}</Typography>
                            </Box>
                        }}
                        onChange={async (changedVal) => {
                            setFields({ ...fields, assigned_to: changedVal?._id })
                        }}
                        titleKey={'name'}
                        valueKey={"_id"}


                    />
                </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)