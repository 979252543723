import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal, openModal } from "../../store/actions/modalAction"
import { Box, CircularProgress, IconButton, Switch, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { Delete, Edit } from "@mui/icons-material"
import { findObjectKeyByValue, moduleAccessCondition } from "../../utils/helpers/helper"
import MODULES from "../../utils/constants/module.constants"
import { getModuleApi } from "../../apis/module.api"
import { MODULES_TYPES } from "../../utils/constants/constants"

const ActionComponent = memo(({ params, setParams, }) => {
    const dispatch = useDispatch()

    const { user } = useSelector(state => state)


    const onEdit = () => {
        dispatch(openModal(<CreateController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm"))
    }


    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        {moduleAccessCondition(user, [MODULES.UPDATE_TEAMS], []) && <IconButton size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
        </IconButton>}

    </Box>
})



const ListController = () => {
    const dispatch = useDispatch()



    const title = "Modules"
    const fetchApi = getModuleApi
    const deleteApi = null


    const columns = useMemo(() => [

        { id: 1, fieldName: 'name', label: 'Name', align: "left", sort: true },

        { id: 2, fieldName: 'code', label: 'Code', align: "left",  },

        { id: 3, fieldName: 'types', label: 'Type', align: "left",  renderValue: (params, setParams) => findObjectKeyByValue(params.types,MODULES_TYPES)},
        { id: 4, fieldName: 'walt', label: 'Watt', align: "left",  },
        { id: 5, fieldName: 'amount', label: 'Amount', align: "left", },

        {
            id: 6,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent params={params} setParams={setParams} />,
        },

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['name'],
        sort: 'name',
        sortDirection: 1,
        types:null
    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController callBack={async () => { fetchList() }} />, "sm"))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController