import {  CircularProgress } from "@mui/material"

import {  memo } from "react"
import {  useSelector } from 'react-redux'



import CustomInput from "../../../components/inputs/CustomInput"

import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import {  MobileDateTimePicker } from "@mui/x-date-pickers"




const LeadCallBackStatusUpdateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog

            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"
            confirmText={`Update`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>

                    {/* {<AsyncDropDown

                        value={fields.assigned_to}
                        lazyFun={getUserApi}
                        defaultOptions={[{ name: "Self", _id: null }]}
                        label="Assigned To"
                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest}>{option.name}</MenuItem>
                        }}
                        onChange={ (changedVal) => { setFields({ ...fields, assigned_to: changedVal ? changedVal._id : null }) }}
                        titleKey={'name'}
                        valueKey={"_id"}
                    />} */}
                    <MobileDateTimePicker
                        inputFormat="DD/MM/YYYY HH:mm"
                        renderInput={(props) => <CustomInput {...props} />}
                        label="Schedule Time"
                        value={fields.scheduled_date}
                        onChange={(changedVal) => { setFields({ ...fields, scheduled_date: changedVal }) }}
                    />
                    {<CustomInput
                        disabled={loading}
                        value={fields.remarks}
                        onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                        multiline
                        rows={3}
                        type="textarea"
                        label={"Remarks"}

                    />}

                </>}

        </CustomDialog>
    </>
}
export default memo(LeadCallBackStatusUpdateUi)