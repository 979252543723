import { memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { io } from "socket.io-client"
import endpoints from "../../apis/endpoints"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants"
import { refreshToken } from "../../utils/helpers/helper"
import { connectSocket, createChatGroupAction, disConnectSocket, onAddedToGroup, onGroupMemberReomved, onMessageDeletedAction, onMessageRecieveAction, resetSocketAndChat } from "../../store/actions/chatAction"
import { Alert, Box, LinearProgress, Typography } from "@mui/material"
import { CLIENT_EVENTS, SERVER_EVENTS } from "../../utils/constants/chat.constant"

const ChatModuleWrapper = (props) => {
    const { user, chat } = useSelector((state) => state)
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    useEffect(() => {
        const socket = io(endpoints.root, {
            autoConnect: false,
            auth: (cb) => {
                cb({
                    token: refreshToken.get()
                });
            }
        })
        if (user.isLoggedIn) {
            setLoading(true)
            socket.connect()
            if (socket.connected) {

            }

            socket.on("connect", () => {

                
                dispatch(connectSocket(socket, () => { setLoading(false) }))

            })

            socket.on(CLIENT_EVENTS.CREATED_GROUP, (data) => {

                if (data?.created_by != user.data._id) {
                    socket.emit(SERVER_EVENTS.CONNECT_GROUP, { group_id: data._id }, () => { })
                    dispatch(createChatGroupAction(data))
                }

            })

            socket.on(CLIENT_EVENTS.MEMBER_REMOVED, (data) => {


                socket.emit(SERVER_EVENTS.LEFT_GROUP, { groupId: data.groupId })
                dispatch(onGroupMemberReomved(data?.groupId, data?.memberId))


            })
            socket.on(CLIENT_EVENTS.ADDED_IN_GROUP, (data) => {


                socket.emit(SERVER_EVENTS.CONNECT_GROUP, { group_id: data._id }, () => { })
                dispatch(onAddedToGroup(data))


            })
            socket.on(CLIENT_EVENTS.MESSAGE, (data) => {

                if (data?.user_id != user.data._id) {

                    dispatch(onMessageRecieveAction(data.group, data))
                }

            })

            socket.on(CLIENT_EVENTS.DELETE_MESSAGE, (data) => {

                
                // if (data?.user_id != user.data._id) {

                dispatch(onMessageDeletedAction(data?.groupId, data?.messageId))
                // }

            })

            socket.on("connect_error", (err) => {
                setLoading(false)
                if (err.message != 'xhr poll error')
                    dispatch(callSnackBar(err?.data?.message ?? err.message, SNACK_BAR_VARIETNS.error))
                

            });
            socket.on("disconnect", () => {
                dispatch(disConnectSocket())

                dispatch(callSnackBar("No internent", SNACK_BAR_VARIETNS.error))
            })
        } else {
            dispatch(disConnectSocket())
            socket.disconnect()
        }



        return () => {
            socket.disconnect();
            dispatch(resetSocketAndChat());
        }
    }, [user.isLoggedIn, dispatch])

    return <>
        {
            user.isLoggedIn &&
            <>
                <Box sx={{ position: "fixed", left: "0px", bottom: "0px", width: "100%", zIndex: 111 }}>
                    {!loading && <Box sx={{ background: chat.connected ? "green" : "red", maxWidth: "50px" }} pl={2} pr={2} >
                        <Typography color="light.main" variant="caption">
                            {
                                chat.connected ? "Online" : "Offline"
                            }
                        </Typography>
                    </Box>}

                    {
                        !loading && !chat.connected && <Alert sx={{ width: "100%" }} severity="error" >No internent connection</Alert>
                    }
                    {
                        loading && <Box sx={{ background: 'gray', maxWidth: "100px" }} p={2}>
                            <LinearProgress />
                        </Box>
                    }
                </Box>

            </>
        }
        {props.children}

    </>
}
export default memo(ChatModuleWrapper)