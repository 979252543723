import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { deleteLeadApi, getLeadApi, getLeadAssignerDetailsApi } from "../../../apis/lead.api"

import { Avatar, Badge, Box, ButtonBase, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material"
import { useMemo } from "react"




import moment from "moment"



import PersonIcon from '@mui/icons-material/Person';
import fileDownload from "js-file-download"




const ListController = () => {
    const dispatch = useDispatch()



    const title = "Find Assigned Person"
    const fetchApi = getLeadAssignerDetailsApi
    const deleteApi = deleteLeadApi

    const columns = useMemo(() => [
        
        { id: 1, fieldName: 'customer_name', label: 'Customer Name', align: "left" ,renderValue:(params)=>params.customer_name+ " "+ params.customer_family_name},

        {
            id: 3, fieldName: 'customer_phone', minWidth: "200px", label: 'Customer Phone No.', align: "left"
        },

        {
            id: 5, fieldName: 'assigned_to', minWidth: 150, label: 'Assigned To', align: "left", renderValue: (params) => params.assigned_to && params.assigned_to.length > 0 ?
            params.assigned_to[0].name
                : "NA",
        },


    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 3,
        search: '',
        searchable: ['customer_name','customer_family_name', 'customer_email', 'customer_phone','customer_address','customer_locality'],
        source: '',
        tab: null,
        status: null,
        assigned_to: null,
        startDate: null, endDate: null,
        sort: 'createdAt',
        rangeSelector: "",
        sortDirection: -1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [exportLoading, setexportLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {

        if (filters.search != '') {
            setLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters }),
                (response) => {
                    setList(response)
                    setLoading(false)
                },
                (err) => {
                    setLoading(false)
                }
            ))
        }

    }




    const onExportBtnClick = () => { fetchList(true) }

    useEffect(() => {
        fetchList()
    }, [filters])





    return (
        <>
            <ListUi
                title={title}

                onExportBtnClick={onExportBtnClick}
                exportLoading={exportLoading}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController