import { Box, Grid, MenuItem, Paper, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material"
import { toTitleCase } from "../../../utils/helpers/helper"
import { USER_ROLES } from "../../../utils/constants/constants"
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop"
import { Pie } from "react-chartjs-2"
import { Chart, ArcElement } from 'chart.js'
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector"
import { useSelector } from "react-redux"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import { StyledSearchBar } from "../../../components/inputs/SearchBar"
import { getUserApi } from "../../../apis/user.api"
import getTeamApi from "../../../apis/team.api"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import SalesPersonFilterDropDown from "../../../components/filterdropdowns/SalesPersonFilter"
import TeamFilterDropDown from "../../../components/filterdropdowns/TeamFilterDropDown"

Chart.register(ArcElement);
const LeadAnalyticsBySourceUI = ({ loading, list, filters, setFilters }) => {
    const { user } = useSelector(state => state)
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"))
    return (
        <>
            <Box display="flex" flexWrap="wrap" sx={{ height: "100%" }} >
                <Paper component={Box} sx={{ display: "flex", flex: 1 }}  >
                    <Box p={3} sx={{ width: "100%" }}>
                        <Box sx={{ display: "flex", alignItems: 'center', flexWrap: "wrap" }} mb={2}>
                            <Box sx={{ display: "flex", flex: 1 }}>
                                <Typography variant="h6" fontWeight={"bold"} >Lead Sources</Typography>
                            </Box>

                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <Grid container spacing={2}>

                                {<Grid item xs={6} >



                                    <TeamFilterDropDown
                                        id={'team-list-lead-analytics'}
                                        filters={filters}
                                        setFilters={setFilters}
                                    />


                                </Grid>}
                                {
                                    <Grid item xs={6} >
                                        <SalesPersonFilterDropDown
                                            id={'sales-lead-analytics-source'}
                                            filters={filters}
                                            setFilters={setFilters}
                                            key={filters.team}
                                        />

                                    </Grid>}
                                <Grid item xs={12} >
                                    <TimeRangeSelector dateType="created_at" defaultVal={{ label: filters.label, _id: filters._id }} onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box width="100%" >
                            <CenteredBox flex="none" sx={{ flex: 0, height: "100%" }} >
                                <Box sx={{ width: "400px", maxWidth: "100%" }}>
                                    {
                                        loading && <Skeleton width={"100%"} variant="circular" height={isSmallScreen ? "250px" : "400px"} />
                                    }
                                    {
                                        !loading && list && list.result && list.result.length > 0 &&

                                        <Pie
                                            options={{
                                                width: isSmallScreen ? "250px" : "400px",
                                                height: isSmallScreen ? "250px" : "400px",
                                            }}


                                            data={{
                                                labels: list.result.map((item) => toTitleCase(item.source)),
                                                datasets: [
                                                    {

                                                        // label: '# of Votes',
                                                        data: list.result.map((item) => (item.count)),
                                                        backgroundColor: ["#003f5c", "#58508d", "#bc5090"]
                                                    }
                                                ]
                                            }}
                                        >

                                        </Pie>

                                    }

                                </Box>
                            </CenteredBox>
                        </Box>
                    </Box>

                </Paper>
                <Paper component={Box} sx={{ display: "flex", flex: 1, minWidth: "300px" }}  >

                    <Box p={3} sx={{ width: "100%" }}>
                        <Box sx={{ display: "flex", alignItems: 'center', flexWrap: "wrap" }} mb={4}>
                            <Box sx={{ display: "flex", flex: 1 }}>
                                <Typography variant="h6" fontWeight={"bold"} >Counts</Typography>
                            </Box>
                        </Box>
                        {/* {
                                    toTitleCase(item.source)
                                } */}
                        <Grid container spacing={3}>
                            {
                                !loading && list && list.result && list.result.length > 0 && list.result.sort((a, b) => b.count - a.count).map((item) =>


                                    <Grid key={item.source} item xs={6} md={4}>
                                        <Paper variant="outlined" sx={{ height: "100%" }}>
                                            <Box p={2} sx={{ height: "100%" }} display="flex" justifyContent="space-between" flexDirection="column" >
                                                <Typography mb={2} align="center" textTransform={"capitalize"} variant="body1" fontWeight={"bold"}>{toTitleCase(item.source)}</Typography>

                                                <Typography mt={2} align="center" textTransform={"capitalize"} variant="h5" color="gray" fontWeight={"bold"}>{item.count} </Typography>
                                            </Box>
                                        </Paper>
                                    </Grid>

                                )
                            }
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </>
    )
}
export default LeadAnalyticsBySourceUI