import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";



export const getLeadApi = async params => {
  const callResponse = await axios({
    url: endpoints.leadBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getUnhandledLeadApi = async params => {
  const callResponse = await axios({
    url: endpoints.leadUnhandled,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getLeadAssignerDetailsApi = async params => {
  const callResponse = await axios({
    url: endpoints.leadAssignerCheck,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getLeadCallsApi = async params => {
  const callResponse = await axios({
    url: endpoints.leadCallBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getLeadAnalytisByStatusApi = async params => {
  const callResponse = await axios({
    url: endpoints.leadAnalysisByStatus,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getLeadAnalytisBySourceApi = async params => {
  const callResponse = await axios({
    url: endpoints.leadAnalysisBySource,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getLeadAnalytisBySourceAndSalesPersonApi = async params => {
  const callResponse = await axios({
    url: endpoints.leadAnalysisBySourceAndSalesPerson,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getLeadAnalytisByStatusAndSalesPersonApi = async params => {
  const callResponse = await axios({
    url: endpoints.leadAnalysisByStatusesAndSalesPerson,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getLeadAnalytisByStatusAndSourceApi = async params => {
  const callResponse = await axios({
    url: endpoints.leadAnalysisByStatusesAndSource,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};




export const getLeadByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.leadById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getLeadAnalysisApi = async params => {
  const callResponse = await axios({
    url: endpoints.leadAnalysis,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addLeadApi = async data => {
  const callResponse = await axios({
    url: endpoints.leadBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const addLeadInOpenSolarApi = async data => {
  const callResponse = await axios({
    url: endpoints.leadCreateInOpenSolar,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};




export const updateLeadField = async data => {
  const callResponse = await axios({
    url: endpoints.leadBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const updateLeadLastUpdatedDateApi = async data => {
  const callResponse = await axios({
    url: endpoints.leadUpdateDate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const updateLeadData = async data => {
  const callResponse = await axios({
    url: endpoints.leadBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateLeadCallApi = async data => {
  const callResponse = await axios({
    url: endpoints.leadCallBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const createLeadCallApi = async data => {
  const callResponse = await axios({
    url: endpoints.leadCallBase,
    method: "POST",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const reAssignLeadCallApi = async data => {
  const callResponse = await axios({
    url: endpoints.leadReassign,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateLeadStatusApi = async data => {
  const callResponse = await axios({
    url: endpoints.leadStatusUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const deleteLeadApi = async data => {
  const callResponse = await axios({
    url: endpoints.leadBase,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};




