import { memo, useEffect, useState } from "react"
import moment from "moment"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { addLeadInOpenSolarApi, deleteLeadApi,  getUnhandledLeadApi } from "../../../apis/lead.api"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import { Badge, Box, ButtonBase, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material"
import { useMemo } from "react"

import CreateController from "../CreateController"

import { Add, Delete, Edit, Info,  Warning, WbTwilight } from "@mui/icons-material"
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import MessageDilog from "../../../components/MessageDilog"
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../../utils/constants/constants"
import { LEAD_REPEAT_PARAMETERS, LEAD_STATUS, REPEAT_LEAD_TYPE } from "../../../utils/constants/lead.constant"

import DetailedViewController from "../DetailedViewController"
import LeadStatusComponent from "../LeadStatusComponent"
import EditAddressController from "../EditAddressController"

import fileDownload from "js-file-download"
import LeadReAssignButton from "../reassign/LeadReAssignButton"
import { createAddress } from "../../../utils/helpers/helper"
import { callSnackBar } from "../../../store/actions/snackbarAction"



const ActionComponent = memo(({ params, setParams, deleteApi }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state)
    const [loading, setLoading] = useState(false)

    const onEdit = () => {
        dispatch(openModal(<CreateController id={params._id} callBack={(updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "md"))
    }

    const onViewMore = () => {
        dispatch(openModal(<DetailedViewController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "md"))
    }

    const deleteFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteApi({ id: params._id }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())


    }
    const onDelete = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to delete lead of "${params.customer_name}" ?`} />, "sm"))
    }


    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <Tooltip title="View More">
            <IconButton disabled={loading} size="inherit" onClick={onViewMore}>
                <Info color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>

        {params.status != LEAD_STATUS.SOLD && params.status != LEAD_STATUS.NOT_INTRESTED && <Tooltip title="Edit Info">
            <IconButton disabled={loading} size="inherit" onClick={onEdit}>
                <Edit color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>}

        {
            <LeadReAssignButton fromTable id={params._id} callBack={(response) => {
                
                setParams({ ...params,...response })
            }} />
        }
        {params.open_solar_id && <Tooltip title="Open in Open solar">
            <IconButton variant="contained" disableElevation disabled={loading} size="inherit" href={`https://app.opensolar.com/#/projects/${params.open_solar_id}/info`} target="_blank">
                <WbTwilight color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>}

        {loading && <CircularProgress color="primary" fontSize="inherit" />}

        {!loading && (user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.partner_admin || user.data.role == USER_ROLES.sub_admin) && <IconButton disabled={loading} size="inherit" onClick={onDelete}>
            <Delete color="error" fontSize="inherit" />
        </IconButton>}




    </Box>
})

const EditComponent = memo(({ params, setParams }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const onEdit = () => {
        dispatch(openModal(<EditAddressController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} addressInitial={params.customer_address} />, "md"))
    }

    return <>
        {params.status == LEAD_STATUS.INCOMPLETE &&
            <Box>
                <Tooltip title="Edit Address">
                    <IconButton color="error"
                        size="small"
                        variant="contained"
                        onClick={onEdit}
                        disableElevation>
                        <EditLocationAltIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        }
        {/* <Box></Box> */}
        <Badge
            color="error"
            badgeContent={

                (params.lead_repeat_paramaters && params.lead_repeat_paramaters?.includes(LEAD_REPEAT_PARAMETERS.ADDRESS)) ? " " : 0
            }
            variant="dot"
        >
            <Box display="inline" dangerouslySetInnerHTML={{ __html: createAddress(params) ?? "NA" }}></Box>
        </Badge>

    </>
})
export const OpenSolarButton = memo(({ setParams, params }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const onOpenSolarProjectCreates = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => addLeadInOpenSolarApi({ id: params._id }),
            (response) => {
                setParams({ ...params, open_solar_id: response?.open_solar_id })
                dispatch(callSnackBar("Project is created in open solar", SNACK_BAR_VARIETNS.suceess))
                setLoading(false)
            },
            (err) => {
                setLoading(false)
                dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
            }
        ))
    }
    if (!(params.customer_phone && params.customer_email && params.customer_address && params.customer_phone != '' && params.customer_email != '' && params.customer_address != ''))
        return <>
            <Tooltip title="Please add all details to create project in open-solar" >
                <Warning color="warning" />
            </Tooltip>
        </>
    if (loading) {
        return <CircularProgress />
    }
    if (params.open_solar_id && params.open_solar_id != '') {
        return <Tooltip title="Open in Open solar">
            <IconButton variant="contained" disableElevation disabled={loading} size="inherit" href={`https://app.opensolar.com/#/projects/${params.open_solar_id}/info`} target="_blank">
                <WbTwilight color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>
    }
    return <Tooltip title="create lead in open solar" >
        <IconButton onClick={onOpenSolarProjectCreates} >
            <Add />
        </IconButton>
    </Tooltip>
})


const ListController = () => {
    const dispatch = useDispatch()


    const onViewMore = (params, setParams) => {
        dispatch(openModal(<DetailedViewController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "md"))
    }
    const title = "Unhandled Lead Management"
    const fetchApi = getUnhandledLeadApi
    const deleteApi = deleteLeadApi

    const columns = useMemo(() => [
        { id: 0, minWidth: 100, fieldName: 'createdAt', minWidth: '110px', label: 'Created At ', align: "left", sort: true, renderValue: (params, setParams) => params.createdAt && params.createdAt != '' ? moment(params.createdAt).format("DD/MM/YYYY") : "NA" },
        { id: 10, minWidth: 100, fieldName: 'datedifference', minWidth: '115px', label: 'Last update', align: "left", sort: true, renderValue: (params, setParams) => params.datedifference + " day ago" },
        { id: 1, minWidth: 150, fieldName: 'name', label: 'Name', align: "left", renderValue: (params, setParams) => <ButtonBase sx={{ textAlign: "left", alignItems: "flex-start" }} onClick={() => onViewMore(params, setParams)}>{params.customer_name + " " + (params.customer_family_name ?? "")}</ButtonBase > },


        {
            id: 5, fieldName: 'assigned_to', minWidth: 150, label: 'Assigned To', align: "left", renderValue: (params) => params.assigned_to && params.assigned_to.length > 0 ?
                <Box >

                    <Typography textTransform="capitalize" variant="td">
                        {params.assigned_to[0].name}
                    </Typography>

                </Box> : "NA",
        },

        {
            id: 6,
            fieldName: 'source',
            label: 'Source',
            minWidth: 50,
            align: "left",
            sort: true,
            renderValue: (params) => params.source ? params.source.name : "NA",
        },
        {
            id: 4, fieldName: 'customer_address', minWidth: 400, label: 'Address', align: "left",
            renderValue: (params, setParams) => <>

                <EditComponent params={params} setParams={setParams} />
            </>
        },
        {
            id: 2, fieldName: 'email', label: 'Email', align: "left",
            minWidth: "250px",
            renderValue: (params, setParams) => <Badge color="error" badgeContent={params.lead_repeat_paramaters == REPEAT_LEAD_TYPE.EMAIL ? " " : 0} variant="dot">
                {params.customer_email}
            </Badge>
        },
        {
            id: 3, fieldName: 'phone', minWidth: "100px", label: 'Phone no.', align: "left"
        },
        {
            id: 7,
            fieldName: 'status',
            label: 'Status',
            minWidth: 200,
            sort: true,
            align: "left",
            renderValue: (params, setParams) => <LeadStatusComponent onlyview params={params} setParams={setParams} />,
        },
        // {
        //     id: 8,
        //     fieldName: 'action',
        //     label: 'Project ',
        //     align: "left",
        //     renderValue: (params, setParams) => <ProjectButton params={params} setParams={setParams} />,
        // },
        {
            id: 9,
            minWidth: "250px",
            fieldName: 'action',
            label: 'Actions',
            align: "left",
            renderValue: (params, setParams) => <ActionComponent deleteApi={deleteApi} params={params} setParams={setParams} />,
        },


    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['customer_name', 'customer_family_name', 'customer_email', 'customer_phone', 'customer_address'],
        source: '',
        tab: null,
        status: null,
        assigned_to: null,
        startDate: null, endDate: null,
        sort: 'createdAt',
        rangeSelector: "",
        sortDirection: -1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [exportLoading, setexportLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = (isExport) => {
        if (!isExport) {
            setLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters }),
                (response) => {
                    setList(response)
                    setLoading(false)
                },
                (err) => {
                    setLoading(false)
                }
            ))
        } else {
            setexportLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters, csv: true }),
                (response) => {
                    fileDownload(response, "lead.csv")
                    setexportLoading(false)
                },
                (err) => {
                     
                    setexportLoading(false)
                },
                true
            ))
        }
    }

    const onCreateBtnClick = (e, alreadyInOpenSolar) => {
        dispatch(openModal(<CreateController alreadyInOpenSolar={alreadyInOpenSolar} callBack={async () => { fetchList() }} />, "sm"))
    }


    const onExportBtnClick = () => { fetchList(true) }

    useEffect(() => {
        fetchList()
    }, [filters])




    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}
                onExportBtnClick={onExportBtnClick}
                exportLoading={exportLoading}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController