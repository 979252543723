import { Badge, Grid, Typography, useTheme } from "@mui/material"
import { Box } from "@mui/material"
import { memo } from "react"
import LeadStatusComponent from "./LeadStatusComponent"

import LeadReAssignButton from "./reassign/LeadReAssignButton"
import SubmitButton from "../../components/button/SubmitButton"
import moment from "moment"
import { createAddress } from "../../utils/helpers/helper"

const LeadViewBox = memo(({ id, address, currentAddress, email, currentEmail, phone, currentPhone, assignedPerson, name, status, submitLoading, createFunction,createdAt,isUpdate }) => {
    const theme = useTheme()
    return <Box sx={{ background: theme.palette.secondary.main, border: "1px solid " + theme.palette.primary.main }} p={2} >
        <Grid container spacing={0} >

        <Grid item xs={4} ><Typography variant="caption" fontWeight="bold" > Date:</Typography></Grid>
            <Grid item xs={8} ><Typography variant="caption" textTransform="capitalize" >


         {moment(createdAt).format("DD/MM/YYYY")}


            </Typography></Grid>

            <Grid item xs={4} ><Typography variant="caption" fontWeight="bold" >Name:</Typography></Grid>
            <Grid item xs={8} ><Typography variant="caption" >{name}</Typography></Grid>


            <Grid item xs={4} >

                <Badge color="error" variant="dot" badgeContent={email == currentEmail && currentEmail != '' ? "" : 0}  >
                    <Typography variant="caption" fontWeight="bold" >Email:</Typography>
                </Badge>
            </Grid>
            <Grid item xs={8} >

                <Typography variant="caption" >{email}</Typography>

            </Grid>


            <Grid item xs={4} >

                <Badge color="error" variant="dot" badgeContent={phone == currentPhone && currentPhone != '' ? "" : 0}  >
                    <Typography variant="caption" fontWeight="bold" >Phone:</Typography>
                </Badge>
            </Grid>
            <Grid item xs={8} >

                <Typography variant="caption" >{phone}</Typography>

            </Grid>


            <Grid item xs={4} >
                <Badge color="error" variant="dot" badgeContent={address == currentAddress ? "" : 0}  >
                    <Typography variant="caption" fontWeight="bold" >Full Address:</Typography>

                </Badge>
            </Grid>
            <Grid item xs={8} ><Typography variant="caption">


                {address}

            </Typography></Grid>


            <Grid item xs={4} ><Typography variant="caption" fontWeight="bold" >Assigned Person:</Typography></Grid>
            <Grid item xs={8} ><Typography variant="caption" textTransform="capitalize" >


                {assignedPerson}


            </Typography></Grid>
            <Grid item xs={4} ><Typography variant="caption" fontWeight="bold" >Status:</Typography></Grid>
            <Grid item xs={8} ><Typography variant="caption" textTransform="capitalize" >


                <LeadStatusComponent onlyview params={{ status }} />


            </Typography></Grid>


        </Grid>
        <Box sx={{ display: "flex" }} mt={2} >
            <Box sx={{ display: "flex", flex: 1 }} >

               {!(email == currentEmail && currentEmail != '') && <SubmitButton title={isUpdate?"Update Forcefully" :"Create Forcefully" }loading={submitLoading} disableElevation onClick={() => {
                    //tru passed here to denote it is forcefully created
                    createFunction(true)
                }} />}
                
            </Box>
            <Box sx={{ display: "flex", flex: 1 }} ml={3} ><LeadReAssignButton id={id} /></Box>
        </Box>
    </Box>
})


const AlreadExistLeadView = ({ currentData, alreadyExistData, createFunction, submitLoading,isUpdate }) => {

    
    if (alreadyExistData && Array.isArray(alreadyExistData) && alreadyExistData.length > 0)
        return <>

            {
                alreadyExistData.map((item) => {
                    return <LeadViewBox
                        id={item._id}
                        key={item._id}
                        createdAt={item.createdAt}
                        isUpdate={isUpdate}
                        currentAddress={[currentData.customer_address, currentData.customer_locality, currentData.customer_locality, currentData.customer_locality, currentData.customer_state, currentData.customer_country].join(',')}
                        address={createAddress(item)}

                        email={item.customer_email}
                        currentEmail={currentData.customer_email}

                        phone={item.customer_phone}
                        currentPhone={currentData.customer_phone}


                        name={[item.customer_name, item.customer_surname].join(" ")}
                        assignedPerson={item?.assigned_to?.[0]?.name}
                        status={item.status}


                        createFunction={createFunction}
                        submitLoading={submitLoading}
                    />
                })
            }

        </>
    return <>


    </>
}
export default memo(AlreadExistLeadView)