import { Box, Button, ButtonBase, Typography, styled, useTheme } from "@mui/material"
import { CALENDAR_ITEMS_TYPES, LOG_TYPE } from "../../../utils/constants/constants"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import { calculateWorkingHours, findObjectKeyByValue, viewLimitedLengthName } from "../../../utils/helpers/helper"

import moment from "moment"
import { PROJECT_FLAGS } from "../../../utils/constants/project.constant"
import { getStatusOfProjectFlags, useProjectStatusColor } from "../../../utils/helpers/project.helper"
import ProjectDetailsController from "../projectdetails/ProjectDetailsController"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import { useDrop } from "react-dnd"
import { INVOICE_STATUS } from "../../../utils/constants/invoice.constant"



export const CalendarItem = styled(ButtonBase)(({ theme, color, completed, hasInvoice, isPaid }) => (
    {
        width: "100%",
        boxShadow: 0,
        overflow: "hidden",
        borderRadius: 0,
        justifyContent: "flex-start",
        marginTop: 0,
        margin: 0,
        marginBottom: 1,
        padding: 0,
        paddingLeft: theme.spacing(1),
        borderRadius: "2px",
        background: "#fff",
        color: color ?? (completed ? theme.palette.success.main : theme.palette.primary.main),
        border: "1px solid " + (color ?? (completed ? theme.palette.success.main : theme.palette.primary.main)),
        borderLeft: "10px solid " + (color ?? (completed ? theme.palette.success.main : theme.palette.primary.main)),
        position: "relative",
        "::before": {
            borderRadius: 0,
            content: "' '",
            position: "absolute",
            opacity: "0.05",
            top: "0px",
            left: "0px",
            zIndex: 0,
            background: color ?? (completed ? theme.palette.success.main : theme.palette.primary.main),
            height: "100%",
            width: "100%"
        },
        "::after": {
            borderRadius: 0,
            content: isPaid ? "'✓'" : "' '",
            fontSize: "8px",
            color: "white",
            position: "absolute",
            opacity: hasInvoice ? 1 : 0,
            height: "10px",
            width: "10px",
            right: "5px",
            zIndex: 0,
            background: isPaid ? theme.palette.success.main : "gray",

        }
    }
))

const TaskPriority = styled(Box)(({ theme, color }) => (
    {
        width: "10px",
        backgroundColor: color,
        height: "100%",
        zIndex: 1,
        position: "absolute"


    }
))


const CalendarInnerBox = ({ data, weeklyOf, weekDay, dateObj, date }) => {

    const dispatch = useDispatch()
    const onClick = (id) => {
        dispatch(openModal(<ProjectDetailsController project={id} />, "xl", undefined, MODAL_KEYS.PROJECT))
    }
    const theme = useTheme()
    const holidays = (data && Array.isArray(data) && data.filter(item => item.type == CALENDAR_ITEMS_TYPES.HOLIDAYS)) ?? []
    const projectStatusColor = useProjectStatusColor()


    const [{ isOver, canDrop }, drop] = useDrop({
        accept: "installation_calendar",
        drop: () => ({ date: moment(dateObj).set('date', date).toISOString() }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
        // Override monitor.canDrop() function
        canDrop: (item) => {

            return (
                true
            );
        }
    });

    const getBoxShadow = () => {
        if (isOver) {
            if (canDrop) {
                return theme.palette.primary.light;
            } else if (!canDrop) {
                return theme.palette.primary.light;
            }
        } else {
            return "";
        }
    };

    return <>

        <Box ref={drop} sx={{ display: "flex", height: "100%", flexDirection: "column", justifyContent: "flex-end", width: "100%", background: getBoxShadow() }} >



            <Box p={1} sx={{ width: "100%", display: "flex", flexDirection: "column", }} >
                {
                    data && data.filter(item => item.type == CALENDAR_ITEMS_TYPES.PROJECT).map((item) => <CalendarItem
                        key={item.title}
                        hasInvoice={item?.invoice}
                        isPaid={item?.invoice?.status === INVOICE_STATUS.PAID}
                        color={projectStatusColor(item.status).bgColor}
                        onClick={() => { onClick(item.relatedId) }}
                        completed={item?.flags?.[PROJECT_FLAGS.INSTALLATION] == getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).COMPLETED}
                    >

                        <Typography variant="caption" lineHeight="120%" >{viewLimitedLengthName(item?.title, 15)}</Typography>
                    </CalendarItem>)
                }
            </Box>

            <Box sx={{ width: "100%" }} >
                {
                    holidays && holidays.map((item) => <CalendarItem key={item.title} color={(theme.palette.dark.main)} >
                        <Typography variant="caption" >{item?.title}</Typography>
                    </CalendarItem>)
                }
            </Box>

        </Box>

    </>
}
export default CalendarInnerBox