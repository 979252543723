
import ProjectFilesUi from "../projectdetails/ProjectFilesUi"

import { useEffect, useState } from "react"

import { callApiAction } from "../../../store/actions/commonAction"
import { getProjectsFilesByIdApi } from "../../../apis/projects.api"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import { useDispatch } from "react-redux"

const OpenSolarFiles = ({ openSolarFiles, setOpenSolarFiles, projectId }) => {
  const dispatch  = useDispatch()
  const modalKey = MODAL_KEYS.OPEN_SOLAR_DOCS
  const [loading, setLoading] = useState(false)
  const [files,setFiles] = useState(openSolarFiles??[])
  
  const fetchFilesProjectById = () => {
    setLoading(true)
    dispatch(callApiAction(
      async () => await getProjectsFilesByIdApi({ id: projectId }),
      (response) => {
        setFiles(response)
        setOpenSolarFiles(response)
        setLoading(false)
      },
      (err) => {
        setLoading(false)
      }
    ))
  }
  useEffect(() => {

    if (files.length == 0)
      fetchFilesProjectById(projectId)

  }, [])

 
  return <>
    <ProjectFilesUi modalKey={modalKey} files={files} loading={loading} />
  </>
}
export default OpenSolarFiles